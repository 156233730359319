import config from './configuration'
import ConstantsList from '../common/SupplierConstants';

// this block needed to get the login Token and Login user _id 
let localData = localStorage.getItem('userData');
let userData={}
if(localData){
    userData = JSON.parse(localData);
}

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

/* Service Function starts here */

// API Error Handling
async function handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
}

// API Error Handling
async function handleError(error) {
    console.log(error.message);
}

// Common GET Method 
async function callGET(api_url) {
    return fetch(api_url,{
                method:'GET',
                headers:{
                    "Content-Type":"application/json",
                    "Accept":"application/json",
                    "Authorization": 'Bearer '+ userData.token
                }
            }).then(response => {
                if (!response.ok) {
                    handleResponseError(response);
                }
                return response.json();
            }).then(items => {
                if(items.responseCode !== 401){
                    // console.log(">> GET Service Call",items);
                    return items;
                }else{
                    console.log(">> Session Timeout",items);
                    localStorage.setItem('token',''); 
                    localStorage.setItem('userData','');
                    window.location = ConstantsList.LOGIN;
                }            
            }).catch(error => {
                console.log(">> e", error);
                handleError(error);
            });
}

// Common POST Method
async function callPOST(api_url,param) {
    // console.log(">> check URL", api_url);
    if(!param){
        param = {}
    }
    param.timeZone = userTimezone;
    return await fetch(api_url,{
                method:'POST',
                headers:{
                    "Content-Type":"application/json",
                    "Accept":"application/json",
                    "Authorization": 'Bearer '+ userData.token
                },
                body:JSON.stringify(param)
            }).then(response => {
                if (!response.ok) {
                    handleResponseError(response);
                }
                return response.json();
            }).then(items => {
                // console.log(">> Service Login Data",items);
                if(items.responseCode !== 401){
                    // console.log(">> POST Service Call",items);
                    return items;
                }else{
                    console.log(">> Session Timeout",items);
                    localStorage.setItem('token',''); 
                    localStorage.setItem('userData','');
                    window.location = ConstantsList.LOGIN;
                }     
               
            }).catch(error => {
                handleError(error);
            });
}

// Common POST Method for Upload
async function callPOSTUpload(api_url,param) {
    // console.log(">>callPOSTUpload param ", param);
    return await fetch(api_url,{
                method:'POST',
                headers:{
                    // 'Content-Type': 'multipart/form-data',
                    "Authorization": 'Bearer '+ userData.token,
                },
                body:param
            }).then(response => {
                if (!response.ok) {
                    handleResponseError(response);
                }
                return response.json();
            }).then(items => {
                // console.log(">> Service Login Data",items);
                if(items.responseCode !== 401){
                    // console.log(">> POST Service Call",items);
                    return items;
                }else{
                    console.log(">> Session Timeout",items);
                    localStorage.setItem('token',''); 
                    localStorage.setItem('userData','');
                    window.location = ConstantsList.LOGIN;
                }     
               
            }).catch(error => {
                handleError(error);
            });
}

//POST - USER PROFILE IMAGE UPLOAD 
async function uploadImg(param) {
    let api_url= config.BASE_URL + 'api/uploadImg';
    return callPOSTUpload(api_url,param);
}

//POST - userUpdate Commmon
async function userUpdate(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/userUpdate";
    return callPOST(api_url, param);
}

/* ---------------------------- Common --------------------------------*/

//POST - getMasterData Commmon
async function getMasterData(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getMasterData";
    return callPOST(api_url, param);
}

//POST - getBankMasterData Commmon
async function getBankMasterData(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getBankMaster";
    return callGET(api_url, param);
}

//GET - getConstantVariable Commmon
async function getConstantVariable(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getConstantVariable";
    return callGET(api_url, param);
}

//POST - getApplicationMaster Commmon
async function getApplicationMaster(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getApplicationMaster";
    return callPOST(api_url, param);
}

//POST - getCategory Commmon
async function getCategory(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getCategory";
    return callPOST(api_url, param);
}

//POST - getSubCategory Commmon
async function getSubCategory(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getSubCategory";
    return callPOST(api_url, param);
}

//POST - getSubCategoryRoleId Commmon
async function getSubCategoryRoleId(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getSubCategoryRoleId";
    return callPOST(api_url, param);
}

//POST - addUser Commmon
async function addUser(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/addUser";
    return callPOST(api_url, param);
}

//POST - getUsersListData Commmon
async function getUsersListData(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getUserList";
    return callPOST(api_url, param);
}

//POST - getUserDetail
async function getUserDetail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getUserDetail";
    return callPOST(api_url, param);
}

// Customer List and Customer Feedback List
async function generateCustomerQuery(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/generateCustomerQuery";
    return callPOST(api_url, param);
}


/* ---------------------------- Workflow --------------------------------*/

//POST - getWorkflowInboxCards
async function getWorkflowInboxCards(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/workflowInboxCards";
    return callPOST(api_url, param);
}

//POST - getworkflowInboxList
async function getworkflowInboxList(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/workflowInboxList";
    return callPOST(api_url, param);
}

//POST - workflowApproveReject
async function workflowApproveReject(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/actionOnCustomerWorkflowEvent";
    return callPOST(api_url, param);
}

//workflowActionAPI
async function workflowActionAPI(URL, param) { // This method calling from Redux
    let api_url= config.BASE_URL + URL;
    return callPOST(api_url, param);
}

/* ---------------------------- Customer --------------------------------*/

//POST - addCustomer
async function addCustomer(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/addCustomer";
    return callPOST(api_url, param);
}

/* ---------------------------- Inquiry --------------------------------*/
//POST - addInquiry
async function addInquiry(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/addInquiry";
    return callPOST(api_url, param);
}
//POST - getInquiryDetail
async function getInquiryDetail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/getInquiryDetail";
    return callPOST(api_url, param);
}
//POST - acknowledgeInquiry
async function acknowledgeInquiry(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/acknowledgeInquiry";
    return callPOST(api_url, param);
}

/* ---------------------------- Quotation --------------------------------*/
//POST - getQuotationDatail
async function getQuotationDatail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/getQuotationDetail";
    return callPOST(api_url, param);
}

//POST - addQuotation
async function addQuotation(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/addQuotation";
    return callPOST(api_url, param);
}

/* ---------------------------- PO --------------------------------*/
//POST - getPODetail
async function getPODetail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/getPODetail";
    return callPOST(api_url, param);
}

/* ---------------------------- Sale Order --------------------------------*/
//POST - getSaleOrderDetail
async function getSaleOrderDetail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/getSaleOrderDetail";
    return callPOST(api_url, param);
}
//POST - getSODeliveryHistory
async function getSODeliveryHistory(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/getSODeliveryHistory";
    return callPOST(api_url, param);
}
//POST - getSOInvoiceHistory
async function getSOInvoiceHistory(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/getSOInvoiceHistory";
    return callPOST(api_url, param);
}

/* ---------------------------- Delivery --------------------------------*/
//POST - getDeliveryDatail
async function getDeliveryDatail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/getDeliveryDetail";
    return callPOST(api_url, param);
}
//POST - addDelivery
async function addDelivery(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/addDelivery";
    return callPOST(api_url, param);
}
//POST - getCustInvoiceDetail
async function getCustInvoiceDetail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/getCustInvoiceDetail";
    return callPOST(api_url, param);
}

/* ---------------------------- CustomerFeedback --------------------------------*/
//POST - getDeliveryNos
async function getDeliveryNos(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/getDeliveryNos";
    return callPOST(api_url, param);
}
//POST - getCustomerFeedbackDetail
async function getCustomerFeedbackDetail(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/getCustomerFeedbackDetail";
    return callPOST(api_url, param);
}
//POST - addCustomerFeedback
async function addCustomerFeedback(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/addCustomerFeedback";
    return callPOST(api_url, param);
}


/* ---------------------------- Report --------------------------------*/
//POST - generateReportQuery Commmon
async function generateReportQuery(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/generateReportQuery";
    return callPOST(api_url, param);
}




// ---------------------------------- SELF SUPPLIER REG API WITHOUT AUTH ----------------------------------------------- 
async function addSelfCustomer(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "customer/api/addSelfCustomer";
    return callPOST(api_url, param);
}
async function getMasterDataSelf(param) { // This method calling from Redux
    let api_url= config.BASE_URL + "api/getMasterDataSelf";
    return callPOST(api_url, param);
}

const func = {
    addSelfCustomer,
    getMasterDataSelf,
    getMasterData,
    getBankMasterData,    
    getConstantVariable,
    getApplicationMaster,
    getCategory,
    getSubCategory,
    getSubCategoryRoleId,
    addUser,
    getUsersListData,
    userUpdate,
    uploadImg,
    handleResponseError,
    handleError,
    callGET,
    callPOST,
    callPOSTUpload,    

    getUserDetail,

    getWorkflowInboxCards,
    getworkflowInboxList,
    workflowApproveReject,
    workflowActionAPI,

    generateCustomerQuery,
    addCustomer,

    addInquiry,
    getInquiryDetail,
    acknowledgeInquiry,
    
    getQuotationDatail,
    addQuotation,

    getPODetail,
    getSaleOrderDetail,
    getSODeliveryHistory,
    getSOInvoiceHistory,
    
    getDeliveryDatail,
    addDelivery,
    getCustInvoiceDetail,

    generateReportQuery,

    getDeliveryNos,
    getCustomerFeedbackDetail,
    addCustomerFeedback,
};

export default func;
import React, { useState, useEffect, useRef } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { getASN } from "../../../../redux/feature/supplier_app/purchasingSlice";

//API
import ItemService from "../../../../services/customer_appCommonService";

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import Constants from "../../../../common/CustomerConstants";
import POCollabrationCommonFillter from "../../../../Components/Filter/POCollabration/POCollabrationCommonFillter";
import HeaderText from "../../../../Components/Modal/supplier_app/PO_Collabration/HeaderText";
import toast from "react-hot-toast";
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";

const CreateDelivery = () => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Sales");
  localStorage.setItem("submenu", "Sales Order");

  let selectedSalesOrderDetails = useSelector(
    (state) => state?.preSales?.salesOrder?.selectedSalesOrderDetails
  ); // selectedSalesOrderDetails Details from Store

  let salesOrderID = useSelector(
    (state) => state?.preSales?.salesOrderID
  ); // purchase_order from Store

  const modalData = {
    title: "Delivery Creation",
    successMsg: "",
  };

  const [disabled, setDisabled] = useState(false);
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getModalInfo, setModalInfo] = useState(modalData);
  const [getHeaderData, setHeaderData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemTableData, setItemTableData] = useState([]);
  const [subTotalValue, setSubTotalValue] = useState(0);
  const [getOtherChargesValue, setOtherChargesValue] = useState(0);

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");
  const [getViewHeaderText, setViewHeaderText] = useState(false);
  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  // const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);
  const [isCardVisible7, setCardVisible7] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [getDisabledStatus, setDisabledStatus] = useState(true);
  const [invoiceData, setInvoiceData] = useState([]);

  const addInvoiceDocument = () => {
    // Create a new document with some data (you can customize the content)
    const newDocument = {
      id: Date.now(),
      name: `Document ${invoiceData.length + 1}`,
    };
    // Update the state with the new document
    setInvoiceData((prevData) => [...prevData, newDocument]);
  };

  const deleteInvoiceDocument = (id) => {
    // Filter out the document with the given id
    const updatedData = invoiceData.filter((doc) => doc.id !== id);
    // Update the state with the remaining documents
    setInvoiceData(updatedData);
  };

  /* radio buttons */
  const [selectedShipment, setSelectedShipment] = useState("road");

  const handleShipmentChange = (event) => {
    setSelectedShipment(event.target.value);
  };

  const [formInputs, setFormInputs] = useState({
    documentType: "DEL",
    transactionActivity: "DEL",
    salesDocumentNr: salesOrderID,
    salesDocumentDate: "",
    statusHeader: "In Transit",
    deliveryDate: "",
    despatchNr: "",
    despatchDate: "",
    customerCode: "",
    customerName: "",
    shippingType: "",
    deliveryRemark: "",
    deliveryCount: "1",
    salesDocumentValue: "",
    deliveredValue: "",
    deliveredStatus: "",
    invoicedValue: "",
    invoicedStatus: "",
    paidValue: "",
    currency: "",
    currencyCode: "",
    paymentTerms: "",
    incoTerms: "",
    incoTermsLocation: "",
    customerInvoiceNo: "",
    customerInvoiceDate: "",
    customerBasicValue: "",
    customerTaxValue: "",
    customerOtherChargesValue: "",
    customerTotalInvoiceValue: "",
    customerInvoiceCurrency: "",
    items: [
      {
        delItemNr: "",
        materialCode: "",
        materialDescription: "",
        deliveryQuantity: "",
        currency: "",
        currencyCode: "",
        orderUnitOfMeasure: "",
        deliveryStatusItem: "New",
        supplierBatchNumber: "",
        plant: "",
        storeLocation: "",
        salesDocumentNr: "",
        salesDocumentItemNr: "",
        grossWeight: "",
        deliveryDeletionIndicator: "",
        cancellationRemarks: "",
        cancelledBy: "",
        cancelledOn: "",
        cancelledTime: "",
      },
    ],
    othersCharge: [
      {
        itemIndex: "",
        documentId: "",
        documentText: "",
      },
    ],
  });

  const [getShipment, setShipment] = useState({
    road: {
      truckNo: "",
      challanDate: "",
      challanNo: "",
      dateOfDispatch: "",
      driverName: "",
      driverNumber: "",
      eWaybillValue: "",
      ewaybillNo: "",
      expectedDeliveryDate: "",
      lrDate: "",
      lrNo: "",
      modeOfPacking: "",
      noOfPackages: "",
      placeOfDelivery: "",
      placeOfLoading: "",
      transporterCourier: "",
      weightOfConsignment: "",
    },
    air: {
      hawbDate: "",
      hawbNo: "",
      NoofPackages: "",
      airportOfArrival: "",
      airportOfShipment: "",
      billOfEntryDate: "",
      billOfEntryNo: "",
      courier: "",
      dateOfDispatch: "",
      eWaybillValue: "",
      ewaybillNo: "",
      expectedDeliveryDate: "",
      mawbDate: "",
      mawbNo: "",
      transporterName: "",
      weightOfConsignment: "",
    },
    courier: {
      courierName: "",
      dateOfDispatch: "",
      docketDate: "",
      docketNo: "",
      ewaybillNo: "",
      expectedArrivalDate: "",
      modeOfPacking: "",
      noOfPackages: "",
      weightOfConsignment: "",
    },
    ship: {
      billOfLadinDate: "",
      billOfLading: "",
      bookingNumber: "",
      dateOfDispatch: "",
      internalReferenceNumber: "",
      shippineLine: "",
    },
  });

  const lineItemColInfo = [
    {
      name: "Code",
      cell: (row) => <span>{row.documentId} </span>,
      width: "200px",
    },
    {
      name: "Text",
      cell: (row) => <span>{row.documentText} </span>,
      width: "250px",
    },
  ];

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  // const toggleCardVisibility3 = () => {
  //   setCardVisible3(!isCardVisible3);
  // };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible6);
  };
  const toggleCardVisibility7 = () => {
    setCardVisible7(!isCardVisible7);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setShipment((prevData) => ({
      ...prevData,
      [selectedShipment]: {
        ...prevData[selectedShipment],
        [name]: value,
      },
    }));
  };

  // Function to handle text changes
  const handleInputChangeTable = (
    id,
    value,
    salesQuantity,
    orderPriceUnit,
    deliveryBalancePurchaseQuantity
  ) => {
    if (Number(salesQuantity) >= Number(value)) {
      let totalPriceVal = Number(orderPriceUnit) * Number(value);

      if (deliveryBalancePurchaseQuantity) {
        if (Number(value) <= Number(deliveryBalancePurchaseQuantity)) {
          const updatedData = itemTableData.map((item) => {
            return item.local_id === id
              ? {
                  ...item,
                  deliveryQuantity: value,
                  deliveryBalancePurchaseQuantity: item.delivery
                    .deliveryBalancePurchaseQuantity
                    ? Number(item.delivery.deliveryBalancePurchaseQuantity) -
                      Number(value)
                    : Number(salesQuantity) - Number(value),
                  totalPrice: totalPriceVal,
                }
              : item;
          });

          setItemTableData(updatedData);
          getTotalCount(updatedData);
        }
      } else {
        const updatedData = itemTableData.map((item) => {
          return item.local_id === id
            ? {
                ...item,
                deliveryQuantity: value,
                deliveryBalancePurchaseQuantity: (
                  Number(salesQuantity) - Number(value)
                ).toFixed(2),
                totalPrice: totalPriceVal,
              }
            : item;
        });

        setItemTableData(updatedData);
        getTotalCount(updatedData);
      }
    } else {
      // console.log("Do Nothing");
    }
  };

  // Get Total for SubTotal Pricing Schedule
  const getTotalCount = (data) => {
    const getTotal = data.reduce(
      (accumulator, currentValue) =>
        accumulator + (currentValue?.totalPrice || 0),
      0
    );
    setSubTotalValue(getTotal);
  };

  const lineItemColumns = [
    // {
    //   name: "Document Number",
    //   selector: (row) => salesOrderID,
    //   sortable: true,
    //   width: "180px",
    // },
    {
      name: "DEL Item Nr",
      selector: (row) => row.delItemNr,
      sortable: true,
      width: "130px",
    },
    {
      name: "SO Item Nr",
      selector: (row) => row.salesDocumentItemNr,
      sortable: true,
      width: "120px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <span className="bg-table-status bg-table-info-status">{"New"}</span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Item Text",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.itemTextData)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1">{row.materialCode} </p>
            <p className="m-0 p-0">{row.materialDescription} </p>
          </div>
        </>
      ),
      width: "180px",
      sortable: true,
    },

    // {
    //   name: "Material Desc",
    //   selector: (row) => row.materialDescription,
    //   cell: (row) => (
    //     <>
    //       <p>{row.materialDescription}</p>
    //     </>
    //   ),
    //   sortable: true,
    //   width: "150px",
    // },

    // {
    //   name: "Status",
    //   cell: (row) => (
    //     <>
    //       {row.statusItem === "new" ? (
    //         <span className="bg-table-status bg-table-info-status">
    //           {row.statusItem}
    //         </span>
    //       ) : row.statusItem !== "new" ? (
    //         <span className="bg-table-status bg-table-success-status">
    //           {row.statusItem}
    //         </span>
    //       ) : (
    //         "-"
    //       )}
    //     </>
    //   ),
    //   width: "150px",
    // },
    {
      name: "Sales Qty",
      selector: (row) => row.salesQuantity,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="salesQuantity"
            value={row.salesQuantity}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Delivery Qty",
      cell: (row) => (
        <>
          <InputField
            type="number"
            className="inputBox mt-1"
            id="deliveryQuantity"
            name="deliveryQuantity"
            value={row.deliveryQuantity || 0}
            onChange={(e) =>
              handleInputChangeTable(
                row.local_id,
                e.target.value,
                row.salesQuantity,
                row.orderPriceUnit,
                row?.delivery?.deliveryBalancePurchaseQuantity
              )
            }
            disabled={row?.delivery?.deliveryBalancePurchaseQuantity === 0 ? true : false}
          />
        </>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "Balance Qty",
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="deliveryBalancePurchaseQuantity"
            value={Number(row?.deliveryBalancePurchaseQuantity) || 0}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "Oder Unit",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "120px",
    },
    {
      name: "Unit Price",
      selector: (row) => row.orderPriceUnit,
      sortable: true,
      width: "120px",
    },
    {
      name: "Item Value",
      selector: (row) => row.totalPrice,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="totalPrice"
            value={row.totalPrice || 0}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode,
      sortable: true,
      width: "120px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      sortable: true,
      width: "140px",
    },    
    {
      name: "Tax Code",
      selector: (row) => row.taxCode,
      sortable: true,
      width: "200px",
    },
    {
      name: "Tax Value",
      selector: (row) => row.taxPrice,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="taxPrice"
            value={row.taxPrice || 0}
            disabled={true}
          />
        </>
      ),
      sortable: true,
      width: "120px",
    },
    // {
    //   name: "Tax Desc",
    //   selector: (row) => row.taxIdDesc,
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "Free Of Cost",
    //   selector: (row) => row.freeCost,
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "PReq Nr",
    //   selector: (row) => row.purchaseReqNr,
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "PReq Item Nr ",
    //   selector: (row) => row.purchaseReqitemNr,
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "ExcessTolerance",
    //   selector: (row) => row.excessTolerance,
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "ShortageTolerance",
    //   selector: (row) => row.shortageTolerance,
    //   sortable: true,
    //   width: "200px",
    // },
  ];

  // Core Logic
  const loadInitalPageData = async () => {
    setLoading(true);
    if (salesOrderID) {      
      setHeaderData(selectedSalesOrderDetails);
      let itemData = selectedSalesOrderDetails?.items;

      const updateditemData = itemData?.map((obj, index) => ({
        ...obj,
        delItemNr: index + 1,
        salesDocumentNr: salesOrderID,
        statusItem: "In Transit",
      }));

      setItemTableData(updateditemData);
      setLoading(false);
    } else {
      navigate(Constants.SALES_DELIVERY_LIST);
    }
  };

  useEffect(() => {
    loadInitalPageData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesOrderID, selectedSalesOrderDetails]);

  /* Shipment details fields */
  const renderShipmentDetails = () => {
    switch (selectedShipment) {
      case "road":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Road Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Truck No"
                    name="truckNo"
                    id="truckNo"
                    value={getShipment?.road?.truckNo}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Transporter/Courier"
                    name="transporterCourier"
                    id="transporterCourier"
                    value={getShipment?.road?.transporterCourier}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    id="dateOfDispatch"
                    value={getShipment?.road?.dateOfDispatch}
                    onChange={handleRadioChange}
                    type="date"
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="LR No"
                    name="lrNo"
                    id="lrNo"
                    value={getShipment?.road?.lrNo}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="LR Date"
                    name="lrDate"
                    id="lrDate"
                    value={getShipment?.road?.lrDate}
                    type="date"
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Expected Delivery Date"
                    name="expectedDeliveryDate"
                    id="expectedDeliveryDate"
                    value={getShipment?.road?.expectedDeliveryDate}
                    onChange={handleRadioChange}
                    type="date"
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="EWaybill No"
                    name="ewaybillNo"
                    id="ewaybillNo"
                    value={getShipment?.road?.ewaybillNo}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E Waybill Value"
                    name="eWaybillValue"
                    id="eWaybillValue"
                    value={getShipment?.road?.eWaybillValue}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Challan No"
                    name="challanNo"
                    id="challanNo"
                    value={getShipment?.road?.challanNo}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Challan Date"
                    name="challanDate"
                    id="challanDate"
                    type="date"
                    value={getShipment?.road?.challanDate}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="No. of Packages"
                    name="noOfPackages"
                    id="noOfPackages"
                    value={getShipment?.road?.noOfPackages}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Weight of Consignment"
                    name="weightOfConsignment"
                    id="weightOfConsignment"
                    value={getShipment?.road?.weightOfConsignment}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Place of Loading"
                    name="placeOfLoading"
                    id="placeOfLoading"
                    value={getShipment?.road?.placeOfLoading}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Place of Delivery"
                    name="placeOfDelivery"
                    id="placeOfDelivery"
                    value={getShipment?.road?.placeOfDelivery}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Mode of Packing"
                    name="modeOfPacking"
                    id="modeOfPacking"
                    value={getShipment?.road?.modeOfPacking}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Driver Name"
                    name="driverName"
                    id="driverName"
                    value={getShipment?.road?.driverName}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Driver Number"
                    name="driverNumber"
                    id="driverNumber"
                    value={getShipment?.road?.driverNumber}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      case "air":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Air Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    id="dateOfDispatch"
                    value={getShipment?.air?.dateOfDispatch}
                    onChange={handleRadioChange}
                    disabled={false}
                    type="date"
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="MAWB No"
                    name="mawbNo"
                    id="mawbNo"
                    value={getShipment?.air?.mawbNo}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="MAWB Date"
                    name="mawbDate"
                    type="date"
                    id="mawbDate"
                    value={getShipment?.air?.mawbDate}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Airport of Shipment"
                    name="airportOfShipment"
                    id="airportOfShipment"
                    value={getShipment?.air?.airportOfShipment}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Airport of Arrival"
                    name="airportOfArrival"
                    id="airportOfArrival"
                    value={getShipment?.air?.airportOfArrival}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Transporter Name"
                    name="transporterName"
                    id="transporterName"
                    value={getShipment?.air?.transporterName}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E waybill No"
                    name="ewaybillNo"
                    id="ewaybillNo"
                    value={getShipment?.air?.ewaybillNo}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Expected Deilevery Date"
                    name="expectedDeliveryDate"
                    id="expectedDeliveryDate"
                    value={getShipment?.air?.expectedDeliveryDate}
                    onChange={handleRadioChange}
                    type="date"
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E Waybill Value"
                    name="eWaybillValue"
                    id="eWaybillValue"
                    value={getShipment?.air?.eWaybillValue}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Courier"
                    name="courier"
                    id="courier"
                    value={getShipment?.air?.courier}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Entry No"
                    name="billOfEntryNo"
                    id="billOfEntryNo"
                    value={getShipment?.air?.billOfEntryNo}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Entry Date"
                    name="billOfEntryDate"
                    id="billOfEntryDate"
                    value={getShipment?.air?.billOfEntryDate}
                    type="date"
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="HAWB No"
                    name="hawbNo"
                    id="hawbNo"
                    value={getShipment?.air?.hawbNo}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="HAWB Date"
                    name="hawbDate"
                    id="hawbDate"
                    value={getShipment?.air?.hawbDate}
                    type="date"
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="No. of Packages"
                    name="NoofPackages"
                    id="noOfPackages"
                    value={getShipment?.air?.noOfPackages}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Weight of Consignment"
                    name="weightOfConsignment"
                    id="weightOfConsignment"
                    value={getShipment?.air?.weightOfConsignment}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      case "courier":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Courier Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    id="dateOfDispatch"
                    value={getShipment?.courier?.dateOfDispatch}
                    onChange={handleRadioChange}
                    disabled={false}
                    type="date"
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Courier Name"
                    name="courierName"
                    id="courierName"
                    value={getShipment?.courier?.courierName}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Docket No"
                    name="docketNo"
                    id="docketNo"
                    value={getShipment?.courier?.docketNo}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Docket Date"
                    name="docketDate"
                    type="date"
                    id="docketDate"
                    value={getShipment?.courier?.docketDate}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E Waybill No"
                    name="ewaybillNo"
                    id="ewaybillNo"
                    value={getShipment?.courier?.ewaybillNo}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Expected Arrival Date"
                    name="expectedArrivalDate"
                    type="date"
                    id="expectedArrivalDate"
                    value={getShipment?.courier?.expectedArrivalDate}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="No. of Packages"
                    name="noOfPackages"
                    id="noOfPackages"
                    value={getShipment?.courier?.noOfPackages}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Weight of Consignment"
                    name="weightOfConsignment"
                    id="weightOfConsignment"
                    value={getShipment?.courier?.weightOfConsignment}
                    onChange={handleRadioChange}
                    // type="date"
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Mode of Packing"
                    name="modeOfPacking"
                    id="modeOfPacking"
                    value={getShipment?.courier?.modeOfPacking}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      case "ship":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Ship Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Lading"
                    name="billOfLading"
                    id="billOfLading"
                    value={getShipment?.ship?.billOfLading}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Ladin Date"
                    name="billOfLadinDate"
                    id="billOfLadinDate"
                    type="date"
                    value={getShipment?.ship?.billOfLadinDate}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Shippine Line"
                    name="shippineLine"
                    id="shippineLine"
                    value={getShipment?.ship?.shippineLine}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Internal Reference Number"
                    name="internalReferenceNumber"
                    id="internalReferenceNumber"
                    value={getShipment?.ship?.internalReferenceNumber}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Booking Number"
                    name="bookingNumber"
                    id="bookingNumber"
                    value={getShipment?.ship?.bookingNumber}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    type="date"
                    id="dateOfDispatch"
                    value={getShipment?.ship?.dateOfDispatch}
                    onChange={handleRadioChange}
                    disabled={false}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  // const [getHeaderTextIndex, setHeaderTextIndex] = useState(0);
  const [HeaderTextModel, setHeaderTextModel] = useState(false);

  /* Header text selected data */
  const [getHeaderTextSelectedData, setHeaderTextSelectedData] = useState([]);
  const handleHeaderTextSelectedData = (data) => {
    setHeaderTextSelectedData(data);
  };

  useEffect(() => {
    const getTotal = getHeaderTextSelectedData.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue?.documentText || 0),
      0
    );
    setOtherChargesValue(getTotal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHeaderTextSelectedData]);

  /* Create Header Text Modal Function */
  const showsetHeaderText = () => {
    setHeaderTextModel(true);
  };
  const hideHeaderTextModel = () => {
    setHeaderTextModel(false);
  };

  const HeaderTextColumns = [
    {
      name: "Code",
      cell: (row) => (
        <>
          <span>{row.documentId}</span>
        </>
      ),
      width: "30rem",
    },
    {
      name: "Value",
      cell: (row) => (
        <>
          <span>{row.documentText}</span>
        </>
      ),
      width: "30rem",
    },
    // {
    //   name: "Delete",
    //   cell: (row, index) => (
    //     <>
    //       <span>
    //         <i
    //           onClick={() => handleDelete(index)}
    //           className="bi bi-trash text-danger fs-3"
    //         ></i>
    //       </span>
    //     </>
    //   ),
    //   width: "10rem",
    // },
  ];

  // SAVE
  const handleSave = async () => {
    // let requiredFields = [];

    // if(!validateRequired(formInputs?.organisationName)) {
    //   requiredFields.push('*Please enter Supplier Name');
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   return;
    // }

    // if (!validateEmail(formInputs?.email)) {
    //   requiredFields.push('*Invalid Contact Email address');
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   return;
    // }

    // if (!getPOLineItemsData[0]?.materialCode) {
    //   toast.error("Alteast one PO Line item should be added.", {
    //     duration: 4000,
    //     position: "top-right",
    //   });
    //   return;
    // }

    formInputs[selectedShipment] = getShipment[selectedShipment];
    formInputs.othersCharge = getHeaderTextSelectedData;

    const userEnteredItemTableData = itemTableData.filter(
      (item) => item.deliveryQuantity != null
    );
    formInputs.items = userEnteredItemTableData;

    formInputs.shippingType = selectedShipment;
    formInputs.customerCode = getHeaderData.customerCode;
    formInputs.customerName = getHeaderData.customerName;
    formInputs.deliveredValue = getHeaderData.deliveredValue;
    formInputs.deliveredStatus = getHeaderData.deliveredStatus;
    formInputs.invoicedValue = getHeaderData.invoicedValue;
    formInputs.invoicedStatus = getHeaderData.invoicedStatus;
    formInputs.paidValue = getHeaderData.paidValue;
    formInputs.customerInvoiceCurrency = getHeaderData.currencyCode;
    formInputs.salesDocumentDate = getHeaderData.salesDocumentDate;
    formInputs.headerTextData = getHeaderData.headerTextData;
    formInputs.currency = getHeaderData.currency;
    formInputs.currencyCode = getHeaderData.currencyCode;
    formInputs.incoTerms = getHeaderData.incoTerms;
    formInputs.paymentTerms = getHeaderData.paymentTerms;

  
    

    formInputs.customerBasicValue = subTotalValue;
    formInputs.customerOtherChargesValue = getOtherChargesValue;
    formInputs.customerTotalInvoiceValue = (
      Number(subTotalValue || 0) +
      Number(getOtherChargesValue || 0) +
      Number(formInputs.customerTaxValue || 0) +
      Number(formInputs.customerFreightValue || 0)
    ).toFixed(2);

    // console.log(">> handleSave", formInputs);

    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });

    let obj = {
      data: formInputs,
    };

    setDisabled(true);
    await ItemService.addDelivery(obj).then((item) => {
      // console.log(">> res!", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        setModalInfo(modalData);
        onSuccessfullyRegistered();

        // Update response value in Redux Store
        dispatch(getASN());
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);
    setDisabled(false);
  };

  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Line Item Details");
    setItemLevelModalShow(true);
  };
  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };

  // OnClick on Item Text  Data
  const headerTextShowModal = (headerTextData) => {
    setItemLevelData(headerTextData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Header Text Details");
    setViewHeaderText(true);
  };
  const headerTextHideModal = () => {
    setViewHeaderText(false);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide quoation">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">
              Create Delivery for{" "}
              <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                {salesOrderID}
              </span>
              <span>
                {getHeaderData?.statusHeader === "New" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {getHeaderData?.statusHeader}
                  </span>
                ) : getHeaderData?.statusHeader === "In Release" ? (
                  <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                    {getHeaderData?.statusHeader}
                  </span>
                ) : getHeaderData?.statusHeader === "Acknowledged" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {getHeaderData?.statusHeader}
                  </span>
                ) : (
                  getHeaderData?.statusHeader
                )}
              </span>
            </h2>
            <p className="subText">Fill Deilevery Details</p>
            <hr />

            {/*Document Header Details */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">DH</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">
                        {" "}
                        Document Header Details
                      </span>
                      <span className="subHeaderTitle">
                        Purchase Order Header Details
                      </span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Document Number"
                          name="salesDocumentNr"
                          placeholder=""
                          value={getHeaderData?.salesDocumentNr}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Status:"
                          name="statusHeader"
                          placeholder=""
                          value={getHeaderData?.statusHeader}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col> */}

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Document Date"
                          name="salesDocumentDate"
                          placeholder=""
                          value={getHeaderData?.salesDocumentDate}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      {/* {getHeaderData?.salesDocumentValue && ( */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Net Value"
                          name="salesDocumentValue"
                          placeholder=""
                          value={getHeaderData?.salesDocumentValue}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      {/* )} */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency"
                          name="currency"
                          placeholder=""
                          value={`${getHeaderData?.currencyCode} - ${getHeaderData?.currency}`}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivered Value:"
                          name="deliveredValue"
                          placeholder=""
                          id="deliveredValue"
                          value={formInputs.deliveredValue}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Delivered Status"
                          name="deliveredStatus"
                          placeholder=""
                          id="deliveredStatus"
                          value={formInputs.deliveredStatus}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoiced Value:"
                          name="invoicedValue"
                          placeholder=""
                          id="invoicedValue"
                          value={formInputs.invoicedValue}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoiced Status"
                          name="invoicedStatus"
                          placeholder=""
                          id="invoicedStatus"
                          value={formInputs.invoicedStatus}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Paid Value"
                          name="paidValue"
                          placeholder=""
                          id="paidValue"
                          value={formInputs.paidValue}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col> */}

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer Code"
                          name="customerCode"
                          placeholder=""
                          value={getHeaderData?.customerCode}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer Name"
                          name="customerName"
                          placeholder=""
                          value={getHeaderData?.customerName}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Inco Terms"
                          name="incoTerms"
                          id="incoTerms"
                          value={getHeaderData?.incoTerms}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Payment Terms"
                          name="incopaymentTermsTerms"
                          id="paymentTerms"
                          value={getHeaderData?.paymentTerms}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Code "
                          name="companyCodeCode"
                          id="companyCodeCode"
                          value={getHeaderData?.companyCodeCode}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Remark"
                          name="salesRemark"
                          value={formInputs.salesRemark}
                          onChange={onInputChange}
                          disabled={false}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding mt-4 pt-3"
                      >
                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion">
                            Header Text
                          </label>
                          <span
                            style={{ marginLeft: "15px" }}
                            onClick={() =>
                              headerTextShowModal(getHeaderData?.headerTextData)
                            }
                          >
                            <i className="bi bi-calendar2-week iconTable"></i>
                          </span>
                          <span className="suppliersIcon">
                            {" "}
                            {getHeaderData?.headerTextData?.length || 0}{" "}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Price Shedule / Line Item */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PI</span>
                    </Col>
                    <Col xs={8} md={6} lg={6}>
                      <span className="headerTitle">
                        Price Shedule / Line Item
                      </span>
                      <span className="subHeaderTitle">
                        Price Shedule / Line Item
                      </span>
                    </Col>
                    <Col xs={4} md={2} lg={2} className="">
                      <button className="btnTable">
                        <i className="bi bi-file-excel"></i>Template
                      </button>
                    </Col>
                    <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-file-excel"></i> Upload
                      </button>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={lineItemColumns}
                          data={itemTableData}
                          progressPending={loading}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* other changes table */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible7
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility7}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OC</span>
                    </Col>

                    <Col xs={5} md={9} lg={9}>
                      <span className="headerTitle">Other Charges</span>
                      <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <button className="btnTable" onClick={showsetHeaderText}>
                        <i className="bi bi-plus-lg"></i>
                      </button>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible7 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={HeaderTextColumns}
                          data={getHeaderTextSelectedData}
                          // progressPending={loading}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Pricing Summary */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible6
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility6}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PS</span>
                    </Col>
                    <Col xs={8} md={4} lg={4}>
                      <span className="headerTitle">Pricing Summary</span>
                      <span className="subHeaderTitle">Pricing Summary </span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible6 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body p-2">
                    <Row className=" ms-3 ">
                      <Col
                        xs={8}
                        md={4}
                        lg={4}
                        className="d-flex my-2 jsutify-content-center align-items-center"
                      >
                        <p className="fw-medium">
                          SubTotal Pricing Schedule / Line Items
                        </p>
                      </Col>
                      <Col
                        xs={4}
                        md={6}
                        lg={6}
                        className="d-flex my-2 jsutify-content-center align-items-center"
                      >
                        <InputField
                          className="inputBox"
                          name="subTotalValue"
                          placeholder=""
                          value={subTotalValue}
                          disabled={true}
                        />
                        {/* <p className="ms-4 fw-medium"> AED</p> */}
                      </Col>
                      <Col
                        xs={8}
                        md={4}
                        lg={4}
                        className="d-flex my-2 jsutify-content-center align-items-center "
                      >
                        <p className="fw-medium"> SubTotal Other Charges</p>
                      </Col>
                      <Col
                        xs={4}
                        md={7}
                        lg={7}
                        className="d-flex my-2 jsutify-content-center align-items-center "
                      >
                        <InputField
                          className="inputBox"
                          name="getOtherChargesValue"
                          placeholder=""
                          value={getOtherChargesValue}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={8}
                        md={4}
                        lg={4}
                        className="d-flex my-2 jsutify-content-center align-items-center"
                      >
                        <p className="fw-medium">Gross Total</p>
                      </Col>
                      <Col
                        xs={4}
                        md={7}
                        lg={7}
                        className="d-flex jsutify-content-center align-items-center"
                      >
                        <InputField
                          className="inputBox"
                          name="grossTotalValue"
                          placeholder=""
                          value={subTotalValue + getOtherChargesValue}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* radio buttons */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">MS</span>
                    </Col>

                    <Col xs={4} md={8} lg={4}>
                      <span className="headerTitle">Mode of Shipment</span>
                      <span className="subHeaderTitle">Shipment Details</span>
                    </Col>
                    <Col xs={4} md={1} lg={4}></Col>
                    <Col xs={1} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <div className="d-flex fs-5 fw-medium ms-3 my-3">
                          <div className="d-flex align-items-center me-3">
                            <div className="form-check">
                              <input
                                ref={inputRef}
                                className="form-check-input me-2"
                                type="radio"
                                value="road"
                                checked={selectedShipment === "road"}
                                onChange={handleShipmentChange}
                              />
                              <label
                                className="form-check-label fs-5"
                                htmlFor="shipment1"
                              >
                                Road
                              </label>
                            </div>
                          </div>
                          <div className="d-flex align-items-center me-3">
                            <div className="form-check">
                              <input
                                ref={inputRef}
                                className="form-check-input me-2"
                                type="radio"
                                value="air"
                                checked={selectedShipment === "air"}
                                onChange={handleShipmentChange}
                              />
                              <label
                                className="form-check-label fs-5"
                                htmlFor="shipment1"
                              >
                                Air
                              </label>
                            </div>
                          </div>
                          <div className="d-flex align-items-center me-3">
                            <div className="form-check">
                              <input
                                ref={inputRef}
                                className="form-check-input me-2"
                                type="radio"
                                value="courier"
                                checked={selectedShipment === "courier"}
                                onChange={handleShipmentChange}
                              />
                              <label
                                className="form-check-label fs-5"
                                htmlFor="shipment1"
                              >
                                Courier
                              </label>
                            </div>
                          </div>
                          <div className="d-flex align-items-center me-2">
                            <div className="form-check">
                              <input
                                ref={inputRef}
                                className="form-check-input me-2"
                                type="radio"
                                value="ship"
                                checked={selectedShipment === "ship"}
                                onChange={handleShipmentChange}
                              />
                              <label
                                className="form-check-label fs-5"
                                htmlFor="shipment1"
                              >
                                Ship
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>{renderShipmentDetails()}</div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* invoice details */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">ID</span>
                    </Col>

                    <Col xs={4} md={8} lg={8}>
                      <span className="headerTitle"> Invoice details</span>
                      <span className="subHeaderTitle">Invoice details</span>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="">
                      <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <div className="accordion-body">
                          <Row className="pt-2 pb-4">
                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Invoice Number *"
                                name="customerInvoiceNo"
                                id="customerInvoiceNo"
                                value={formInputs.customerInvoiceNo}
                                onChange={onInputChange}
                                disabled={false}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Invoice Date*"
                                name="customerInvoiceDate"
                                id="customerInvoiceDate"
                                type="date"
                                value={formInputs.customerInvoiceDate}
                                onChange={onInputChange}
                                disabled={false}
                              />
                            </Col>

                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Basic Value *"
                                name="customerBasicValue"
                                placeholder="0.00"
                                id="customerBasicValue"
                                value={subTotalValue.toFixed(2)}
                                disabled={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Tax Amount"
                                name="customerTaxValue"
                                placeholder="0.00"
                                id="customerTaxValue"
                                value={formInputs.customerTaxValue}
                                onChange={onInputChange}
                                disabled={false}
                              />
                            </Col>

                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Other Charges"
                                name="customerOtherChargesValue"
                                placeholder="0.00"
                                id="customerOtherChargesValue"
                                value={getOtherChargesValue.toFixed(2)}
                                disabled={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Freight Value"
                                name="customerFreightValue"
                                placeholder="0.00"
                                id="customerFreightValue"
                                value={formInputs.customerFreightValue}
                                onChange={onInputChange}
                                disabled={false}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Total Invoice Value "
                                name="customerTotalInvoiceValue "
                                placeholder="0.00"
                                id="customerTotalInvoiceValue"
                                value={(
                                  Number(subTotalValue || 0) +
                                  Number(getOtherChargesValue || 0) +
                                  Number(formInputs.customerTaxValue || 0) +
                                  Number(formInputs.customerFreightValue || 0)
                                ).toFixed(2)}
                                // onChange={onInputChange}
                                disabled={true}
                              />
                            </Col>
                          </Row>
                          <hr className="mx-4 mb-3" />
                          <Row className="commLeftRightPadding">
                            <Col md={{ span: 3, offset: 9 }}>
                              <button
                                className="btn w-full"
                                onClick={addInvoiceDocument}
                              >
                                Add Document
                              </button>
                            </Col>
                          </Row>

                          <div className="d-flex-column justify-content-center">
                            <Row>
                              <Col xs={6} md={4} lg={4}>
                                <p className="fs-6 fw-medium d-flex justify-content-center w-25">
                                  File
                                </p>
                              </Col>
                              <Col xs={5} md={4} lg={4}>
                                <p className="fs-6 fw-medium d-flex justify-content-center w-25">
                                  Description
                                </p>
                              </Col>
                              <Col xs={1} md={4} lg={4}>
                                <p className="fs-6 fw-medium d-flex justify-content-center w-25">
                                  Action
                                </p>
                              </Col>
                            </Row>
                            {invoiceData.map((doc, index) => (
                              <Row key={index}>
                                <Col xs={12} md={12} lg={12}>
                                  <hr className="mx-4 my-0 text-color-black" />
                                </Col>
                                <Col
                                  xs={5}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    // label="TECHNICAL PROPOSAL"
                                    type="file"
                                    name="description"
                                    placeholder=""
                                    // value={formInputs.firstName || ""}
                                    onChange={onInputChange}
                                  />
                                </Col>
                                <Col
                                  xs={5}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <InputField
                                    className="inputBox"
                                    type="text"
                                    name="description"
                                    placeholder="description"
                                    // value={formInputs.firstName || ""}
                                    onChange={onInputChange}
                                  />
                                </Col>
                                <Col
                                  xs={2}
                                  md={4}
                                  lg={4}
                                  className="commTopButtonRightLeftPadding"
                                >
                                  <i
                                    onClick={() =>
                                      deleteInvoiceDocument(doc.id)
                                    }
                                    className="bi bi-trash text-danger fs-4"
                                  />
                                </Col>
                              </Row>
                            ))}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="pageFooter">
        <Row className="rowDirection">
          <Col xs={12} md={2} lg={2} className="commTopButtonRightLeftPadding">
            <button
              className="btn w-full"
              onClick={handleSave}
              disabled={disabled}
            >
              <i className="bi bi-save"></i> Create
            </button>
          </Col>
          <Col xs={12} md={2} lg={2} className="commTopButtonRightLeftPadding">
            <button
              className="btn w-full"
              //  onClick={handleModify}
            >
              <i className="bi bi-pencil-square"></i> Modify
            </button>
          </Col>
        </Row>
      </div>

      {/* Header Text */}
      <HeaderText
        index={0}
        headername="Add Other Charges"
        show={HeaderTextModel}
        onHide={hideHeaderTextModel}
        hedertextdata={handleHeaderTextSelectedData}
        showcurrentdata={getHeaderTextSelectedData}
        masterdata="OtherChargesID"
        type="Number"
      />

      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={Constants.SALES_DELIVERY_LIST}
      />

      {/* Item Text  */}
      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />

      {/* Header Text  */}
      <CommonItemLevelDatatableModal
        show={getViewHeaderText}
        onHide={() => headerTextHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />
    </main>
  );
};

export default CreateDelivery;

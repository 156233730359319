import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import ItemService from "../services/supplier_appCommonService";
import Page401 from "./Page401";
import Loader from "../Components/Loader";
import ConstantsList from "../common/SupplierConstants";
import PasswordChecklist from "react-password-checklist";
import { jwtDecode } from "jwt-decode";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getQueryParam = (param) => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
  };

  const token = getQueryParam("token");

  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);

  const [getValidToken, setValidToken] = useState(false);
  const [getInValidToken, setInValidToken] = useState(false);
  const [getLoadingStatus, setLoadingStatus] = useState(true);

  const [loading, setLoading] = useState(false);
  const [getPasswordValidation, setPasswordValidation] = useState(false);
  const [getAllPassValStatus, getallPassValStatus] = useState(false);

  const handleChangePassword = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setPasswordValidation(false);
    } else {
      setPasswordValidation(true);
    }

    setPasswords({ ...passwords, [name]: value });
  };

  const onInputChangePassword = ({ target: { name, value } }) => {
    setPasswords((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!getAllPassValStatus) {
      toast.error("Password should accept all validation", {
        duration: 3000,
        position: "top-right",
      });
      return;
    }
    if (passwords.newPassword !== passwords.confirmPassword) {
      toast.error("Password not matched", {
        duration: 3000,
        position: "top-right",
      });
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const payload = {
        email: decodedToken.email,
        newPassword: passwords.newPassword
      };

      setLoading(true);
      const response = await ItemService.resetPassword(payload, token);

      if (response?.status === 200) {
        toast.success(response?.message, { position: "top-right" });
        navigate(ConstantsList.LOGIN);
      } else {
        toast.error(response?.message || "Invalid Token", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error submitting password change:", error);
    } finally {
      setLoading(false);
    }
  };

  //Verify the token
  const getValidationStatus = () => {
    console.log(">> Token >> ", token);

    ItemService.validateToken(token).then((item) => {
      // console.log('>> item >>', item);
      if (item?.status === 200) {
        setValidToken(true);
        setLoadingStatus(false);
      } else {
        setInValidToken(true);
        setLoadingStatus(false);
      }
    });
  };

  useEffect(() => {
    getValidationStatus();
    console.log(">> Token >> ", token);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="reset-password py-0">
      <Loader isLoading={getLoadingStatus} />

      {getValidToken ? (
        <>
          <div className="row">
            <div className="col-md-12 col-sm-12 d-flex flex-column resetPageHeader">
              <h1 className="">{process.env.REACT_APP_LOGIN_HEADER_TITLE}</h1>
            </div>
          </div>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={6}>
                <div className="text-center mb-4 mt-4">
                  <h3>Reset Your Password</h3>
                  <p>Securely update your account credentials</p>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter new password"
                        name="newPassword"
                        value={passwords.newPassword}
                        onChange={handleChangePassword}
                        required
                      />
                      <button
                        type="button"
                        aria-label="Toggle password visibility"
                        className="eyeCss absolute inset-y-0 right-0 flex items-center justify-center pr-3 h-full"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <i className="bi bi-eye-fill"></i>
                        ) : (
                          <i className="bi bi-eye-slash-fill"></i>
                        )}
                      </button>
                    </InputGroup>

                    {getPasswordValidation && (
                      <PasswordChecklist
                        rules={[
                          "minLength",
                          "specialChar",
                          "number",
                          "capital",
                        ]}
                        minLength={5}
                        value={passwords.newPassword}
                        onChange={(isValid) => {
                          getallPassValStatus(isValid);
                        }}
                        className="passwordValidation"
                      />
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={confirmShowPassword ? "text" : "password"}
                        placeholder="Confirm new password"
                        name="confirmPassword"
                        value={passwords.confirmPassword}
                        onChange={onInputChangePassword}
                        required
                      />
                      <button
                        type="button"
                        aria-label="Toggle password visibility"
                        className="eyeCss absolute inset-y-0 right-0 flex items-center justify-center pr-3 h-full"
                        onClick={() =>
                          setConfirmShowPassword(!confirmShowPassword)
                        }
                      >
                        {confirmShowPassword ? (
                          <i className="bi bi-eye-fill"></i>
                        ) : (
                          <i className="bi bi-eye-slash-fill"></i>
                        )}
                      </button>
                    </InputGroup>
                  </Form.Group>

                  <div className="d-grid">
                    <Button variant="primary" type="submit" disabled={loading}>
                      Change Password
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </>
      ) : getInValidToken ? (
        <Col xs={12}>
          <Page401 />
        </Col>
      ) : null}
    </div>
  );
};

export default ResetPassword;

import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
// import { useNavigate } from "react-router-dom";

// Components
// import { ComboboxField } from "../../formElements/ComboboxField";
// import Constants from "../../../../common/CustomerConstants";

//Serivce
// import ItemService from "../../../services/supplier_appCommonService";

//Redux
// import { useDispatch } from "react-redux";
// import { addAccountGroup } from "../../../../redux/feature/supplier_app/supplierSlice";
// import { ComboboxField } from "../../../formElements/ComboboxField";
import { InputField } from "../../../formElements/InputField";

const CatalogEditMaterialModal = React.forwardRef((props, ref) => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  // const [errorMsg, setErrorMsg] = useState("");
  // const [getDropdownOption, setDropdownOption] = useState([]);
  // const [getDropdownData, setDropdownData] = useState("");

  // Handle Submit IviteTeamMembers
  // const handleSubmit = async () => {
    // if (getDropdownData?.name === "") {
    //   setErrorMsg("* Fields are mandatory");
    //   return;
    // } else {
    //   setErrorMsg("");
    // }

    // const data = {
    //   supplierCategory: formInputs.supplierCategory
    // };
    // console.log(">> data ", data);

    // dispatch(addAccountGroup(getDropdownData?.name));
    // navigate(Constants.CUSTOMER_MASTER_CREATE);
    // const toastId = toast.loading("Loading...", {
    //   position: "top-right",
    // });

    // setDisabled(true);
    // await ItemService.inviteTeamMembers(data).then((item) => {
    //   // console.log('>> item!',item);
    //   if (item?.status === 200) {
    //     toast.success(item?.message || "User Invited successfully", {
    //       duration: 2000,
    //       position: "top-right",
    //     });
    //     setErrorMsg("");

    //     // Update response value in Redux Store
    //     dispatch(getTeamData(compIdObj));
    //   } else {
    //     toast.error(item?.message || "Please try again !!", {
    //       duration: 4000,
    //       position: "top-right",
    //     });
    //     setErrorMsg("*" + item?.message);
    //   }
    //   setDisabled(false);
    // });
    // toast.dismiss(toastId);
  // };

  useEffect(() => {
    // console.log(">> props?.dropdownData", props?.dropdownData);
    // setDropdownOption(props?.dropdownData);

    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  // const onInputChange = ({ target: { name, value } }) => {
  //   setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  // };
  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  return (
    // backdrop="static"
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="xl"
      ref={ref}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {/* <i className="bi bi-plus-lg"></i>  */}
         Modify Material Details
          {/* <p className="subText">
            At supplier_app, every person has a role, each with its own level of
            access.
          </p> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div
          xs={12}
          md={6}
          lg={6}
          className="commLeftRightPadding d-flex align-items-end mb-4"
        >
          <img className="me-4" src="assets/img/profile-img1.png" alt="" />
          <InputField
            placeholder=""
            className="inputBox"
            label=""
            id="updateImage"
            name="updateImage"
            type="file"
            // value={formInputs.password || ""}
            // onChange={onInputChange}
          />
        </div>
         {/* accordin1 */}
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible1 ? "bi-chevron-down" : "bi-chevron-right"
                    }`}
                    onClick={toggleCardVisibility1}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">BI</span>
                </Col>
                <Col xs={8} md={9} lg={9}>
                  <span className="headerTitle">Basic Information</span>
                  <span className="subHeaderTitle">Basic Infromation</span>
                </Col>
              </Row>
            </h2>

            <div
              className={`accordion-collapse collapse show ${
                isCardVisible1 ? "visible" : ""
              }`}
            >
              <div className="accordion-body">
                <Row className="pt-2 pb-4 d-flex align-items-center">
                  <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
                    <InputField
                      placeholder=""
                      className="inputBox"
                      label="Product Name"
                      id="productName"
                      name="productName"
                      type="text"
                      // value={formInputs.password || ""}
                      // onChange={onInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
                    <InputField
                      placeholder=""
                      className="inputBox"
                      label="Product Number"
                      id="productNumber"
                      name="productNumber"
                      type="text"
                      // value={formInputs.password || ""}
                      // onChange={onInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
                    <InputField
                      placeholder=""
                      className="inputBox"
                      label="Price"
                      id="price"
                      name="price"
                      type="text"
                      // value={formInputs.password || ""}
                      // onChange={onInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
                    <InputField
                      placeholder=""
                      className="inputBox"
                      label="Description"
                      id="description"
                      name="description"
                      type="text"
                      // value={formInputs.password || ""}
                      // onChange={onInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
                    <InputField
                      placeholder=""
                      className="inputBox"
                      label="Vendor"
                      id="vendor"
                      name="vendor"
                      type="text"
                      // value={formInputs.password || ""}
                      // onChange={onInputChange}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        {/* accordion 2 */}
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible2 ? "bi-chevron-down" : "bi-chevron-right"
                    }`}
                    onClick={toggleCardVisibility2}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">F</span>
                </Col>
                <Col xs={8} md={9} lg={9}>
                  <span className="headerTitle">Features</span>
                  <span className="subHeaderTitle">Features</span>
                </Col>
              </Row>
            </h2>

            <div
              className={`accordion-collapse collapse show ${
                isCardVisible2 ? "visible" : ""
              }`}
            >
              <div className="accordion-body">
                <Row className="pt-2 pb-4 d-flex align-items-center">
                  <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
                    <InputField
                      placeholder=""
                      className="inputBox"
                      label="Product Name"
                      id="productName"
                      name="productName"
                      type="text"
                      // value={formInputs.password || ""}
                      // onChange={onInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
                    <InputField
                      placeholder=""
                      className="inputBox"
                      label="Product Number"
                      id="productNumber"
                      name="productNumber"
                      type="text"
                      // value={formInputs.password || ""}
                      // onChange={onInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
                    <InputField
                      placeholder=""
                      className="inputBox"
                      label="Price"
                      id="price"
                      name="price"
                      type="text"
                      // value={formInputs.password || ""}
                      // onChange={onInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
                    <InputField
                      placeholder=""
                      className="inputBox"
                      label="Description"
                      id="description"
                      name="description"
                      type="text"
                      // value={formInputs.password || ""}
                      // onChange={onInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
                    <InputField
                      placeholder=""
                      className="inputBox"
                      label="Vendor"
                      id="vendor"
                      name="vendor"
                      type="text"
                      // value={formInputs.password || ""}
                      // onChange={onInputChange}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        {/* accordion 3 */}
        <div className="accordion mb-4" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <Row className="accordion-button">
                <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                  <i
                    className={`bi ${
                      isCardVisible3 ? "bi-chevron-down" : "bi-chevron-right"
                    }`}
                    onClick={toggleCardVisibility3}
                  ></i>
                </Col>
                <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                  <span className="iconLogo">BI</span>
                </Col>
                <Col xs={8} md={9} lg={9}>
                  <spSan className="headerTitle">Specification</spSan>
                  <span className="subHeaderTitle">Specification</span>
                </Col>
              </Row>
            </h2>
            <div
              className={`accordion-collapse collapse show ${
                isCardVisible3 ? "visible" : ""
              }`}
            >
              <div className="accordion-body">
                <Row className="pt-2 pb-4 d-flex align-items-center">
                  <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
                    <InputField
                      placeholder=""
                      className="inputBox"
                      label="Product Name"
                      id="productName"
                      name="productName"
                      type="text"
                      // value={formInputs.password || ""}
                      // onChange={onInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
                    <InputField
                      placeholder=""
                      className="inputBox"
                      label="Product Number"
                      id="productNumber"
                      name="productNumber"
                      type="text"
                      // value={formInputs.password || ""}
                      // onChange={onInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
                    <InputField
                      placeholder=""
                      className="inputBox"
                      label="Price"
                      id="price"
                      name="price"
                      type="text"
                      // value={formInputs.password || ""}
                      // onChange={onInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
                    <InputField
                      placeholder=""
                      className="inputBox"
                      label="Description"
                      id="description"
                      name="description"
                      type="text"
                      // value={formInputs.password || ""}
                      // onChange={onInputChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6} className="commLeftRightPadding">
                    <InputField
                      placeholder=""
                      className="inputBox"
                      label="Vendor"
                      id="vendor"
                      name="vendor"
                      type="text"
                      // value={formInputs.password || ""}
                      // onChange={onInputChange}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <form className="space-y-4">
          <Row className="justify-end">
            <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
              <button
                type="button"
                onClick={() => props.onHide()}
                className="btn btn-danger mt-4 w-full justify-center rounded-md"
              >
                Cancel
              </button>
            </Col>
            <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
              <button
                type="button"
                // onClick={handleSubmit}
                className="btn btn-primary mt-4 w-full justify-center rounded-md"
              >
                Save
              </button>
            </Col>
            <Col xs={12} md={12} lg={12} className="commLeftRightPadding">
              {/* <p className="errorMsg"> {errorMsg} </p> */}
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default CatalogEditMaterialModal;

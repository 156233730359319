// import Constants from './Constants';

import moment from "moment";
import configFromEnv from "../services/configuration";

/* -------------------------------------------- TIME  ----------------------------------------------------------- */

// Convert Text to Time
function convertTextToTime(text) {
  const timeRegex = /^(\d{1,2})(:)?(\d{2})?\s?(AM|PM)?$/i; // Regex to match time format
  const match = text.match(timeRegex);

  if (match) {
    let hours = parseInt(match[1], 10);
    const minutes = match[3] ? parseInt(match[3], 10) : 0;
    const period = match[4] ? match[4].toUpperCase() : "AM";

    if (hours === 12) {
      hours = period === "AM" ? 0 : 12;
    } else if (period === "PM") {
      hours += 12;
    }

    const time = new Date();
    time.setHours(hours, minutes, 0, 0);

    return time;
  }

  return null; // Return null for invalid format
}

// Check the Time Difference and Check Time difference is within 12 hours
function checkTimeDifference(startDateTime, endDateTime, withInHours) {
  const start = new Date(startDateTime);
  const end = new Date(endDateTime);
  let obj = {};

  // Check if dates are valid
  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    obj.status = false;
    obj.msg = "* Invalid date format";
    return obj;
  }

  // Check if start time is less than end time
  if (start >= end) {
    obj.status = false;
    obj.msg = "* Opening time cannot be less than or equal to Closing time.";
    return obj;
  }

  // Calculate difference in milliseconds
  const diffInMilliseconds = Math.abs(end - start);

  // Calculate the difference in hours
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

  obj.status = true;
  obj.totalHours = diffInHours;
  obj.msg = diffInHours + " hours";
  return obj;

  // Check if difference is within 12 hours
  // if (diffInHours <= withInHours) {
  //   obj.status = true;
  //   obj.totalHours = diffInHours;
  //   obj.msg = diffInHours + " hours";
  //   return obj;
  //   // return <p className='successMsg'>Shifts hours: {diffInHours} hours.</p>;
  // } else {
  //   obj.status = false;
  //   obj.msg = "* Shifts cannot be longer than " + withInHours + " hours.";
  //   return obj;
  // }
}

// Get only Time in 24 Hours formate
function getTimeFromStringIn24hours(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid date";
  }

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
}

// Get only Time in AM and PM Hours formate
function getTimeFromStringInAmPm(dateString) {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "Invalid date";
  }

  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";
  const displayHours = hours % 12 || 12; // Convert 0 to 12 for AM/PM format

  return `${displayHours}:${minutes} ${period}`;
}

// Show the time in display screen "just now," "1 minute ago," "10 minutes ago," "1 hour ago,"
export const formatTimeDifference = (timestamp) => {
  const now = new Date();
  const timeDiff = now - new Date(timestamp); // Difference in milliseconds

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return "just now";
  } else if (minutes < 60) {
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  } else {
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  }
};

export const getTodaysData = () =>{
  const date = new Date();
  const formattedDate = moment(date).format('YYYY-MM-DD');
  return formattedDate
}


/* -------------------------------------------- TIME END ----------------------------------------------------------- */

// Common Validation functions

// Capitalize function
function capitalize(text) {
  if (!text) return "";
  return text.charAt(0).toUpperCase() + text.slice(1);
}

// Required Fields
export const validateRequired = (value) => {
  // console.log(">> validateRequired", value);
  if (typeof value === "undefined") {
    return false;
  } else {
    return value.trim() !== "";
  }
};

// Email
export const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

// Password
export const validatePassword = (password) => {
  // Password must be at least 8 characters long
  return password.length >= 8;
};

// Contact Number
export const validateContactNumber = (contactNumber) => {
  // Example: Validate US phone number (10 digits)
  const re = /^\d{10}$/;
  return re.test(contactNumber);
};


// Contact Number
export const isValidNumber = (value) => {
  return value === "" || /^\d*\.?\d{0,2}$/.test(value);
};

//Get Date & Time   //'default'
export const getCurrentDate = (format = "default") => {
  const currentDate = new Date();

  switch (format) {
    case "default": {
      // Get the current date 
      // Format the date as YYYY-MM-DD
      // console.log(">> configFromEnv.TIME_FORMATE", configFromEnv.TIME_FORMATE);
      const now = moment();
      const formattedDate = now.format(configFromEnv.TIME_FORMATE);
      return formattedDate
    }
    case "locale": {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      const date = currentDate.toLocaleDateString("en-US", options);

      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const time = currentDate.toLocaleTimeString("en-US", timeOptions);
      return `${date} ${time}`;
    }
    case "iso": {
      return currentDate.toISOString();
    }
    case "short": {
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const day = String(currentDate.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    default: {
      const date = new Date();
      const formattedDate = moment(date).format(configFromEnv.TIME_FORMATE);
      return formattedDate
    }
  }
};

// Number Format Currency
export const currencyFormat = (number) => {
  // Format the price above to USD using the locale, style, and currency.
  // let USDollar = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "INR",  //USD
  // });
  // return USDollar.format(value);

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// format Date
export const formatDate = (date) => {
  const now = moment(date);
  const formattedDate = now.format(configFromEnv.TIME_FORMATE);
  return formattedDate
}

// format Date Time
export const formatDateTime = (date) => {
  const now = moment(date);
  const formattedDate = now.format(configFromEnv.TIME_FORMATE_WITH_TIME);
  return formattedDate
};

// format Price Value
export const formatPriceValue = (value) => {
  return value && !isNaN(value)
  ? parseFloat(value).toFixed(2)
  : "0.00";
};

export const validateTextArea = (value) => {
  const specialCharRegex = /^[a-zA-Z0-9\s!@#$%^&*()_+={}[\]|:;,.<>?/-]{1,500}$/; 
  return specialCharRegex.test(value.trim()) 
};

export const validateBankAccount = (accountNumber) => {
  // Ensure the value is numeric and has a length between 6 and 18
  const isValid = /^\d+$/.test(accountNumber) && accountNumber.length >= 6 && accountNumber.length <= 18;
  return isValid;
};

export const validateIBAN = (iban) => {
  // Remove spaces and convert to uppercase
  const cleanIBAN = iban.replace(/\s+/g, '').toUpperCase();

  // Ensure IBAN is at least 15 and at most 34 characters long
  if (cleanIBAN.length < 15 || cleanIBAN.length > 34) {
    return false;
  }

  // Check if IBAN matches the basic format
  const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]+$/;
  if (!ibanRegex.test(cleanIBAN)) {
    return false;
  }

  // Rearrange IBAN (move first 4 characters to the end)
  const rearrangedIBAN = cleanIBAN.slice(4) + cleanIBAN.slice(0, 4);

  // Convert letters to numbers (A=10, B=11, ..., Z=35)
  const numericIBAN = rearrangedIBAN
    .split('')
    .map((char) =>
      isNaN(char) ? char.charCodeAt(0) - 55 : char // Convert letters to numbers
    )
    .join('');

  // Perform modulo 97 using repeated division
  let remainder = 0;
  for (let i = 0; i < numericIBAN.length; i += 7) {
    const part = `${remainder}${numericIBAN.slice(i, i + 7)}`;
    remainder = parseInt(part, 10) % 97;
  }

  return remainder === 1; // Valid if remainder is 1
};

export const validateName = (value) => {
  const specialCharRegex = /^[a-zA-Z\s.]*$/; 
  return specialCharRegex.test(value.trim());
};

export const validPostalCode = (value) => {
  return /^[A-Z0-9]{6}$/.test(value);
};

export const formatNumberShort = (amount) => {
  if (amount === null || amount === undefined || isNaN(amount)) return "N/A";

  const suffixes = ["", "K", "M", "B", "T"];
  let tier = Math.log10(Math.abs(amount)) / 3 | 0;

  if (tier === 0) return amount.toFixed(2); // No formatting for numbers < 1K

  const suffix = suffixes[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = amount / scale;

  // Format to 2 decimal places
  return `${scaled.toFixed(2)}${suffix}`;
};

export const formatToTwoDigits = (number) => {
  if (isNaN(number)) return "Invalid number";
  return number < 10 ? `0${number}` : `${number}`;
};


export {
  convertTextToTime,
  checkTimeDifference,
  getTimeFromStringIn24hours,
  getTimeFromStringInAmPm,
  capitalize,
};

import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector, useDispatch } from "react-redux";

//Components
import Layout from "../../../../../Layout/Layout";
// import { InputField } from "../../../Components/formElements/InputField";
// import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import DataTable from "react-data-table-component";
import SourcingCommonFilter from "../../../../../Components/Filter/Sourcing/SourcingCommonFilter";
import { useNavigate } from "react-router-dom";
import Constants from "../../../../../common/CustomerConstants";
import {
  addPoDetailNumber,
  getInvoiceList,
} from "../../../../../redux/feature/supplier_app/purchasingSlice";
import { SearchField } from "../../../../../Components/formElements/SearchField";
import CommonItemLevelDatatableModal from "../../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import ShowStatus from "../../../../../Components/Modal/supplier_app/Workflow/ShowStatus";
import {
  currencyFormat,
  formatDateTime,
} from "../../../../../common/Validations";
// import CancelModal from "../../../../Components/Modal/supplier_app/common/CancelModal";
import CancelModal from "../../../../../Components/Modal/supplier_app/common/CancelModal";
import {
  addInvoiceID,
  getCustomerInvoiceList,
} from "../../../../../redux/feature/customer_app/preSalesSlice";
import { TableStatusField } from "../../../../../Components/formElements/TableStatusField";

const CustomerInvoiceDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  localStorage.setItem("menu", "Sales");
  localStorage.setItem("submenu", "Invoice");

  let invoiceListData = useSelector((state) => state?.preSales?.invoice); // User Details from Store
  let invoiceListDataTable = useSelector(
    (state) => state?.preSales?.invoice?.pagination
  ); // User Details from Store

  let module = useSelector(
    (state) => state?.common?.data?.purchaseOrder?.INVOICE?.module
  ); // config Details from Store

  const [isCardVisible1, setCardVisible1] = useState(true);

  const [data, setData] = useState([]);
  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");

  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);
  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };
  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Line Item Details");
    setItemLevelModalShow(true);
  };

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const detailedPageInvoice = (invoiceNumber) => {
    dispatch(addInvoiceID(invoiceNumber));
    navigate(Constants.CUSTOMER_INVOICE_DETAILS);
  };

  const detailedPagePO = (poNumber) => {
    dispatch(addPoDetailNumber(poNumber));
    navigate(Constants.SALES_PURCHASE_ORDER_DETAILS);
  };

  // Invoice Table Col
  const invoiceDataColumns = [
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Invoice Number",
      selector: (row) => row.documentNumber,
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => detailedPageInvoice(row.documentNumber)}
        >
          {row.documentNumber}{" "}
        </button>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "120px",
    },
    // {
    //   name: "Status",
    //   cell: (row) => (
    //     <>
    //       <span className="bg-table-status bg-table-new-status">
    //         {row.invoiceStatusHeader}
    //       </span>
    //     </>
    //   ),
    //   width: "180px",
    // },
    // Conditionally include the 'workflowActionLogs' column
    ...(data?.some((row) => row.workflowActionLogs !== undefined)
      ? [
          {
            name: "Workflow log",
            selector: (row) => row.workflowActionLogs,
            cell: (row) => (
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-center iconTable"
              >
                <img
                  src="assets/img/newIcons/workflow.png"
                  alt="attachments"
                  style={{ width: "35px" }}
                  className="mt-1"
                  onClick={() => showStatusInfo(row.workflowActionLogs)}
                />
              </div>
            ),
            sortable: true,
            width: "132px",
          },
        ]
      : []),
    {
      name: "Status",
      selector: (row) => row.invoiceStatusHeader,
      cell: (row) => (
        <>
          <TableStatusField status={row.invoiceStatusHeader} />
        </>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Invoice Date",
      selector: (row) => row.customerInvoiceDate,
      sortable: true,
      width: "130px",
    },
    {
      name: "Vendor",
      selector: (row) => row.customerCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.customerCode} </p>
          <p className="m-0 p-0">{row.customerName} </p>
        </div>
      ),
    },
    {
      name: "Invoice Value",
      selector: (row) => row.customerTotalInvoiceValue,
      cell: (row) => (
        <span className="numberFormate m-0 p-0 pt-1 fw-bold">
          {currencyFormat(Number(row.customerTotalInvoiceValue).toFixed(2))}
        </span>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "Currency",
      selector: (row) => row.customerInvoiceCurrency,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.customerInvoiceCurrency} </p>
          <p className="m-0 p-0">{row.currency} </p>
        </div>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Delivery Nr",
      cell: (row) => (
        <button className="btnInfo">{row.deliveryNumber || "--"}</button>
      ),
      width: "150px",
    },
    {
      name: "SO Number",
      selector: (row) => row.salesDocumentNr,
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => detailedPagePO(row.salesDocumentNr)}
        >
          {row.salesDocumentNr}
        </button>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "SO Date",
      selector: (row) => formatDateTime(row?.salesDocumentDate) || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "SAP Invoice",
      selector: (row) => row.invoiceItemNr,
      sortable: true,
      width: "150px",
    },
    {
      name: "Posted On",
      selector: (row) => row.grnItemNr,
      sortable: true,
      width: "130px",
    },
    // {
    //   name: "GRN Value",
    //   selector: (row) => row.purchasingDocumentItemNr,
    //   sortable: true,
    //   width: "150px",
    // },
    // {
    //   name: "GRN Number",
    //   selector: (row) => row.purchasingDocumentItemNr,
    //   sortable: true,
    //   width: "150px",
    // },
    // {
    //   name: "GRN Value",
    //   selector: (row) => row.purchasingDocumentItemNr,
    //   sortable: true,
    //   width: "150px",
    // },
    {
      name: "Cancelled INV Nr",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "Cancelled INV Date",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "Cancelled INV Remark",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "200px",
    },
  ];

  // Invoice Item Table Col
  const lineItemColInfo = [
    {
      name: "Inv Item Nr",
      cell: (row) => <span>{row.invoiceItemNr} </span>,
      width: "180px",
    },
    {
      name: "Material/Service",

      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.materialCode} </p>
          <p className="m-0 p-0">{row.materialDescription} </p>
        </div>
      ),
      width: "180px",
    },
    // {
    //   name: "Material Desc",
    //   cell: (row) => <span>{row.materialDescription} </span>,
    //   width: "220px",
    // },
    {
      name: "Material Group",
      cell: (row) => <span>{row.materialGroup} </span>,
      width: "220px",
    },
    {
      name: "Price Unit",
      cell: (row) => <span>{row.orderPriceUnit} </span>,
      width: "220px",
    },
    {
      name: "UOM",
      cell: (row) => <span>{row.orderUnitOfMeasure} </span>,
      width: "220px",
    },
    {
      name: "Invoice Quantity",
      cell: (row) => <span>{row.invoiceQuantity} </span>,
      width: "220px",
    },
    {
      name: "Item Net Value",
      cell: (row) => <span>{row.totalPrice} </span>,
      width: "220px",
    },
    {
      name: "Item Tax Value",
      cell: (row) => <span>{row.invItemTaxValue} </span>,
      width: "220px",
    },
    {
      name: "SO Nr",
      cell: (row) => <span>{row.salesDocumentNr} </span>,
      width: "220px",
    },
    {
      name: "SO Item Nr",
      cell: (row) => <span>{row.salesDocumentItemNr} </span>,
      width: "220px",
    },
    {
      name: "Schedule Nr",
      cell: (row) => <span>{row.purchasingDocumentItemNr} </span>,
      width: "220px",
    },
  ];

  // Show Status Modal
  const [modalShowStatus, setModalShowStatus] = useState(false);
  const [sendDataModal, setSendDataModal] = useState("");
  const [modelTitle, setModelTitle] = useState("");

  /* Modal Function */
  const showStatusInfo = (rowData) => {
    setModalShowStatus(true);
    setSendDataModal(rowData);
    setModelTitle("Status Information");
  };

  const hideModalStatus = () => {
    setModalShowStatus(false);
  };

  const handlePageChange = (page) => {
    let obj = {
      page: page,
      per_page: getNewPerPage,
      searchTerm: getCommonSearchTxt,
    };

    dispatch(getInvoiceList(obj));
    setData(invoiceListData?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getInvoiceList(obj));
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      page: page,
      per_page: newPerPage,
      searchTerm: getCommonSearchTxt,
    };
    setNewPerPage(newPerPage);
    dispatch(getInvoiceList(obj));
    setData(invoiceListData?.data);
  };

  const loadInitialPOData = async () => {
    setData(invoiceListData?.data);
  };

  useEffect(() => {
    loadInitialPOData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceListData]);

  //on page load
  useEffect(() => {
    let obj = {
      queryName: "getCustomerInvoiceList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
    };
    dispatch(getCustomerInvoiceList(obj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Cancel Modal */
  const [getCancelModalStatus, setCancelModalStatus] = useState(false);
  const cancelModalShow = () => {
    setCancelModalStatus(true);
  };
  const cancelModalHide = () => {
    setCancelModalStatus(false);
  };

  /* Handle selected rows */
  const [getSelectedRows, setSelectedRows] = useState([]);
  const handleRowSelected = ({ selectedRows }) => {
    const validRows = selectedRows.filter(
      (item) => item?.invoiceStatusHeader !== "Cancelled"
    );
    setSelectedRows(validRows);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle">Invoice</h2>
                <p className="subText">Lists of Invoice and details</p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>

            <hr />

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>

                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">IN</span>
                    </Col>

                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle">Invoice List</span>
                      <span className="subHeaderTitle">
                        View of Invoice and Status{" "}
                      </span>
                    </Col>

                    <Col xs={12} md={2} lg={2} className="headerButtonAlign">
                      <Col xs={6} md={6} lg={6}>
                        <button
                          className="btnTable"
                          onClick={cancelModalShow}
                          disabled={getSelectedRows.length > 0 ? false : true}
                        >
                          <i className="bi bi-x-circle"></i> Cancel
                        </button>
                      </Col>
                      <Col xs={6} md={1} lg={1}>
                        <SourcingCommonFilter />
                      </Col>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={invoiceDataColumns}
                          data={data}
                          progressPending={invoiceListData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          paginationTotalRows={invoiceListDataTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          onSelectedRowsChange={handleRowSelected}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />
      <ShowStatus
        show={modalShowStatus}
        onHide={() => hideModalStatus()}
        rowdatamodal={sendDataModal}
        modeltitle={modelTitle}
      />
      <CancelModal
        title="Cancel the Invoice"
        type={module}
        show={getCancelModalStatus}
        onHide={cancelModalHide}
        selectedrow={getSelectedRows}
      />
    </main>
  );
};

export default CustomerInvoiceDetails;

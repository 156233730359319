/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";



//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import Layout from "../../../Layout/Layout";
import DataTable from "react-data-table-component";
import SourcingCommonFilter from "../../../Components/Filter/Sourcing/SourcingCommonFilter";
import { useNavigate } from "react-router-dom";
import Constants from "../../../common/SupplierConstants";
import { addUserID } from "../../../redux/feature/userSlice";
import { currencyFormat, formatDate } from "../../../common/Validations";

// import configFromEnv from "../../../services/configuration";
// import Moment from "react-moment";

const InvoiceDownPaymentList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "Invoice Down Payment List");

  let userListData = useSelector((state) => state?.user.userList); // User Details from Store

  const [isCardVisible1, setCardVisible1] = useState(true);
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const [data, setData] = useState([]);
  const [isCardVisible, setCardVisible] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const [getPurchaseDocType, setPurchaseDocType] = useState("");

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");

  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);
  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");


  // const navigateInvoiceDownPaymentCreate = () => {
  //   navigate(Constants.INVOICE_DOWN_PAYMENT_CREATE);
  // };
  const detailedPage = (userId) => {
      dispatch(addUserID(userId));
      navigate(Constants.CREATE_USER_DETAILS);
    };


  const userListColInfo = [
    {
      name: "Message",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "BP ID / Name",
      selector: (row) => row.firstName,
      sortable: true,

      cell: (row) => (
        <span className="m-0 p-0 pt-1 fw-bold">{row.firstName}</span>
        // <>
        //   {row.firstName} {row.lastName}
        // </>
      ),
      width: "150px",
    },
    {
      name: "Order Ref",
      selector: (row) => row.userId,
      sortable: true,
      cell: (row) => (
        <>
          <button className="btnInfo" onClick={() => detailedPage(row.userId)}>
            {row.userId}
          </button>
        </>
      ),
      width: "150px",
    },
    {
      name: "Order Date",
      selector: (row) => formatDate(row?.asnDate) || "--",
      sortable: true,
      width: "120px",
    },
    {
      name: "Assign Buyer / Email",
      selector: (row) => row.email || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "PO Type",
      selector: (row) => row.POType || "--",
      sortable: true,
      width: "100px",
    },
    {
      name: "Invoice Value",
      selector: (row) => row.Number || "--",
      sortable: true,
      cell: (row) => <>{row.Number}</>,
      width: "130px",
    },
    {
      name: "Payment Terms",
      selector: (row) => row.paymentTermsCode || "--",
      sortable: true,
      width: "150px",
      
    },
    {
      name: "DownPayment %",
      selector: (row) => row.Number || "--",
      sortable: true,
      //cell: (row) => <>{row.Number}</>,
      width: "160px",
    },
    {
      name: "DownPayment Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => <>{row.amount}</>,
      width: "190px",
    },

    {
      name: "Invoice",
      cell: (row) => (
        <>
          <button className="btnTable" onClick={() => createDPR()}>
            Create DPR
          </button>
          {/* <button className="btnTable" onClick={() => createInvoice() >Approved {row.order}</button> */}
        </>
      ),
      width: "140px",
    },

  ];

  const loadInitialSuuplierData = async () => {
    setData(userListData?.data);
  };

  
  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListData]);

  //Create DPR
    const createDPR = () => {
      navigate(Constants.INVOICE_DOWN_PAYMENT_CREATE);
    };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={8} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">Invoice Down Payment</h2>
                <p className="subText">Purchase Order List</p>
              </Col>
              {/* <Col xs={4} md={2} lg={2} className="mb-2">
                <button className="btn w-full" onClick={navigateInvoiceDownPaymentCreate}>
                  Create
                </button>
              </Col> */}
              <hr />
            </Row>

            {/* user list */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${isCardVisible1
                          ? "bi-chevron-right"
                          : "bi-chevron-down"
                          }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PO</span>
                    </Col>
                    <Col xs={8} md={3} lg={3}>
                      <span className="headerTitle">Invoice Down Payment List</span>
                      {/*  <span className="subHeaderTitle">
                        View of Supplier List{" "}
                      </span> */}
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${isCardVisible1 ? "visible" : ""
                    }`}
                // aria-labelledby="headingOne"
                // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={userListColInfo}
                          data={data}
                          progressPending={userListData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default InvoiceDownPaymentList;

export let colData = [
  {
    name: "PO_REPORT",
    columns: [
      {
        name: "Company Code",
        cell: (row) => (
          <>
            <span className="m-0 p-0 pt-1 fw-bold">{row.companyCode}</span>
          </>
        ),
        selector: (row) => row.companyCode || "--",
        sortable: true,
        width: "150px",
      },     
      {
        name: "Pur Grp",
        selector: (row) => row.purchasingGroup || "--",
        sortable: true,
        width: "120px",
      },
      {
        name: "Doc Type",
        cell: (row) => (
          <>
            <span className="m-0 p-0 pt-1 fw-bold">{row.purchasingDocumentType}</span>
          </>
        ),
        selector: (row) => row.purchasingDocumentType || "--",
        sortable: true,
        width: "130px",
      },   
      {
        name: "PO Nr",
        selector: (row) => row.purchasingDocumentNr || "--",
        cell: (row) => (
          <>
            <span className="bg-table-status bg-table-new-status">
              {row.purchasingDocumentNr}
            </span>
          </>
        ),
        sortable: true,
        width: "150px",
      },
      {
        name: "PO Date",
        selector: (row) => row.purchasingDocumentDate || "--",
        sortable: true,
        width: "150px",
      },       
      {
        name: "Vendor Code",
        selector: (row) => row.vendorCode || "--",
        sortable: true,
        width: "130px",
      },
      {
        name: "Vendor Name",
        selector: (row) => row.vendorName || "--",
        sortable: true,
        width: "150px",
      },
      {
        name: "Satus",
        selector: (row) => row.statusHeader || "--",
        cell: (row) => (
          <>
            <span className="bg-table-status bg-table-success-status">
              {row.statusHeader}
            </span>
          </>
        ),
        sortable: true,
        width: "150px",
      },
      {
        name: "PO Item Nr",
        selector: (row) => row.purchasingDocumentItemNr || "--",
        sortable: true,
        width: "150px",
      }, 
       {
        name: "Material/Service",
        selector: (row) => row.materialCode,
        cell: (row) => (
          <>
            <div>
              <p className="m-0 p-0 pt-1">{row.materialCode} </p>
              <p className="m-0 p-0">{row.materialDescription} </p>
            </div>
          </>
        ),
        width: "180px",
        sortable: true,
      },
      {
        name: "Material Group ",
        selector: (row) => row.materialGroup || "--",
        sortable: true,
        width: "150px",
      },
      {
        name: "Quantity ",
        selector: (row) => row.purchaseQuantity || "--",
        sortable: true,
        width: "150px",
      },
      {
        name: "UOM ",
        selector: (row) => row.orderUnitOfMeasure || "--",
        width: "100px",
      },
      {
        name: "Unit Price ",
        selector: (row) => row.orderPriceUnit || "--",
        width: "100px",
      },
      {
        name: "Currency",
        selector: (row) => row.currency || "--",
        width: "100px",
      },
      {
        name: "Delivery Date ",
        selector: (row) => row.deliveryDate || "--",
        width: "150px",
      },
      {
        name: "Plant",
        selector: (row) => row.plant || "--",
        width: "100px",
      }
    ],
  },
  {
    name: "PR_REPORT",
    columns: [
      {
        name: "Company Code",
        cell: (row) => (
          <>
            <span className="m-0 p-0 pt-1 fw-bold">{row.companyCode}</span>
          </>
        ),
        selector: (row) => row.companyCode || "--",
        sortable: true,
        width: "150px",
      },     
      {
        name: "Pur Org",
        selector: (row) => row.purchasingOrg || "--",
        sortable: true,
        width: "120px",
      },
      {
        name: "Pur Category",
        cell: (row) => (
          <>
            <span className="m-0 p-0 pt-1 fw-bold">{row.purchaseCategory}</span>
          </>
        ),
        selector: (row) => row.purchaseCategory || "--",
        sortable: true,
        width: "130px",
      },   
      {
        name: "PR Nr",
        selector: (row) => row.purchaseReqDocumentNr || "--",
        cell: (row) => (
          <>
            <span className="bg-table-status bg-table-new-status">
              {row.purchaseReqDocumentNr}
            </span>
          </>
        ),
        sortable: true,
        width: "150px",
      },
      {
        name: "PR Date",
        selector: (row) => row.purchasingDocumentDate || "--",
        sortable: true,
        width: "150px",
      },       
    
      {
        name: "Satus",
        selector: (row) => row.prStatusHeader || "--",
        cell: (row) => (
          <>
            <span className="bg-table-status bg-table-success-status">
              {row.prStatusHeader}
            </span>
          </>
        ),
        sortable: true,
        width: "150px",
      },
      {
        name: "PR Item Nr",
        selector: (row) => row.purchasingReqDocumentItemNr || "--",
        sortable: true,
        width: "150px",
      }, 
       {
        name: "Material/Service",
        selector: (row) => row.materialCode,
        cell: (row) => (
          <>
            <div>
              <p className="m-0 p-0 pt-1">{row.materialCode} </p>
              <p className="m-0 p-0">{row.materialDescription} </p>
            </div>
          </>
        ),
        width: "180px",
        sortable: true,
      },
      {
        name: "Material Group ",
        selector: (row) => row.materialGroup || "--",
        sortable: true,
        width: "150px",
      },
      {
        name: "Quantity ",
        selector: (row) => row.purchaseQuantity || "--",
        sortable: true,
        width: "150px",
      },
      {
        name: "UOM ",
        selector: (row) => row.orderUnitOfMeasure || "--",
        width: "100px",
      },
      {
        name: "Unit Price ",
        selector: (row) => row.orderPriceUnit || "--",
        width: "100px",
      },
      {
        name: "Currency",
        selector: (row) => row.currency || "--",
        width: "100px",
      },
      {
        name: "Delivery Date ",
        selector: (row) => row.deliveryDate || "--",
        width: "150px",
      },
      {
        name: "Storage Locn",
        selector: (row) => row.storageLocn || "--",
        width: "150px",
      }
    ],
  },
  {
    name: "ASN_REPORT",
    columns: [
     
       
      {
        name: "ASN Nr",
        selector: (row) => row.asnNumber || "--",
        cell: (row) => (
          <>
            <span className="bg-table-status bg-table-new-status">
              {row.asnNumber}
            </span>
          </>
        ),
        sortable: true,
        width: "150px",
      },
      {
        name: "ASN Date",
        selector: (row) => row.purchasingDocumentDate || "--",
        sortable: true,
        width: "150px",
      },  
      {
        name: "ASN Status",
        selector: (row) => row.asnStatusHeader || "--",
        sortable: true,
        width: "150px",
      },     
     
      {
        name: "Pur Doc Nr",
        selector: (row) => row.purchasingDocumentNr || "--",
        cell: (row) => (
          <>
            <span className="bg-table-status bg-table-success-status">
              {row.purchasingDocumentNr}
            </span>
          </>
        ),
        sortable: true,
        width: "150px",
      },
      {
        name: "Vendor Code",
        selector: (row) => row.vendorCode || "--",
        sortable: true,
        width: "150px",
      }, 
      {
        name: "Vendor Name",
        selector: (row) => row.vendorName || "--",
        sortable: true,
        width: "150px",
      }, 
      // {
      //   name: "Pur Category",
      //   cell: (row) => (
      //     <>
      //       <span className="m-0 p-0 pt-1 fw-bold">{row.purchaseCategory}</span>
      //     </>
      //   ),
      //   selector: (row) => row.purchaseCategory || "--",
      //   sortable: true,
      //   width: "130px",
      // }, 
      {
        name: "ASN Item Nr",
        selector: (row) => row.asnItemNr || "--",
        sortable: true,
        width: "150px",
      }, 
       {
        name: "Material/Service",
        selector: (row) => row.materialCode,
        cell: (row) => (
          <>
            <div>
              <p className="m-0 p-0 pt-1">{row.materialCode} </p>
              <p className="m-0 p-0">{row.materialDescription} </p>
            </div>
          </>
        ),
        width: "180px",
        sortable: true,
      },
      {
        name: "Material Group ",
        selector: (row) => row.materialGroup || "--",
        sortable: true,
        width: "150px",
      },
      {
        name: " ASN Qty ",
        selector: (row) => row.asnQuantity || "--",
        sortable: true,
        width: "150px",
      },
      {
        name: "UOM ",
        selector: (row) => row.orderUnitOfMeasure || "--",
        width: "100px",
      },
      {
        name: "Unit Price ",
        selector: (row) => row.orderPriceUnit || "--",
        width: "100px",
      },
      {
        name: "Currency",
        selector: (row) => row.currency || "--",
        width: "100px",
      },
      {
        name: "Delivery Date ",
        selector: (row) => row.deliveryDate || "--",
        width: "150px",
      },
      {
        name: "Storage Locn",
        selector: (row) => row.storageLocn || "--",
        width: "150px",
      }
    ],
  },
  {
    name: "INVOICE_REPORT",
    columns: [
     
       
      {
        name: "INV Nr",
        selector: (row) => row.invoiceNr || "--",
        cell: (row) => (
          <>
            <span className="bg-table-status bg-table-new-status">
              {row.invoiceNr}
            </span>
          </>
        ),
        sortable: true,
        width: "150px",
      },
      {
        name: "INV Date",
        selector: (row) => row.invDocumentDate || "--",
        sortable: true,
        width: "150px",
      },  
      {
        name: "Status",
        selector: (row) => row.invoiceStatusHeader || "--",
        sortable: true,
        width: "150px",
      },     
      {
        name: "Vendor Code",
        selector: (row) => row.vendorCode || "--",
        sortable: true,
        width: "150px",
      }, 
      {
        name: "Vendor Name",
        selector: (row) => row.vendorName || "--",
        sortable: true,
        width: "150px",
      }, 
      {
        name: "Pur Doc Nr",
        selector: (row) => row.purchasingDocumentNr || "--",
        cell: (row) => (
          <>
            <span className="bg-table-status bg-table-success-status">
              {row.purchasingDocumentNr}
            </span>
          </>
        ),
        sortable: true,
        width: "150px",
      },
      {
        name: "Pur Item Nr",
        selector: (row) => row.purchasingDocumentItemNr || "--",
        sortable: true,
        width: "150px",
      },
      // {
      //   name: "Pur Category",
      //   cell: (row) => (
      //     <>
      //       <span className="m-0 p-0 pt-1 fw-bold">{row.purchaseCategory}</span>
      //     </>
      //   ),
      //   selector: (row) => row.purchaseCategory || "--",
      //   sortable: true,
      //   width: "130px",
      // }, 
      {
        name: "INV Item Nr",
        selector: (row) => row.invoiceItemNr || "--",
        sortable: true,
        width: "150px",
      }, 
       {
        name: "Material/Service",
        selector: (row) => row.materialCode,
        cell: (row) => (
          <>
            <div>
              <p className="m-0 p-0 pt-1">{row.materialCode} </p>
              <p className="m-0 p-0">{row.materialDescription} </p>
            </div>
          </>
        ),
        width: "180px",
        sortable: true,
      },
      {
        name: "Material Group ",
        selector: (row) => row.materialGroup || "--",
        sortable: true,
        width: "150px",
      },
      {
        name: " INV Qty ",
        selector: (row) => row.invoiceQuantity || "--",
        sortable: true,
        width: "150px",
      },
      {
        name: "UOM ",
        selector: (row) => row.orderUnitOfMeasure || "--",
        width: "100px",
      },
      {
        name: "Unit Price ",
        selector: (row) => row.orderPriceUnit || "--",
        width: "100px",
      },
      {
        name: "Currency",
        selector: (row) => row.currency || "--",
        width: "100px",
      },
      {
        name: "Delivery Date ",
        selector: (row) => row.deliveryDate || "--",
        width: "150px",
      },
      {
        name: "Storage Locn",
        selector: (row) => row.storageLocn || "--",
        width: "150px",
      }
    ],
  },
  {
    name: "GRN_REPORT",
    columns: [
     
       
      {
        name: "GRN Nr",
        selector: (row) => row.grnNr|| "--",
        cell: (row) => (
          <>
            <span className="bg-table-status bg-table-new-status">
              {row.grnNr}
            </span>
          </>
        ),
        sortable: true,
        width: "150px",
      },
      {
        name: "GRN Date",
        selector: (row) => row.grnDocumentDate || "--",
        sortable: true,
        width: "150px",
      },  
      {
        name: "Status",
        selector: (row) => row.grnStatusHeader || "--",
        sortable: true,
        width: "150px",
      },  
      {
        name: "Vendor Code",
        selector: (row) => row.vendorCode || "--",
        sortable: true,
        width: "150px",
      }, 
      {
        name: "Vendor Name",
        selector: (row) => row.vendorName || "--",
        sortable: true,
        width: "150px",
      }, 
      {
        name: "Pur Doc Nr",
        selector: (row) => row.purchasingDocumentNr || "--",
        cell: (row) => (
          <>
            <span className="bg-table-status bg-table-success-status">
              {row.purchasingDocumentNr}
            </span>
          </>
        ),
        sortable: true,
        width: "150px",
      },
      {
        name: "PO Item Nr",
        selector: (row) => row.purchasingDocumentItemNr || "--",
        sortable: true,
        width: "150px",
      }, 
    
      // {
      //   name: "Pur Category",
      //   cell: (row) => (
      //     <>
      //       <span className="m-0 p-0 pt-1 fw-bold">{row.purchaseCategory}</span>
      //     </>
      //   ),
      //   selector: (row) => row.purchaseCategory || "--",
      //   sortable: true,
      //   width: "130px",
      // }, 
      {
        name: "GRN Item Nr",
        selector: (row) => row.grnItemNr || "--",
        sortable: true,
        width: "150px",
      }, 
       {
        name: "Material/Service",
        selector: (row) => row.materialCode,
        cell: (row) => (
          <>
            <div>
              <p className="m-0 p-0 pt-1">{row.materialCode} </p>
              <p className="m-0 p-0">{row.materialDescription} </p>
            </div>
          </>
        ),
        width: "180px",
        sortable: true,
      },
      {
        name: "Material Group ",
        selector: (row) => row.materialGroup || "--",
        sortable: true,
        width: "150px",
      },
      {
        name: " GRN Qty ",
        selector: (row) => row.grnQuantity || "--",
        sortable: true,
        width: "150px",
      },
      {
        name: "UOM ",
        selector: (row) => row.orderUnitOfMeasure || "--",
        width: "100px",
      },

      {
        name: "Delivery Date ",
        selector: (row) => row.deliveryDate || "--",
        width: "150px",
      },
      {
        name: "Plant",
        selector: (row) => row.plant || "--",
        width: "150px",
      },
      {
        name: "Storage Locn",
        selector: (row) => row.storageLocn || "--",
        width: "150px",
      },
      {
        name: "Mov Type",
        selector: (row) => row.movementType || "--",
        width: "150px",
      }
    ],
  }
];

import React, { Component } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Layout from '../Layout/Layout';
import { Link   } from "react-router-dom";
import ConstantsList from '../common/SupplierConstants';

export class Page401 extends Component {
    render() {
        return (
            <main className="dashboard main" id="">
                {/* <Layout /> */}
                <Row className="justify-center subContent">
                    <Col xs={12} md={12} className="rightSide">
                        <div className="container">
                            <section className="section error-404 d-flex flex-column align-items-center justify-content-center">
                                <h1>401</h1>
                                <h2>The page you are looking you don't have access to it.</h2>
                                {/* <a className="btn" href="/dashboard">Back to home</a> */}
                                <Link to={ConstantsList.HOME} className="btn">
                                    Back to home
                                </Link >
                                <img src="/assets/img/401.svg" className="img-fluid py-0" alt="Page Not Found" />
                                {/* <div className="credits">
                                    Designed by <a href="/">supplier_app</a>
                                </div> */}
                            </section>
                        </div>
                    </Col>
                </Row>
            </main>
        )
    }
}

export default Page401
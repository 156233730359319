import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Materialcatalog.scss";

//Components
import Layout from "../../../Layout/Layout";
import StarRating from "./StarRating";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import { InputField } from "../../../Components/formElements/InputField";
import CatalogMaterialDetailsModal from "./CatalogMaterialDetailsModal";
import FormRange from "react-bootstrap/esm/FormRange";

let sortDropDown = [
  {
    id: "1",
    name: "Default Sorting",
  },
  {
    id: "2",
    name: "Sort by Popularity",
  },
  {
    id: "3",
    name: "Sort by price: Low to High",
  },
  {
    id: "4",
    name: "Sort by price: High to Low",
  },
];
let pagination = [
  {
    id: "1",
    name: "5",
  },
  {
    id: "2",
    name: "10",
  },
  {
    id: "3",
    name: "15",
  },
];

const products = [
  {
    id: 1,
    productName: "Cotton Yarn",
    productNumber: "CY12345",
    category: "Raw Materials",
    subCategory: "Yarn",
    stock: 500,
    sold: 200,
    views: 1500,
    price: 250,
    discount: 10,
    discountedPrice: 225,
    description:
      "High-quality cotton yarn for making soft and breathable fabrics.High-quality cotton yarn for making soft and breathable fabrics.",
    vendor: "CottonCo",
    vendorLocation: "Texas, USA",
    rating: 4,
    reviews: 50,
    weight: "1kg",
    dimensions: "30cm x 30cm x 15cm",
    color: "White",
    material: "100% Cotton",
    careInstructions: "Keep dry, avoid high humidity",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M01.png",
    createdAt: "2024-02-01",
    updatedAt: "2024-02-15",
    tags: ["yarn", "cotton", "soft"],
    warranty: "Not Applicable",
    returnPolicy: "Return within 15 days if unopened",
    shipping: {
      availableRegions: ["USA", "India", "Europe"],
      estimatedDelivery: "7-10 business days",
      cost: "$5",
    },
    featured: false,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 2,
    productName: "Denim Fabric",
    productNumber: "DF98765",
    category: "Raw Materials",
    subCategory: "Fabric",
    stock: 800,
    sold: 400,
    views: 2400,
    price: 1500,
    discount: 20,
    discountedPrice: 1200,
    description:
      "Durable denim fabric perfect for making jeans and jackets.Keep dry, avoid high humidity Wash with cold water, avoid direct sunlight",
    vendor: "DenimWorks",
    vendorLocation: "Los Angeles, USA",
    rating: 3,
    reviews: 60,
    weight: "2kg",
    dimensions: "100cm x 150cm",
    color: "Blue",
    material: "100% Cotton",
    careInstructions: "Wash with cold water, avoid direct sunlight",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M02.png",
    createdAt: "2024-03-01",
    updatedAt: "2024-03-05",
    tags: ["denim", "durable", "jeans"],
    warranty: "Not Applicable",
    returnPolicy: "No returns for cut fabric",
    shipping: {
      availableRegions: ["USA", "Europe"],
      estimatedDelivery: "5-7 business days",
      cost: "$10",
    },
    featured: true,
    seasonal: false,
    bestseller: true,
  },
  {
    id: 3,
    productName: "Polyester Thread",
    productNumber: "PT54321",
    category: "Raw Materials",
    subCategory: "Thread",
    stock: 1000,
    sold: 600,
    views: 1800,
    price: 50,
    discount: 5,
    discountedPrice: 47.5,
    description:
      "Strong and durable polyester thread for sewing and stitching.",
    vendor: "ThreadMakers",
    vendorLocation: "Mumbai, India",
    rating: 2,
    reviews: 30,
    weight: "500g",
    dimensions: "15cm x 10cm x 5cm",
    color: "Black",
    material: "Polyester",
    careInstructions: "Store in a dry place",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M03.png",
    createdAt: "2024-01-10",
    updatedAt: "2024-01-20",
    tags: ["thread", "polyester", "sewing"],
    warranty: "Not Applicable",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["India", "USA", "Europe"],
      estimatedDelivery: "3-5 business days",
      cost: "$2",
    },
    featured: false,
    seasonal: true,
    bestseller: false,
  },
  {
    id: 4,
    productName: "Lycra Fabric",
    productNumber: "LF87654",
    category: "Raw Materials",
    subCategory: "Fabric",
    stock: 600,
    sold: 300,
    views: 2000,
    price: 1200,
    discount: 15,
    discountedPrice: 1020,
    description: "Stretchable Lycra fabric for making sportswear and leggings.",
    vendor: "FlexTex",
    vendorLocation: "Shanghai, China",
    rating: 5,
    reviews: 40,
    weight: "1.5kg",
    dimensions: "200cm x 150cm",
    color: "Red",
    material: "Lycra",
    careInstructions: "Hand wash, do not iron",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M04.png",
    createdAt: "2024-04-01",
    updatedAt: "2024-04-10",
    tags: ["lycra", "stretchable", "sports"],
    warranty: "Not Applicable",
    returnPolicy: "No returns for cut fabric",
    shipping: {
      availableRegions: ["Asia", "Europe"],
      estimatedDelivery: "10-12 business days",
      cost: "$15",
    },
    featured: false,
    seasonal: true,
    bestseller: true,
  },
  {
    id: 5,
    productName: "Metal Buttons",
    productNumber: "MB56789",
    category: "Raw Materials",
    subCategory: "Buttons",
    stock: 2000,
    sold: 1000,
    views: 2200,
    price: 15,
    discount: 5,
    discountedPrice: 14.25,
    description: "Premium metal buttons for jeans and jackets.",
    vendor: "ButtonForge",
    vendorLocation: "Birmingham, UK",
    rating: 3,
    reviews: 25,
    weight: "100g",
    dimensions: "5cm x 5cm x 2cm",
    color: "Silver",
    material: "Metal",
    careInstructions: "Keep dry to prevent rust",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M05.png",
    createdAt: "2024-03-20",
    updatedAt: "2024-03-25",
    tags: ["buttons", "metal", "jeans"],
    warranty: "Not Applicable",
    returnPolicy: "Return within 15 days",
    shipping: {
      availableRegions: ["Europe", "USA"],
      estimatedDelivery: "5-7 business days",
      cost: "$3",
    },
    featured: false,
    seasonal: false,
    bestseller: true,
  },
  {
    id: 6,
    productName: "Nylon Zippers",
    productNumber: "NZ65432",
    category: "Raw Materials",
    subCategory: "Zippers",
    stock: 3000,
    sold: 1500,
    views: 2500,
    price: 10,
    discount: 10,
    discountedPrice: 9,
    description: "Durable nylon zippers for jackets and bags.",
    vendor: "ZipCraft",
    vendorLocation: "Seoul, South Korea",
    rating: 4,
    reviews: 40,
    weight: "200g",
    dimensions: "30cm x 5cm x 2cm",
    color: "Black",
    material: "Nylon",
    careInstructions: "Store in a cool, dry place",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M06.png",
    createdAt: "2024-02-15",
    updatedAt: "2024-02-28",
    tags: ["zippers", "nylon", "durable"],
    warranty: "Not Applicable",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["Asia", "USA"],
      estimatedDelivery: "7-10 business days",
      cost: "$2",
    },
    featured: true,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 7,
    productName: "Wool Yarn",
    productNumber: "WY43210",
    category: "Raw Materials",
    subCategory: "Yarn",
    stock: 700,
    sold: 350,
    views: 1800,
    price: 500,
    discount: 12,
    discountedPrice: 440,
    description: "Soft wool yarn ideal for sweaters and scarves.",
    vendor: "WoolCo",
    vendorLocation: "Sydney, Australia",
    rating: 5,
    reviews: 60,
    weight: "1.2kg",
    dimensions: "40cm x 30cm x 20cm",
    color: "Gray",
    material: "100% Wool",
    careInstructions: "Dry clean only",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M07.png",
    createdAt: "2024-01-05",
    updatedAt: "2024-01-18",
    tags: ["wool", "yarn", "soft"],
    warranty: "Not Applicable",
    returnPolicy: "Return within 15 days if unopened",
    shipping: {
      availableRegions: ["Australia", "Europe", "USA"],
      estimatedDelivery: "10-12 business days",
      cost: "$8",
    },
    featured: true,
    seasonal: true,
    bestseller: true,
  },
  {
    id: 8,
    productName: "Synthetic Fabric",
    productNumber: "SF76543",
    category: "Raw Materials",
    subCategory: "Fabric",
    stock: 1000,
    sold: 500,
    views: 3000,
    price: 800,
    discount: 20,
    discountedPrice: 640,
    description: "Synthetic fabric for making affordable clothing.",
    vendor: "SynTex",
    vendorLocation: "Beijing, China",
    rating: 4,
    reviews: 35,
    weight: "3kg",
    dimensions: "200cm x 150cm",
    color: "Beige",
    material: "Polyester Blend",
    careInstructions: "Machine wash, tumble dry low",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M08.png",
    createdAt: "2024-04-10",
    updatedAt: "2024-04-15",
    tags: ["synthetic", "fabric", "affordable"],
    warranty: "Not Applicable",
    returnPolicy: "No returns for cut fabric",
    shipping: {
      availableRegions: ["Asia", "USA", "Europe"],
      estimatedDelivery: "7-10 business days",
      cost: "$12",
    },
    featured: false,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 9,
    productName: "Leather Fabric",
    productNumber: "LF90876",
    category: "Raw Materials",
    subCategory: "Fabric",
    stock: 400,
    sold: 200,
    views: 1200,
    price: 5000,
    discount: 25,
    discountedPrice: 3750,
    description: "Premium leather fabric for jackets and bags.",
    vendor: "LeatherLux",
    vendorLocation: "Milan, Italy",
    rating: 3,
    reviews: 70,
    weight: "4kg",
    dimensions: "100cm x 150cm",
    color: "Brown",
    material: "Genuine Leather",
    careInstructions: "Wipe clean with a damp cloth",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M09.png",
    createdAt: "2024-05-01",
    updatedAt: "2024-05-10",
    tags: ["leather", "premium", "durable"],
    warranty: "Not Applicable",
    returnPolicy: "No returns for cut fabric",
    shipping: {
      availableRegions: ["Europe", "USA"],
      estimatedDelivery: "5-7 business days",
      cost: "$20",
    },
    featured: true,
    seasonal: true,
    bestseller: true,
  },
  {
    id: 10,
    productName: "Silk Yarn",
    productNumber: "SY34567",
    category: "Raw Materials",
    subCategory: "Yarn",
    stock: 300,
    sold: 100,
    views: 1400,
    price: 1500,
    discount: 10,
    discountedPrice: 1350,
    description: "Luxurious silk yarn for high-end garments.",
    vendor: "SilkRoad",
    vendorLocation: "Bangkok, Thailand",
    rating: 5,
    reviews: 45,
    weight: "500g",
    dimensions: "25cm x 25cm x 10cm",
    color: "Ivory",
    material: "100% Silk",
    careInstructions: "Dry clean only",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M010.png",
    createdAt: "2024-06-01",
    updatedAt: "2024-06-10",
    tags: ["silk", "yarn", "luxury"],
    warranty: "Not Applicable",
    returnPolicy: "Return within 15 days if unopened",
    shipping: {
      availableRegions: ["Asia", "Europe", "USA"],
      estimatedDelivery: "10-12 business days",
      cost: "$15",
    },
    featured: true,
    seasonal: true,
    bestseller: false,
  },
];
const MaterialCatalogShopwise = () => {
  localStorage.setItem("menu", "Catalog Manage");
  localStorage.setItem("submenu", "Matetial Shop");
  /* toggle button  */
  const [getToggle, setToggle] = useState(true);
  const toggleRowProduct = () => {
    setToggle(true);
  };
  const toggleColProduct = () => {
    setToggle(false);
  };
  /* modal  */
  const [modalShowDetailProduct, setModalShowDetailProduct] = useState(false);
  const [modalProduct, setModalProduct] = useState(null);

  const showProductDetail = (product) => {
    setModalShowDetailProduct(true);
    setModalProduct(product);
  };
  const hideModal = () => {
    setModalShowDetailProduct(false);
  };

  const categories = [
    {
      name: "Women",
      items: ["Paints", "Skirts", "Dresses", "Tops"],
    },
    {
      name: "Top",
      items: ["Blouses", "Crop Tops", "Tank Tops", "Shirts"],
    },
    {
      name: "T-Shirts",
      items: ["Graphic T-Shirts", "Plain T-Shirts", "Polo T-Shirts", "V-Neck"],
    },
    {
      name: "Men",
      items: ["Shirts", "Trousers", "Shorts", "Suits"],
    },
    {
      name: "Shoes",
      items: ["Sneakers", "Boots", "Formal Shoes", "Sandals"],
    },
  ];
  /* send product details to modal */
  // const showProductDetail = (product) => {
  //     // setModalProduct(product);
  //   };
  const [openCategory, setOpenCategory] = useState(null);
  const handleCategoryClick = (categoryName) => {
    if (openCategory === categoryName) {
      setOpenCategory(null);
    } else {
      setOpenCategory(categoryName);
    }
  };
  /* range */

  const [getRangeValue, setRangeValue] = useState(0);

  const rangeHandleChange = (e) => {
    setRangeValue(e.target.value);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Catalog </h2>
            <p className="subText">Catalog </p>
            <hr />
            <Row>
              <Col
                lg={2}
                className="d-flex flex-lg-column flex-xs-row flex-wrap"
                style={{ fontSize: "18px" }}
              >
                <Col xs={4} lg={12}>
                  <h5>Categories</h5>
                  <ul className="category">
                    {categories.map((category) => (
                      <React.Fragment key={category.name}>
                        {/* Main category item */}
                        <li className=" d-flex justify-content-between">
                          <span className="d-flex">
                            <div
                              className={`${
                                openCategory === category.name
                                  ? "rotate"
                                  : "still"
                              }`}
                            >
                              <i
                                className="bi bi-chevron-right"
                                onClick={() =>
                                  handleCategoryClick(category.name)
                                }
                              ></i>
                            </div>
                            <span className="ms-2">{category.name}</span>
                          </span>
                          <span className="me-4">
                            ({category.items.length})
                          </span>
                        </li>
                        {/* Dropdown items */}
                        {openCategory === category.name && (
                          <ul className="ms-4">
                            {category.items.map((item, index) => (
                              <li key={index} className="subcategory">
                                {item}
                              </li>
                            ))}
                          </ul>
                        )}
                      </React.Fragment>
                    ))}
                  </ul>
                  <hr className="d-lg-block d-none me-4" />
                </Col>
                <Col xs={4} lg={12}>
                  <h5>Filter</h5>
                  <ul>
                    <li className="pe-4">
                      <FormRange
                        min={0}
                        max={500}
                        value={getRangeValue}
                        onChange={rangeHandleChange}
                      />
                    </li>
                    <li>
                      Price : <span className="fw-bold">${getRangeValue}</span>
                    </li>
                  </ul>
                  <hr className="d-lg-block d-none me-4" />
                </Col>
                <Col xs={4} lg={12} className="brnad">
                  <h5>Brand</h5>
                  <ul>
                    <li>
                      <input type="checkbox" />
                      <span>Women</span>
                    </li>
                    <li>
                      <input type="checkbox" />
                      <span>Top</span>
                    </li>
                    <li>
                      <input type="checkbox" />
                      <span>T-Shirts</span>
                    </li>
                    <li>
                      <input type="checkbox" />
                      <span>Men</span>
                    </li>
                    <li>
                      <input type="checkbox" />
                      <span>Shoes</span>
                    </li>
                  </ul>
                  <hr className="d-lg-block d-none me-4" />
                </Col>
                <Col xs={4} lg={12} className="size">
                  <h5>Size</h5>
                  <ul className="d-flex">
                    <li>XS</li>
                    <li>S</li>
                    <li>M</li>
                    <li>L</li>
                    <li>XL</li>
                    <li>2XL</li>
                    <li>3XL</li>
                  </ul>
                  <hr className="d-lg-block d-none me-4" />
                </Col>
                <Col xs={12} lg={12}>
                  <h5>color</h5>
                  <ul className="d-flex">
                    <div className="product_color_switch d-flex flex-wrap">
                      <span
                        style={{
                          backgroundColor: " rgb(135, 85, 75)",
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "rgb(51, 51, 51)",
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "rgb(218, 50, 63)",
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "rgb(47, 54, 108)",
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "rgb(181, 182, 187)",
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "rgb(185, 194, 223)",
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "rgb(95, 183, 212)",
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "rgb(47, 54, 108)",
                        }}
                      ></span>
                    </div>
                  </ul>
                </Col>
              </Col>
              <Col lg={10}>
                <Col
                  lg={12}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <ComboboxField
                      label=""
                      placeholder="Sort the product"
                      data={sortDropDown}
                      id="sortProduct"
                      iconClassName="dropdownIcon"
                      name="sortProduct"
                      code="sortProductcode"
                      description="countryDesc"
                    />
                  </div>
                  <div className="d-flex gap-2 align-items-center ms-4 ">
                    <button
                      className={`${
                        getToggle ? "active-grid-layout" : "grid-layout "
                      }`}
                      onClick={toggleRowProduct}
                    >
                      <i class="bi bi-grid-3x3-gap-fill fs-4"></i>
                    </button>
                    <button
                      className={`${
                        getToggle ? "grid-layout" : "active-grid-layout"
                      }`}
                      onClick={toggleColProduct}
                    >
                      <i class="bi bi-list-task fs-4"></i>
                    </button>
                    <ComboboxField
                      // label="Store"
                      placeholder="Showing"
                      data={pagination}
                      id="pagination"
                      iconClassName="dropdownIcon"
                      name="pagination"
                      code="paginationcode"
                      description="countryDesc"
                    />
                  </div>
                </Col>
                <div className="d-flex gap-4 flex-wrap justify-content-start mt-4">
                  {getToggle ? (
                    <>
                      {products?.map((items) => (
                        <Col xs={12} md={4} lg={3} className="product-width">
                          <div className="product-grid">
                            <div className="product_img">
                              <img
                                src={items.imageUrl}
                                alt="product"
                                style={{ width: "520px", height: "400px" }}
                              />
                              <div className="product_action_box">
                                <ul className="list_none pr_action_btn">
                                  <li className="add-to-cart">
                                    <p>
                                      <i className="bi bi-cart2"></i> Add To
                                      Cart
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <i className="bi bi-shuffle"></i>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <i className="bi bi-search"></i>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <i className="bi bi-heart"></i>
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="product_info px-2">
                              <h6 className="product_title">
                                <span onClick={() => showProductDetail(items)}>
                                  {items.productName}
                                </span>
                              </h6>
                              <div className="product_price d-flex align-items-center">
                                <span className="price">$45.00</span>
                                <del>$55.25</del>
                                <div className="on_sale">
                                  <span>35% Off</span>
                                </div>
                              </div>
                              <div className="rating_wrap d-flex align-items-center px-2">
                                <div className="rating">
                                  <div className="product_rate">
                                    <StarRating
                                      totalStars={5}
                                      fixedRating={items.rating}
                                      enableHover={false}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="pr_switch_wrap d-flex flex-wrap justify-content-between px-2 pb-2">
                                <div className="product_color_switch d-flex flex-wrap">
                                  <span
                                    style={{
                                      backgroundColor: " rgb(135, 85, 75)",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      backgroundColor: "rgb(51, 51, 51)",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      backgroundColor: "rgb(218, 50, 63)",
                                    }}
                                  ></span>
                                </div>
                                <div>
                                  <InputField
                                    type="number"
                                    className="inputBox"
                                    label="Qnt:"
                                    name="quntity"
                                    placeholder="0"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </>
                  ) : (
                    <>
                      {products?.map((items) => (
                        <Col lg={12}>
                          <div className="product-row d-md-flex d-sm-block m-2">
                            <div className="product_img w-lg-25 w-sm-100">
                              <img
                                src={items.imageUrl}
                                alt="product"
                                style={{ width: "540px", height: "320px" }}
                              />
                            </div>
                            <div className="d-sm-block d-lg-flex w-75 px-2 mt-2">
                              <Col xs={12} md={8} lg={8}>
                                <h6 className="product_title">
                                  <span
                                    onClick={() => showProductDetail(items)}
                                  >
                                    {items.productName}
                                  </span>
                                </h6>
                                <div className="product_price d-flex align-items-center">
                                  <span className="price">{items.price}</span>
                                  <del>$55.25</del>
                                  <div className="on_sale">
                                    <span>35% Off</span>
                                  </div>
                                </div>
                                <div className="rating_wrap d-flex">
                                  <div className="rating">
                                    <div className="product_rate">
                                      <StarRating
                                        totalStars={5}
                                        fixedRating={items.rating}
                                        enableHover={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="pr_desc">
                                  <p className="m-0">{items.description}</p>
                                </div>
                                <div className="product_color_switch mt-4">
                                  <span
                                    style={{
                                      backgroundColor: " rgb(135, 85, 75)",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      backgroundColor: "rgb(51, 51, 51)",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      backgroundColor: "rgb(218, 50, 63)",
                                    }}
                                  ></span>
                                </div>
                              </Col>
                              <Col xs={12} md={4} lg={4} className="d-flex justify-content-lg-center mt-2">
                                <div className="pr_switch_wrap pb-2 d-flex flex-column justify-content-center">
                                  <div>
                                    <InputField
                                      type="number"
                                      className="inputBox"
                                      label="Qnt:"
                                      name="quntity"
                                      placeholder="0"
                                    />
                                  </div>
                                  <div className="list_product_action_box mt-2">
                                    <ul className="list_none pr_action_btn d-flex align-itmes-center gap-4 pb-2">
                                      <li className="add-to-cart btn d-felx justify-content-center fs-5">
                                        <p className="m-0 d-flex">
                                          <i className="bi bi-cart2"></i> 
                                          <span className="ms-2 text-nowrap">
                                          Add To
                                          Cart
                                          </span>
                                          </p>
                                      </li>
                                      <li>
                                        <p className="m-0">
                                          <i className="bi bi-shuffle"></i>
                                        </p>
                                      </li>
                                      <li>
                                        <p className="m-0">
                                          <i className="bi bi-search"></i>
                                        </p>
                                      </li>
                                      <li>
                                        <p className="m-0">
                                          <i className="bi bi-heart"></i>
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <CatalogMaterialDetailsModal
        show={modalShowDetailProduct}
        onHide={() => hideModal()}
        product={modalProduct}
      />
    </main>
  );
};

export default MaterialCatalogShopwise;

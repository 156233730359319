import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Serivce

//Components
import Layout from "../../../Layout/Layout";
import CustomerConstants from "../../../common/CustomerConstants";
// import { InputField } from "../../../Components/formElements/InputField";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import { SearchField } from "../../../Components/formElements/SearchField";
import CatalogListEditModal from "../../../Components/Modal/customer_app/CatalogModal/CatalogListEditModal";
import CatalogListAddProductModal from "../../../Components/Modal/customer_app/CatalogModal/CatalogListAddProductModal";
import CatalogProductDetailModal from "../../../Components/Modal/customer_app/CatalogModal/CatalogProductDetailModal";

const Catalog = () => {
  localStorage.setItem("menu", "ProductCatalog");
  localStorage.setItem("submenu", "CatalogList");

  //   let userData = useSelector((state) => state?.user); // User Details from Store
  // let purchaseOrderData = useSelector(
  //   (state) => state?.purchasing?.purchase_order
  // );
  let purchaseOrderTable = useSelector(
    (state) => state?.purchasing?.purchase_order?.pagination
  );

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  let CategorydropdownOptions = [
    {
      id: "1",
      name: "Jackets(132)",
    },
    {
      id: "2",
      name: "Pantes(40)",
    },
    {
      id: "3",
      name: "Cap(60)",
    },
  ];
  let StatusdropdownOptions = [
    {
      id: "1",
      name: "All status",
    },
    {
      id: "2",
      name: "single status",
    },
    {
      id: "3",
      name: "double status",
    },
  ];
  let PricedropdownOptions = [
    {
      id: "1",
      name: "$100-500",
    },
    {
      id: "2",
      name: "$500-2000",
    },
    {
      id: "3",
      name: "$2000-7000",
    },
  ];
  let StoredropdownOptions = [
    {
      id: "1",
      name: "All store",
    },
    {
      id: "2",
      name: "Adiddas",
    },
    {
      id: "3",
      name: "Alian soly",
    },
  ];
  let LineItemStatusdropdownOptions = [
    {
      id: "1",
      name: "Active",
    },
    {
      id: "2",
      name: "Pending",
    },
    {
      id: "3",
      name: "Sold Out",
    },
  ];
  const data = [
    {
      id: 1,
      productName: "Arrow Classic Fit Cotton Shirt",
      productNumber: "AR12345",
      category: "Clothing",
      subCategory: "Shirts",
      stock: 100,
      sold: 80,
      views: 2500,
      price: 3000,
      discount: 15,
      discountedPrice: 2550,
      description:
        "A premium cotton shirt with a classic fit for formal occasions.",
      vendor: "Arrow",
      vendorLocation: "New York, USA",
      rating: 4.5,
      reviews: 35,
      weight: "300g",
      dimensions: "30cm x 20cm x 5cm",
      color: "White",
      material: "100% Cotton",
      careInstructions: "Machine wash, do not bleach",
      availability: "In Stock",
      imageUrl: "assets/img/product_img/P01.png",
      createdAt: "2024-03-01",
      updatedAt: "2024-03-10",
      tags: ["formal", "classic", "cotton"],
      warranty: "1 year",
      returnPolicy: "Return within 30 days",
      shipping: {
        availableRegions: ["USA", "India", "Europe"],
        estimatedDelivery: "5-7 business days",
        cost: "Free",
      },
      featured: true,
      seasonal: false,
      bestseller: true,
    },
    {
      id: 2,
      productName: "Calvin Klein Slim Fit Jeans",
      productNumber: "CK98765",
      category: "Clothing",
      subCategory: "Pants",
      stock: 200,
      sold: 150,
      views: 3500,
      price: 5000,
      discount: 10,
      discountedPrice: 4500,
      description:
        "Slim fit jeans made from premium stretch denim for ultimate comfort.",
      vendor: "Calvin Klein",
      vendorLocation: "California, USA",
      rating: 4.3,
      reviews: 60,
      weight: "700g",
      dimensions: "35cm x 25cm x 5cm",
      color: "Blue",
      material: "98% Cotton, 2% Elastane",
      careInstructions: "Machine wash cold, tumble dry low",
      availability: "In Stock",
      imageUrl: "assets/img/product_img/P02.png",
      createdAt: "2024-03-05",
      updatedAt: "2024-03-20",
      tags: ["jeans", "stretch", "slim fit"],
      warranty: "6 months",
      returnPolicy: "Return within 15 days",
      shipping: {
        availableRegions: ["USA", "Canada", "Australia"],
        estimatedDelivery: "4-6 business days",
        cost: "Free",
      },
      featured: false,
      seasonal: false,
      bestseller: false,
    },
    {
      id: 3,
      productName: "Flying Machine Denim Jacket",
      productNumber: "FM45678",
      category: "Clothing",
      subCategory: "Jackets",
      stock: 50,
      sold: 30,
      views: 4000,
      price: 6000,
      discount: 20,
      discountedPrice: 4800,
      description:
        "A stylish denim jacket with a rugged look, perfect for casual wear.",
      vendor: "Flying Machine",
      vendorLocation: "Mumbai, India",
      rating: 4.6,
      reviews: 75,
      weight: "800g",
      dimensions: "40cm x 30cm x 7cm",
      color: "Black",
      material: "100% Denim",
      careInstructions: "Hand wash cold, do not tumble dry",
      availability: "In Stock",
      imageUrl: "assets/img/product_img/P03.png",
      createdAt: "2024-02-25",
      updatedAt: "2024-03-15",
      tags: ["jacket", "denim", "casual"],
      warranty: "1 year",
      returnPolicy: "Return within 30 days",
      shipping: {
        availableRegions: ["India", "UK", "USA"],
        estimatedDelivery: "6-8 business days",
        cost: "Paid",
      },
      featured: true,
      seasonal: false,
      bestseller: true,
    },
    {
      id: 4,
      productName: "Tommy Hilfiger Casual Polo T-Shirt",
      productNumber: "TH76543",
      category: "Clothing",
      subCategory: "T-Shirts",
      stock: 300,
      sold: 270,
      views: 6000,
      price: 2500,
      discount: 10,
      discountedPrice: 2250,
      description:
        "A breathable polo T-shirt with a classic Tommy Hilfiger design.",
      vendor: "Tommy Hilfiger",
      vendorLocation: "Amsterdam, Netherlands",
      rating: 4.8,
      reviews: 100,
      weight: "200g",
      dimensions: "25cm x 15cm x 2cm",
      color: "Red",
      material: "95% Cotton, 5% Lycra",
      careInstructions: "Machine wash, iron on low heat",
      availability: "In Stock",
      imageUrl: "assets/img/product_img/P04.png",
      createdAt: "2024-01-15",
      updatedAt: "2024-02-25",
      tags: ["casual", "polo", "breathable"],
      warranty: "6 months",
      returnPolicy: "Return within 15 days",
      shipping: {
        availableRegions: ["USA", "UK", "India"],
        estimatedDelivery: "3-5 business days",
        cost: "Free",
      },
      featured: false,
      seasonal: false,
      bestseller: true,
    },
    {
      id: 5,
      productName: "Polo Assn. Slim Fit Chinos",
      productNumber: "PA54321",
      category: "Clothing",
      subCategory: "Pants",
      stock: 120,
      sold: 90,
      views: 3200,
      price: 3500,
      discount: 5,
      discountedPrice: 3325,
      description:
        "Slim fit chinos for a modern, stylish look. chinos for a modern.",
      vendor: "Polo Assn.",
      vendorLocation: "London, UK",
      rating: 4.4,
      reviews: 40,
      weight: "600g",
      dimensions: "30cm x 20cm x 4cm",
      color: "Beige",
      material: "100% Cotton",
      careInstructions: "Machine wash cold, do not bleach",
      availability: "In Stock",
      imageUrl: "assets/img/product_img/P05.png",
      createdAt: "2024-03-01",
      updatedAt: "2024-03-18",
      tags: ["chinos", "slim fit", "cotton"],
      warranty: "1 year",
      returnPolicy: "Return within 20 days",
      shipping: {
        availableRegions: ["UK", "USA", "Europe"],
        estimatedDelivery: "4-6 business days",
        cost: "Free",
      },
      featured: true,
      seasonal: false,
      bestseller: false,
    },
    {
      id: 6,
      productName: "Arrow Formal Blazer",
      productNumber: "AR22334",
      category: "Clothing",
      subCategory: "Jackets",
      stock: 80,
      sold: 60,
      views: 2400,
      price: 12000,
      discount: 20,
      discountedPrice: 9600,
      description: "A premium formal blazer for business and formal occasions.",
      vendor: "Arrow",
      vendorLocation: "New York, USA",
      rating: 4.7,
      reviews: 50,
      weight: "1.5kg",
      dimensions: "40cm x 30cm x 10cm",
      color: "Navy Blue",
      material: "Polyester Blend",
      careInstructions: "Dry clean only",
      availability: "In Stock",
      imageUrl: "assets/img/product_img/P06.png",
      createdAt: "2024-02-01",
      updatedAt: "2024-03-01",
      tags: ["formal", "blazer", "business"],
      warranty: "1 year",
      returnPolicy: "Return within 30 days",
      shipping: {
        availableRegions: ["USA", "Canada", "Europe"],
        estimatedDelivery: "5-7 business days",
        cost: "Free",
      },
      featured: true,
      seasonal: false,
      bestseller: false,
    },
    {
      id: 7,
      productName: "Calvin Kleint Leather Jacke",
      productNumber: "CK33445",
      category: "Clothing",
      subCategory: "Jackets",
      stock: 40,
      sold: 25,
      views: 4500,
      price: 15000,
      discount: 15,
      discountedPrice: 12750,
      description:
        "Stylish leather jacket perfect for casual outings and travel.",
      vendor: "Calvin Klein",
      vendorLocation: "California, USA",
      rating: 4.8,
      reviews: 80,
      weight: "2kg",
      dimensions: "45cm x 35cm x 15cm",
      color: "Black",
      material: "Genuine Leather",
      careInstructions: "Dry clean only",
      availability: "In Stock",
      imageUrl: "assets/img/product_img/P07.png",
      createdAt: "2024-02-10",
      updatedAt: "2024-03-05",
      tags: ["jacket", "leather", "stylish"],
      warranty: "2 years",
      returnPolicy: "Return within 30 days",
      shipping: {
        availableRegions: ["USA", "Europe", "Australia"],
        estimatedDelivery: "4-6 business days",
        cost: "Paid",
      },
      featured: false,
      seasonal: true,
      bestseller: true,
    },
    {
      id: 8,
      productName: "Flying Machine Graphic T-Shirt",
      productNumber: "FM44321",
      category: "Clothing",
      subCategory: "T-Shirts",
      stock: 150,
      sold: 120,
      views: 3100,
      price: 1800,
      discount: 10,
      discountedPrice: 1620,
      description: "Graphic T-shirt with bold prints for a trendy casual look.",
      vendor: "Flying Machine",
      vendorLocation: "Mumbai, India",
      rating: 4.2,
      reviews: 40,
      weight: "250g",
      dimensions: "30cm x 20cm x 3cm",
      color: "White",
      material: "100% Cotton",
      careInstructions: "Machine wash, do not bleach",
      availability: "In Stock",
      imageUrl: "assets/img/product_img/P08.png",
      createdAt: "2024-01-20",
      updatedAt: "2024-02-25",
      tags: ["graphic", "T-shirt", "casual"],
      warranty: "6 months",
      returnPolicy: "Return within 15 days",
      shipping: {
        availableRegions: ["India", "USA", "UK"],
        estimatedDelivery: "5-7 business days",
        cost: "Free",
      },
      featured: true,
      seasonal: false,
      bestseller: false,
    },
    {
      id: 9,
      productName: "Tommy Hilfiger Winter Jacket",
      productNumber: "TH56432",
      category: "Clothing",
      subCategory: "Jackets",
      stock: 70,
      sold: 50,
      views: 5000,
      price: 18000,
      discount: 20,
      discountedPrice: 14400,
      description:
        "Warm and comfortable winter jacket for extreme cold conditions.",
      vendor: "Tommy Hilfiger",
      vendorLocation: "Amsterdam, Netherlands",
      rating: 4.9,
      reviews: 95,
      weight: "2.5kg",
      dimensions: "50cm x 40cm x 15cm",
      color: "Grey",
      material: "Polyester Blend",
      careInstructions: "Dry clean only",
      availability: "In Stock",
      imageUrl: "assets/img/product_img/P09.png",
      createdAt: "2024-01-25",
      updatedAt: "2024-02-20",
      tags: ["winter", "jacket", "warm"],
      warranty: "1 year",
      returnPolicy: "Return within 30 days",
      shipping: {
        availableRegions: ["USA", "Canada", "Europe"],
        estimatedDelivery: "6-8 business days",
        cost: "Free",
      },
      featured: true,
      seasonal: true,
      bestseller: true,
    },
    {
      id: 10,
      productName: "Polo Assn. Sports Cap",
      productNumber: "PA67543",
      category: "Accessories",
      subCategory: "Caps",
      stock: 300,
      sold: 250,
      views: 2200,
      price: 1200,
      discount: 5,
      discountedPrice: 1140,
      description: "Lightweight and durable sports cap for outdoor activities.",
      vendor: "Polo Assn.",
      vendorLocation: "London, UK",
      rating: 4.1,
      reviews: 30,
      weight: "150g",
      dimensions: "20cm x 15cm x 5cm",
      color: "Navy Blue",
      material: "Polyester",
      careInstructions: "Hand wash only",
      availability: "In Stock",
      imageUrl: "assets/img/product_img/P010.png",
      createdAt: "2024-03-01",
      updatedAt: "2024-03-10",
      tags: ["sports", "cap", "durable"],
      warranty: "3 months",
      returnPolicy: "Return within 15 days",
      shipping: {
        availableRegions: ["UK", "USA", "Europe"],
        estimatedDelivery: "3-5 business days",
        cost: "Free",
      },
      featured: false,
      seasonal: false,
      bestseller: true,
    },
  ];
  const [modalShowEditProduct, setModalShowEditProduct] = useState(false);
  const [modalShowAddProduct, setModalShowAddProduct] = useState(false);
  const [modalShowDetailProduct, setModalShowDetailProduct] = useState(false);
  const showEditProduct = () => {
    setModalShowEditProduct(true);
  };
  const showAddProduct = () => {
    setModalShowAddProduct(true);
    };
    const showProductDetail = (e) => {
      setModalShowDetailProduct(true);
      console.log("product detail clicked",e)
    };
  const hideModal = () => {
    setModalShowEditProduct(false);
    setModalShowAddProduct(false);
    setModalShowDetailProduct(false);
  };
  const columns = [
    {
      name: "Product Name",
      selector: (row) => row.productName || "--",
      cell: (row) => (
        <div className="d-flex justify-content-center align-items-center cursor-pointer" onClick={(e)=>showProductDetail(e)} >
          <img
            src={row.imageUrl}
            alt="Profile"
            className="profileImageCss p-1"
            style={{ width: "60px" }}
          />
          <div className="ms-2" >
            <p className="m-0 p-0 pt-1 fw-bold">{row.productName} </p>
            <p className="m-0 p-0 pt-1">{row.productNumber} </p>
          </div>
        </div>
      ),
      sortable: true,
      width: "400px",
    },
    {
      name: "Purchase Unit Price",
      selector: (row) => row.price || "--",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.price} </p>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Stock",
      selector: (row) => row.stock || "--",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.stock} </p>
        </div>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Views",
      selector: (row) => row.views || "--",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.views} </p>
        </div>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Status",
      selector: (row) => row.purchaseType || "--",
      cell: (row) => (
        <div>
          <ComboboxField
            label=""
            placeholder="Please select the status"
            data={LineItemStatusdropdownOptions}
            id="category"
            iconClassName="dropdownIcon"
            // className="z-9"
            name="category"
            code="categorycode"
            description="countryDesc"
            //   setValue={formInputs.country}
            // getvalue={setDropdownData}
          />
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      selector: (row) => row.purchaseType || "--",
      cell: (row) => (
        <div>
          <button
            className="btn d-flex align-items-center w-100"
            onClick={showEditProduct}
          >
            <i class="bi bi-pencil-square pe-2"></i>{" "}
            <sapn className="m-0 p-0 pt-1">Edit </sapn>
          </button>
        </div>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Delete",
      selector: (row) => row.purchaseType || "--",
      cell: (row) => (
        <div>
          <button class="btn btn-danger">
            <i class="bi bi-trash3"></i>
          </button>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
  ];
 
  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
          <h2 className="mainTitle">Catalog </h2>
            <p className="subText">Catalog </p>
            <hr />
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-down"
                            : "bi-chevron-right"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AF</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">Advanced Filter</span>
                      <span className="subHeaderTitle">Advanced Filter</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4 d-flex align-items-center">
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding mt-4"
                      >
                        <SearchField
                          name="searchKey"
                          // value={getCommonSearchTxt}
                          // onChange={(event) =>
                          //   setCommonSearchTxt(event.target.value)
                          // }
                          // onClick={() => handleSearchFuncApi()}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Show"
                          placeholder="Please select the Category"
                          data={CategorydropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                          //   setValue={formInputs.country}
                          // getvalue={setDropdownData}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Sort by"
                          placeholder="Please select the Category"
                          data={CategorydropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                          //   setValue={formInputs.country}
                          // getvalue={setDropdownData}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Category"
                          placeholder="Please select the Category"
                          data={CategorydropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                          //   setValue={formInputs.country}
                          // getvalue={setDropdownData}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Status"
                          placeholder="Please select the status"
                          data={StatusdropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                          //   setValue={formInputs.country}
                          // getvalue={setDropdownData}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Price"
                          placeholder="Please select the Price"
                          data={PricedropdownOptions}
                          id="price"
                          iconClassName="dropdownIcon"
                          name="price"
                          code="pricecode"
                          description="countryDesc"
                          //   setValue={formInputs.country}
                          // getvalue={setDropdownData}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Store"
                          placeholder="Please select the status"
                          data={StoredropdownOptions}
                          id="store"
                          iconClassName="dropdownIcon"
                          name="store"
                          code="storecode"
                          description="countryDesc"
                          //   setValue={formInputs.country}
                          // getvalue={setDropdownData}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={{ span: 2, offset: 8 }}
                        lg={{ span: 2, offset: 8 }}
                        className="commTopButtonRightLeftPadding"
                      >
                        <button className="btnTable">Search</button>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        lg={2}
                        className="commTopButtonRightLeftPadding"
                      >
                        <button className="btnTable">Referesh</button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-down"
                            : "bi-chevron-right"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">P</span>
                    </Col>
                    <Col xs={6} md={4} lg={4}>
                      <span className="headerTitle">Products</span>
                      <span className="subHeaderTitle">Products</span>
                    </Col>

                    <Col xs={12} md={8} lg={8} className="headerButtonAlign">
                      <Col xs={3} md={3} lg={3} className="">
                        <button
                          className="btnTable d-flex justify-content-center"
                          onClick={showAddProduct}
                        >
                          <p className="m-0 me-2">Add Product</p>
                          <i class="bi bi-plus-square"></i>
                        </button>
                      </Col>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          // progressPending={purchaseOrderData?.status}
                          paginationRowsPerPageOptions={
                            CustomerConstants.ROW_PER_PAGE
                          }
                          pagination
                          paginationServer
                          paginationTotalRows={purchaseOrderTable.total}
                          //   onChangeRowsPerPage={handlePerRowsChange}
                          //   onChangePage={handlePageChange}
                          //   onSelectedRowsChange={handleRowSelected}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <CatalogListEditModal
        show={modalShowEditProduct}
        onHide={() => hideModal()}
        // dropdownData={getAccountGroup}
      />
      <CatalogListAddProductModal
        show={modalShowAddProduct}
        onHide={() => hideModal()}
        // dropdownData={getAccountGroup}
      />
         <CatalogProductDetailModal
        show={modalShowDetailProduct}
        onHide={() => hideModal()}
      />
    </main>
  );
};

export default Catalog;

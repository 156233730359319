import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { ComboboxField } from '../../../Components/formElements/ComboboxField';
import Layout from "../../../../Layout/Layout";
import { useNavigate } from "react-router-dom";
import Constants from "../../../../common/CustomerConstants";
import configFromEnv from "../../../../services/configuration";
import Moment from "react-moment";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch
import { InputField } from "../../../../Components/formElements/InputField";

//Serivce
import ItemService from "../../../../services/customer_appCommonService";
import DataTable from "react-data-table-component";
import {
  addCreateDelivery,
  addDeliveryID,
  addInvoiceID,
  addSalesOrderSelectedDetails,
} from "../../../../redux/feature/customer_app/preSalesSlice";
import { currencyFormat, formatDateTime } from "../../../../common/Validations";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";

const SalesOrderDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Sales");
  localStorage.setItem("submenu", "Sales Order");

  let salesOrderID = useSelector((state) => state?.preSales?.salesOrderID); // User Details from Store
  let userInfo = useSelector((state) => state?.user?.data); // User Details from Store

  const [formInputs, setFormInputs] = useState({
    organisationName: "",
    companyName: "",
    email: "",
    firstName: "",
    lastName: "",
  });
  const [getSODeliveryHistory, setSODeliveryHistory] = useState([]);
  const [getSOInvoiceHistory, setSOInvoiceHistory] = useState([]);

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };

  // eslint-disable-next-line no-unused-vars
  const [getDisabledStatus, setDisabledStatus] = useState(true);

  const columns = [
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Image",
      width: "80px",
      cell: (row) => (
        <img
          src="assets/img/profile-img.png"
          alt="Profile"
          className="rounded-circle profileImageCss"
          style={{ width: "40px" }}
        />
      ),
    },

    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.materialCode} </p>
          <p className="m-0 p-0">{row.materialDescription} </p>
        </div>
      ),
    },
    {
      name: "Material Group",
      selector: (row) => row.materialGroup,
      sortable: true,
      width: "180px",
    },
    {
      name: "Sales Quantity",
      selector: (row) => row.salesQuantity,
      sortable: true,
      width: "180px",
    },
    {
      name: "Oder Unit",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "120px",
    },
    {
      name: "Unit Price",
      selector: (row) => row.orderPriceUnit,
      sortable: true,
      width: "120px",
    },
    {
      name: "Item Value",
      // selector: (row) => row.netValue,
      cell: (row) => (
        <span className="numberFormate m-0 p-0 pt-1 fw-bold">
          {currencyFormat(Number(row.netValue).toFixed(2))}
        </span>
      ),

      sortable: true,
      width: "180px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode,
      sortable: true,
      width: "120px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      sortable: true,
      width: "140px",
    },
    {
      name: "Tax Amount",
      selector: (row) => row.taxAmount,
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Price Unit Qty",
    //   selector: (row) => row.priceUnit,
    //   sortable: true,
    //   width: "200px",
    // },
    {
      name: "Tax Code",
      selector: (row) => row.taxId,
      sortable: true,
      width: "200px",
    },
    {
      name: "Tax Desc",
      selector: (row) => row.taxIdDesc,
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Free Of Cost",
    //   selector: (row) => row.freeCost,
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "Purch Req Nr",
    //   selector: (row) => row.requestNumber,
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "PR Item Nr",
    //   selector: (row) => row.prItemNr,
    //   sortable: true,
    //   width: "200px",
    // },
  ];

  const deliveryDetailedPage = (inquiryID) => {
    dispatch(addDeliveryID(inquiryID));
    navigate(Constants.SALES_DELIVERY_DETAILS);
  };

  const invoiceDetailedPage = (invoiceNumber) => {
    dispatch(addInvoiceID(invoiceNumber));
    navigate(Constants.CUSTOMER_INVOICE_DETAILS);
  };

  // Delivery Table Col
  const deliveryColumns = [
    {
      name: "Document",
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => deliveryDetailedPage(row.deliveryNumber)}
        >
          {row.deliveryNumber}{" "}
        </button>
      ),
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => row.deliveryStatusHeader,
      cell: (row) => (
        <>    
        <TableStatusField status={row.deliveryStatusHeader}/>
        </>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Attachments",
      selector: (row) => row.attachments,
      cell: (row) => (
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center"
        >
          <img
            src="assets/img/newIcons/attached-file.png"
            alt="attachments"
            style={{ width: "25px" }}
            className="mt-1"
          />
        </div>
      ),
      sortable: true,
      width: "130px",
    },

    {
      name: "Date",
      selector: (row) => formatDateTime(row?.purchasingDocumentDate) || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Value",
      cell: (row) => (
        <span className="numberFormate m-0 p-0 pt-1 fw-bold">
          {currencyFormat(Number(row.customerTotalInvoiceValue).toFixed(2))}
        </span>
      ),
      selector: (row) => row.customerTotalInvoiceValue || "--",
      sortable: true,
      width: "120px",
    },
    {
      name: "Currency ",
      cell: (row) => (
        <>
          <span className="m-0 p-0 pt-1 fw-bold">{row.currency}</span>
        </>
      ),
      selector: (row) => row.currency || "--",
      sortable: true,
      width: "110px",
    },
    {
      name: "Customer",
      selector: (row) => row.customerCode,
      sortable: true,
      width: "110px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.customerCode} </p>
          <p className="m-0 p-0">{row.customerName} </p>
        </div>
      ),
    },
    {
      name: "Payment Terms",
      selector: (row) => row.paymentTermsCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.paymentTerms} </p>
        </div>
      ),
    },
    {
      name: "Inco Terms",
      selector: (row) => row.incoTermsCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 ">{row.incoTerms} </p>
        </div>
      ),
    },
    {
      name: "Created Date",
      selector: (row) => formatDateTime(row.createdAt),
      cell: (row) => (
        <Moment format={configFromEnv.TIME_FORMATE_WITH_TIME}>
          {row.createdAt}
        </Moment>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "Company",
      cell: (row) => (
        <>
          <span> {row.companyCodeDesc} </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Quotation",
      cell: (row) => (
        <>
          <span> {row.Quotation || "--"} </span>
        </>
      ),
      width: "150px",
    },
  ];

  // Invoice Table Col
  const invoiceColumns = [
    {
      name: "Invoice Number",
      selector: (row) => row.documentNumber,
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => invoiceDetailedPage(row.documentNumber)}
        >
          {row.documentNumber}{" "}
        </button>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "Status",
      selector: (row) => row.invoiceStatusHeader,
      cell: (row) => (
        <>         
          <TableStatusField status={row.invoiceStatusHeader}/>
        </>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Invoice Date",
      selector: (row) => row.customerInvoiceDate,
      sortable: true,
      width: "130px",
    },
    {
      name: "Vendor",
      selector: (row) => row.customerCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.customerCode} </p>
          <p className="m-0 p-0">{row.customerName} </p>
        </div>
      ),
    },
    {
      name: "Invoice Value",
      selector: (row) => row.customerTotalInvoiceValue,
      cell: (row) => (
        <span className="numberFormate m-0 p-0 pt-1 fw-bold">
          {currencyFormat(Number(row.customerTotalInvoiceValue).toFixed(2))}
        </span>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "Currency",
      selector: (row) => row.customerInvoiceCurrency,
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.customerInvoiceCurrency} </p>
          <p className="m-0 p-0">{row.currency} </p>
        </div>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "SO Date",
      selector: (row) => formatDateTime(row?.salesDocumentDate) || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "SAP Invoice",
      selector: (row) => row.invoiceItemNr,
      sortable: true,
      width: "150px",
    },
    {
      name: "Posted On",
      selector: (row) => row.grnItemNr,
      sortable: true,
      width: "130px",
    },
    {
      name: "Cancelled INV Nr",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "Cancelled INV Date",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "Cancelled INV Remark",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "200px",
    },
  ];

  //Create CreateDelivery
  const createDelivery = (data) => {
    dispatch(addCreateDelivery(data));
    navigate(Constants.SALES_DELIVERY_CREATION);
  };

  const loadFirstLevelData = async (page) => {
    // console.log(">> salesOrderID", salesOrderID);
    if (salesOrderID) {
      let obj = {
        documentNumber: salesOrderID,
      };
      await ItemService.getSaleOrderDetail(obj).then((item) => {
        if (item?.status === 200) {
          // console.log(">> item", item);
          setDisabledStatus(true);
          setFormInputs(item?.data[0]);
          dispatch(addSalesOrderSelectedDetails(item?.data[0]));

          loadDeliveryHistoryData();
          loadInvoiceHistoryData();
        } else {
          setFormInputs([]);
          console.log(">> Error: Loading getQuotationDatail");
        }
      });
    } else {
      navigate(Constants.SALES_SALES_ORDER_LIST);
    }
  };

  const loadDeliveryHistoryData = async () => {
    if (salesOrderID) {
      let obj = {
        salesDocumentNr: salesOrderID,
      };
      await ItemService.getSODeliveryHistory(obj).then((item) => {
        if (item?.status === 200) {
          setSODeliveryHistory(item?.data);
        } else {
          setSODeliveryHistory([]);
          console.log(">> Error: Loading getSODeliveryHistory");
        }
      });
    } else {
      console.log(">> Error: salesOrderID Not found");
    }
  };

  const loadInvoiceHistoryData = async () => {
    if (salesOrderID) {
      let obj = {
        salesDocumentNr: salesOrderID,
      };
      await ItemService.getSOInvoiceHistory(obj).then((item) => {
        if (item?.status === 200) {
          setSOInvoiceHistory(item?.data);
        } else {
          setSOInvoiceHistory([]);
          console.log(">> Error: Loading getSOInvoiceHistory");
        }
      });
    } else {
      console.log(">> Error: salesOrderID Not found");
    }
  };

  useEffect(() => {
    loadFirstLevelData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <h2 className="mainTitle">
              Sales Order Details :{" "}
              <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                {salesOrderID}
              </span>
              <span>
                {formInputs?.status === "Pending" ? (
                  <span className="bg-table-status bg-table-info-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Sent" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Draft" ? (
                  <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Completed" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Reject" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Deactivated" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : (
                  formInputs?.status
                )}
              </span>
            </h2>
            <p className="subText">Sales Order Information </p>
            <hr />

            {/* 01 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">HD</span>
                    </Col>
                    <Col xs={12} md={3} lg={3}>
                      <span className="headerTitle">Header Details</span>
                      <span className="subHeaderTitle">Header Details</span>
                    </Col>

                    {userInfo?.roles?.length > 0 &&
                      userInfo?.roles[0].roleId === "STORE" && (
                        <>
                          <Col
                            xs={12}
                            md={8}
                            lg={8}
                            className="headerButtonAlign"
                          >
                            <Col xs={4} md={3} lg={3} className="">
                              <button
                                className="btnTable"
                                onClick={() =>
                                  createDelivery(formInputs?.items)
                                }
                              >
                                <i className="bi bi-calendar-check"></i> Create
                                Delivery
                              </button>
                            </Col>
                          </Col>
                        </>
                      )}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Sales Document Nr"
                          name="salesDocumentNr"
                          extraLabel=""
                          placeholder=""
                          value={formInputs?.salesDocumentNr || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col> */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Document Number"
                          name="salesDocumentNr"
                          placeholder=""
                          value={formInputs?.salesDocumentNr || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Document Date"
                          name="salesDocumentDate"
                          placeholder=""
                          value={formInputs?.salesDocumentDate || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Net Value"
                          name="salesDocumentValue"
                          placeholder=""
                          value={currencyFormat(
                            Number(formInputs?.salesDocumentValue).toFixed(2)
                          )}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency"
                          name="currency"
                          placeholder=""
                          value={`${formInputs?.currencyCode} - ${formInputs?.currency}`}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer Code"
                          name="customerCode"
                          placeholder=""
                          value={formInputs?.customerCode || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer Name"
                          name="customerName"
                          placeholder=""
                          value={formInputs?.customerName || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Payment Terms"
                          name="paymentTerms"
                          placeholder=""
                          value={formInputs?.paymentTerms || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Inco Terms"
                          name="incoTerms"
                          placeholder=""
                          value={formInputs?.incoTerms || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Code "
                          name="companyCodeCode"
                          id="companyCodeCode"
                          value={formInputs?.companyCodeCode}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Purchse Type"
                          name="purchasingRemark"
                          placeholder=""
                          value={formInputs?.purchaseType || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding mt-4 pt-3"
                      >
                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion">
                            Header Text
                          </label>
                          <span
                            style={{ marginLeft: "15px" }}
                            // onClick={() =>
                            //   headerTextShowModal(formInputs?.headerTextData)
                            // }
                          >
                            <i className="bi bi-calendar2-week iconTable"></i>
                          </span>
                          <span className="suppliersIcon">
                            {" "}
                            {formInputs?.headerTextData?.length || 0}{" "}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* 02 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LI</span>
                    </Col>
                    <Col xs={12} md={3} lg={3}>
                      <span className="headerTitle">Line Items</span>
                      {/* <span className="subHeaderTitle">Header Details</span> */}
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={formInputs?.items}
                          // progressPending={purchaseOrderData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          // paginationTotalRows={purchaseOrderTable.total}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelected}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* 03 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">DH</span>
                    </Col>
                    <Col xs={12} md={3} lg={3}>
                      <span className="headerTitle">Delivery History</span>
                      {/* <span className="subHeaderTitle">Header Details</span> */}
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={deliveryColumns}
                          data={getSODeliveryHistory}
                          // progressPending={purchaseOrderData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          // paginationTotalRows={purchaseOrderTable.total}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelected}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* 04 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">IH</span>
                    </Col>
                    <Col xs={12} md={3} lg={3}>
                      <span className="headerTitle">Invoice History</span>
                      {/* <span className="subHeaderTitle">Header Details</span> */}
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={invoiceColumns}
                          data={getSOInvoiceHistory}
                          // progressPending={purchaseOrderData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          // paginationTotalRows={purchaseOrderTable.total}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelected}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default SalesOrderDetails;

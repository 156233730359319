import { useState } from "react";
import "./Materialcatalog.scss";

const StarRating = ({ totalStars = 5, onRatingChange, fixedRating, enableHover = true }) => {
  const [rating, setRating] = useState(fixedRating || 0);
  const [hover, setHover] = useState(0);

  const handleRatingChange = (newRating) => {
    if (!fixedRating) { // Prevent updates if fixedRating is provided
      setRating(newRating);
      if (onRatingChange) {
        onRatingChange(newRating);
      }
    }
  };

  return (
    <div className="star-rating">
      {[...Array(totalStars)].map((_, index) => {
        const starValue = index + 1;
        return (
          <span
            key={index}
            className={`star ${starValue <= (hover || rating) ? 'filled' : ''}`}
            onClick={() => handleRatingChange(starValue)}
            onMouseEnter={enableHover ? () => setHover(starValue) : null}
            onMouseLeave={enableHover ? () => setHover(0) : null}
            style={{
              cursor: fixedRating ? 'default' : 'pointer',
              fontSize: '24px',
            }}
          >
            ★
          </span>
        );
      })}
    </div>
  );
};

export default StarRating;

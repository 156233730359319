/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

//Redux
import { useDispatch, useSelector } from "react-redux"; //useDispatch

//Components
import Layout from "../../../Layout/Layout";
import Constants from "../../../common/SupplierConstants";
import CommonItemLevelDatatableModal from "../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import { getPurchaseOrder } from "../../../redux/feature/supplier_app/purchasingSlice";
import GatePassCommonFilter from "../../../Components/Filter/GatePass/GatePassCommonFilter";
import { InputField } from "../../../Components/formElements/InputField";

//Serivce
import ItemService from "../../../services/supplier_appCommonService";
import { capitalize } from "../../../common/Validations";
import { TableStatusField } from "../../../Components/formElements/TableStatusField";

const GatepassDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Gatepass");
  localStorage.setItem("submenu", "-");

  let asn_data = useSelector((state) => state?.gatepass?.data); // User Details from Store

  let gatepassNr = useSelector((state) => state?.gatepass?.gatepassNr); // User Details from Store

  // Format the price above to USD using the locale, style, and currency.
  // let USDollar = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [getShipment, setShipment] = useState();
  const [getHeaderData, setHeaderData] = useState(false);
  // const [totalRows, setTotalRows] = useState(10);
  // const [perPage, setPerPage] = useState(10);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");

  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };
  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const hideModal = () => {
    setModalShow(false);
  };

  const loadInitialPageData = async () => {
    setLoading(true);
    if (gatepassNr) {
      let filteredData = asn_data.filter(
        (items) => items.gatepassNr === gatepassNr
      );
      console.log(">> filteredData", filteredData);
      setHeaderData(filteredData[0]);
      let itemData = filteredData[0]?.items;

      let shipmentData = filteredData[0][filteredData[0].shippingType];
      let obj = {
        [filteredData[0].shippingType]: shipmentData,
      };
      setSelectedShipment(filteredData[0].shippingType);
      setShipment(obj);

      setData(itemData);
      setLoading(false);
    } else {
      navigate(Constants.GATEPASS_GATEPASS_LIST);
    }
  };

  useEffect(() => {
    loadInitialPageData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatepassNr, asn_data]);

  const columns = [
    {
      name: "Gatepass Item",
      selector: (row) => row.gpItemNr,
      sortable: true,
      width: "135px",
    },
    {
      name: "ASN Item",
      selector: (row) => row.asnItemNr,
      sortable: true,
      width: "110px",
    },
    {
      name: "PO Number",
      selector: (row) => row.purchasingDocumentNr,
      sortable: true,
      width: "120px",
    },
    {
      name: "PO Item",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "100px",
    },
    // {
    //   name: "PO Number",
    //   cell: (row) => (
    //     <button
    //       className="btnInfo"
    //       onClick={() => detailedPage(row.purchasingDocumentNr)}
    //     >
    //       {row.purchasingDocumentNr}{" "}
    //     </button>
    //   ),
    //   width: "150px",
    // },
    {
      name: "Item Status",
      selector: (row) => row.gpStatusItem,
      cell: (row) => (
        <>
          <TableStatusField status={row.gpStatusItem} />
        </>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Material",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "180px",
    },
    {
      name: "Description",
      selector: (row) => row.materialDescription,
      sortable: true,
      width: "180px",
    },
    {
      name: "Order Quantity",
      selector: (row) => row.asnQuantity,
      sortable: true,
      width: "180px",
    },
    {
      name: "UOM",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "180px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      sortable: true,
      width: "180px",
    },
  ];

  /* radio buttons */
  const [selectedShipment, setSelectedShipment] = useState("");

  /* raddio buttons input fields */
  const renderShipmentDetails = () => {
    switch (selectedShipment) {
      case "road":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Road Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Truck No"
                    name="truckNo"
                    id="truckNo"
                    value={getShipment?.road?.truckNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Transporter/Courier"
                    name="transporterCourier"
                    id="transporterCourier"
                    value={getShipment?.road?.transporterCourier}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    id="dateOfDispatch"
                    value={getShipment?.road?.dateOfDispatch}
                    type="date"
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="LR No"
                    name="lrNo"
                    id="lrNo"
                    value={getShipment?.road?.lrNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="LR Date"
                    name="lrDate"
                    id="lrDate"
                    value={getShipment?.road?.lrDate}
                    type="date"
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Expected Delivery Date"
                    name="expectedDeliveryDate"
                    id="expectedDeliveryDate"
                    value={getShipment?.road?.expectedDeliveryDate}
                    type="date"
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="EWaybill No"
                    name="ewaybillNo"
                    id="ewaybillNo"
                    value={getShipment?.road?.ewaybillNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E Waybill Value"
                    name="eWaybillValue"
                    id="eWaybillValue"
                    value={getShipment?.road?.eWaybillValue}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Challan No"
                    name="challanNo"
                    id="challanNo"
                    value={getShipment?.road?.challanNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Challan Date"
                    name="challanDate"
                    id="challanDate"
                    value={getShipment?.road?.challanDate}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="No. of Packages"
                    name="noOfPackages"
                    id="noOfPackages"
                    value={getShipment?.road?.noOfPackages}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Weight of Consignment"
                    name="weightOfConsignment"
                    id="weightOfConsignment"
                    value={getShipment?.road?.weightOfConsignment}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Place of Loading"
                    name="placeOfLoading"
                    id="placeOfLoading"
                    value={getShipment?.road?.placeOfLoading}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Place of Delivery"
                    name="placeOfDelivery"
                    id="placeOfDelivery"
                    value={getShipment?.road?.placeOfDelivery}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Mode of Packing"
                    name="modeOfPacking"
                    id="modeOfPacking"
                    value={getShipment?.road?.modeOfPacking}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Driver Name"
                    name="driverName"
                    id="driverName"
                    value={getShipment?.road?.driverName}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Driver Number"
                    name="driverNumber"
                    id="driverNumber"
                    value={getShipment?.road?.driverNumber}
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      case "air":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Air Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    id="dateOfDispatch"
                    value={getShipment?.air?.dateOfDispatch}
                    disabled={true}
                    type="date"
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="MAWB No"
                    name="mawbNo"
                    id="mawbNo"
                    value={getShipment?.air?.mawbNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="MAWB Date"
                    name="mawbDate"
                    type="date"
                    id="mawbDate"
                    value={getShipment?.air?.mawbDate}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Airport of Shipment"
                    name="airportOfShipment"
                    id="airportOfShipment"
                    value={getShipment?.air?.airportOfShipment}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Airport of Arrival"
                    name="airportOfArrival"
                    id="airportOfArrival"
                    value={getShipment?.air?.airportOfArrival}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Transporter Name"
                    name="transporterName"
                    id="transporterName"
                    value={getShipment?.air?.transporterName}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E waybill No"
                    name="ewaybillNo"
                    id="ewaybillNo"
                    value={getShipment?.air?.ewaybillNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Expected Deilevery Date"
                    name="expectedDeliveryDate"
                    id="expectedDeliveryDate"
                    value={getShipment?.air?.expectedDeliveryDate}
                    type="date"
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E Waybill Value"
                    name="eWaybillValue"
                    id="eWaybillValue"
                    value={getShipment?.air?.eWaybillValue}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Courier"
                    name="courier"
                    id="courier"
                    value={getShipment?.air?.courier}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Entry No"
                    name="billOfEntryNo"
                    id="billOfEntryNo"
                    value={getShipment?.air?.billOfEntryNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Entry Date"
                    name="billOfEntryDate"
                    id="billOfEntryDate"
                    value={getShipment?.air?.billOfEntryDate}
                    type="date"
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="HAWB No"
                    name="hawbNo"
                    id="hawbNo"
                    value={getShipment?.air?.hawbNo}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="HAWB Date"
                    name="hawbDate"
                    id="hawbDate"
                    value={getShipment?.air?.hawbDate}
                    type="date"
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="No. of Packages"
                    name="NoofPackages"
                    id="noOfPackages"
                    value={getShipment?.air?.noOfPackages}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Weight of Consignment"
                    name="weightOfConsignment"
                    id="weightOfConsignment"
                    value={getShipment?.air?.weightOfConsignment}
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      case "courier":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Courier Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    id="dateOfDispatch"
                    value={getShipment?.courier?.dateOfDispatch}
                    disabled={true}
                    type="date"
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Courier Name"
                    name="courierName"
                    id="courierName"
                    value={getShipment?.courier?.courierName}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Docket No"
                    name="docketNo"
                    id="docketNo"
                    value={getShipment?.courier?.docketNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Docket Date"
                    name="docketDate"
                    type="date"
                    id="docketDate"
                    value={getShipment?.courier?.docketDate}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E Waybill No"
                    name="ewaybillNo"
                    id="ewaybillNo"
                    value={getShipment?.courier?.ewaybillNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Expected Arrival Date"
                    name="expectedArrivalDate"
                    type="date"
                    id="expectedArrivalDate"
                    value={getShipment?.courier?.expectedArrivalDate}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="No. of Packages"
                    name="noOfPackages"
                    id="noOfPackages"
                    value={getShipment?.courier?.noOfPackages}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Weight of Consignment"
                    name="weightOfConsignment"
                    id="weightOfConsignment"
                    value={getShipment?.courier?.weightOfConsignment}
                    // type="date"
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Mode of Packing"
                    name="modeOfPacking"
                    id="modeOfPacking"
                    value={getShipment?.courier?.modeOfPacking}
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      case "ship":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Ship Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Lading"
                    name="billOfLading"
                    id="billOfLading"
                    value={getShipment?.ship?.billOfLading}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Ladin Date"
                    name="billOfLadinDate"
                    id="billOfLadinDate"
                    value={getShipment?.ship?.billOfLadinDate}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Shippine Line"
                    name="shippineLine"
                    id="shippineLine"
                    value={getShipment?.ship?.shippineLine}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Internal Reference Number"
                    name="internalReferenceNumber"
                    id="internalReferenceNumber"
                    value={getShipment?.ship?.internalReferenceNumber}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Booking Number"
                    name="bookingNumber"
                    id="bookingNumber"
                    value={getShipment?.ship?.bookingNumber}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    type="date"
                    id="dateOfDispatch"
                    value={getShipment?.ship?.dateOfDispatch}
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">
              Gatepass Details : {gatepassNr}
              <span>
                {getHeaderData?.gpStatusHeader === "ASN Cancelled" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {getHeaderData?.gpStatusHeader}
                  </span>
                ) : getHeaderData?.gpStatusHeader === "In Transit" ? (
                  <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                    {getHeaderData?.gpStatusHeader}
                  </span>
                ) : getHeaderData?.gpStatusHeader === "Gatepass Created" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {getHeaderData?.gpStatusHeader}
                  </span>
                ) : (
                  getHeaderData?.gpStatusHeader
                )}
              </span>
            </h2>
            <p className="subText">Gatepass Information </p>
            <hr />

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">HR</span>
                    </Col>
                    <Col xs={12} md={2} lg={2}>
                      <span className="headerTitle">Header Details</span>
                      <span className="subHeaderTitle"> </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Gatepass Number"
                          id="gatepassNr"
                          name="gatepassNr"
                          value={getHeaderData?.gatepassNr || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Gatepass Date"
                          id="createdOn"
                          name="createdOn"
                          value={getHeaderData?.gatepassDate || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Gatepass Time"
                          id="gatepassTime"
                          name="gatepassTime"
                          value={getHeaderData?.gatepassTime || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vehicle Number"
                          id="vechicleNumber"
                          name="vechicleNumber"
                          value={getHeaderData?.vechicleNumber || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vehicle In Date"
                          id="vechicleInDate"
                          name="vechicleInDate"
                          value={getHeaderData?.vechicleInDate || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vehicle In Time"
                          id="vechicleInTime"
                          name="vechicleInTime"
                          value={getHeaderData?.vechicleInTime || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vehicle out Time"
                          id="vehicleOutTime"
                          name="vehicleOutTime"
                          value={getHeaderData?.vehicleOutTime || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Inbound Delivery"
                          id="sapInboundDelivery"
                          name="sapInboundDelivery"
                          value={getHeaderData?.sapInboundDelivery || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vendor Code"
                          id="vendorCode"
                          name="vendorCode"
                          value={getHeaderData?.vendorCode || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vendor Name"
                          id="vendorName"
                          name="vendorName"
                          value={getHeaderData?.vendorName || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Shipment Type"
                          id="shippingType"
                          name="shippingType"
                          value={capitalize(getHeaderData?.shippingType) || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoice Number"
                          id="vendorInvoiceNo"
                          name="vendorInvoiceNo"
                          value={getHeaderData?.vendorInvoiceNo || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoice Date"
                          id="vendorInvoiceDate"
                          name="vendorInvoiceDate"
                          value={getHeaderData?.vendorInvoiceDate || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vendor Total Invoice"
                          id="vendorTotalInvoiceValue"
                          name="vendorTotalInvoiceValue"
                          value={getHeaderData?.vendorTotalInvoiceValue || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency"
                          id="vendorInvoiceCurrency"
                          name="vendorInvoiceCurrency"
                          value={getHeaderData?.vendorInvoiceCurrency || ""}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Radio buttons */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">MS</span>
                    </Col>

                    <Col xs={8} md={8} lg={4}>
                      <span className="headerTitle">Mode of Shipment</span>
                      <span className="subHeaderTitle">Shipment Details</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <div>{renderShipmentDetails()}</div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LI</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Line Items</span>
                      <span className="subHeaderTitle"> </span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <GatePassCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          progressPending={loading}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />
    </main>
  );
};

export default GatepassDetailPage;

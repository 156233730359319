import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import "./Materialcatalog.scss";
import StarRating from "./StarRating";
// import { InputField } from "../../../Components/formElements/InputField";
// import { useNavigate } from "react-router-dom";

const CatalogMaterialDetailsModal = React.forwardRef((props, ref) => {

  useEffect(() => {
    // console.log(">> props?.dropdownData", props?.dropdownData);
    // setDropdownOption(props?.dropdownData);
    const handleEscapeKey = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        props.onHide();
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  // const onInputChange = ({ target: { name, value } }) => {
  //   setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  // };
  /*   const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true); */
  /* 
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  }; */
  return (
    // backdrop="static"
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      keyboard={false}
      size="xl"
      ref={ref}
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {/* <i className="bi bi-plus-lg"></i>  */}
          Material Details
          {/* <p className="subText">
            At supplier_app, every person has a role, each with its own level of
            access.
          </p> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        {/* accordin1 */}
        <Row>
          <Col
            xs={12}
            md={4}
            lg={4}
            className="commLeftRightPadding d-flex align-items-start mb-4"
          >
            <img
              className="me-4"
              style={{ wedth: "540px", height: "320px" }}
              src={props?.product?.imageUrl}
              alt=""
            />
          </Col>
          <Col
            xs={12}
            md={8}
            lg={8}
            className="pop-module commLeftRightPadding d-flex align-items-start mb-4"
          >
            <div>
              <div className="product-title">
                <p>
                  {props?.product?.productName} | {props?.product?.category} |
                  {props?.product?.subCategory} |
                  {props?.product?.careInstructions}
                </p>
              </div>
              <div className="rating">
                <p className="m-0">
                  <StarRating
                    totalStars={5}
                    fixedRating={props?.product?.rating}
                    enableHover={false}
                  />
                </p>
              </div>
              <div className="product-details">
                <span>{props?.product?.description},</span>
                <span>{props?.product?.dimensions},</span>
                <span>{props?.product?.weight},</span>
                <span>{props?.product?.material}.</span>
                <span className="price">${props?.product?.price}</span>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-end">
          <Col xs={6} md={3} lg={3} className="commLeftRightPadding">
            <button
              type="button"
              onClick={() => props.onHide()}
              className="btn btn-danger mt-4 w-full justify-center rounded-md"
            >
              Cancel
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
});

export default CatalogMaterialDetailsModal;

import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { statusData } from "./dataColor";

const SuppliersColumnChart = (props) => {
  const chartRef = useRef(null);
  // console.log(">> props ", props.chartdata);

  let defaultSeries = [
    {
      name: "",
      data: [],
    },
  ];
  let defaultCategories = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const seriesData = props?.chartdata?.series || defaultSeries;
  const categoriesData = props?.chartdata?.categories || defaultCategories;

  // Map the seriesData names to the statusData colors dynamically
  let colors = seriesData.map((series) => {
    const status = statusData.find((item) => item.message === series.name);
    return status?.color || "#808080"; // Default to gray if not found
  });

  useEffect(() => {
    let options = {
      series: seriesData,
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "80%",
          borderRadius: 5,
          borderRadiusApplication: "end",
        },
      },
      colors: colors, // Use the dynamically generated colors array
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: categoriesData,
      },
      // yaxis: {
      //   title: {
      //     text: "$ (thousands)",
      //   },
      // },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            // return "$ " + val + " thousands";
            return "Count " + val;
          },
        },
      },
    };

    const chart = new ApexCharts(chartRef.current, options);

    chart.render();

    return () => {
      chart.destroy();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id={`columnChart-${props?.key}`} ref={chartRef}></div>;
};

export default SuppliersColumnChart;

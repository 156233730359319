/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import Layout from "../../../Layout/Layout";
import Constants from "../../../common/SupplierConstants";
import { InputField } from "../../../Components/formElements/InputField";
import { TextAreaField } from "../../../Components/formElements/TextAreaField";
import SourcingCommonFilter from "../../../Components/Filter/Sourcing/SourcingCommonFilter";

//Services
import { capitalize, currencyFormat } from "../../../common/Validations";

const CreateDPR = () => {
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Create Downpayment Request");
  let dropdownOption = [
    {
      id: "1",
      name: "Sample Data 1",
    },
    {
      id: "2",
      name: "Sample Data 2",
    },
  ];
  // const [data, setData] = useState([]);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);



  const [getAccessData, setAccessData] = useState("");
  const [getDisabledStatus, setDisabledStatus] = useState(false);

  const [loading, setLoading] = useState(false);
  const [PoCreatelineItemModel, setPoCreatelineItemModel] = useState(false);
  const [HeaderTextModel, setHeaderTextModel] = useState(false);
  const [PriceSheduleModuledata, setPriceSheduleModuledata] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [CreateOtherchangedata, setCreateOtherchangedata] = useState([]);
  const [getAsnHistoricalData, setAsnHistoricalData] = useState([]);

  const [formInputs, setFormInputs] = useState({
    email: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    companyName: "",
    _id: "",
  });

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };

  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  const showCreatepomodal = () => {
    setPoCreatelineItemModel(true);
  };
  const hidePoCreatelineItemModel = () => {
    setPoCreatelineItemModel(false);
  };


  /* Create po Modal Function */
  const showsetHeaderText = () => {
    setHeaderTextModel(true);
  };
  const hideHeaderTextModel = () => {
    setHeaderTextModel(false);
  };

  // ASN Historical Table
  const asnHistoryColInfo = [
    {
      name: "SCH Ref",
      selector: (row) => row.asnNumber,
      sortable: true,
      width: "150px",
    },
    {
      name: "SCH DPR",
      selector: (row) => row.asnNumber,
      sortable: true,
      width: "150px",
    },

    {
      name: "Created On",
      selector: (row) => row.asnDate || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "SAH DPR",
      selector: (row) => row.asnNumber,
      sortable: true,
      width: "150px",
    },
    {
      name: "Posted On",
      selector: (row) => row.asnDate || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "SAP GRN",
      selector: (row) => row.grnNumber || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Posted On",
      selector: (row) => row.postedOn || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "DPR Value",
      selector: (row) => row.vendorInvoiceNo,
      sortable: true,
      width: "180px",
    },
    {
      name: "DPR Status",
      selector: (row) => row.vendorInvoiceDate || "--",
      sortable: true,
      width: "150px",
    },

    {
      name: "Payment Date",
      selector: (row) => row?.gatepassDate || "--",
      sortable: true,
      width: "150px",
    },

    {
      name: "Remarks",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "200px",
    },
  ];


  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide quoation">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Create Down Payment Request</h2>
            <p className="subText">Invoice Down payment Request</p>
            <hr />

            {/* PO Details */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                          }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PO</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">PO Details</span>
                      <span className="subHeaderTitle">PO Details</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${isCardVisible ? "visible" : ""
                    }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Purchasing DocumentNr"
                          name="purchasingDocument"
                          id="purchasingDocument"
                          extraLabel=""
                          placeholder="Purchasing Document"
                          value={PriceSheduleModuledata.purchasingDocument || ""}
                          onChange={onInputChange}

                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency"
                          name="currency"
                          id="currency"
                          extraLabel=""
                          placeholder="Currency"
                          value={PriceSheduleModuledata.currency || ""}
                          onChange={onInputChange}

                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vendor Name"
                          name="VendorName"
                          id="vendorName"
                          extraLabel=""
                          placeholder="VendorName"
                          value={PriceSheduleModuledata.vendorName || ""}
                          onChange={onInputChange}

                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vendor Number"
                          name="vendorNumber"
                          id="vendorNumber"
                          extraLabel=""
                          placeholder="Vendor Number"
                          value={PriceSheduleModuledata.vendorNumber || ""}
                          onChange={onInputChange}

                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Code"
                          name="companyCode"
                          id="companyCode"
                          extraLabel=""
                          placeholder="Company Code"
                          value={PriceSheduleModuledata.companyCode || ""}
                          onChange={onInputChange}

                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Purchase Group"
                          name="purchaseGroup"
                          id="purchaseGroup"
                          extraLabel=""
                          placeholder="Purchase Group"
                          value={PriceSheduleModuledata.purchaseGroup || ""}
                          onChange={onInputChange}

                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Payment Term"
                          name="paymentTerm"
                          id="paymentTerm"
                          extraLabel=""
                          placeholder="Payment Term"
                          value={PriceSheduleModuledata.paymentTerm || ""}
                          onChange={onInputChange}

                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >

                        <TextAreaField
                          className="inputBox"
                          label="PO Description "
                          type="text"
                          name="poDescription"
                          id="poDescription"
                          placeholder="PO Description"
                          value={formInputs.poDescription || ""}
                          onChange={onInputChange}

                        />
                      </Col>

                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* DP Request Details */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                          }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">DD</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">DP Request Details</span>
                      <span className="subHeaderTitle">DP Request Details</span>
                    </Col>

                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${isCardVisible2 ? "visible" : ""
                    }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Proforma Inv no"
                          name="proformaInvno"
                          id="proformaInvno"
                          extraLabel=""
                          placeholder="Proforma Inv no"
                          value={PriceSheduleModuledata.proformaInvno || ""}
                          onChange={onInputChange}

                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Proforma Inv data"
                          name="proformaInvdata"
                          id="proformaInvdata"
                          extraLabel=""
                          placeholder="proforma Inv data"
                          value={PriceSheduleModuledata.proformaInvdata || ""}
                          onChange={onInputChange}

                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="DP %"
                          name="dP"
                          id="dP"
                          extraLabel=""
                          placeholder="DP %"
                          value={PriceSheduleModuledata.dP || ""}
                          onChange={onInputChange}

                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="DP Value"
                          name="dpValue"
                          id="dpValue"
                          extraLabel=""
                          placeholder="DP Value"
                          value={PriceSheduleModuledata.dpValue || ""}
                          onChange={onInputChange}

                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Raised DP Value"
                          name="raisedDPValue"
                          id="raisedDPValue"
                          extraLabel=""
                          placeholder="Raised DP Value"
                          value={PriceSheduleModuledata.raisedDPValue || ""}
                          onChange={onInputChange}

                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Balance DP Value"
                          name="balanceDPValue"
                          id="balanceDPValue"
                          extraLabel=""
                          placeholder="Balance DP Value"
                          value={PriceSheduleModuledata.balanceDPValue || ""}
                          onChange={onInputChange}

                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoice value"
                          name="invoiceValue"
                          id="invoiceValue"
                          extraLabel=""
                          placeholder="Invoice value"
                          value={PriceSheduleModuledata.invoiceValue || ""}
                          onChange={onInputChange}

                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="DP INVOICE DOCUMENT:"
                          name="PODescription"
                          placeholder="PO Description"
                          value={PriceSheduleModuledata.PODescription || ""}
                          onChange={onInputChange}

                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="SUPPORTING DOCUMENTS:"
                          name="PODescription"
                          placeholder="PO Description"
                          value={PriceSheduleModuledata.PODescription || ""}
                          onChange={onInputChange}

                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >

                        <TextAreaField
                          className="inputBox"
                          label="Remarks"
                          type="text"
                          name="remarks"
                          id="remarks"
                          extraLabel=""
                          placeholder="Remarks"
                          value={formInputs.remarks || ""}
                          onChange={onInputChange}

                        />
                      </Col>

                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* DPR History */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col
                      xs={1}
                      md={1}
                      lg={1}
                      className="text-left arrowBtn"
                    >
                      <i
                        className={`bi ${isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                          }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col
                      xs={1}
                      md={1}
                      lg={1}
                      className="text-center logoBtn"
                    >
                      <span className="iconLogo">SL</span>
                    </Col>
                    <Col xs={5} md={8} lg={8}>
                      <span className="headerTitle">DPR History</span>
                      <span className="subHeaderTitle">
                        View of ASN List{" "}
                      </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${isCardVisible4 ? "visible" : ""
                    }`}
                // aria-labelledby="headingOne"
                // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={asnHistoryColInfo}
                          data={getAsnHistoricalData}
                          progressPending={loading}
                          paginationRowsPerPageOptions={
                            Constants.ROW_PER_PAGE
                          }
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
              


          </div>
        </Col>
      </Row>


    </main>
  );
};




export default CreateDPR;
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Serivce

//Components
import Layout from "../../../Layout/Layout";
import CustomerConstants from "../../../common/CustomerConstants";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import { SearchField } from "../../../Components/formElements/SearchField";
import CatalogEditMaterialModal from "../../../Components/Modal/supplier_app/MaterialCatalogModal/CatalogEditMaterialModal";
import CatalogAddMaterialModal from "../../../Components/Modal/supplier_app/MaterialCatalogModal/CatalogAddMaterialModal";
import CatalogMaterialDetailModal from "../../../Components/Modal/supplier_app/MaterialCatalogModal/CatalogMaterialDetailModal";

const MaterialCatalog = () => {
  localStorage.setItem("menu", "Catalog Manage");
  localStorage.setItem("submenu", "Catalog Manager");

  let purchaseOrderTable = useSelector(
    (state) => state?.purchasing?.purchase_order?.pagination
  );

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  let CategorydropdownOptions = [
    {
      id: "1",
      name: "Jackets(132)",
    },
    {
      id: "2",
      name: "Pantes(40)",
    },
    {
      id: "3",
      name: "Cap(60)",
    },
  ];
  let StatusdropdownOptions = [
    {
      id: "1",
      name: "All status",
    },
    {
      id: "2",
      name: "single status",
    },
    {
      id: "3",
      name: "double status",
    },
  ];
  let PricedropdownOptions = [
    {
      id: "1",
      name: "$100-500",
    },
    {
      id: "2",
      name: "$500-2000",
    },
    {
      id: "3",
      name: "$2000-7000",
    },
  ];
  let StoredropdownOptions = [
    {
      id: "1",
      name: "All store",
    },
    {
      id: "2",
      name: "Adiddas",
    },
    {
      id: "3",
      name: "Alian soly",
    },
  ];
  let LineItemStatusdropdownOptions = [
    {
      id: "1",
      name: "Active",
    },
    {
      id: "2",
      name: "Pending",
    },
    {
      id: "3",
      name: "Sold Out",
    },
  ];
  const data = [
    {
      id: 1,
      productName: "Cotton Yarn",
      productNumber: "CY12345",
      category: "Raw Materials",
      subCategory: "Yarn",
      stock: 500,
      sold: 200,
      views: 1500,
      price: 250,
      discount: 10,
      discountedPrice: 225,
      description:
        "High-quality cotton yarn for making soft and breathable fabrics.",
      vendor: "CottonCo",
      vendorLocation: "Texas, USA",
      rating: 4.7,
      reviews: 50,
      weight: "1kg",
      dimensions: "30cm x 30cm x 15cm",
      color: "White",
      material: "100% Cotton",
      careInstructions: "Keep dry, avoid high humidity",
      availability: "In Stock",
      imageUrl: "assets/img/meterial_img/M01.png",
      createdAt: "2024-02-01",
      updatedAt: "2024-02-15",
      tags: ["yarn", "cotton", "soft"],
      warranty: "Not Applicable",
      returnPolicy: "Return within 15 days if unopened",
      shipping: {
        availableRegions: ["USA", "India", "Europe"],
        estimatedDelivery: "7-10 business days",
        cost: "$5",
      },
      featured: false,
      seasonal: false,
      bestseller: false,
    },
    {
      id: 2,
      productName: "Denim Fabric",
      productNumber: "DF98765",
      category: "Raw Materials",
      subCategory: "Fabric",
      stock: 800,
      sold: 400,
      views: 2400,
      price: 1500,
      discount: 20,
      discountedPrice: 1200,
      description: "Durable denim fabric perfect for making jeans and jackets.",
      vendor: "DenimWorks",
      vendorLocation: "Los Angeles, USA",
      rating: 4.6,
      reviews: 60,
      weight: "2kg",
      dimensions: "100cm x 150cm",
      color: "Blue",
      material: "100% Cotton",
      careInstructions: "Wash with cold water, avoid direct sunlight",
      availability: "In Stock",
      imageUrl: "assets/img/meterial_img/M02.png",
      createdAt: "2024-03-01",
      updatedAt: "2024-03-05",
      tags: ["denim", "durable", "jeans"],
      warranty: "Not Applicable",
      returnPolicy: "No returns for cut fabric",
      shipping: {
        availableRegions: ["USA", "Europe"],
        estimatedDelivery: "5-7 business days",
        cost: "$10",
      },
      featured: true,
      seasonal: false,
      bestseller: true,
    },
    {
      id: 3,
      productName: "Polyester Thread",
      productNumber: "PT54321",
      category: "Raw Materials",
      subCategory: "Thread",
      stock: 1000,
      sold: 600,
      views: 1800,
      price: 50,
      discount: 5,
      discountedPrice: 47.5,
      description:
        "Strong and durable polyester thread for sewing and stitching.",
      vendor: "ThreadMakers",
      vendorLocation: "Mumbai, India",
      rating: 4.4,
      reviews: 30,
      weight: "500g",
      dimensions: "15cm x 10cm x 5cm",
      color: "Black",
      material: "Polyester",
      careInstructions: "Store in a dry place",
      availability: "In Stock",
      imageUrl: "assets/img/meterial_img/M03.png",
      createdAt: "2024-01-10",
      updatedAt: "2024-01-20",
      tags: ["thread", "polyester", "sewing"],
      warranty: "Not Applicable",
      returnPolicy: "Return within 30 days",
      shipping: {
        availableRegions: ["India", "USA", "Europe"],
        estimatedDelivery: "3-5 business days",
        cost: "$2",
      },
      featured: false,
      seasonal: true,
      bestseller: false,
    },
    {
      id: 4,
      productName: "Lycra Fabric",
      productNumber: "LF87654",
      category: "Raw Materials",
      subCategory: "Fabric",
      stock: 600,
      sold: 300,
      views: 2000,
      price: 1200,
      discount: 15,
      discountedPrice: 1020,
      description:
        "Stretchable Lycra fabric for making sportswear and leggings.",
      vendor: "FlexTex",
      vendorLocation: "Shanghai, China",
      rating: 4.3,
      reviews: 40,
      weight: "1.5kg",
      dimensions: "200cm x 150cm",
      color: "Red",
      material: "Lycra",
      careInstructions: "Hand wash, do not iron",
      availability: "In Stock",
      imageUrl: "assets/img/meterial_img/M04.png",
      createdAt: "2024-04-01",
      updatedAt: "2024-04-10",
      tags: ["lycra", "stretchable", "sports"],
      warranty: "Not Applicable",
      returnPolicy: "No returns for cut fabric",
      shipping: {
        availableRegions: ["Asia", "Europe"],
        estimatedDelivery: "10-12 business days",
        cost: "$15",
      },
      featured: false,
      seasonal: true,
      bestseller: true,
    },
    {
      id: 5,
      productName: "Metal Buttons",
      productNumber: "MB56789",
      category: "Raw Materials",
      subCategory: "Buttons",
      stock: 2000,
      sold: 1000,
      views: 2200,
      price: 15,
      discount: 5,
      discountedPrice: 14.25,
      description: "Premium metal buttons for jeans and jackets.",
      vendor: "ButtonForge",
      vendorLocation: "Birmingham, UK",
      rating: 4.2,
      reviews: 25,
      weight: "100g",
      dimensions: "5cm x 5cm x 2cm",
      color: "Silver",
      material: "Metal",
      careInstructions: "Keep dry to prevent rust",
      availability: "In Stock",
      imageUrl: "assets/img/meterial_img/M05.png",
      createdAt: "2024-03-20",
      updatedAt: "2024-03-25",
      tags: ["buttons", "metal", "jeans"],
      warranty: "Not Applicable",
      returnPolicy: "Return within 15 days",
      shipping: {
        availableRegions: ["Europe", "USA"],
        estimatedDelivery: "5-7 business days",
        cost: "$3",
      },
      featured: false,
      seasonal: false,
      bestseller: true,
    },
    {
      id: 6,
      productName: "Nylon Zippers",
      productNumber: "NZ65432",
      category: "Raw Materials",
      subCategory: "Zippers",
      stock: 3000,
      sold: 1500,
      views: 2500,
      price: 10,
      discount: 10,
      discountedPrice: 9,
      description: "Durable nylon zippers for jackets and bags.",
      vendor: "ZipCraft",
      vendorLocation: "Seoul, South Korea",
      rating: 4.5,
      reviews: 40,
      weight: "200g",
      dimensions: "30cm x 5cm x 2cm",
      color: "Black",
      material: "Nylon",
      careInstructions: "Store in a cool, dry place",
      availability: "In Stock",
      imageUrl: "assets/img/meterial_img/M06.png",
      createdAt: "2024-02-15",
      updatedAt: "2024-02-28",
      tags: ["zippers", "nylon", "durable"],
      warranty: "Not Applicable",
      returnPolicy: "Return within 30 days",
      shipping: {
        availableRegions: ["Asia", "USA"],
        estimatedDelivery: "7-10 business days",
        cost: "$2",
      },
      featured: true,
      seasonal: false,
      bestseller: false,
    },
    {
      id: 7,
      productName: "Wool Yarn",
      productNumber: "WY43210",
      category: "Raw Materials",
      subCategory: "Yarn",
      stock: 700,
      sold: 350,
      views: 1800,
      price: 500,
      discount: 12,
      discountedPrice: 440,
      description: "Soft wool yarn ideal for sweaters and scarves.",
      vendor: "WoolCo",
      vendorLocation: "Sydney, Australia",
      rating: 4.8,
      reviews: 60,
      weight: "1.2kg",
      dimensions: "40cm x 30cm x 20cm",
      color: "Gray",
      material: "100% Wool",
      careInstructions: "Dry clean only",
      availability: "In Stock",
      imageUrl: "assets/img/meterial_img/M07.png",
      createdAt: "2024-01-05",
      updatedAt: "2024-01-18",
      tags: ["wool", "yarn", "soft"],
      warranty: "Not Applicable",
      returnPolicy: "Return within 15 days if unopened",
      shipping: {
        availableRegions: ["Australia", "Europe", "USA"],
        estimatedDelivery: "10-12 business days",
        cost: "$8",
      },
      featured: true,
      seasonal: true,
      bestseller: true,
    },
    {
      id: 8,
      productName: "Synthetic Fabric",
      productNumber: "SF76543",
      category: "Raw Materials",
      subCategory: "Fabric",
      stock: 1000,
      sold: 500,
      views: 3000,
      price: 800,
      discount: 20,
      discountedPrice: 640,
      description: "Synthetic fabric for making affordable clothing.",
      vendor: "SynTex",
      vendorLocation: "Beijing, China",
      rating: 4.1,
      reviews: 35,
      weight: "3kg",
      dimensions: "200cm x 150cm",
      color: "Beige",
      material: "Polyester Blend",
      careInstructions: "Machine wash, tumble dry low",
      availability: "In Stock",
      imageUrl: "assets/img/meterial_img/M08.png",
      createdAt: "2024-04-10",
      updatedAt: "2024-04-15",
      tags: ["synthetic", "fabric", "affordable"],
      warranty: "Not Applicable",
      returnPolicy: "No returns for cut fabric",
      shipping: {
        availableRegions: ["Asia", "USA", "Europe"],
        estimatedDelivery: "7-10 business days",
        cost: "$12",
      },
      featured: false,
      seasonal: false,
      bestseller: false,
    },
    {
      id: 9,
      productName: "Leather Fabric",
      productNumber: "LF90876",
      category: "Raw Materials",
      subCategory: "Fabric",
      stock: 400,
      sold: 200,
      views: 1200,
      price: 5000,
      discount: 25,
      discountedPrice: 3750,
      description: "Premium leather fabric for jackets and bags.",
      vendor: "LeatherLux",
      vendorLocation: "Milan, Italy",
      rating: 4.9,
      reviews: 70,
      weight: "4kg",
      dimensions: "100cm x 150cm",
      color: "Brown",
      material: "Genuine Leather",
      careInstructions: "Wipe clean with a damp cloth",
      availability: "In Stock",
      imageUrl: "assets/img/meterial_img/M09.png",
      createdAt: "2024-05-01",
      updatedAt: "2024-05-10",
      tags: ["leather", "premium", "durable"],
      warranty: "Not Applicable",
      returnPolicy: "No returns for cut fabric",
      shipping: {
        availableRegions: ["Europe", "USA"],
        estimatedDelivery: "5-7 business days",
        cost: "$20",
      },
      featured: true,
      seasonal: true,
      bestseller: true,
    },
    {
      id: 10,
      productName: "Silk Yarn",
      productNumber: "SY34567",
      category: "Raw Materials",
      subCategory: "Yarn",
      stock: 300,
      sold: 100,
      views: 1400,
      price: 1500,
      discount: 10,
      discountedPrice: 1350,
      description: "Luxurious silk yarn for high-end garments.",
      vendor: "SilkRoad",
      vendorLocation: "Bangkok, Thailand",
      rating: 4.7,
      reviews: 45,
      weight: "500g",
      dimensions: "25cm x 25cm x 10cm",
      color: "Ivory",
      material: "100% Silk",
      careInstructions: "Dry clean only",
      availability: "In Stock",
      imageUrl: "assets/img/meterial_img/M010.png",
      createdAt: "2024-06-01",
      updatedAt: "2024-06-10",
      tags: ["silk", "yarn", "luxury"],
      warranty: "Not Applicable",
      returnPolicy: "Return within 15 days if unopened",
      shipping: {
        availableRegions: ["Asia", "Europe", "USA"],
        estimatedDelivery: "10-12 business days",
        cost: "$15",
      },
      featured: true,
      seasonal: true,
      bestseller: false,
    },
  ];
  const [modalShowEditProduct, setModalShowEditProduct] = useState(false);
  const [modalShowAddProduct, setModalShowAddProduct] = useState(false);
  const [modalShowDetailProduct, setModalShowDetailProduct] = useState(false);
  const showEditProduct = () => {
    setModalShowEditProduct(true);
  };
  const showAddProduct = () => {
    setModalShowAddProduct(true);
  };
  const showProductDetail = (e) => {
    setModalShowDetailProduct(true);
    console.log("product detail clicked", e);
  };
  const hideModal = () => {
    setModalShowEditProduct(false);
    setModalShowAddProduct(false);
    setModalShowDetailProduct(false);
  };
  const columns = [
    {
      name: "Material Name",
      selector: (row) => row.productName || "--",
      cell: (row) => (
        <div
          className="d-flex justify-content-center align-items-center cursor-pointer"
          onClick={(e) => showProductDetail(e)}
        >
          <img
            src={row.imageUrl}
            alt="Profile"
            className="profileImageCss p-1"
            style={{ width: "60px" }}
          />
          <div className="ms-2">
            <p className="m-0 p-0 pt-1 fw-bold">{row.productName} </p>
            <p className="m-0 p-0 pt-1">{row.productNumber} </p>
          </div>
        </div>
      ),
      sortable: true,
      width: "400px",
    },
    {
      name: "Purchase Unit Price",
      selector: (row) => row.price || "--",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.price} </p>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Stock",
      selector: (row) => row.stock || "--",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.stock} </p>
        </div>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Views",
      selector: (row) => row.views || "--",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.views} </p>
        </div>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Status",
      selector: (row) => row.purchaseType || "--",
      cell: (row) => (
        <div>
          <ComboboxField
            label=""
            placeholder="Please select the status"
            data={LineItemStatusdropdownOptions}
            id="category"
            iconClassName="dropdownIcon"
            // className="z-9"
            name="category"
            code="categorycode"
            description="countryDesc"
            //   setValue={formInputs.country}
            // getvalue={setDropdownData}
          />
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      selector: (row) => row.purchaseType || "--",
      cell: (row) => (
        <div>
          <button
            className="btn d-flex align-items-center w-100"
            onClick={showEditProduct}
          >
            <i class="bi bi-pencil-square pe-2"></i>{" "}
            <sapn className="m-0 p-0 pt-1">Edit </sapn>
          </button>
        </div>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Delete",
      selector: (row) => row.purchaseType || "--",
      cell: (row) => (
        <div>
          <button class="btn btn-danger">
            <i class="bi bi-trash3"></i>
          </button>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
  ];

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Catalog </h2>
            <p className="subText">Catalog </p>
            <hr />
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-down"
                            : "bi-chevron-right"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AF</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">Advanced Filter</span>
                      <span className="subHeaderTitle">Advanced Filter</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4 d-flex align-items-center">
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding mt-4"
                      >
                        <SearchField name="searchKey" />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Show"
                          placeholder="Please select the Category"
                          data={CategorydropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Sort by"
                          placeholder="Please select the Category"
                          data={CategorydropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Category"
                          placeholder="Please select the Category"
                          data={CategorydropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Status"
                          placeholder="Please select the status"
                          data={StatusdropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Price"
                          placeholder="Please select the Price"
                          data={PricedropdownOptions}
                          id="price"
                          iconClassName="dropdownIcon"
                          name="price"
                          code="pricecode"
                          description="countryDesc"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Store"
                          placeholder="Please select the status"
                          data={StoredropdownOptions}
                          id="store"
                          iconClassName="dropdownIcon"
                          name="store"
                          code="storecode"
                          description="countryDesc"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={{ span: 2, offset: 8 }}
                        lg={{ span: 2, offset: 8 }}
                        className="commTopButtonRightLeftPadding"
                      >
                        <button className="btnTable">Search</button>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        lg={2}
                        className="commTopButtonRightLeftPadding"
                      >
                        <button className="btnTable">Referesh</button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-down"
                            : "bi-chevron-right"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">M</span>
                    </Col>
                    <Col xs={6} md={4} lg={4}>
                      <span className="headerTitle">Material</span>
                      <span className="subHeaderTitle">Material</span>
                    </Col>

                    <Col xs={12} md={8} lg={8} className="headerButtonAlign">
                      <Col xs={3} md={3} lg={3} className="">
                        <button
                          className="btnTable d-flex justify-content-center"
                          onClick={showAddProduct}
                        >
                          <p className="m-0 me-2">Add Material</p>
                          <i class="bi bi-plus-square"></i>
                        </button>
                      </Col>
                    </Col>
                  </Row>
                </h2>
                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          paginationRowsPerPageOptions={
                            CustomerConstants.ROW_PER_PAGE
                          }
                          pagination
                          paginationServer
                          paginationTotalRows={purchaseOrderTable.total}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <CatalogEditMaterialModal
        show={modalShowEditProduct}
        onHide={() => hideModal()}
        // dropdownData={getAccountGroup}
      />
      <CatalogAddMaterialModal
        show={modalShowAddProduct}
        onHide={() => hideModal()}
        // dropdownData={getAccountGroup}
      />
      <CatalogMaterialDetailModal
        show={modalShowDetailProduct}
        onHide={() => hideModal()}
      />
    </main>
  );
};

export default MaterialCatalog;

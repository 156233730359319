import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { ComboboxField } from '../../../Components/formElements/ComboboxField';
import Layout from "../../../../Layout/Layout";
import DataTable from "react-data-table-component";
import configFromEnv from "../../../../services/configuration";
import { currencyFormat, formatDateTime } from "../../../../common/Validations";
import { useNavigate } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useSelector
import CustomerConstants from "../../../../common/CustomerConstants";
import {
  addPoID,
  getCustomerPOList,
} from "../../../../redux/feature/customer_app/preSalesSlice";
import { SearchField } from "../../../../Components/formElements/SearchField";
import Moment from "react-moment";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";

const CustPurchaseOrderList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Sales");
  localStorage.setItem("submenu", "Purchase Order");

  let preSalesData = useSelector((state) => state?.preSales?.po); // User Details from Store
  let preSalesTable = useSelector((state) => state?.preSales?.po?.pagination); // User Details from Store

  const [data, setData] = useState([]);
  const [isCardVisible, setCardVisible] = useState(true);

  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");
  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  const detailedPage = (poID) => {
    dispatch(addPoID(poID));
    navigate(CustomerConstants.SALES_PURCHASE_ORDER_DETAILS);
  };

  const columns = [
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Document Type",
      selector: (row) => row.purchasingDocumentCode,
      sortable: true,
      width: "220px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.purchasingDocumentType} </p>
        </div>
      ),
    },
    {
      name: "Purchase Type",
      selector: (row) => row.purchaseType || "--",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.purchaseType} </p>
        </div>
      ),
      sortable: true,
      width: "140px",
    },
    {
      name: "Document",
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => detailedPage(row.purchasingDocumentNr)}
        >
          {row.purchasingDocumentNr}{" "}
        </button>
      ),
      width: "150px",
    },
    // Conditionally include the 'workflowActionLogs' column
    ...(data?.some((row) => row.workflowActionLogs !== undefined)
      ? [
          {
            name: "Workflow log",
            selector: (row) => row.workflowActionLogs,
            cell: (row) => (
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-center iconTable"
              >
                <img
                  src="assets/img/newIcons/workflow.png"
                  alt="attachments"
                  style={{ width: "35px" }}
                  className="mt-1"
                  // onClick={() => showStatusInfo(row.workflowActionLogs)}
                />
              </div>
            ),
            sortable: true,
            width: "132px",
          },
        ]
      : []),
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "100px",
    },
    {
      name: "Attachments",
      selector: (row) => row.attachments,
      cell: (row) => (
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center"
        >
          <img
            src="assets/img/newIcons/attached-file.png"
            alt="attachments"
            style={{ width: "25px" }}
            className="mt-1"
          />
        </div>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "Version",
      selector: (row) => row.version || "--",
      sortable: true,
      width: "100px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <TableStatusField status={row.statusHeader} />
        </>
      ),
      selector: (row) => row.statusHeader || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Date",
      selector: (row) => formatDateTime(row?.purchasingDocumentDate) || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "PO Value",
      cell: (row) => (
        <span className="numberFormate m-0 p-0 pt-1 fw-bold">
          {currencyFormat(Number(row.purchasingDocumentValue).toFixed(2))}
        </span>
      ),
      selector: (row) => row.purchasingDocumentValue || "--",
      sortable: true,
      width: "120px",
    },
    {
      name: "Currency ",
      cell: (row) => (
        <>
          <span className="m-0 p-0 pt-1 fw-bold">{row.currency}</span>
        </>
      ),
      selector: (row) => row.currency || "--",
      sortable: true,
      width: "110px",
    },
    {
      name: "Customer",
      selector: (row) => row.vendorCode,
      sortable: true,
      width: "110px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.vendorCode} </p>
          <p className="m-0 p-0">{row.vendorName} </p>
        </div>
      ),
    },
    // {
    //   name: "Payment Terms",
    //   cell: (row) => <>{row.paymentTerms}</>,
    //   selector: (row) => row.paymentTerms,
    //   sortable: true,
    //   width: "180px",
    // },
    // {
    //   name: "Inco Terms",
    //   selector: (row) => row.incoTerms,
    //   sortable: true,
    //   width: "180px",
    // },
    {
      name: "Payment Terms",
      selector: (row) => row.paymentTermsCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1">{row.paymentTerms} </p>
        </div>
      ),
    },
    {
      name: "Inco Terms",
      selector: (row) => row.incoTermsCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 ">{row.incoTerms} </p>
        </div>
      ),
    },
    {
      name: "Created Date",
      selector: (row) => formatDateTime(row.createdAt),
      cell: (row) => (
        <Moment format={configFromEnv.TIME_FORMATE_WITH_TIME}>
          {row.createdAt}
        </Moment>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: "Company",
      cell: (row) => (
        <>
          <span> {row.companyCodeDesc} </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Quotation",
      cell: (row) => (
        <button className="btnInfo">{row.quotationNumber || "--"}</button>
      ),
      width: "150px",
    },

    {
      name: "Qtn Version",
      selector: (row) => row.rfqTechFocalPerson,
      sortable: true,
      width: "120px",
    },
    // {
    //   name: "Delivery Status",
    //   cell: (row) => (
    //     <>
    //       <button className="btnTable">{row.deliveredStatus}</button>
    //     </>
    //   ),
    //   width: "140px",
    // },
    // {
    //   name: "Invoice Status",
    //   cell: (row) => (
    //     <>
    //       <button className="btnTable">{row.invoicedStatus}</button>
    //     </>
    //   ),
    //   width: "140px",
    // },
    // {
    //   name: "Payment Status",
    //   cell: (row) => (
    //     <>
    //       <button className="btnTable">{row.paymentStatus}</button>
    //     </>
    //   ),
    //   width: "140px",
    // },
  ];

  const lineItemColInfo = [
    {
      name: "Item Nr",
      cell: (row) => <span>{row.purchasingDocumentItemNr} </span>,
      width: "180px",
    },
    {
      name: "Schedule Nr",
      cell: (row) => <span>{row.purchasingDocumentItemNr} </span>,
      width: "180px",
    },
    {
      name: "Material/Service",

      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.materialCode} </p>
          <p className="m-0 p-0">{row.materialDescription} </p>
        </div>
      ),
      width: "180px",
    },
    {
      name: "Material Group",
      cell: (row) => <span>{row.materialGroup} </span>,
      width: "220px",
    },
    {
      name: "Quantity",
      cell: (row) => <span>{row.purchaseQuantity} </span>,
      width: "220px",
    },
    {
      name: "Price Unit",
      cell: (row) => <span>{row.orderPriceUnit} </span>,
      width: "220px",
    },
    {
      name: "UOM",
      cell: (row) => <span>{row.orderUnitOfMeasure} </span>,
      width: "220px",
    },
    {
      name: "Net Value",
      cell: (row) => <span>{row.netValue} </span>,
      width: "220px",
    },
    {
      name: "Delivery Date",
      cell: (row) => <span>{row.deliveryDate} </span>,
      width: "220px",
    },
  ];

  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Line Item Details");
    setItemLevelModalShow(true);
  };
  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };

  const loadInitialSuuplierData = async () => {
    setData(preSalesData?.data);
  };

  const handlePageChange = (page) => {
    let obj = {
      queryName: "getCustomerPOList",
      module: "CUSTOMER",
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(getCustomerPOList(obj));
    setData(preSalesData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      queryName: "getCustomerPOList",
      module: "CUSTOMER",
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(getCustomerPOList(obj));
    setData(preSalesData?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      queryName: "getCustomerPOList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getCustomerPOList(obj));
  };

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSalesData]);

  //on page load
  useEffect(() => {
    let obj = {
      queryName: "getCustomerPOList",
      module: "CUSTOMER",
      page: 1,
      per_page: 10,
    };
    dispatch(getCustomerPOList(obj));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle">Purchase Order List</h2>
                <p className="subText">View of Purchase Order and Status</p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>

            <hr />

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CL</span>
                    </Col>
                    <Col xs={8} md={3} lg={3}>
                      <span className="headerTitle">Purchase Order List</span>
                      <span className="subHeaderTitle">
                        View of Customers and Status{" "}
                      </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          progressPending={preSalesData?.status}
                          paginationRowsPerPageOptions={
                            CustomerConstants.ROW_PER_PAGE
                          }
                          pagination
                          paginationServer
                          paginationTotalRows={preSalesTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelect}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Item Text  */}
      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />
    </main>
  );
};

export default CustPurchaseOrderList;

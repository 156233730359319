/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import Layout from "../../../Layout/Layout";
import DataTable from "react-data-table-component";
import SourcingCommonFilter from "../../../Components/Filter/Sourcing/SourcingCommonFilter";
import { useNavigate } from "react-router-dom";
import Constants from "../../../common/SupplierConstants";
import {
  addCategoryDetails,
  getCategoryListData,
} from "../../../redux/feature/userSlice";
import toast from "react-hot-toast";
import ItemService from "../../../services/supplier_appCommonService";

const CategoryList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Admin");
  localStorage.setItem("submenu", "Category");

  let categoryList = useSelector((state) => state?.user?.categoryList); // User Details from Store
  let categoryListTable = useSelector(
    (state) => state?.user?.categoryList?.pagination
  ); // User Details from Store

  const [isCardVisible1, setCardVisible1] = useState(true);
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const [data, setData] = useState([]);
  const [getNewPerPage, setNewPerPage] = useState(10);
  const [disabled, setDisabled] = useState(false);

  const navigateCreateCategory = () => {
    navigate(Constants.CREATE_CATEGORY);
  };

  const detailedPage = (CategoryCode) => {
    dispatch(addCategoryDetails(CategoryCode));
    navigate(Constants.CATEGORY_DETAILS);
  };

  const userListColInfo = [
    {
      name: "Category Code",
      selector: (row) => row.CategoryCode,
      sortable: true,
      cell: (row) => (
        <>
          <button
            className="btnInfo"
            onClick={() => detailedPage(row)}
          >
            {row.CategoryCode}
          </button>
        </>
      ),
      width: "150px",
    },
    {
      name: "Category",
      selector: (row) => row.vendorCategoryText,
      sortable: true,

      cell: (row) => (
        <span className="m-0 p-0 pt-1 fw-bold">{row.vendorCategoryText}</span>
      ),
      width: "120px",
    },
    {
      name: "SubCategory Code",
      selector: (row) => row.vendorSubCategoryText,
      sortable: true,

      cell: (row) => (
        <span className="m-0 p-0 pt-1 fw-bold">{row.vendorSubCategoryText}</span>
      ),
      width: "180px",
    },
    {
      name: "SubCategory",
      selector: (row) => row.vendorSubCategoryCode,
      sortable: true,
      cell: (row) => <>{row.vendorSubCategoryCode}</>,
      width: "180px",
    },
    {
      name: "SubCategory Description",
      selector: (row) => row.vendorSubCategoryLongText,
      sortable: true,
      cell: (row) => <>{row.vendorSubCategoryLongText}</>,
      width: "180px",
    },
    {
      name: "Delete",
      selector: (row) => row.CategoryCode || "--",
      sortable: true,
      cell: (row) => (
        <>
          <button
            className="btn btn-danger"
            disabled={disabled}
            onClick={() => deleteCategoryCode(row.CategoryCode)}
          >
            <i className="bi bi-trash3"></i>
          </button>
        </>
      ),
      width: "200px",
    },
  ];

  const loadInitialSuuplierData = async () => {
    setData(categoryList?.data);
  };

  const handlePageChange = (page) => {
    let obj = {
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(getCategoryListData(obj));
    setData(categoryList?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(getCategoryListData(obj));
    setData(categoryList?.data);
  };

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryList]);

  //on page load
  useEffect(() => {
    let obj = {
      queryName: "CategoryMasterList",
      page: 1,
      per_page: 10,
    };
    dispatch(getCategoryListData(obj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* deleteCategoryCode Button */
  const deleteCategoryCode = async (CategoryCode) => {

    let obj = {
      CategoryCode: CategoryCode,
    };
    setDisabled(true);
    await ItemService.deleteCategory(obj).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        toast.error(item?.message || "Added Category Master Data ", {
          duration: 4000,
          position: "top-right",
        });

        let loadObj = {
          queryName: "CategoryMasterList",
          page: 1,
          per_page: 10,
        };
        // Update response value in Redux Store
        dispatch(getCategoryListData(loadObj)); // event store
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setDisabled(false);
  };


  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={8} md={10} lg={10} className="mb-2">
                <h2 className="mainTitle">Category List</h2>
                <p className="subText">Category User Details</p>
              </Col>
              <Col xs={4} md={2} lg={2} className="mb-2">
                <button className="btn w-full" onClick={navigateCreateCategory}>
                  Create
                </button>
              </Col>
              <hr />
            </Row>

            {/* user list */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LC</span>
                    </Col>
                    <Col xs={8} md={3} lg={3}>
                      <span className="headerTitle">List Of Category</span>
                      {/*  <span className="subHeaderTitle">
                        View of Supplier List{" "}
                      </span> */}
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={userListColInfo}
                          data={data}
                          progressPending={categoryList?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          paginationTotalRows={categoryListTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default CategoryList;

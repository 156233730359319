/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../Layout/Layout";
import { Card } from "react-bootstrap";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import { SearchField } from "../../../Components/formElements/SearchField";
import CatalogMaterialDetailModal from "../../../Components/Modal/supplier_app/MaterialCatalogModal/CatalogMaterialDetailModal";
import { InputField } from "../../../Components/formElements/InputField";

const products = [
  {
    id: 1,
    productName: "Cotton Yarn",
    productNumber: "CY12345",
    category: "Raw Materials",
    subCategory: "Yarn",
    stock: 500,
    sold: 200,
    views: 1500,
    price: 250,
    discount: 10,
    discountedPrice: 225,
    description:
      "High-quality cotton yarn for making soft and breathable fabrics.",
    vendor: "CottonCo",
    vendorLocation: "Texas, USA",
    rating: 4.7,
    reviews: 50,
    weight: "1kg",
    dimensions: "30cm x 30cm x 15cm",
    color: "White",
    material: "100% Cotton",
    careInstructions: "Keep dry, avoid high humidity",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M01.png",
    createdAt: "2024-02-01",
    updatedAt: "2024-02-15",
    tags: ["yarn", "cotton", "soft"],
    warranty: "Not Applicable",
    returnPolicy: "Return within 15 days if unopened",
    shipping: {
      availableRegions: ["USA", "India", "Europe"],
      estimatedDelivery: "7-10 business days",
      cost: "$5",
    },
    featured: false,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 2,
    productName: "Denim Fabric",
    productNumber: "DF98765",
    category: "Raw Materials",
    subCategory: "Fabric",
    stock: 800,
    sold: 400,
    views: 2400,
    price: 1500,
    discount: 20,
    discountedPrice: 1200,
    description: "Durable denim fabric perfect for making jeans and jackets.",
    vendor: "DenimWorks",
    vendorLocation: "Los Angeles, USA",
    rating: 4.6,
    reviews: 60,
    weight: "2kg",
    dimensions: "100cm x 150cm",
    color: "Blue",
    material: "100% Cotton",
    careInstructions: "Wash with cold water, avoid direct sunlight",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M02.png",
    createdAt: "2024-03-01",
    updatedAt: "2024-03-05",
    tags: ["denim", "durable", "jeans"],
    warranty: "Not Applicable",
    returnPolicy: "No returns for cut fabric",
    shipping: {
      availableRegions: ["USA", "Europe"],
      estimatedDelivery: "5-7 business days",
      cost: "$10",
    },
    featured: true,
    seasonal: false,
    bestseller: true,
  },
  {
    id: 3,
    productName: "Polyester Thread",
    productNumber: "PT54321",
    category: "Raw Materials",
    subCategory: "Thread",
    stock: 1000,
    sold: 600,
    views: 1800,
    price: 50,
    discount: 5,
    discountedPrice: 47.5,
    description:
      "Strong and durable polyester thread for sewing and stitching.",
    vendor: "ThreadMakers",
    vendorLocation: "Mumbai, India",
    rating: 4.4,
    reviews: 30,
    weight: "500g",
    dimensions: "15cm x 10cm x 5cm",
    color: "Black",
    material: "Polyester",
    careInstructions: "Store in a dry place",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M03.png",
    createdAt: "2024-01-10",
    updatedAt: "2024-01-20",
    tags: ["thread", "polyester", "sewing"],
    warranty: "Not Applicable",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["India", "USA", "Europe"],
      estimatedDelivery: "3-5 business days",
      cost: "$2",
    },
    featured: false,
    seasonal: true,
    bestseller: false,
  },
  {
    id: 4,
    productName: "Lycra Fabric",
    productNumber: "LF87654",
    category: "Raw Materials",
    subCategory: "Fabric",
    stock: 600,
    sold: 300,
    views: 2000,
    price: 1200,
    discount: 15,
    discountedPrice: 1020,
    description: "Stretchable Lycra fabric for making sportswear and leggings.",
    vendor: "FlexTex",
    vendorLocation: "Shanghai, China",
    rating: 4.3,
    reviews: 40,
    weight: "1.5kg",
    dimensions: "200cm x 150cm",
    color: "Red",
    material: "Lycra",
    careInstructions: "Hand wash, do not iron",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M04.png",
    createdAt: "2024-04-01",
    updatedAt: "2024-04-10",
    tags: ["lycra", "stretchable", "sports"],
    warranty: "Not Applicable",
    returnPolicy: "No returns for cut fabric",
    shipping: {
      availableRegions: ["Asia", "Europe"],
      estimatedDelivery: "10-12 business days",
      cost: "$15",
    },
    featured: false,
    seasonal: true,
    bestseller: true,
  },
  {
    id: 5,
    productName: "Metal Buttons",
    productNumber: "MB56789",
    category: "Raw Materials",
    subCategory: "Buttons",
    stock: 2000,
    sold: 1000,
    views: 2200,
    price: 15,
    discount: 5,
    discountedPrice: 14.25,
    description: "Premium metal buttons for jeans and jackets.",
    vendor: "ButtonForge",
    vendorLocation: "Birmingham, UK",
    rating: 4.2,
    reviews: 25,
    weight: "100g",
    dimensions: "5cm x 5cm x 2cm",
    color: "Silver",
    material: "Metal",
    careInstructions: "Keep dry to prevent rust",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M05.png",
    createdAt: "2024-03-20",
    updatedAt: "2024-03-25",
    tags: ["buttons", "metal", "jeans"],
    warranty: "Not Applicable",
    returnPolicy: "Return within 15 days",
    shipping: {
      availableRegions: ["Europe", "USA"],
      estimatedDelivery: "5-7 business days",
      cost: "$3",
    },
    featured: false,
    seasonal: false,
    bestseller: true,
  },
  {
    id: 6,
    productName: "Nylon Zippers",
    productNumber: "NZ65432",
    category: "Raw Materials",
    subCategory: "Zippers",
    stock: 3000,
    sold: 1500,
    views: 2500,
    price: 10,
    discount: 10,
    discountedPrice: 9,
    description: "Durable nylon zippers for jackets and bags.",
    vendor: "ZipCraft",
    vendorLocation: "Seoul, South Korea",
    rating: 4.5,
    reviews: 40,
    weight: "200g",
    dimensions: "30cm x 5cm x 2cm",
    color: "Black",
    material: "Nylon",
    careInstructions: "Store in a cool, dry place",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M06.png",
    createdAt: "2024-02-15",
    updatedAt: "2024-02-28",
    tags: ["zippers", "nylon", "durable"],
    warranty: "Not Applicable",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["Asia", "USA"],
      estimatedDelivery: "7-10 business days",
      cost: "$2",
    },
    featured: true,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 7,
    productName: "Wool Yarn",
    productNumber: "WY43210",
    category: "Raw Materials",
    subCategory: "Yarn",
    stock: 700,
    sold: 350,
    views: 1800,
    price: 500,
    discount: 12,
    discountedPrice: 440,
    description: "Soft wool yarn ideal for sweaters and scarves.",
    vendor: "WoolCo",
    vendorLocation: "Sydney, Australia",
    rating: 4.8,
    reviews: 60,
    weight: "1.2kg",
    dimensions: "40cm x 30cm x 20cm",
    color: "Gray",
    material: "100% Wool",
    careInstructions: "Dry clean only",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M07.png",
    createdAt: "2024-01-05",
    updatedAt: "2024-01-18",
    tags: ["wool", "yarn", "soft"],
    warranty: "Not Applicable",
    returnPolicy: "Return within 15 days if unopened",
    shipping: {
      availableRegions: ["Australia", "Europe", "USA"],
      estimatedDelivery: "10-12 business days",
      cost: "$8",
    },
    featured: true,
    seasonal: true,
    bestseller: true,
  },
  {
    id: 8,
    productName: "Synthetic Fabric",
    productNumber: "SF76543",
    category: "Raw Materials",
    subCategory: "Fabric",
    stock: 1000,
    sold: 500,
    views: 3000,
    price: 800,
    discount: 20,
    discountedPrice: 640,
    description: "Synthetic fabric for making affordable clothing.",
    vendor: "SynTex",
    vendorLocation: "Beijing, China",
    rating: 4.1,
    reviews: 35,
    weight: "3kg",
    dimensions: "200cm x 150cm",
    color: "Beige",
    material: "Polyester Blend",
    careInstructions: "Machine wash, tumble dry low",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M08.png",
    createdAt: "2024-04-10",
    updatedAt: "2024-04-15",
    tags: ["synthetic", "fabric", "affordable"],
    warranty: "Not Applicable",
    returnPolicy: "No returns for cut fabric",
    shipping: {
      availableRegions: ["Asia", "USA", "Europe"],
      estimatedDelivery: "7-10 business days",
      cost: "$12",
    },
    featured: false,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 9,
    productName: "Leather Fabric",
    productNumber: "LF90876",
    category: "Raw Materials",
    subCategory: "Fabric",
    stock: 400,
    sold: 200,
    views: 1200,
    price: 5000,
    discount: 25,
    discountedPrice: 3750,
    description: "Premium leather fabric for jackets and bags.",
    vendor: "LeatherLux",
    vendorLocation: "Milan, Italy",
    rating: 4.9,
    reviews: 70,
    weight: "4kg",
    dimensions: "100cm x 150cm",
    color: "Brown",
    material: "Genuine Leather",
    careInstructions: "Wipe clean with a damp cloth",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M09.png",
    createdAt: "2024-05-01",
    updatedAt: "2024-05-10",
    tags: ["leather", "premium", "durable"],
    warranty: "Not Applicable",
    returnPolicy: "No returns for cut fabric",
    shipping: {
      availableRegions: ["Europe", "USA"],
      estimatedDelivery: "5-7 business days",
      cost: "$20",
    },
    featured: true,
    seasonal: true,
    bestseller: true,
  },
  {
    id: 10,
    productName: "Silk Yarn",
    productNumber: "SY34567",
    category: "Raw Materials",
    subCategory: "Yarn",
    stock: 300,
    sold: 100,
    views: 1400,
    price: 1500,
    discount: 10,
    discountedPrice: 1350,
    description: "Luxurious silk yarn for high-end garments.",
    vendor: "SilkRoad",
    vendorLocation: "Bangkok, Thailand",
    rating: 4.7,
    reviews: 45,
    weight: "500g",
    dimensions: "25cm x 25cm x 10cm",
    color: "Ivory",
    material: "100% Silk",
    careInstructions: "Dry clean only",
    availability: "In Stock",
    imageUrl: "assets/img/meterial_img/M010.png",
    createdAt: "2024-06-01",
    updatedAt: "2024-06-10",
    tags: ["silk", "yarn", "luxury"],
    warranty: "Not Applicable",
    returnPolicy: "Return within 15 days if unopened",
    shipping: {
      availableRegions: ["Asia", "Europe", "USA"],
      estimatedDelivery: "10-12 business days",
      cost: "$15",
    },
    featured: true,
    seasonal: true,
    bestseller: false,
  },
];

let CategorydropdownOptions = [
  {
    id: "1",
    name: "Jackets(132)",
  },
  {
    id: "2",
    name: "Pantes(40)",
  },
  {
    id: "3",
    name: "Cap(60)",
  },
];
let StatusdropdownOptions = [
  {
    id: "1",
    name: "All status",
  },
  {
    id: "2",
    name: "single status",
  },
  {
    id: "3",
    name: "double status",
  },
];
let PricedropdownOptions = [
  {
    id: "1",
    name: "$100-500",
  },
  {
    id: "2",
    name: "$500-2000",
  },
  {
    id: "3",
    name: "$2000-7000",
  },
];
let StoredropdownOptions = [
  {
    id: "1",
    name: "All store",
  },
  {
    id: "2",
    name: "Adiddas",
  },
  {
    id: "3",
    name: "Alian soly",
  },
];
const MaterialCatalogMaster = () => {
  localStorage.setItem("menu", "Catalog Manage");
  localStorage.setItem("submenu", "Matetial Catalog");
  const [isCardVisible1, setCardVisible1] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const [getToggle, setToggle] = useState(true);
  const toggleRowProduct = () => {
    setToggle(true);
  };
  const toggleColProduct = () => {
    setToggle(false);
  };
  const [modalShowDetailProduct, setModalShowDetailProduct] = useState(false);
  const showProductDetail = () => {
    setModalShowDetailProduct(true);
  };
  const hideModal = () => {
    setModalShowDetailProduct(false);
  };
  return (
    <main className="dashboard main homePage" id="main">
      <Layout />
      <Row className="homeScreen justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Catalog Master</h2>
            <p className="subText">Catalog Master</p>
            <hr />
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-down"
                            : "bi-chevron-right"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AF</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">Advanced Filter</span>
                      <span className="subHeaderTitle">Advanced Filter</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4 d-flex align-items-center">
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding mt-4"
                      >
                        <SearchField name="searchKey" />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Show"
                          placeholder="Please select the Category"
                          data={CategorydropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Sort by"
                          placeholder="Please select the Category"
                          data={CategorydropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Category"
                          placeholder="Please select the Category"
                          data={CategorydropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Status"
                          placeholder="Please select the status"
                          data={StatusdropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Price"
                          placeholder="Please select the Price"
                          data={PricedropdownOptions}
                          id="price"
                          iconClassName="dropdownIcon"
                          name="price"
                          code="pricecode"
                          description="countryDesc"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Store"
                          placeholder="Please select the status"
                          data={StoredropdownOptions}
                          id="store"
                          iconClassName="dropdownIcon"
                          name="store"
                          code="storecode"
                          description="countryDesc"
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={{ span: 2, offset: 8 }}
                        lg={{ span: 2, offset: 8 }}
                        className="commTopButtonRightLeftPadding"
                      >
                        <button className="btnTable">Search</button>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        lg={2}
                        className="commTopButtonRightLeftPadding"
                      >
                        <button className="btnTable">Referesh</button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <Row className="d-flex justify-content-center justify-content-sm-start">
              <Col
                xs={2}
                md={{ span: 1, offset: 10 }}
                lg={{ span: 1, offset: 10 }}
                className="commTopButtonRightLeftPadding"
              >
                <button className="btn" onClick={toggleRowProduct}>
                  <i class="bi bi-list-task fs-4"></i>
                </button>
              </Col>
              <Col
                xs={2}
                md={1}
                lg={1}
                className="commTopButtonRightLeftPadding"
              >
                <button className="btn" onClick={toggleColProduct}>
                  <i class="bi bi-grid-3x3-gap-fill fs-4"></i>
                </button>
              </Col>
              {getToggle ? (
                <>
                  {products?.map((items) => (
                    <Col md={6} lg={12} className={`mb-3`}>
                      <Card
                        className="d-flex flex-row align-items-center p-2"
                        style={{ width: "100%" }}
                      >
                        {/* Image Section */}
                        <Col xs={4} lg={{ span: 2, offset: 1 }} className="p-0"  onClick={showProductDetail}>
                          <img
                            src={items.imageUrl}
                            alt="Product"
                            style={{
                              width: "160px",
                              height: "160px",
                              objectFit: "cover",
                            }}
                          />
                        </Col>
                        {/* Content Section */}
                        <Col
                          xs={4}
                          lg={{ span: 3, offset: 0 }}
                          style={{ fontSize: "18px" }}
                        >
                          <Card.Body>
                            <p className="m-0 fw-bold">{items.productName}</p>

                            <p className="m-0">
                              <span className="fw-bold">Rating :</span>{" "}
                              {items.rating}/5
                            </p>
                            <p className="m-0">
                              <spaan className="fw-bold">Product ID : </spaan>
                              {items.productNumber}
                            </p>
                            <p className="m-0">{items.vendor}</p>
                            <p className="m-0">{items.description}</p>
                          </Card.Body>
                        </Col>
                        <Col
                          xs={4}
                          md={{ span: 2, offset: 2 }}
                          lg={{ span: 2, offset: 2 }}
                          className="d-flex flex-column"
                        >
                          <Card.Title>$ {items.price}</Card.Title>
                          <InputField
                            className="inputBox"
                            label="Each"
                            // extraLabel="Each"
                            name="Each"
                            placeholder=""
                            type="number"
                          />
                          <button className="btn btn-primary mt-2 d-flex justi align-items-center">
                            <p className="m-0 me-4">Add to Cart</p>
                            <i class="bi bi-cart-plus fs-5"></i>
                          </button>
                        </Col>
                      </Card>
                    </Col>
                  ))}
                </>
              ) : (
                <>
                  {products?.map((items) => (
                    <Col xs={12} md={6} lg={3} className="mb-3">
                      <Card
                        className="d-flex flex-column align-items-center p-2"
                        style={{ width: "90%" }}
                        onClick={showProductDetail}
                      >
                        {/* Image Section */}
                        <img
                          src={items.imageUrl}
                          alt="Product"
                          style={{
                            width: "160px",
                            height: "160px",
                            objectFit: "cover",
                          }}
                        />
                        {/* Content Section */}
                        <Card.Body className="text-start">
                          <p className="m-0 fw-bold">{items.productName}</p>
                          <Card.Title>$ {items.price}</Card.Title>
                          <p className="m-0">
                            <span className="fw-bold">Rating :</span>{" "}
                            {items.rating}/5
                          </p>
                          <p className="m-0">
                            <spaan className="fw-bold">Product ID : </spaan>
                            {items.productNumber}
                          </p>

                          <p className="m-0">{items.vendor}</p>
                          <p className="m-0">
                            {items.description.slice(0, 60)}...
                          </p>

                          <button className="btn btn-primary mt-2 d-flex align-items-center">
                            <p className="m-0 me-4">Add to Cart</p>
                            <i class="bi bi-cart-plus fs-5"></i>
                          </button>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <CatalogMaterialDetailModal
        show={modalShowDetailProduct}
        onHide={() => hideModal()}
      />
    </main>
  );
};

export default MaterialCatalogMaster;

import React from "react";

export const TableStatusField = ({ className, status }) => {
  const message = {
    NON_INVITED: "Non Invited",
    INVITED: "Invited",
    IN_REGISTRATION: "In Registration",
    REGISTERD: "Registered",
    REJECT: "Reject",
    DEACTIVATED: "Deactivated",
    IN_RELEASE: "In Release",
    CANCELLED: "Cancelled",
    PR_APPOVED: "PR Approved",
    ACKNOWLEDGED: "Acknowledged",
    PUBLISHED: "Published",
    QTN_RECEIVED: "QTN Received",
    RFQ_CREATED: "RFQ Created",
    COMERCIAL_APPROVED: "Commercial Approved",
    TECH_APPROVED: "Tech Approved",
    TECHNICAL_OPEN: "Technical Open",
    COMERCIAL_OPEN: "Commercial Open",
    SUBMITTED: "Submitted",
    NEW: "New",
    IN_TRANSIT: "In Transit",
    GATEPASS_CREATED: "Gatepass Created",
    GRN_CREATED: "GRN Created",
    PENDING_FOR_APPROVAL: "Pending for Approval",
    INVOICE_CANCELLED: "Invoice Cancelled",
    GRN_REJECTED: "GRN Rejected",
    POSTED: "Posted",
    GRN_APPROVED: "GRN Approved",
    ENTRY_SHEET_CREATED: "Entrysheet Created",
    SES_REJECTED: "SES Rejected",
    INVOICE_CREATED: "Invoice Created",
    SES_CANCELLED: "SES Cancelled",
    INVOICE_APPROVED: "Invoice Approved",
    ON_BOARDED: "On Boarded",
    PENDING: "Pending",
    ASN_CANCELLED:"ASN Cancelled",
    GRN_CSNCELLED:"GRN Cancelled",
    APPROVE:"Approve",
    SES_CREATED:"SES Created",
    OPEN:"OPEN",
    CLOSE:"CLOSE"
  };
 
  return (
    <>
      {status === message.NON_INVITED ? (
        <span className="bg-table-status bg-table-info-status">{status}</span>
      ) : status === message.INVITED ? (
        <span className="bg-table-status bg-table-new-status">{status}</span>
      ) : status === message.IN_REGISTRATION ? (
        <span className="bg-table-status bg-table-progress-status">
          {status}
        </span>
      ) : status === message.REGISTERD ? (
        <span className="bg-table-status bg-table-success-status">
          {status}
        </span>
      ) : status === message.REJECT ? (
        <span className="bg-table-status bg-table-danger-status">{status}</span>
      ) : status === message.DEACTIVATED ? (
        <span className="bg-table-status bg-table-danger-status">{status}</span>
      ) : status === message.IN_RELEASE ? (
        <span className="bg-table-status bg-table-progress-status">
          {status}
        </span>
      ) : status === message.CANCELLED ? (
        <span className="bg-table-status bg-table-danger-status">{status}</span>
      ) : status === message.PR_APPOVED ? (
        <span className="bg-table-status bg-table-new-status">{status}</span>
      ) : status === message.ACKNOWLEDGED ? (
        <span className="bg-table-status bg-table-success-status">
          {status}
        </span>
      ) : status === message.PUBLISHED ? (
        <span className="bg-table-status bg-table-success-status">
          {status}
        </span>
      ) : status === message.QTN_RECEIVED ? (
        <span className="bg-table-status bg-table-progress-status">
          {status}
        </span>
      ) : status === message.CANCELLED ? (
        <span className="bg-table-status bg-table-danger-status">{status}</span>
      ) : status === message.RFQ_CREATED ? (
        <span className="bg-table-status bg-table-new-status">{status}</span>
      ) : status === message.COMERCIAL_APPROVED ? (
        <span className="bg-table-status bg-table-success-status">
          {status}
        </span>
      ) : status === message.TECH_APPROVED ? (
        <span className="bg-table-status bg-table-success-status">
          {status}
        </span>
      ) : status === message.TECHNICAL_OPEN ? (
        <span className="bg-table-status bg-table-new-status">{status}</span>
      ) : status === message.COMERCIAL_OPEN ? (
        <span className="bg-table-status bg-table-new-status">{status}</span>
      ) : status === message.SUBMITTED ? (
        <span className="bg-table-status bg-table-new-status">{status}</span>
      ) : status === message.NEW ? (
        <span className="bg-table-status bg-table-new-status">{status}</span>
      ) : status === message.IN_TRANSIT ? (
        <span className="bg-table-status bg-table-progress-status">
          {status}
        </span>
      ) : status === message.GATEPASS_CREATED ? (
        <span className="bg-table-status bg-table-success-status">
          {status}
        </span>
      ) : status === message.GRN_CREATED ? (
        <span className="bg-table-status bg-table-new-status">{status}</span>
      ) : status === message.PENDING_FOR_APPROVAL ? (
        <span className="bg-table-status bg-table-progress-status">
          {status}
        </span>
      ) : status === message.PENDING_FOR_APPROVAL ? (
        <span className="bg-table-status bg-table-progress-status">
          {status}
        </span>
      ) : status === message.INVOICE_CANCELLED ? (
        <span className="bg-table-status bg-table-danger-status">{status}</span>
      ) : status === message.GRN_REJECTED ? (
        <span className="bg-table-status bg-table-danger-status">{status}</span>
      ) : status === message.POSTED ? (
        <span className="bg-table-status bg-table-info-status">{status}</span>
      ) : status === message.GRN_APPROVED ? (
        <span className="bg-table-status bg-table-success-status">
          {status}
        </span>
      ) : status === message.GRN_APPROVED ? (
        <span className="bg-table-status bg-table-new-status">{status}</span>
      ) : status === message.ENTRY_SHEET_CREATED ? (
        <span className="bg-table-status bg-table-new-status">{status}</span>
      ) : status === message.SES_REJECTED ? (
        <span className="bg-table-status bg-table-danger-status">{status}</span>
      ) : status === message.INVOICE_CREATED ? (
        <span className="bg-table-status bg-table-new-status">{status}</span>
      ) : status === message.SES_CANCELLED ? (
        <span className="bg-table-status bg-table-danger-status">{status}</span>
      ) : status === message.INVOICE_APPROVED ? (
        <span className="bg-table-status bg-table-success-status">
          {status}
        </span>
      ) : status === message.ON_BOARDED ? (
        <span className="bg-table-status bg-table-success-status">
          {status}
        </span>
      ) : status === message.PENDING ? (
        <span className="bg-table-status bg-table-info-status">
          {status}
        </span>
      ) : status === message.ASN_CANCELLED ? (
        <span className="bg-table-status bg-table-danger-status">
          {status}
        </span>
      ) : status === message.GRN_CSNCELLED ? (
        <span className="bg-table-status bg-table-danger-status">
          {status}
        </span>
      ) : status === message.APPROVE ? (
        <span className="bg-table-status bg-table-success-status">
          {status}
        </span>
      ) : status === message.SES_CREATED ? (
        <span className="bg-table-status bg-table-new-status">
          {status}
        </span>
      ) : status === message.OPEN ? (
        <span className="bg-table-status bg-table-new-status">
          {status}
        </span>
      ) : status === message.CLOSE ? (
        <span className="bg-table-status bg-table-success-status">
          {status}
        </span>
      ) : (
        status || "-"
      )}
    </>
  );
};

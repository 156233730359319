
/* APPLICATION ROUTE CONSTANTS */

module.exports = Object.freeze({
  
    ROW_PER_PAGE : [10, 50, 100, 300, 500],

    // Status
    STATUS_REGISTER:'Register',

    /* Routers Link supplier_app */
    APP_HOME: '/applications',
    HOME: '/home',
    
    HOME_INTRO:'/customer-intro',
    DASHBOARD: '/customer-dashboard',
    INBOX: '/customer-inbox',
    OUTBOX: '/customer-outbox',

    /*Customer*/
    CUSTOMER_REGISTRATION: '/customer-registration',
    CUSTOMER_LIST: '/customer-list',
    CUSTOMER_MASS_UPLOAD: '/customer-mass-upload',
    CUSTOMER_MASTER_CREATE: '/customer-master-creation',
    CUSTOMER_SELF_REGISTRATION: '/customer-self-registration',
    CUSTOMER_DETAILED_PAGE_CREATE:'/customer-details',
    CUSTOMER_DETAILED_PAGE_WORKFLOW:'/customer-details-workflow',

    /*PreSales*/
    PRESALES_INQUIRY_CREATION:'/customer-inquiry-create',
    PRESALES_INQUIRY_LIST:'/customer-inquiry',
    PRESALES_INQUIRY_DETAILS:'/customer-inquiry-details',

    PRESALES_CUSTOMER_CREATE_QUOTATION:'/customer-create-quotation',
    PRESALES_CUSTOMER_QUOTATION_DETAILS:'/customer-quotation-details',
    PRESALES_CUSTOMER_QUOTATION_LIST: '/customer-quotation',
    PRESALES_CUSTOMER_QUOTATION_CREATE_PO: '/create-po-quotation',

    /*Sales*/
    SALES_PURCHASE_ORDER_LIST:'/customer-purchase-order',
    SALES_PURCHASE_ORDER_CREATION:'/customer-create-purchase-order',
    SALES_PURCHASE_ORDER_DETAILS:'/customer-purchase-order-detail',

    SALES_SALES_ORDER_LIST:'/sales-order',
    SALES_SALES_ORDER_CREATION:'/create-sales-order',
    SALES_SALES_ORDER_DETAILS:'/sales-order-detail',
    
    SALES_DELIVERY_CREATION:'/create-delivery',
    SALES_DELIVERY_LIST:'/delivery',
    SALES_DELIVERY_DETAILS:'/delivery-detail',

    /* UserManual */
    USER_MANUAL_CUSTOMER_MANUAL:'/customer-manual',
    USER_MANUAL_MANAGER_MANUAL:'/manager-manual',

    //REPORTS
    CUSTOMER_REPORTS:'/customer-reports',
    CUSTOMER_REPORT_DETAILS:'/customer-report-details',

    /* catalog */
    PRODUCT_CATALOG_LIST:'/product-catalog',
    PRODUCT_CATALOG_DETAILS:'/product-catalog-details',
    PRODUCT_CATALOG_MASTER:'/product-catalog-master',

    /* Product Catalog  Shopwise */
    PRODUCT_CATALOG_SHOPWISE:'/product-catalog-shopwise',

    /* CSTOMER INVOICE */
    CUSTOMER_INVOICE_DETAILS:"/customer-invoice-details",
    CUSTOMER_INVOICE_LIST:"/customer-invoice-list",

    /*BILLING */
    CUSTOMER_BILLING:"/billing",
    CUSTOMER_BILLING_DETAILS:"/billing-detials",

    /* customer FEEDBACK */
    CUSTOMER_FEEDBACK:"/customer-feedback",
    CUSTOMER_FEEDBACK_LIST:"/customer-feedback-list",

})


import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { ComboboxField } from '../../../Components/formElements/ComboboxField';
import Layout from "../../../../Layout/Layout";
import { useNavigate } from "react-router-dom";
import Constants from "../../../../common/CustomerConstants";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch
import { InputField } from "../../../../Components/formElements/InputField";

//Serivce
import ItemService from "../../../../services/customer_appCommonService";
import { formatDateTime } from "../../../../common/Validations";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import DataTable from "react-data-table-component";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import { addCreatePOQuotation } from "../../../../redux/feature/customer_app/preSalesSlice";

const QuotationDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Pre Sales");
  localStorage.setItem("submenu", "Quotation");

  let quotationID = useSelector((state) => state?.preSales?.quotationID); // User Details from Store

  const [formInputs, setFormInputs] = useState({
    organisationName: "",
    companyName: "",
    email: "",
    firstName: "",
    lastName: "",
  });

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible6, setCardVisible6] = useState(true);

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility6 = () => {
    setCardVisible6(!isCardVisible6);
  };

  const [getDisabledStatus, setDisabledStatus] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [selectedShipment, setSelectedShipment] = useState("Yes");
  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");
  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);
  const [itemTableData, setItemTableData] = useState();

  const lineItemColInfo = [
    {
      name: "Code",
      cell: (row) => <span>{row.documentId} </span>,
      width: "200px",
    },
    {
      name: "Text",
      cell: (row) => <span>{row.documentText} </span>,
      width: "250px",
    },
  ];

  const HeaderTextColumns = [
    {
      name: "Code",
      cell: (row) => (
        <>
          <span>{row.documentId}</span>
        </>
      ),
      width: "30rem",
    },
    {
      name: "Value",
      cell: (row) => (
        <>
          <span>{row.documentText}</span>
        </>
      ),
      width: "30rem",
    },
  ];

  //Create createPOQuotation
  const createPOQuotation = (data) => {
    dispatch(addCreatePOQuotation(data));
    navigate(Constants.PRESALES_CUSTOMER_QUOTATION_CREATE_PO);
  };

  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Line Item Details");
    setItemLevelModalShow(true);
  };
  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };

  let dropdownOption = [
    {
      id: "1",
      name: "Sample Data 1",
    },
    {
      id: "2",
      name: "Sample Data 2",
    },
  ];

  /*  PRLineItem*/
  let PRLineItemInitialCol = [
    {
      name: "Sl no",
      selector: (row) => row.local_id,
      sortable: true,
      width: "100px",
    },

    {
      name: "Item Text",
      selector: (row) => row.local_id || "--",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.itemTextData)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
    },
    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1">{row.materialCode} </p>
            <p className="m-0 p-0">{row.materialDescription} </p>
          </div>
        </>
      ),
      width: "180px",
      sortable: true,
    },
    {
      name: "Material Group ",
      selector: (row) => row.materialGroup || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "Inquiry Qty ",
      selector: (row) => row.inquiryQuantity || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Qtn Quantity ",
      cell: (row) => (
        <>
          <InputField
            type="number"
            className="inputBox mt-1"
            id="qtnQuantity"
            name="qtnQuantity"
            value={row.qtnQuantity || 0}
            disabled={disabled}
          />
        </>
      ),
      width: "160px",
    },
    {
      name: "UOM ",
      selector: (row) => row.orderUnitOfMeasure || "--",
      width: "100px",
    },
    {
      name: "Unit Price ",
      cell: (row) => (
        <>
          <InputField
            type="number"
            className="inputBox mt-1"
            id="quotationUnitPrice"
            name="quotationUnitPrice"
            value={row.quotationUnitPrice || 0}
            disabled={disabled}
          />
        </>
      ),
    },
    {
      name: "Item Value ", // calculation
      selector: (row) => row.qtnitemValue || "--",
      width: "150px",
    },   
    {
      name: "taxAmount ", // calculation
      selector: (row) => row.taxAmount || "--",
      width: "150px",
    },
    {
      name: "Delivery Lead Time (Days)", // input
      cell: (row) => (
        <>
          <InputField
            type="number"
            className="inputBox mt-1"
            id="deliveryLeadTime"
            name="deliveryLeadTime"
            value={row.deliveryLeadTime || 0}
            disabled={disabled}
          />
        </>
      ),
      width: "200px",
    },   
    {
      name: "Deviation", // input yes/NO
      selector: (row) => row.deviation || "--",
      cell: (row) => (
        <>
          <ComboboxField
            placeholder=""
            data={dropdownOption}
            id="deviation"
            name="deviation"
            value={row.deviation || 0}
            disabled={disabled}
          />
        </>
      ),
      width: "200px",
    },
    {
      name: "Deviation Remarks", // input
      selector: (row) => row.deviationRemark || "--",
      cell: (row) => (
        <>
          <TextAreaField
            className="inputBox mt-1"
            id="deviation"
            name="deviation"
            value={row.deviation}
            disabled={disabled}
          />
        </>
      ),
      width: "200px",
    },
  ];

  const loadFirstLevelData = async (page) => {
    // console.log(">> quotationID", quotationID);
    if (quotationID) {
      let obj = {
        documentNumber: quotationID,
      };
      await ItemService.getQuotationDatail(obj).then((item) => {
        if (item?.status === 200) {
          // console.log(">> item", item);
          setDisabledStatus(true);
          setFormInputs(item?.data[0]);
          setItemTableData(item?.data[0].items);
        } else {
          setFormInputs([]);
          console.log(">> Error: Loading getQuotationDatail");
        }
      });
    } else {
      navigate(Constants.PRESALES_CUSTOMER_QUOTATION_LIST);
    }
  };

  useEffect(() => {
    loadFirstLevelData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Commmon OnChange of the input scetion
  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };
  

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <h2 className="mainTitle">
              Quotation Details :{" "}
              <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                {quotationID}
              </span>
              <span>
                {formInputs?.status === "Pending" ? (
                  <span className="bg-table-status bg-table-info-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Sent" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Draft" ? (
                  <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Completed" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Reject" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Deactivated" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : (
                  formInputs?.status
                )}
              </span>
            </h2>
            <p className="subText">Quotation Information </p>
            <hr />
            {/* 01 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QH</span>
                    </Col>
                    <Col xs={12} md={3} lg={3}>
                      <span className="headerTitle">Quotation Header</span>
                      <span className="subHeaderTitle">Header Description</span>
                    </Col>

                    <Col xs={12} md={8} lg={8} className="headerButtonAlign">
                      <Col xs={4} md={3} lg={3} className="">
                        <button
                          className="btnTable"
                          onClick={() => createPOQuotation(itemTableData)}
                        >
                          <i className="bi bi-calendar-check"></i> Create PO
                        </button>
                      </Col>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Inquiry Nr"
                          name="inquiryNr"
                          id="inquiryNr"
                          value={formInputs?.inquiryNr}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Inquiry Date"
                          name="inquiryDocumentType"
                          id="inquiryDocumentType"
                          value={formatDateTime(
                            formInputs?.inquiryDocumentType
                          )}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer Code"
                          name="customerCode"
                          placeholder=""
                          value={formInputs.customerCode || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer Name"
                          name="customerName"
                          placeholder=""
                          value={formInputs.customerName || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Sold to Party"
                          type="text"
                          name="soldToParty"
                          placeholder=""
                          value={formInputs.customerCode || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Ship to Party"
                          name="shipToParty"
                          placeholder=""
                          value={formInputs.customerCode || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Payment Terms"
                          name="paymentTerms"
                          placeholder=""
                          value={formInputs.paymentTerms || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Inco Terms"
                          name="incoTerms"
                          placeholder=""
                          value={formInputs.incoTerms || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Sales Organization"
                          name="salesOrg"
                          placeholder=""
                          value={formInputs.salesOrg || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Disrtibution Channel"
                          name="disrtibutionChannel"
                          placeholder=""
                          value={formInputs.disrtibutionChannel || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Division"
                          name="division"
                          placeholder=""
                          value={formInputs.division || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Remark"
                          type="text"
                          name="inquiryRemark"
                          id="inquiryRemark"
                          placeholder=""
                          value={formInputs?.inquiryRemark || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* 02 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">QI</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Quotation Header Info</span>
                      <span className="subHeaderTitle">
                        Quotation Header Information
                      </span>
                    </Col>

                    {/* <Col xs={2} md={1} lg={1} className="">
                      <SourcingCommonFilter />
                    </Col> */}
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationReference"
                          className="inputBox"
                          label="Quotation Reference"
                          name="quotationReference"
                          placeholder=""
                          value={formInputs?.quotationReference}
                          onChange={onInputChange}
                          disabled={disabled}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="quotationSubmissionDate"
                          className="inputBox"
                          type="date"
                          label="Quotation Date"
                          name="quotationSubmissionDate"
                          placeholder=""
                          value={formInputs?.quotationSubmissionDate}
                          onChange={onInputChange}
                          disabled={disabled}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Payment term"
                          placeholder=""
                          // data={getpaymentTerm}
                          id="qtnPaymentTerms"
                          iconClassName="dropdownIcon"
                          name="qtnPaymentTerms"
                          code="qtnPaymentTermsCode"
                          description="qtnPaymentTermsDesc"
                          setValue={formInputs?.qtnPaymentTerms}
                          // getvalue={setDropdownData}
                          disabled={disabled}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Inco Terms"
                          placeholder=""
                          data={[]}
                          id="qtnIncoTerms"
                          iconClassName="dropdownIcon"
                          name="qtnIncoTerms"
                          code="qtnIncoTermsCode"
                          description="qtnIncoTermsDesc"
                          setValue={formInputs?.qtnIncoTerms}
                          // getvalue={setDropdownData}
                          disabled={disabled}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Bid Currency"
                          placeholder=""
                          data={[]}
                          id="qtnBidderCurrency"
                          iconClassName="dropdownIcon"
                          name="qtnBidderCurrency"
                          code="qtnBidderCurrencyCode"
                          description="qtnBidderCurrencyDesc"
                          setValue={formInputs?.qtnBidderCurrency}
                          // getvalue={setDropdownData}
                          disabled={disabled}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Pricing Notes"
                          type="text"
                          name="qtnPricingNotes"
                          id="qtnPricingNotes"
                          placeholder=""
                          value={formInputs?.qtnPricingNotes || ""}
                          onChange={onInputChange}
                          disabled={disabled}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Quotation Notes"
                          type="text"
                          name="quotationNotes"
                          id="quotationNotes"
                          placeholder=""
                          value={formInputs?.quotationNotes || ""}
                          onChange={onInputChange}
                          disabled={disabled}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          id="commercialQuoteArticle"
                          className="inputBox"
                          label="COMMERIAL PROPOSAL"
                          type="file"
                          name="commercialQuoteArticle"
                          placeholder=""
                          value={formInputs?.commercialQuoteArticle}
                          onChange={onInputChange}
                          disabled={disabled}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* 03 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PI</span>
                    </Col>
                    <Col xs={12} md={4} lg={4}>
                      <span className="headerTitle">
                        Price Shedule / Line Item
                      </span>
                      <span className="subHeaderTitle">
                        Price Shedule / Line Item
                      </span>
                    </Col>
                  </Row>
                </h2>
                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={PRLineItemInitialCol}
                          data={itemTableData}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* other Charges table */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">OC</span>
                    </Col>

                    <Col xs={4} md={6} lg={6}>
                      <span className="headerTitle">Other Charges</span>
                      <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          // title=""
                          columns={HeaderTextColumns}
                          data={formInputs?.othersCharge}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination

                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Pricing Summary */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible6
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility6}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PS</span>
                    </Col>
                    <Col xs={12} md={4} lg={4}>
                      <span className="headerTitle">Pricing Summary</span>
                      <span className="subHeaderTitle">Pricing Summary </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible6 ? "visible" : ""
                  }`}
                >
                  <Row className="my-4 ms-3 ">
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="d-flex mb-2 jsutify-content-center align-items-center"
                    >
                      <p className="fw-medium">
                        SubTotal Pricing Schedule / Line Items (A){" "}
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md={6}
                      lg={6}
                      className="d-flex mb-2 jsutify-content-center align-items-center"
                    >
                      <InputField
                        id="qtnSubTotalLineItem"
                        className="inputBox"
                        name="qtnSubTotalLineItem"
                        placeholder=""
                        value={formInputs?.qtnSubTotalLineItem}
                        disabled={getDisabledStatus}
                      />
                      <p className="ms-4 fw-medium">
                        {" "}
                        {formInputs?.rfxCurrency}
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="d-flex mb-2 jsutify-content-center align-items-center "
                    >
                      <p className="fw-medium"> SubTotal Other Charges (B)</p>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      lg={7}
                      className="d-flex mb-2 jsutify-content-center align-items-center "
                    >
                      <InputField
                        id="getOtherChargesValue37"
                        className="inputBox"
                        name="getOtherChargesValue"
                        placeholder=""
                        value={formInputs?.qtnSubTotalOtherCharges}
                        disabled={getDisabledStatus}
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      lg={4}
                      className="d-flex mb-2 jsutify-content-center align-items-center"
                    >
                      <p className="fw-medium fw-bold">
                        Quotation Gross Total (C = A + B)
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      lg={7}
                      className="d-flex mb-2 jsutify-content-center align-items-center"
                    >
                      <InputField
                        id="qtnTotal"
                        className="inputBox"
                        /* label="Closing Date:" */
                        name="qtnTotal"
                        placeholder=""
                        value={formInputs?.qtnTotal}
                        onChange={onInputChange}
                        disabled={getDisabledStatus}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Item Text  */}
      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />
    </main>
  );
};

export default QuotationDetailPage;

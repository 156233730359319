/* This page is for Approval and Display page */

import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector, useDispatch } from "react-redux";

//Serivce
import ItemService from "../../../../services/supplier_appCommonService";

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import DataTable from "react-data-table-component";
// import { formatDateTime } from "../../../common/Validations";
import ShowHorizontalStatus from "../../../../Components/Modal/supplier_app/Workflow/ShowHorizontalStatus";
import Loader from "../../../../Components/Loader";
import EvaluationTable from "../../../../Components/PivotTable/EvaluationTable";
import TechLineItemTable from "../../../../Components/PivotTable/TechLineItemTable";
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
import Constants from "../../../../common/SupplierConstants";
import { getRfqQuotation } from "../../../../redux/feature/supplier_app/sourcingSlice";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import { formatDateTime, getCurrentDate } from "../../../../common/Validations";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import WorkflowApproveReject from "../../../../Components/Workflow/WorkflowApproveReject";
import RichTextEditor from "../../../../Components/Editor/RichTextEditor";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";

const TechnicalEvaluation = () => {
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Request for Quotation");

  let rfxNr = useSelector((state) => state?.sourcing?.rfq?.rfxNr); // User Details from Store
  let workflowConfig = useSelector((state) => state?.common?.data?.workflow); // workflowConfig Details from Store
  let sourcingConfig = useSelector((state) => state?.common?.data?.sourcing); // Common Details from Store
  let workflow = useSelector((state) => state?.sourcing?.rfq?.workflowTECH); // workflowTECH from Store

  const modalData = {
    title: "Technical Evaluation",
    successMsg: "",
  };

  const [loading, setLoading] = useState(true);
  const [techData, setTechData] = useState([]);
  const [getTechnicalEvalHeaderData, setTechnicalEvalHeaderData] = useState([]);
  const [lineItemData, setLineItemData] = useState([]);
  const [technicalEvaluationTableData, setTechnicalEvaluationTableData] =
    useState([]);
  const [itemTableData, setItemTableData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getModalInfo, setModalInfo] = useState(modalData);
  const [getTechnicalUserList, setTechnicalUserList] = useState([]);
  const [getDropdownData, setDropdownData] = useState("");
  const [getDisabledStatus, setDisabledStatus] = useState(false);

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible8, setCardVisible8] = useState(true);
  const [isCardVisible9, setCardVisible9] = useState(true);
  const [isCardVisible10, setCardVisible10] = useState(true);

  const [formInputs, setFormInputs] = useState({
    rfxType: "",
    productType: "",
    rfxValue: "",
    rfxHeaderStatus: "",
    rfxbidType: "",
    rfxNoOfEvaluationType: "",
    rfxCurrency: "",
    rfxTechFocalPersonId: "",
    technicalApprovalType: "",
    plant: "",
    buyerResponsible: "",
    companyAddress: "",
    rfxVersion: "",
    buyerEmail: "",
    initiatorName: "",
    initiator: "",
    billingAddressCodeDesc: "",
    createdOn: "",
    buyerConact: "",
    deliveryAddressCodeDesc: "",
    rfxCategoryType: "",
    rfxCategoryCode: "",
    rfxCategoryDesc: "",
    rfqCreation: "",
    rfxTitle: "",
    openingdate: "",
    openingtime: "",
    closingdate: "",
    closingtime: "",
    rfxDescription: "",
    typeOfPurchase: "",
    otherCurrencies: "",
    headerIncoTerms: "",
    incoTermsLocation: "",
    procurementStrategies: "",
    items: "",
    formCOMM: "",
    formTECH: "",
    termsConditions: [],
    supplierList: [],

    openingDateTime: "",
    closingDateTime: "",
    totalHour: "",
    techEvaluationStartedOn: "",
    techEvaluationPreparedOn: "",
    technicalEvaluator: "",
  });
  console.log(formInputs);
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility8 = () => {
    setCardVisible8(!isCardVisible8);
  };
  const toggleCardVisibility9 = () => {
    setCardVisible9(!isCardVisible9);
  };
  const toggleCardVisibility10 = () => {
    setCardVisible10(!isCardVisible10);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  // Function to handle text changes
  const handleInputChangeTable = (id, value) => {
    const updatedData = formInputs?.supplierList?.map((item) => {
      return item.AssociateNumber === id
        ? {
            ...item,
            qtnSupplierRemarks: value,
          }
        : item;
    });

    setFormInputs((prevState) => ({
      ...prevState, // Keep the other fields in formInputs unchanged
      supplierList: updatedData, // Update the supplierList field
    }));
  };

  const suppliersColumns = [
    {
      name: "Supplier ID",
      selector: (row) => row.AssociateNumber || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btnInfo"
          // onClick={() => detailedPage(row.AssociateNumber)}
        >
          {row.AssociateNumber || "-"}{" "}
        </button>
      ),
      width: "120px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <>
          <TableStatusField status={row.status} />
        </>
      ),
      width: "170px",
    },

    {
      name: "Supplier",
      selector: (row) => row.organisationName,
      sortable: true,
      cell: (row) => <>{row.organisationName}</>,
      width: "180px",
    },
    {
      name: "SAP Number",
      selector: (row) => row.sapAssociateNumber || "--",
      sortable: true,
      width: "150px",
    },

    {
      name: "Quotation Nr",
      selector: (row) => row.quotationNr || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btnInfo"
          // onClick={() => detailedPage(row.AssociateNumber)}
        >
          {row.quotationNr || "-"}{" "}
        </button>
      ),
      width: "140px",
    },
    {
      name: "Quotation Date",
      selector: (row) => formatDateTime(row?.quotationCreatedAt) || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Quotation Version",
      selector: (row) => row?.qtnVersion || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Status",
      selector: (row) => row.qtnTechEvlStatus || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Remarks",
      selector: (row) => row.qtnSupplierRemarks,
      cell: (row) => (
        <>
          {getDisabledStatus ? (
            row?.qtnSupplierRemarks
          ) : (
            <InputField
              className="inputBox mt-1"
              name="qtnSupplierRemarks"
              value={row.qtnSupplierRemarks}
              onChange={(e) =>
                handleInputChangeTable(row.AssociateNumber, e.target.value)
              }
              disabled={getDisabledStatus}
            />
          )}
        </>
      ),
      sortable: true,
      width: "150px",
    },
  ];

  const loadGetInfo = async () => {
    let obj = {
      queryName: "getRFXTechEvaluationDetail",
      documentNumber: rfxNr,
    };
    await ItemService.generateQuery(obj).then((item) => {
      // console.log(">> res!", item);
      if (item?.status === 200) {
        setTechnicalEvalHeaderData(item?.data[0]);
        setTimeout(() => {
          // console.log(">> setTechData", item?.data);
          setTechData(item?.data);
          setLineItemData(item?.data);
        }, 1000);
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
  };
  // Load data
  const loadTechnicalEvaluationData = async () => {
    if (rfxNr) {
      let obj = {
        queryName: "ShowRFXTechEvaluation",
        rfxNr: rfxNr,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });
      await ItemService.generateQuery(obj).then((item) => {
        // console.log(">> res!", item);
        if (item?.status === 200) {
          setFormInputs(item.data[0]);
          if (item?.data[0]?.rfxHeaderStatus === "Tech Approved") {
            setDisabledStatus(true);
            loadGetInfo();
          } else {
            setDisabledStatus(false);
            setTechnicalEvalHeaderData(item?.data[0]);
            setTimeout(() => {
              setTechData(item?.data);
              setLineItemData(item?.data);
            }, 1000);
          }
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });
      setLoading(false);
    } else {
      window.history.back();
    }
  };

  // From Child Component
  const handleEvaluationTableData = (data) => {
    // console.log(">> child tech ", data);
    setTechnicalEvaluationTableData(data);
    setFormInputs((formInputs) => ({
      ...formInputs,
      rfxQuestions: data,
    }));
  };

  // From Child Component
  const handleItemData = (data) => {
    // console.log(">> item tech ", data);
    setItemTableData(data);
    setFormInputs((formInputs) => ({
      ...formInputs,
      items: data,
    }));
  };

  useEffect(() => {
    // loadInitialPageData();
    loadTechnicalEvaluationData();
    loadTechUser();
    formInputs.techEvaluationStartedOn = getCurrentDate("");
    formInputs.techEvaluationPreparedOn = getCurrentDate("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfxNr]);

  const loadTechUser = async () => {
    let obj = {
      roleId: "TECHNICAL",
    };
    await ItemService.getTechnicalUser(obj).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setTechnicalUserList(item?.data);
      } else {
        setTechnicalUserList([]);
        console.log(">> Error: Loading loadTechUser");
      }
    });
  };

  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  // Get Dropdown data select
  useEffect(() => {
    // console.log(">>datarfq", getDropdownData);
    if (getDropdownData?.name) {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  // SAVE
  // eslint-disable-next-line no-unused-vars
  const handleSave = async () => {
    // let requiredFields = [];

    // if(!validateRequired(formInputs?.organisationName)) {
    //   requiredFields.push('*Please enter Supplier Name');
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   return;
    // }

    // if (!validateEmail(formInputs?.email)) {
    //   requiredFields.push('*Invalid Contact Email address');
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   return;
    // }

    // if (!getPOLineItemsData[0]?.materialCode) {
    //   toast.error("Alteast one PO Line item should be added.", {
    //     duration: 4000,
    //     position: "top-right",
    //   });
    //   return;
    // }

    formInputs.rfxQuestions = technicalEvaluationTableData;
    formInputs.items = itemTableData;

    // console.log(">> handleSave", formInputs);

    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });

    let obj = {
      data: formInputs,
    };

    setDisabled(true);
    await ItemService.addRfxTechEvaluation(obj).then((item) => {
      // console.log(">> res!", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        setModalInfo(modalData);
        onSuccessfullyRegistered();

        // Update response value in Redux Store
        dispatch(getRfqQuotation({}));
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);
    setDisabled(false);
  };

  const handleEditorChange = (content) => {
    setFormInputs((formInputs) => ({
      ...formInputs,
      instructions: content,
    }));
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <Loader isLoading={loading} className={"text-center"} />
        </Col>
        {!loading && (
          <Col xs={12} md={12} className="rightSide">
            <div className="rightSideInner PageCommonCSS">
              <h2 className="mainTitle">
                Technical Evaluation for
                <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                  {formInputs?.rfxNr}
                </span>
                <span>
                  {formInputs?.rfxHeaderStatus === "Published" ? (
                    <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "QTN Received" ? (
                    <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "Cancelled" ? (
                    <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "RFQ Created" ? (
                    <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "Acknowledged" ? (
                    <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "Commercial Approved" ? (
                    <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "Tech Approved" ? (
                    <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "Technical Open" ? (
                    <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "Commercial Open" ? (
                    <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : (
                    formInputs?.rfxHeaderStatus
                  )}
                </span>
              </h2>
              <p className="subText">Technical Evaluation Details</p>
              <hr />

              {/* Workflow Component */}
              <WorkflowApproveReject
                workflowData={workflow}
                formInputsData={formInputs}
              />

              {workflowConfig?.showInDetailPage &&
                formInputs?.workflowActionLogs?.length > 0 && (
                  <ShowHorizontalStatus
                    rowdatamodal={formInputs?.workflowActionLogs}
                  />
                )}

              {/*RFQ Type  */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible1
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility1}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">RT</span>
                      </Col>
                      <Col xs={12} md={4} lg={4}>
                        <span className="headerTitle">RFQ Details </span>
                        <span className="subHeaderTitle">
                          RFQ Type Information
                        </span>
                      </Col>
                    </Row>
                  </h2>

                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible1 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="RFQ ID"
                            name="rfxNr"
                            // placeholder=""
                            value={formInputs?.rfxNr || ""}
                            // onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="RFQ Version"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.rfxVersion || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Title"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxTitle || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Description"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxDescription || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="RFQ Category"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxCategoryDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="RFQ Type"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxTypeDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Number Of Evaluation"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxNoOfEvaluationTypeDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Product Type"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.productTypeDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Buyer Contact"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxCurrencyCode || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Buyer Responsible"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.initiatorName || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>

                        {/* <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Billing Address"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.billingAddressDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col> */}
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Company Address"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.companyAddressDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Delivery Address"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.companyAddressDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="INCOTerms"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.INCOTermslocation || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="INCOTerms Location"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.INCOTermslocation || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              {/*Header Info */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible3
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility3}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">HI</span>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <span className="headerTitle">
                          Technical Evaluation Details
                        </span>
                        <span className="subHeaderTitle">
                          Technical Evaluation Information
                        </span>
                      </Col>
                    </Row>
                  </h2>

                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible3 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Tech Evaluation Started On"
                            type="date"
                            name="techEvaluationStartedOn"
                            value={
                              (formInputs?.techEvaluationStartedOn || getTechnicalEvalHeaderData?.techEvaluationStartedOn) ||
                              getCurrentDate("")
                            }
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Tech Evaluation Prepared On "
                            name="techEvaluationPreparedOn "
                            type="date"
                            value={
                              (formInputs?.techEvaluationPreparedOn || getTechnicalEvalHeaderData?.techEvaluationPreparedOn) ||
                              getCurrentDate("")
                            }
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <ComboboxField
                            label="Tech Focal Point"
                            placeholder=""
                            data={getTechnicalUserList}
                            id="rfxTechFocalPersonId"
                            name="rfxTechFocalPersonId"
                            code="rfxTechFocalPersonIdCode"
                            description="rfxTechFocalPersonIdDesc"
                            iconClassName="dropdownIcon"
                            setValue={
                              formInputs?.rfxTechFocalPersonId || getTechnicalEvalHeaderData?.rfxTechFocalPersonId
                            }
                            getvalue={setDropdownData}
                            disabled={getDisabledStatus}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label=" Technical Approval Type"
                            name="firstName"
                            placeholder=""
                            value={
                              formInputs?.technicalApprovalType || getTechnicalEvalHeaderData?.technicalApprovalType
                            }
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Eligibility Score"
                            name="firstName"
                            placeholder=""
                            value={
                              sourcingConfig?.rfxControlData?.eligibilityScore
                            }
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Technical Evaluator"
                            name="technicalEvaluator"
                            placeholder=""
                            value={
                              formInputs?.technicalEvaluator || getTechnicalEvalHeaderData?.technicalEvaluator
                            }
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <TextAreaField
                            className="inputBox"
                            label="Evaluator Comments"
                            name="evaluatorComments"
                            value={
                              formInputs?.evaluatorComments || getTechnicalEvalHeaderData?.evaluatorComments 
                            }
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <TextAreaField
                            className="inputBox"
                            label="Evaluator Supplier Clarification"
                            name="evaluatorSupplierClarification"
                            value={
                              formInputs?.evaluatorSupplierClarification || getTechnicalEvalHeaderData?.evaluatorSupplierClarification
                            }
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Upload Tech Eval Report Attachments"
                            name="uploadTechEvalReportAttachments"
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="file"
                              id="formFile"
                              disabled={getDisabledStatus}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* Instructions 10*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible10
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility10}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">RE</span>
                      </Col>
                      <Col xs={8} md={9} lg={9}>
                        <span className="headerTitle">Instructions</span>
                        <span className="subHeaderTitle">
                          Instructions Section
                        </span>
                      </Col>
                    </Row>
                  </h2>

                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible10 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="p-4">
                        <Col xs={12} md={12} lg={12}>
                          <RichTextEditor
                            value={getTechnicalEvalHeaderData?.instructions}
                            readOnly={getDisabledStatus}
                            onChange={handleEditorChange}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* 5 */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible5
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility5}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">SD</span>
                      </Col>
                      <Col xs={12} md={6} lg={7}>
                        <span className="headerTitle">Suppliers</span>
                        <span className="subHeaderTitle">
                          Selected Suppliers Details
                        </span>
                      </Col>
                    </Row>
                  </h2>
                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible5 ? "visible" : ""
                    }`}
                    // aria-labelledby="headingOne"
                    // data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <DataTable
                            // title=""
                            columns={suppliersColumns}
                            data={getTechnicalEvalHeaderData?.supplierList}
                            // progressPending={loading}
                            // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                            // pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            selectableRows
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* Technical Evaluation 08*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible8
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility8}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">OC</span>
                      </Col>

                      <Col xs={4} md={8} lg={7}>
                        <span className="headerTitle">
                          Technical Evaluation Questions
                        </span>
                        {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                      </Col>
                    </Row>
                  </h2>

                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible8 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body autoScroll">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <EvaluationTable
                            data={techData}
                            sendDataToParent={handleEvaluationTableData}
                            pageStatus={getDisabledStatus}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* Line Item 09*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible9
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility9}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">LI</span>
                      </Col>

                      <Col xs={4} md={8} lg={7}>
                        <span className="headerTitle">Line Item</span>
                        {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                      </Col>
                    </Row>
                  </h2>

                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible9 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body autoScroll">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <TechLineItemTable
                            data={lineItemData}
                            sendDataToParent={handleItemData}
                            pageStatus={getDisabledStatus}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {!getDisabledStatus && (
                <div className="pageFooter">
                  <Row className="rowDirection">
                    {/* <Col xs={12} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        // onClick={handleSave}
                        disabled={disabled}
                      >
                        Create Technical Evaluation
                      </button>
                    </Col> */}
                    <Col xs={12} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        // onClick={handleSave}
                        disabled={disabled}
                      >
                        Assign Tech Evaluator
                      </button>
                    </Col>
                    <Col xs={12} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        // onClick={handleSave}
                        disabled={disabled}
                      >
                        Save as Draft
                      </button>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </Col>
        )}
      </Row>

      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={Constants.SOURCING_BUYER_REQUEST_FOR_QUOTATION}
      />
    </main>
  );
};

export default TechnicalEvaluation;

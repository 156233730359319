/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

//API
import ItemService from "../../../../services/customer_appCommonService";
import ItemServiceSupplier from "../../../../services/supplier_appCommonService";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch
import {
  getSupplierCategorySelection,
  getSupplierList,
} from "../../../../redux/feature/supplier_app/supplierSlice";

//Components
import { InputField } from "../../../../Components/formElements/InputField";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import CategorySelectionModal from "../../../../Components/Modal/supplier_app/CategorySelectionModal";
import DataTable from "react-data-table-component";
import Constants from "../../../../common/SupplierConstants";
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
import FailureModal from "../../../../Components/Modal/supplier_app/FailureModal";
import {
  validateContactNumber,
  validateEmail,
  validateName,
  validateTextArea,
  validateRequired,
  validPostalCode,
} from "../../../../common/Validations";

const message = {
  VALID_CUSTOMER_NAME: "Special characters or numbers are not allowed.",
  VALID_MAIL_ID: "Please enter a valid email address.",
  VALID_ALTERNATE_MAIL_ID: "Please enter a valid alternate email address.",
  VALID_CONTACT_NUMBER: "Please enter a valid contact number.",
  VALID_ALTERNATE_CONTACT_NUMBER:
    "Please enter a valid alternate contact number.",
  VALID_PURPOSE_JUSTIFICATION:
    "Please ensure the justification is under 500 characters.",
  VALID_POSTAL_CODE:
    "Enter a 6-character postal code with uppercase letters and numbers only",
  VALID_CONTACT_FIRST_NAME: "Special characters and numbers are not allowed",
  VALID_CONTACT_LAST_NAME: "Special characters and numbers are not allowed",
  EXIStS_MAIL_ID: "Email already exists",
  EXIStS_CONTACT_NUMBER: "Contact Number already exists",
  EXIStS_ALTERNATE_CONTACT_NUMBER: "Contact Number already exists",
  EXIStS_ALTERNATE_MAIL_ID: "Alternate Contact Number already exists",
  FILL_REQUIRED_FIELD: "Please fill the required field",
  ALTERNATE_MAIL_ID: "Email ID is same",
  ALTERNATE_CONTACT_NUMBER: "Contact number is same",
};

const CustomerSelfRegistration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  localStorage.setItem("menu", "Customer");
  localStorage.setItem("submenu", "Customer List");

  let userInfo = useSelector((state) => state?.user?.data); // User Details from Store
  let selectedCategoryData = useSelector(
    (state) => state?.supplier?.selectedCategory
  ); // User Details from Store
  let accountGroupName = useSelector((state) => state?.supplier?.accountGroup); // User Details from Store

  // Format the price above to USD using the locale, style, and currency.
  // let USDollar = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });
  const modalData = {
    title: "Customer Registration",
    successMsg: "",
  };
  let dropdownOption = [
    {
      id: "1",
      name: "Yes",
    },
    {
      id: "2",
      name: "No",
    },
  ];

  /* pop failure modal */
  const failureModalData = {
    title: "Warning",
    ErrorMsg: "",
  };

  let requiredFields = [];

  const [getDropdownData, setDropdownData] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [getAccessData, setAccessData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [getSelectedData, setSelectedData] = useState([]);
  const [getDisabledRemove, setDisabledRemove] = useState(true);

  const [getPurchaseOrg, setPurchaseOrg] = useState([]);
  const [getCompanyCode, setCompanyCode] = useState([]);

  const [getCountry, setCountry] = useState([]);
  const [getState, setState] = useState([]);
  const [getTitle, setTitle] = useState([]);
  const [getcontactPosition, setcontactPosition] = useState([]);
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getCustomerType, setCustomerType] = useState([]);
  const [getModalInfo, setModalInfo] = useState(modalData);

  const [getFailureModal, setFailureModal] = useState(failureModalData);
  const [getShowFailureModal, setShowFailureModal] = useState(false);
  const [getErrorFields, setErrorFields] = useState([]);

  const [validation, setValidation] = useState({
    organisationName: "",
    alternateContactNumber: "",
    alternateEmailId: "",
    purposeJustification: "",
    contactNumber: "",
    email: "",
    cityPostalCode: "",
    address: "",
    address2: "",
    city: "",
    contactfirstName: "",
    contactlastName: "",
    contactPosition: "",
    contactTitle: "",
    ISOCertification: "",
    CompanyBrochure: "",
    customerType: "",
  });

  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };

  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };

  /* Modal Function */
  const categorySelection = () => {
    setModalShow(true);
  };
  const hideModal = () => {
    setModalShow(false);
  };

  // get SelectedCatData
  const getSelectedCatData = (data) => {
    // console.log(">> getSelectedCatData ", data);
    setData(data);
    formInputs.vendorCategory = data;
  };

  const onInputChange = ({ target: { name, value } }) => {
    const errors = {};
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
    validateName(name === "organisationName" ? value : "")
      ? (errors.organisationName = "")
      : (errors.organisationName = message.VALID_CUSTOMER_NAME);
    validateEmail(name === "email" ? value : "")
      ? (errors.email = "")
      : (errors.email = message.VALID_MAIL_ID);
    validateEmail(name === "alternateEmailId" ? value : "")
      ? (errors.alternateEmailId = "")
      : (errors.alternateEmailId = message.VALID_ALTERNATE_MAIL_ID);
    validateContactNumber(name === "contactNumber" ? value : "")
      ? (errors.contactNumber = "")
      : (errors.contactNumber = message.VALID_CONTACT_NUMBER);
    validateContactNumber(name === "alternateContactNumber" ? value : "")
      ? (errors.alternateContactNumber = "")
      : (errors.alternateContactNumber =
          message.VALID_ALTERNATE_CONTACT_NUMBER);
    validateTextArea(name === "purposeJustification" ? value : "")
      ? (errors.purposeJustification = "")
      : (errors.purposeJustification = message.VALID_PURPOSE_JUSTIFICATION);
    validPostalCode(name === "cityPostalCode" ? value : "")
      ? (errors.cityPostalCode = "")
      : (errors.cityPostalCode = message.VALID_POSTAL_CODE);
    validateName(name === "contactfirstName" ? value : "")
      ? (errors.contactfirstName = "")
      : (errors.contactfirstName = message.VALID_CONTACT_FIRST_NAME);
    validateName(name === "contactlastName" ? value : "")
      ? (errors.contactlastName = "")
      : (errors.contactlastName = message.VALID_CONTACT_LAST_NAME);
    setValidation((prev) => ({
      ...prev,
      [name]: errors[name] || "",
    }));
  };

  const handleCheckValidation = async ({ target: { name, value } }) => {
    const errors = {};
    let obj = {};
    if (value) {
      if (name === "email") {
        if (validateEmail(value)) {
          obj = { queryName: "ValidateEmail", validate: value };
          await ItemServiceSupplier.checkValidation(obj).then((item) => {
            // console.log(">> item", item);
            item.status === 200
              ? (errors.email = "")
              : (errors.email = message.EXIStS_MAIL_ID);
          });
          setValidation((prev) => ({
            ...prev,
            [name]: errors[name] || "",
          }));
        }
      }
      if (name === "contactNumber") {
        if (validateContactNumber(value)) {
          obj = { queryName: "ValidateContactNumber", validate: value };
          await ItemServiceSupplier.checkValidation(obj).then((item) => {
            // console.log(">> item", item);
            item.status === 200
              ? (errors.contactNumber = "")
              : (errors.contactNumber = message.EXIStS_CONTACT_NUMBER);
          });
          setValidation((prev) => ({
            ...prev,
            [name]: errors[name] || "",
          }));
        }
      }
    }
  };
  const handleCheckValidationAlternet = async ({ target: { name, value } }) => {
    const errors = {};
    let obj = {};
    if (value) {
      if (name === "alternateEmailId") {
        if (validateEmail(value)) {
          obj = { queryName: "ValidateEmail", validate: value };
          await ItemServiceSupplier.checkValidation(obj).then((item) => {
            // console.log(">> item", item);
            item.status === 200
              ? (errors.alternateEmailId = "")
              : (errors.alternateEmailId = message.EXIStS_ALTERNATE_MAIL_ID);
          });
          setValidation((prev) => ({
            ...prev,
            [name]: errors[name] || "",
          }));
        }
      }
      if (name === "alternateContactNumber") {
        if (validateContactNumber(value)) {
          obj = { queryName: "ValidateContactNumber", validate: value };
          await ItemServiceSupplier.checkValidation(obj).then((item) => {
            // console.log(">> item", item);
            item.status === 200
              ? (errors.alternateContactNumber = "")
              : (errors.alternateContactNumber =
                  message.EXIStS_ALTERNATE_CONTACT_NUMBER);
          });
          setValidation((prev) => ({
            ...prev,
            [name]: errors[name] || "",
          }));
        }
      }
    }
  };

  const [formInputs, setFormInputs] = useState({
    email: "",
    organisationName: "",
    firstName: "",
    version: "v0",
    status: "Non Invited",
    contactfirstName: "",
    contactlastName: "",
    contactNumber: "",
    category: "VENDOR",
    subCategory: [
      {
        categoryId: "TEMP-VENDOR",
        active: true,
      },
    ],
    roles: [
      {
        roleId: "TEMP-VENDOR",
        active: true,
      },
    ],
    accountGroup: accountGroupName,
    // initiatorName: "Admin",
    // initiator: "Admin",
    companyName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    contactTitle: "",
    cityPostalCode: "",
    ISOCertification: "",
    ICVCertificate: "",
    CompanyBrochure: "",
    purposeJustification: "",
    vendorCategory: [
      {
        categoryCode: "",
        subCategoryCode: "",
        vendorSubCategoryLongText: "",
        vendorCategoryText: "",
      },
    ],
    purchaseOrg: "",
    companyCode: "",
    contactDetails: [
      {
        lastName: "",
        firstName: "",
        contactEmail: "",
        contactTitle: "",
        contactNumber: "",
        contactPosition: "",
      },
    ],
  });
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    dispatch(getSupplierCategorySelection()); // getSupplierCategorySelection store
    getMasterData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get Dropdown data select
  // useEffect(() => {
  //   // console.log(">> data ss", getDropdownData);
  //   if (getDropdownData?.name) {
  //     setFormInputs((formInputs) => ({
  //       ...formInputs,
  //       [getDropdownData?.textField]: getDropdownData?.name,
  //     }));
  //     // setStateCode(getDropdownData?.code);
  //     getStateData(getDropdownData?.code);
  //   } else {
  //     setFormInputs((formInputs) => ({
  //       ...formInputs,
  //       [getDropdownData?.textField]: getDropdownData?.name,
  //     }));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getDropdownData]);

  useEffect(() => {
    console.log(">>Check getDropdownData Data", getDropdownData);
    if (getDropdownData?.codeType === "country") {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
      getStateData(getDropdownData?.code);
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    }
    setValidation((prev) => ({
      ...prev,
      [getDropdownData?.textField]: "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  // console.log(">> formInputs", formInputs);

  // Datatables
  const fetchUsers = async (page) => {
    setLoading(true);
    setData(selectedCategoryData);
    formInputs.vendorCategory = selectedCategoryData;
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategoryData]);

  const columns = [
    {
      name: "Category Code",
      selector: (row) => row.vendorCategoryCode || "--",
      sortable: true,
      width: "250px",
    },
    {
      name: "Category Desc",
      selector: (row) => row.vendorCategoryText || "--",
      sortable: true,
      width: "280px",
    },

    {
      name: "SubCategory",
      selector: (row) => row.vendorSubCategoryText || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "SubCategory Code",
      selector: (row) => row.vendorSubCategoryCode || "--",
      sortable: true,
      width: "200px",
    },
    {
      name: "SubCategory Desc",
      selector: (row) => row.vendorSubCategoryLongText || "--",
      sortable: true,
      width: "400px",
    },
  ];

  const handleChange = ({ selectedRows }) => {
    // console.log(">> selectedRows ", selectedRows);
    if (selectedRows?.length > 0) {
      setDisabledRemove(false);
    } else {
      setDisabledRemove(true);
    }
    setSelectedData(selectedRows);
  };

  const removeCategory = () => {
    const updatedInitialValue = data.filter(
      (item) => !getSelectedData.some((selected) => selected._id === item._id)
    );
    setData(updatedInitialValue);
    setDisabledRemove(true);
  };

  // SAVE
  const handleSave = async () => {
    const requiredFields = [];
    const anyFieldNotEmpty = Object.values(validation).some(
      (value) => value !== null && value !== ""
    );
    if (anyFieldNotEmpty) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      return;
    }
    if (!validateRequired(formInputs.organisationName)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        organisationName: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }

    if (!validateName(formInputs.organisationName)) {
      requiredFields.push(`${message.VALID_SUPPLIER_NAME}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        organisationName: message.VALID_SUPPLIER_NAME,
      }));
      return;
    }
    if (!validateRequired(formInputs.address)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        address: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.address2)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        address2: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.city)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        city: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.country)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        country: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.state)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        state: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.cityPostalCode)) {
      requiredFields.push(`*${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        cityPostalCode: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validPostalCode(formInputs.cityPostalCode)) {
      requiredFields.push(`*${message.VALID_POSTAL_CODE}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        cityPostalCode: message.VALID_POSTAL_CODE,
      }));
      return;
    }
    if (!validateRequired(formInputs.contactPosition)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        contactPosition: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.contactTitle)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        contactTitle: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.contactfirstName)) {
      requiredFields.push(`*${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        contactfirstName: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateName(formInputs.contactfirstName)) {
      requiredFields.push(`*${message.VALID_CONTACT_FIRST_NAME}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        contactfirstName: message.VALID_CONTACT_FIRST_NAME,
      }));
      return;
    }
    if (!validateRequired(formInputs.contactlastName)) {
      requiredFields.push(`*${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        contactlastName: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateName(formInputs.contactlastName)) {
      requiredFields.push(`*${message.VALID_CONTACT_LAST_NAME}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        contactlastName: message.VALID_CONTACT_LAST_NAME,
      }));
      return;
    }
    if (!validateRequired(formInputs.email)) {
      requiredFields.push(`*${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        email: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateEmail(formInputs.email)) {
      requiredFields.push(`*${message.VALID_MAIL_ID}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        email: message.VALID_MAIL_ID,
      }));
      return;
    }
    if (!validateRequired(formInputs.contactNumber)) {
      requiredFields.push(`*${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        contactNumber: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateContactNumber(formInputs.contactNumber)) {
      requiredFields.push(`*${message.VALID_CONTACT_NUMBER}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        contactNumber: message.VALID_CONTACT_NUMBER,
      }));
      return;
    }
    if (formInputs.alternateEmailId === formInputs.email) {
      requiredFields.push(`*${message.ALTERNATE_MAIL_ID}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        alternateEmailId: message.ALTERNATE_MAIL_ID,
      }));
      return;
    }
    if (formInputs.alternateContactNumber === formInputs.contactNumber) {
      requiredFields.push(`*${message.ALTERNATE_CONTACT_NUMBER}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        alternateContactNumber: message.ALTERNATE_CONTACT_NUMBER,
      }));
      return;
    }
    if (!validateRequired(formInputs.ISOCertification)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        ISOCertification: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.CompanyBrochure)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        CompanyBrochure: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.customerType)) {
      requiredFields.push(`${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        customerType: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateRequired(formInputs.purposeJustification)) {
      requiredFields.push(`*${message.FILL_REQUIRED_FIELD}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        purposeJustification: message.FILL_REQUIRED_FIELD,
      }));
      return;
    }
    if (!validateTextArea(formInputs.purposeJustification)) {
      requiredFields.push(`*${message.VALID_PURPOSE_JUSTIFICATION}`);
      setErrorFields(requiredFields);
      setShowFailureModal(true);
      setValidation((prev) => ({
        ...prev,
        purposeJustification: message.VALID_PURPOSE_JUSTIFICATION,
      }));
      return;
    }

    console.log(">> handleSave", formInputs);
    formInputs.firstName = formInputs.organisationName;

    formInputs.initiatorName = "Self";
    formInputs.initiator = "Self";

    // initiatedBy: "Admin",
    // initiator: "Admin",

    let objContactDetails = {
      lastName: formInputs?.contactlastName,
      firstName: formInputs?.contactfirstName,
      contactEmail: formInputs?.email,
      contactTitle: formInputs?.contactTitle,
      contactNumber: formInputs?.contactNumber,
      contactPosition: formInputs?.contactPosition,
    };

    formInputs.contactDetails = [objContactDetails];

    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });
    let obj = {
      data: formInputs,
    };

    setDisabled(true);
    await ItemService.addSelfCustomer(obj).then((item) => {
      console.log(">> item!", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        // toast.success(item?.message || "Deleted successfully", {
        //   duration: 3000,
        //   position: "top-right",
        // });

        setModalInfo(modalData);
        onSuccessfullyRegistered();
        // Update response value in Redux Store
        dispatch(getSupplierList()); // event store
      } else {
        toast.error("Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);
    setDisabled(false);
  };

  //Load Master Data
  const getMasterData = async () => {
    let obj = {
      codeType: "title",
    };
    await ItemService.getMasterDataSelf(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setTitle(item?.data);
      } else {
        setTitle([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });

    let obj0 = {
      codeType: "country",
    };
    await ItemService.getMasterDataSelf(obj0).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setCountry(item?.data);
      } else {
        setCountry([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
    let obj1 = {
      codeType: "companyCode",
    };
    await ItemService.getMasterDataSelf(obj1).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setCompanyCode(item?.data);
      } else {
        setCompanyCode([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
    let obj2 = {
      codeType: "purchaseOrganisation",
    };
    await ItemService.getMasterDataSelf(obj2).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setPurchaseOrg(item?.data);
      } else {
        setPurchaseOrg([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
    let obj3 = {
      codeType: "jobtitle",
    };
    await ItemService.getMasterDataSelf(obj3).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setcontactPosition(item?.data);
      } else {
        setcontactPosition([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
    let obj4 = {
      codeType: "CustomerType",
    };
    await ItemService.getMasterDataSelf(obj4).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setCustomerType(item?.data);
      } else {
        setCustomerType([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
  };

  // Onselect of Country state will load
  const getStateData = async (code) => {
    let obj1 = {
      codeType: "region",
      code: code,
    };
    await ItemService.getMasterDataSelf(obj1).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setState(item?.data);
      } else {
        setState([]);
        console.log(">> Error: Loading getMasterDataSelf");
      }
    });
  };

  const hideFailureModal = () => {
    setShowFailureModal(false);
  };

  const navigateToLogin = () => {
    navigate(Constants.LOGIN);
  };

  return (
    <main className="dashboard">
      <Row className="justify-center">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            {/* <h2 className="mainTitle">Self Customer Registration Form</h2>
            <p className="subText">Register as a new Customer or Sub-Contractor with our Company</p>
            <hr /> */}

            <Row className="mb-4">
              <Col xs={12} md={2} lg={2} className="text-left">
                <button className="btnBack" onClick={() => navigateToLogin()}>
                  <i className="bi bi-arrow-return-left"></i> Go Back Login
                </button>
              </Col>
              <Col
                xs={12}
                md={{ span: 2, offset: 8 }}
                lg={{ span: 2, offset: 8 }}
                className="text-left"
              >
                <button
                  className="btnTable"
                  onClick={handleSave}
                  disabled={disabled}
                >
                  <i className="bi bi-save"></i> Create
                </button>
              </Col>
            </Row>

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">BI</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">Primary Data</span>
                      <span className="subHeaderTitle">Primary Details</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer Name"
                          extraLabel="As per the trade license"
                          name="organisationName"
                          placeholder=""
                          value={formInputs.organisationName || ""}
                          onChange={onInputChange}
                          required={true}
                          error={validation.organisationName}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label=" Address Lines 1 / Street"
                          name="address"
                          placeholder=""
                          value={formInputs.address || ""}
                          onChange={onInputChange}
                          error={validation.address}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Address Line 2"
                          name="address2"
                          placeholder=""
                          value={formInputs.address2 || ""}
                          onChange={onInputChange}
                          error={validation.address2}
                          required={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="City"
                          name="city"
                          placeholder=""
                          value={formInputs.city || ""}
                          onChange={onInputChange}
                          error={validation.city}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Country"
                          placeholder=""
                          data={getCountry}
                          id="country"
                          iconClassName="dropdownIcon"
                          name="country"
                          code="countryCode"
                          description="countryDesc"
                          setValue={formInputs.country}
                          getvalue={setDropdownData}
                          required={true}
                          error={validation.country}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="State"
                          placeholder=""
                          data={getState}
                          id="state"
                          iconClassName="dropdownIcon"
                          name="state"
                          code="stateCode"
                          description="stateDesc"
                          setValue={formInputs.state}
                          getvalue={setDropdownData}
                          required={true}
                          error={validation.state}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Postal Code/PO Box"
                          name="cityPostalCode"
                          placeholder=""
                          value={formInputs.cityPostalCode || ""}
                          onChange={onInputChange}
                          error={validation.cityPostalCode}
                          required={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">BI</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">Contact Person</span>
                      <span className="subHeaderTitle">
                        Contact Person Details
                      </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Job Title"
                          placeholder=""
                          data={getcontactPosition}
                          id="contactPosition"
                          iconClassName="dropdownIcon"
                          name="contactPosition"
                          code="contactPositionCode"
                          description="contactPositionDesc"
                          setValue={formInputs.contactPosition}
                          getvalue={setDropdownData}
                          required={true}
                          error={validation.contactPosition}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Contact Title"
                          placeholder=""
                          data={getTitle}
                          id="contactTitle"
                          iconClassName="dropdownIcon"
                          name="contactTitle"
                          code="contactTitleCode"
                          description="contactTitleDesc"
                          setValue={formInputs.contactTitle}
                          getvalue={setDropdownData}
                          required={true}
                          error={validation.contactTitle}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Contact First Name"
                          name="contactfirstName"
                          placeholder=""
                          value={formInputs.contactfirstName || ""}
                          onChange={onInputChange}
                          error={validation.contactfirstName}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Contact Last Name"
                          name="contactlastName"
                          placeholder=""
                          value={formInputs.contactlastName || ""}
                          onChange={onInputChange}
                          error={validation.contactlastName}
                          required={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Contact Email"
                          name="email"
                          placeholder=""
                          value={formInputs.email || ""}
                          onChange={onInputChange}
                          onBlur={handleCheckValidation}
                          required={true}
                          error={validation.email}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Contact Number"
                          name="contactNumber"
                          placeholder=""
                          value={formInputs.contactNumber || ""}
                          onChange={onInputChange}
                          onBlur={handleCheckValidation}
                          required={true}
                          error={validation.contactNumber}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Alternate Contact Number"
                          name="alternateContactNumber"
                          placeholder=""
                          value={formInputs.alternateContactNumber || ""}
                          onChange={onInputChange}
                          onBlur={handleCheckValidationAlternet}
                          error={validation.alternateContactNumber}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Alternate Email-ID"
                          name="alternateEmailId"
                          placeholder=""
                          value={formInputs.alternateEmailId || ""}
                          onChange={onInputChange}
                          onBlur={handleCheckValidationAlternet}
                          error={validation.alternateEmailId}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AI</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">More Information</span>
                      <span className="subHeaderTitle">
                        Additional Information
                      </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Do you have ISO Certification?"
                          extraLabel="Certification?"
                          name="ISOCertification"
                          code="ISOCertificationCode"
                          description="ISOCertificationDesc"
                          data={dropdownOption}
                          id="ISOCertification"
                          iconClassName="dropdownIcon"
                          onChange={setDropdownData}
                          setValue={formInputs.ISOCertification}
                          error={validation.ISOCertification}
                          getvalue={setDropdownData}
                          required={true}
                          // className="dropdownOption"
                        />
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div>
                      </Col>

                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Do you have a valid ICV Certification?"
                          extraLabel="ICV Certification?"
                          name="ICVCertificate"
                          data={dropdownOption}
                          id="ICVCertificate"
                          iconClassName="dropdownIcon"
                          value={formInputs.ICVCertificate || ""}
                          onChange={onInputChange}

                          // className="dropdownOption"
                        />
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div>
                      </Col> */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Website/Logo and Company Brochure"
                          extraLabel="Web site URL /Logo  and Company Brochure Attachment"
                          name="CompanyBrochure"
                          placeholder=""
                          value={formInputs.CompanyBrochure || ""}
                          onChange={onInputChange}
                          disabled={false}
                          error={validation.CompanyBrochure}
                          required={true}
                        />
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Customer Type"
                          placeholder=""
                          data={getCustomerType}
                          id="customertype"
                          iconClassName="dropdownIcon"
                          name="customerType"
                          code="customerTypeCode"
                          description="customerTypeDesc"
                          setValue={formInputs.customerType}
                          getvalue={setDropdownData}
                          error={validation.customerType}
                          required={true}
                        />
                        {/* <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div> */}
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <TextAreaField
                          className="inputBox"
                          label="Purpose/Justification: Please outline the advantages our company would gain by registering your organization as a Customer"
                          extraLabel="Please explain benefits Our Company would get from registering your company?"
                          type="text"
                          name="purposeJustification"
                          id="purposeJustification"
                          value={formInputs.purposeJustification || ""}
                          onChange={onInputChange}
                          error={validation.purposeJustification}
                          // className="dropdownOption"
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">CS</span>
                    </Col>
                    <Col xs={8} md={6} lg={6}>
                      <span className="headerTitle">
                        Classification / Category
                      </span>
                      <span className="subHeaderTitle">
                        Select Required Group
                      </span>
                    </Col>
                    <Col xs={4} md={2} lg={2} className="btnSpaceInMobile">
                      <button
                        className="btnTable"
                        onClick={() => categorySelection()}
                      >
                        <i className="bi bi-plus-lg"></i> Add
                      </button>
                    </Col>

                    <Col xs={5} md={2} lg={2} className="btnSpaceInMobile">
                      <button
                        className="btnTable"
                        onClick={() => removeCategory()}
                        disabled={getDisabledRemove}
                      >
                        <i className="bi bi-trash3"></i> Remove Category
                      </button>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          progressPending={loading}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                          onSelectedRowsChange={handleChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">SI</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">SAP Information</span>
                      <span className="subHeaderTitle">
                        SAP Information of Customer
                      </span>
                    </Col>

                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Account Group"
                          name="accountGroup"
                          placeholder=""
                          value={formInputs.accountGroup || ""}
                          onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Company Code"
                          extraLabel="SAP Company Code"
                          placeholder=""
                          data={getCompanyCode}
                          id="companyCode"
                          iconClassName="dropdownIcon"
                          name="companyCode"
                          setValue={formInputs.companyCode || ""}
                          getvalue={setDropdownData}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Purchase Organization"
                          extraLabel="SAP Purchase organization"
                          placeholder=""
                          data={getPurchaseOrg}
                          id="purchaseOrg"
                          iconClassName="dropdownIcon"
                          name="purchaseOrg"
                          setValue={formInputs.purchaseOrg || ""}
                          getvalue={setDropdownData}
                          onChange={onInputChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </Col>
      </Row>

      {/* <Row className="rowDirection">
        <Col xs={12} md={2} lg={2} className="">
          <button className="btnTable" onClick={handleSave} disabled={disabled}>
            <i className="bi bi-save"></i> Save
          </button>
        </Col>
      </Row> */}

      <CategorySelectionModal
        show={modalShow}
        onHide={() => hideModal()}
        onselectedcatdata={getSelectedCatData}
      />

      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={Constants.SUPPILER_LIST}
      />

      <FailureModal
        show={getShowFailureModal}
        onHide={() => hideFailureModal()}
        failuremodaldata={getFailureModal}
        requiredfields={getErrorFields}
      />
    </main>
  );
};

export default CustomerSelfRegistration;

import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Components
import Layout from "../../../../Layout/Layout";
import CreateSupplierMasterModal from "../../../../Components/Modal/supplier_app/CreateSupplierMasterModal";
import Constants from "../../../../common/SupplierConstants";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import POCollabrationCommonFillter from "../../../../Components/Filter/POCollabration/POCollabrationCommonFillter";
import { useNavigate } from "react-router-dom";
import {
  addPoDetailNumber,
  addSesDetailNumber,
  getGoodsReceivedNote,
} from "../../../../redux/feature/supplier_app/purchasingSlice";
import ShowStatus from "../../../../Components/Modal/supplier_app/Workflow/ShowStatus";
import { SearchField } from "../../../../Components/formElements/SearchField";
import { currencyFormat, formatDateTime } from "../../../../common/Validations";
import CancelModal from "../../../../Components/Modal/supplier_app/common/CancelModal";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";

const ServiceEntrySheetList = () => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const navigate = useNavigate();

  localStorage.setItem("menu", "Purchasing");
  localStorage.setItem("submenu", "Service Entry Sheet");

  let sesData = useSelector((state) => state?.purchasing?.ses); // get ses from Store

  let sesDataTable = useSelector((state) => state?.purchasing?.ses?.pagination); // sesDataTable Details from Store

  let module = useSelector(
    (state) => state?.common?.data?.purchaseOrder?.SES?.module
  ); // config Details from Store

  const [data, setData] = useState([]);
  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");

  const [isCardVisible, setCardVisible] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");

  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  // Show Status Modal
  const [modalShowStatus, setModalShowStatus] = useState(false);
  const [sendDataModal, setSendDataModal] = useState("");
  const [modelTitle, setModelTitle] = useState("");

  /* Modal Function */
  const showStatusInfo = (rowData) => {
    setModalShowStatus(true);
    setSendDataModal(rowData);
    setModelTitle("Status Information");
  };

  const hideModalStatus = () => {
    setModalShowStatus(false);
  };

  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };
  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Line Item Details");
    setItemLevelModalShow(true);
  };

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  const hideModal = () => {
    setModalShow(false);
  };

  const loadInitialPageData = async () => {
    setData(sesData.data);
  };

  useEffect(() => {
    loadInitialPageData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sesData]);

  const handlePageChange = (page) => {
    let obj = {
      page: page,
      per_page: getNewPerPage,
      searchTerm: getCommonSearchTxt,
    };

    dispatch(getGoodsReceivedNote(obj));
    setData(sesData?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getGoodsReceivedNote(obj));
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      page: page,
      per_page: newPerPage,
      searchTerm: getCommonSearchTxt,
    };
    setNewPerPage(newPerPage);
    dispatch(getGoodsReceivedNote(obj));
    setData(sesData?.data);
  };

  const detailedPage = (sesNumber) => {
    dispatch(addSesDetailNumber(sesNumber));
    navigate(Constants.POCOLLABRATION_SERVICE_ENTRY_DETAILS);
  };

  const detailedPagePO = (poNumber) => {
    dispatch(addPoDetailNumber(poNumber));
    navigate(Constants.POCOLLABRATION_PURCHASE_ORDER_DETAIL_PAGE);
  };

  const columns = [
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "SES Number",
      selector: (row) => row.documentNumber,
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() => detailedPage(row.documentNumber)}
        >
          {row.documentNumber}{" "}
        </button>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Line Items",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length} </span>
        </>
      ),
      width: "120px",
    },
    // Conditionally include the 'workflowActionLogs' column
    ...(data?.some((row) => row.workflowActionLogs !== undefined)
      ? [
          {
            name: "Workflow log",
            selector: (row) => row.workflowActionLogs,
            cell: (row) => (
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-center iconTable"
              >
                <img
                  src="assets/img/newIcons/workflow.png"
                  alt="attachments"
                  style={{ width: "35px" }}
                  className="mt-1"
                  onClick={() => showStatusInfo(row.workflowActionLogs)}
                />
              </div>
            ),
            sortable: true,
            width: "132px",
          },
        ]
      : []),

    {
      name: "Status",
      selector: (row) => row.sesStatusHeader,
      cell: (row) => (
        <>
          <TableStatusField status={row.sesStatusHeader} />
        </>
      ),
      sortable: true,
      width: "200px",
    },

    {
      name: "SES Date/Time",
      selector: (row) => formatDateTime(row.createdAt) || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Vendor",
      selector: (row) => row.vendorCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.vendorCode} </p>
          <p className="m-0 p-0">{row.vendorName} </p>
        </div>
      ),
    },
    {
      name: "SES Value",
      selector: (row) => row.sumOfSes || "--",
      cell: (row) => (
        <span className="numberFormate m-0 p-0 pt-1 fw-bold">
          {currencyFormat(Number(row.sumOfSes).toFixed(2))}
        </span>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode || "--",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.currencyCode} </p>
          <p className="m-0 p-0">{row.currency} </p>
        </div>
      ),

      width: "150px",
    },
    {
      name: "PO/SA Number",
      selector: (row) => row.purchasingDocumentNr,
      cell: (row) => (
        <>
          {row.purchasingDocumentNr && (
            <button
              className="btnInfo"
              onClick={() => detailedPagePO(row.purchasingDocumentNr)}
            >
              {row.purchasingDocumentNr}{" "}
            </button>
          )}
          {!row.purchasingDocumentNr && (
            <>
              {" "}
              <p>--</p>{" "}
            </>
          )}
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "PO/SA Date/Time",
      selector: (row) => formatDateTime(row?.purchasingDocumentDate) || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "SAP SES",
      selector: (row) => row.sapDocumentNumber,
      sortable: true,
      width: "120px",
    },
    {
      name: "Posted On",
      selector: (row) => row.postedOn || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Delivery Note",
      selector: (row) => row.deliveryNoteNr || "--",
      sortable: true,
      width: "140px",
    },

    {
      name: "Payment Terms",
      selector: (row) => row.paymentTerms || "--",
      cell: (row) => (
        <div>
          <span>{row.paymentTerms} </span>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
  ];

  const lineItemColInfo = [
    {
      name: "Item Nr",
      cell: (row) => <span>{row.SesItemNr} </span>,
      width: "180px",
    },
    {
      name: "Material/Service",

      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.materialCode} </p>
          <p className="m-0 p-0">{row.materialDescription} </p>
        </div>
      ),
      width: "180px",
    },
    {
      name: "Material Group",
      cell: (row) => <span>{row.materialClass2} </span>,
      width: "220px",
    },
    {
      name: "SES Quantity",
      cell: (row) => <span>{row.sesQuantity} </span>,
      width: "220px",
    },
    {
      name: "UOM",
      cell: (row) => <span>{row.orderUnit} </span>,
      width: "220px",
    },
    {
      name: "Currency",
      cell: (row) => <span>{row.pocurrencyKey} </span>,
      width: "220px",
    },
    {
      name: "Delivery Date",
      cell: (row) => <span>{row.deliveryDate} </span>,
      width: "220px",
    },
    {
      name: "PO Number",
      cell: (row) => <span>{row.purchasingDocumentNr} </span>,
      width: "220px",
    },
    {
      name: "PO Item Number",
      cell: (row) => <span>{row.purchaseDocumentItem} </span>,
      width: "220px",
    },
  ];
  /* Cancel Modal */
  const [getCancelModalStatus, setCancelModalStatus] = useState(false);
  const cancelModalShow = () => {
    setCancelModalStatus(true);
  };
  const cancelModalHide = () => {
    setCancelModalStatus(false);
  };

  /* Handle selected rows */
  const [getSelectedRows, setSelectedRows] = useState([]);
  console.log("getSelectedRows>>", getSelectedRows);
  const handleRowSelected = ({ selectedRows }) => {
    const validRows = selectedRows.filter(
      (item) => item?.sesStatusHeader !== "Cancelled"
    );
    setSelectedRows(validRows);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle">Service Entry Sheet</h2>
                <p className="subText">
                  Lists of Service Entry Sheet and details
                </p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>

            <hr />

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">SS</span>
                    </Col>
                    <Col xs={12} md={8} lg={8}>
                      <span className="headerTitle">SES List</span>
                      <span className="subHeaderTitle">
                        View of Service Entry Sheet and Status{" "}
                      </span>
                    </Col>

                    {/* <Col xs={4} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={() => {
                          alert("asd");
                        }}
                      >
                        <i className="bi bi-plus-lg"></i> Change Buyer
                      </button>
                    </Col> */}
                    <Col xs={12} md={2} lg={2} className="headerButtonAlign">
                      <Col xs={6} md={6} lg={6} className="">
                        <button
                          className="btnTable"
                          onClick={cancelModalShow}
                          disabled={getSelectedRows.length > 0 ? false : true}
                        >
                          <i className="bi bi-x-circle"></i> Cancel
                        </button>
                      </Col>
                      <Col xs={6} md={1} lg={1} className="">
                        <POCollabrationCommonFillter />
                      </Col>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          progressPending={sesData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          paginationTotalRows={sesDataTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          onSelectedRowsChange={handleRowSelected}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <CreateSupplierMasterModal show={modalShow} onHide={() => hideModal()} />

      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />

      <ShowStatus
        show={modalShowStatus}
        onHide={() => hideModalStatus()}
        rowdatamodal={sendDataModal}
        modeltitle={modelTitle}
      />
      <CancelModal
        title="Cancel the SES"
        type={module}
        show={getCancelModalStatus}
        onHide={cancelModalHide}
        selectedrow={getSelectedRows}
      />
    </main>
  );
};

export default ServiceEntrySheetList;

import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { ComboboxField } from '../../../Components/formElements/ComboboxField';
import Layout from "../../../../Layout/Layout";
import { useNavigate } from "react-router-dom";
import Constants from "../../../../common/CustomerConstants";

//Redux
import { useSelector } from "react-redux"; //useDispatch
import { InputField } from "../../../../Components/formElements/InputField";

//Serivce
import ItemService from "../../../../services/customer_appCommonService";
import DataTable from "react-data-table-component";
import { currencyFormat } from "../../../../common/Validations";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";

const CustPurchaseOrderDetails = () => {
  const navigate = useNavigate();
  localStorage.setItem("menu", "Sales");
  localStorage.setItem("submenu", "Purchase Order");

  let poID = useSelector((state) => state?.preSales?.poID); // User Details from Store
  
  const [formInputs, setFormInputs] = useState({
    organisationName: "",
    companyName: "",
    email: "",
    firstName: "",
    lastName: "",
  });

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible1, setCardVisible1] = useState(true);
  const [itemTableData, setItemTableData] = useState();

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const [getDisabledStatus, setDisabledStatus] = useState(true);

  const dataRow =itemTableData

// PO columns Details
  const columns = [
    {
      name: "PO Number",
      selector: (row) => poID,
      sortable: true,
      width: "150px",
    },
    {
      name: "PO Item Nr",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "150px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>         
          <TableStatusField status={row.statusItem}/>
        </>
      ),
      width: "160px",
    },
    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.materialCode} </p>
          <p className="m-0 p-0">{row.materialDescription} </p>
        </div>
      ),
    },
    {
      name: "Item Text",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            // onClick={() => lineItemShowModal(row.itemTextData)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
      width: "100px",
    },
    {
      name: "Purchase Quantity",
      selector: (row) => row.purchaseQuantity,
      sortable: true,
      width: "180px",
    },
    {
      name: "Order Unit",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "120px",
    },
    {
      name: "Unit Price",
      selector: (row) => row.orderPriceUnit,
      sortable: true,
      width: "120px",
    },
    {
      name: "Item Value",
      // selector: (row) => row.netValue,
      cell: (row) => (
        <span className="numberFormate m-0 p-0 pt-1 fw-bold">
          {currencyFormat(Number(row.netValue).toFixed(2))}
        </span>
      ),

      sortable: true,
      width: "180px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode,
      sortable: true,
      width: "120px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      sortable: true,
      width: "140px",
    },
    {
      name: "Tax Amount",      
      selector: (row) => row.taxAmount,
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "Price Unit Qty",
    //   selector: (row) => row.priceUnit,
    //   sortable: true,
    //   width: "200px",
    // },
    {
      name: "Tax Code",
      selector: (row) => row.taxId,
      sortable: true,
      width: "200px",
    },
    {
      name: "Tax Desc",
      selector: (row) => row.taxIdDesc,
      sortable: true,
      width: "200px",
    },
    {
      name: "Free Of Cost",
      selector: (row) => row.freeCost,
      sortable: true,
      width: "200px",
    },
    {
      name: "Purch Req Nr",
      selector: (row) => row.requestNumber,
      sortable: true,
      width: "200px",
    },
    {
      name: "PR Item Nr",
      selector: (row) => row.prItemNr,
      sortable: true,
      width: "200px",
    },
    // {
    //   name: "ExcessTolerance",
    //   selector: (row) => row.excessTolerance,
    //   sortable: true,
    //   width: "200px",
    // },
    // {
    //   name: "ShortageTolerance",
    //   selector: (row) => row.shortageTolerance,
    //   sortable: true,
    //   width: "200px",
    // },
  ];

  const loadFirstLevelData = async (page) => {
    if (poID) {
      let obj = {
        documentNumber: poID,
      };
      await ItemService.getPODetail(obj).then((item) => {
        if (item?.status === 200) {
          // console.log(">> item", item);
          setDisabledStatus(true);
          setFormInputs(item?.data[0]);
          setItemTableData(item?.data[0].items);
        } else {
          setFormInputs([]);
          console.log(">> Error: Loading getQuotationDatail");
        }
      });
    } else {
      navigate(Constants.PRESALES_CUSTOMER_QUOTATION_LIST);
    }
  };

  useEffect(() => {
    loadFirstLevelData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Commmon OnChange of the input scetion
  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <h2 className="mainTitle">
              Purchase Order Details :{" "}
              <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                {poID}
              </span>
              <span>
                {formInputs?.status === "Pending" ? (
                  <span className="bg-table-status bg-table-info-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Sent" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Draft" ? (
                  <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Completed" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Reject" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : formInputs?.status === "Deactivated" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {formInputs?.status}
                  </span>
                ) : (
                  formInputs?.status
                )}
              </span>
            </h2>
            <p className="subText">Purchase Order Information </p>
            <hr />

            {/* 01 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">HD</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Header Details</span>
                      <span className="subHeaderTitle">Header Details</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer Name"
                          name="customerName"
                          extraLabel="As per the Trade license"
                          placeholder=""
                          value={formInputs?.customerName || ""}
                          onChange={onInputChange}
                          disabled={getDisabledStatus}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Document Number"
                          name="purchasingDocumentNr"
                          placeholder=""
                          value={formInputs?.purchasingDocumentNr || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Document Date"
                          name="purchasingDocumentDate"
                          placeholder=""
                          value={formInputs?.purchasingDocumentDate || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Net Value"
                          name="purchasingDocumentValue"
                          placeholder=""
                          value={currencyFormat(
                            Number(formInputs?.purchasingDocumentValue).toFixed(
                              2
                            )
                          )}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency"
                          name="currency"
                          placeholder=""
                          value={`${formInputs?.currencyCode} - ${formInputs?.currency}`}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer Code"
                          name="customerCode"
                          placeholder=""
                          value={formInputs?.customerCode || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Customer Name"
                          name="customerName"
                          placeholder=""
                          value={formInputs?.customerName || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Payment Terms"
                          name="paymentTerms"
                          placeholder=""
                          value={formInputs?.paymentTerms || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Inco Terms"
                          name="incoTerms"
                          placeholder=""
                          value={formInputs?.incoTerms || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Code "
                          name="companyCodeCode"
                          id="companyCodeCode"
                          value={formInputs?.companyCodeCode}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Purchse Type"
                          name="purchasingRemark"
                          placeholder=""
                          value={formInputs?.purchaseType || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding mt-4 pt-3"
                      >
                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion">
                            Header Text
                          </label>
                          <span
                            style={{ marginLeft: "15px" }}
                            // onClick={() =>
                            //   headerTextShowModal(formInputs?.headerTextData)
                            // }
                          >
                            <i className="bi bi-calendar2-week iconTable"></i>
                          </span>
                          <span className="suppliersIcon">
                            {" "}
                            {formInputs?.headerTextData?.length || 0}{" "}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* 02 */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LI</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Line Items</span>
                      <span className="subHeaderTitle">Line Details</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={dataRow}
                          // progressPending={purchaseOrderData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          // paginationTotalRows={purchaseOrderTable.total}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          // onSelectedRowsChange={handleRowSelected}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default CustPurchaseOrderDetails;

export const statusData = [
  { key: "NON_INVITED", message: "Non Invited", color: "#555555b2" },
  { key: "INVITED", message: "Invited", color: "#002387" },
  { key: "IN_REGISTRATION", message: "In Registration", color: "#ED9121" },
  { key: "REGISTERD", message: "Registered", color: "#80FF00" },
  { key: "REJECT", message: "Reject", color: "#FF0800" },
  { key: "DEACTIVATED", message: "Deactivated", color: "#FE6F5E" },

  { key: "IN_RELEASE", message: "In Release", color: "#1E90FF" },
  { key: "CANCELLED", message: "Cancelled", color: "#DC143C" },
  { key: "PR_APPOVED", message: "PR Approved", color: "#006400" },
  { key: "ACKNOWLEDGED", message: "Acknowledged", color: "#4682B4" },
  { key: "PUBLISHED", message: "Published", color: "#0000FF" },
  { key: "QTN_RECEIVED", message: "QTN Received", color: "#8A2BE2" },
  { key: "RFQ_CREATED", message: "RFQ Created", color: "#A52A2A" },
  {
    key: "COMERCIAL_APPROVED",
    message: "Commercial Approved",
    color: "#008B8B",
  },
  { key: "TECH_APPROVED", message: "Tech Approved", color: "#20B2AA" },
  { key: "TECHNICAL_OPEN", message: "Technical Open", color: "#FF69B4" },
  { key: "COMERCIAL_OPEN", message: "Commercial Open", color: "#00CED1" },
  { key: "SUBMITTED", message: "Submitted", color: "#4B0082" },
  { key: "NEW", message: "New", color: "#7FFF00" },
  { key: "IN_TRANSIT", message: "In Transit", color: "#FF6347" },
  { key: "GATEPASS_CREATED", message: "Gatepass Created", color: "#40E0D0" },
  { key: "GRN_CREATED", message: "GRN Created", color: "#6495ED" },
  {
    key: "PENDING_FOR_APPROVAL",
    message: "Pending for Approval",
    color: "#FFD700",
  },
  { key: "INVOICE_CANCELLED", message: "Invoice Cancelled", color: "#FF0000" },
  { key: "GRN_REJECTED", message: "GRN Rejected", color: "#B22222" },
  { key: "POSTED", message: "Posted", color: "#00FF00" },
  { key: "GRN_APPROVED", message: "GRN Approved", color: "#32CD32" },
  {
    key: "ENTRY_SHEET_CREATED",
    message: "Entrysheet Created",
    color: "#1E90FF",
  },
  { key: "SES_REJECTED", message: "SES Rejected", color: "#8B0000" },
  { key: "INVOICE_CREATED", message: "Invoice Created", color: "#00FA9A" },
  { key: "SES_CANCELLED", message: "SES Cancelled", color: "#FF7F50" },
  { key: "INVOICE_APPROVED", message: "Invoice Approved", color: "#ADFF2F" },
  { key: "ON_BOARDED", message: "On Boarded", color: "#00008B" },
  { key: "PENDING", message: "Pending", color: "#FFD700" },
  { key: "ASN_CANCELLED", message: "ASN Cancelled", color: "#FF4500" },
  { key: "GRN_CSNCELLED", message: "GRN Cancelled", color: "#8B0000" },
  { key: "APPROVE", message: "Approve", color: "#32CD32" },
  { key: "SES_CREATED", message: "SES Created", color: "#20B2AA" },
  { key: "OPEN", message: "OPEN", color: "#1E90FF" },
  { key: "CLOSE", message: "CLOSE", color: "#DC143C" },
];

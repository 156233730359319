import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

//Redux
import { useDispatch, useSelector } from "react-redux"; //useDispatch

//Components
import Layout from "../../../../Layout/Layout";
import Constants from "../../../../common/SupplierConstants";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";

import { InputField } from "../../../../Components/formElements/InputField";
import { capitalize } from "../../../../common/Validations";
import { addSubTransactionActivity } from "../../../../redux/feature/supplier_app/purchasingSlice";
import toast from "react-hot-toast";

//Serivce
import ItemService from "../../../../services/supplier_appCommonService";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";

const ASNDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Purchasing");
  localStorage.setItem("submenu", "Advanced Shipping Notice");

  // let asn_data = useSelector((state) => state?.purchasing?.asn?.data); // User Details from Store
  let asnNumber = useSelector((state) => state?.purchasing?.asn?.asnNumber); // User Details from Store

  const [formInputs, setFormInputs] = useState({
    documentType: "ASN",
    transactionActivity: "ASN",
    purchasingDocumentNr: "",
    purchasingDocumentDate: "",
    asnStatusHeader: "In Transit ",
    asnDate: "",
    despatchNr: "",
    despatchDate: "",
    vendorCode: "",
    vendorName: "",
    shippingType: "",
    asnRemark: "",
    asnCount: "1",
    purchasingDocumentValue: "",
    deliveredValue: "",
    deliveredStatus: "",
    invoicedValue: "",
    invoicedStatus: "",
    paidValue: "",
    currency: "",
    vendorInvoiceNo: "",
    vendorInvoiceDate: "",
    vendorBasicValue: "",
    vendorTaxValue: "",
    vendorOtherChargesValue: "",
    vendorTotalInvoiceValue: "",
    vendorInvoiceCurrency: "",
    items: [
      {
        asnItemNr: "",
        materialCode: "",
        materialDescription: "",
        asnQuantity: "",
        currency: "",
        orderUnitOfMeasure: "",
        asnStatusItem: "New",
        supplierBatchNumber: "",
        plant: "",
        storeLocation: "",
        purchasingDocumentNr: "",
        purchasingDocumentItemNr: "",
        grossWeight: "",
        asnDeletionIndicator: "",
        cancellationRemarks: "",
        cancelledBy: "",
        cancelledOn: "",
        cancelledTime: "",
      },
    ],
    othersCharge: [
      {
        itemIndex: "",
        documentId: "",
        documentText: "",
      },
    ],
  });

  const [getShipment, setShipment] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getHeaderData, setHeaderData] = useState(false);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible4, setCardVisible4] = useState(true);

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };
  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility4 = () => {
    setCardVisible4(!isCardVisible4);
  };

  //Create GRN
  const createGRN = () => {
    let obj = {
      asnNumber,
      subTransactionActivity: "ASN",
    };
    dispatch(addSubTransactionActivity(obj));
    navigate(Constants.POCOLLABRATION_GRN_CREATION);
  };

  // -----------------------   START Header and Item Text - PopUp modal
  const itemHeaderColInfo = [
    {
      name: "Code",
      cell: (row) => <span>{row.documentId} </span>,
      width: "200px",
    },
    {
      name: "Text",
      cell: (row) => <span>{row.documentText} </span>,
      width: "250px",
    },
  ];

  const [getItemHeaderTextData, setItemHeaderTextData] = useState("");
  const [getItemHeaderTextColumns, setItemHeaderTextColumns] = useState("");
  const [getItemHeaderTextModalTitle, setItemHeaderTextModalTitle] =
    useState(false);
  const [getItemTextModalShow, setItemTextModalShow] = useState(false);
  const [getHeaderTextModalShow, setHeaderTextModalShow] = useState(false);

  // OnClick on ITEM Text Data
  const itemTextShowModal = (itemTextData) => {
    setItemHeaderTextData(itemTextData);
    setItemHeaderTextColumns(itemHeaderColInfo);
    setItemHeaderTextModalTitle("Item Text Details");
    setItemTextModalShow(true);
  };
  const itemTextHideModal = () => {
    setItemTextModalShow(false);
  };

  // OnClick on Header Text  Data
  const headerTextShowModal = (headerTextData) => {
    setItemHeaderTextData(headerTextData);
    setItemHeaderTextColumns(itemHeaderColInfo);
    setItemHeaderTextModalTitle("Header Text Details");
    setHeaderTextModalShow(true);
  };
  const headerTextHideModal = () => {
    setHeaderTextModalShow(false);
  };

  // -----------------------   END Header and Item Text - PopUp modal

  const loadInitialPageData = async () => {
    setLoading(true);
    if (asnNumber) {
      let obj = {
        documentNumber: asnNumber,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });
      await ItemService.getAsnDetail(obj).then((item) => {
        // console.log(">> res!", item);
        if (item?.status === 200) {
          setHeaderData(item?.data[0]);

          let itemData = item?.data[0]?.items;
          let shipmentData = item?.data[0][item?.data[0]?.shippingType];
          let obj = {
            [item?.data[0]?.shippingType]: shipmentData,
          };

          setSelectedShipment(item?.data[0]?.shippingType);
          setFormInputs(item?.data[0]);
          setShipment(obj);
          setData(itemData);

          // dispatch(addPoSelectedDetails(item?.data));
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });

      setLoading(false);
    } else {
      navigate(Constants.POCOLLABRATION_ASN);
    }
  };

  useEffect(() => {
    loadInitialPageData(); // fetch page 1 of users

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asnNumber]);

  const columns = [
    {
      name: "ASN Item Nr",
      selector: (row) => row.asnItemNr,
      sortable: true,
      width: "130px",
    },
    {
      name: "PO Item",
      selector: (row) => row.purchasingDocumentItemNr,
      sortable: true,
      width: "100px",
    },
    {
      name: "Schedule Number",
      selector: (row) => row.scheduleNr,
      sortable: true,
      width: "180px",
    },
    {
      name: "Status",
      selector: (row) => row.asnStatusItem,
      cell: (row) => (
        <>         
          <TableStatusField status={row.asnStatusItem}/>
        </>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: "Material/Service",

      cell: (row) => (
        <div>
          <p className="m-0 p-0 pt-1 fw-bold">{row.materialCode} </p>
          <p className="m-0 p-0">{row.materialDescription} </p>
        </div>
      ),
      width: "180px",
    },
    {
      name: "Item Text",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => itemTextShowModal(row.itemTextData)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.itemTextData?.length || 0}{" "}
          </span>
        </>
      ),
      width: "100px",
    },
    {
      name: "Purchase Qty",
      selector: (row) => row.purchaseQuantity,
      sortable: true,
      width: "180px",
    },
    {
      name: "ASN Qty",
      selector: (row) => row.asnQuantity,
      sortable: true,
      width: "150px",
    },
    {
      name: "Balance Qty",
      selector: (row) => row.asnBalancePurchaseQuantity,
      sortable: true,
      width: "150px",
    },
    {
      name: "UOM",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "150px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      sortable: true,
      width: "200px",
    },
    {
      name: "Plant",
      selector: (row) => row.businessLocation,
      sortable: true,
      width: "200px",
    },
    {
      name: "ShortageLocation",
      selector: (row) => row.storagePlace,
      sortable: true,
      width: "200px",
    },
  ];

  /* radio buttons */
  const [selectedShipment, setSelectedShipment] = useState("");

  /* raddio buttons input fields */
  const renderShipmentDetails = () => {
    switch (selectedShipment) {
      case "road":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Road Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Truck No"
                    name="truckNo"
                    id="truckNo"
                    value={getShipment?.road?.truckNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Transporter/Courier"
                    name="transporterCourier"
                    id="transporterCourier"
                    value={getShipment?.road?.transporterCourier}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    id="dateOfDispatch"
                    value={getShipment?.road?.dateOfDispatch}
                    type="date"
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="LR No"
                    name="lrNo"
                    id="lrNo"
                    value={getShipment?.road?.lrNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="LR Date"
                    name="lrDate"
                    id="lrDate"
                    value={getShipment?.road?.lrDate}
                    type="date"
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Expected Delivery Date"
                    name="expectedDeliveryDate"
                    id="expectedDeliveryDate"
                    value={getShipment?.road?.expectedDeliveryDate}
                    type="date"
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="EWaybill No"
                    name="ewaybillNo"
                    id="ewaybillNo"
                    value={getShipment?.road?.ewaybillNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E Waybill Value"
                    name="eWaybillValue"
                    id="eWaybillValue"
                    value={getShipment?.road?.eWaybillValue}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Challan No"
                    name="challanNo"
                    id="challanNo"
                    value={getShipment?.road?.challanNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Challan Date"
                    name="challanDate"
                    id="challanDate"
                    value={getShipment?.road?.challanDate}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="No. of Packages"
                    name="noOfPackages"
                    id="noOfPackages"
                    value={getShipment?.road?.noOfPackages}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Weight of Consignment"
                    name="weightOfConsignment"
                    id="weightOfConsignment"
                    value={getShipment?.road?.weightOfConsignment}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Place of Loading"
                    name="placeOfLoading"
                    id="placeOfLoading"
                    value={getShipment?.road?.placeOfLoading}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Place of Delivery"
                    name="placeOfDelivery"
                    id="placeOfDelivery"
                    value={getShipment?.road?.placeOfDelivery}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Mode of Packing"
                    name="modeOfPacking"
                    id="modeOfPacking"
                    value={getShipment?.road?.modeOfPacking}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Driver Name"
                    name="driverName"
                    id="driverName"
                    value={getShipment?.road?.driverName}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Driver Number"
                    name="driverNumber"
                    id="driverNumber"
                    value={getShipment?.road?.driverNumber}
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      case "air":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Air Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    id="dateOfDispatch"
                    value={getShipment?.air?.dateOfDispatch}
                    disabled={true}
                    type="date"
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="MAWB No"
                    name="mawbNo"
                    id="mawbNo"
                    value={getShipment?.air?.mawbNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="MAWB Date"
                    name="mawbDate"
                    type="date"
                    id="mawbDate"
                    value={getShipment?.air?.mawbDate}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Airport of Shipment"
                    name="airportOfShipment"
                    id="airportOfShipment"
                    value={getShipment?.air?.airportOfShipment}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Airport of Arrival"
                    name="airportOfArrival"
                    id="airportOfArrival"
                    value={getShipment?.air?.airportOfArrival}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Transporter Name"
                    name="transporterName"
                    id="transporterName"
                    value={getShipment?.air?.transporterName}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E waybill No"
                    name="ewaybillNo"
                    id="ewaybillNo"
                    value={getShipment?.air?.ewaybillNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Expected Deilevery Date"
                    name="expectedDeliveryDate"
                    id="expectedDeliveryDate"
                    value={getShipment?.air?.expectedDeliveryDate}
                    type="date"
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E Waybill Value"
                    name="eWaybillValue"
                    id="eWaybillValue"
                    value={getShipment?.air?.eWaybillValue}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Courier"
                    name="courier"
                    id="courier"
                    value={getShipment?.air?.courier}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Entry No"
                    name="billOfEntryNo"
                    id="billOfEntryNo"
                    value={getShipment?.air?.billOfEntryNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Entry Date"
                    name="billOfEntryDate"
                    id="billOfEntryDate"
                    value={getShipment?.air?.billOfEntryDate}
                    type="date"
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="HAWB No"
                    name="hawbNo"
                    id="hawbNo"
                    value={getShipment?.air?.hawbNo}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="HAWB Date"
                    name="hawbDate"
                    id="hawbDate"
                    value={getShipment?.air?.hawbDate}
                    type="date"
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="No. of Packages"
                    name="NoofPackages"
                    id="noOfPackages"
                    value={getShipment?.air?.noOfPackages}
                    disabled={true}
                  />
                </Col>
                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Weight of Consignment"
                    name="weightOfConsignment"
                    id="weightOfConsignment"
                    value={getShipment?.air?.weightOfConsignment}
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      case "courier":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Courier Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    id="dateOfDispatch"
                    value={getShipment?.courier?.dateOfDispatch}
                    disabled={true}
                    type="date"
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Courier Name"
                    name="courierName"
                    id="courierName"
                    value={getShipment?.courier?.courierName}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Docket No"
                    name="docketNo"
                    id="docketNo"
                    value={getShipment?.courier?.docketNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Docket Date"
                    name="docketDate"
                    type="date"
                    id="docketDate"
                    value={getShipment?.courier?.docketDate}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="E Waybill No"
                    name="ewaybillNo"
                    id="ewaybillNo"
                    value={getShipment?.courier?.ewaybillNo}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Expected Arrival Date"
                    name="expectedArrivalDate"
                    type="date"
                    id="expectedArrivalDate"
                    value={getShipment?.courier?.expectedArrivalDate}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="No. of Packages"
                    name="noOfPackages"
                    id="noOfPackages"
                    value={getShipment?.courier?.noOfPackages}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Weight of Consignment"
                    name="weightOfConsignment"
                    id="weightOfConsignment"
                    value={getShipment?.courier?.weightOfConsignment}
                    // type="date"
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Mode of Packing"
                    name="modeOfPacking"
                    id="modeOfPacking"
                    value={getShipment?.courier?.modeOfPacking}
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      case "ship":
        return (
          <>
            <div className="accordion-body pb-4">
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="commTopButtonRightLeftPadding"
                >
                  <p className="fs-5 fw-medium ms-2 pt-4">
                    Ship Shipment Details
                  </p>
                  <hr className="mx-2" />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Lading"
                    name="billOfLading"
                    id="billOfLading"
                    value={getShipment?.ship?.billOfLading}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Bill of Ladin Date"
                    name="billOfLadinDate"
                    id="billOfLadinDate"
                    value={getShipment?.ship?.billOfLadinDate}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Shippine Line"
                    name="shippineLine"
                    id="shippineLine"
                    value={getShipment?.ship?.shippineLine}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Internal Reference Number"
                    name="internalReferenceNumber"
                    id="internalReferenceNumber"
                    value={getShipment?.ship?.internalReferenceNumber}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Booking Number"
                    name="bookingNumber"
                    id="bookingNumber"
                    value={getShipment?.ship?.bookingNumber}
                    disabled={true}
                  />
                </Col>

                <Col
                  xs={12}
                  md={4}
                  lg={3}
                  className="commTopButtonRightLeftPadding"
                >
                  <InputField
                    className="inputBox"
                    label="Date of Dispatch"
                    name="dateOfDispatch"
                    type="date"
                    id="dateOfDispatch"
                    value={getShipment?.ship?.dateOfDispatch}
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">
              ASN Details :<span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                  {asnNumber}
                </span>
              <span>
                {getHeaderData?.asnStatusHeader === "Cancelled" ? (
                  <>
                    <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                      {getHeaderData?.asnStatusHeader}
                    </span>
                    <div className="d-inline ms-2 remarks">
                      <i
                        className="fs-5 text-dark cursor-pointer bi bi-info-circle-fill"
                        title={getHeaderData.cancellationRemark}
                      ></i>
                    </div>
                  </>
                ) : getHeaderData?.asnStatusHeader === "In Transit" ? (
                  <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                    {getHeaderData?.asnStatusHeader}
                  </span>
                ) : getHeaderData?.asnStatusHeader === "Gatepass Created" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {getHeaderData?.asnStatusHeader}
                  </span>
                ) : (
                  getHeaderData?.asnStatusHeader
                )}
              </span>
            </h2>
            <p className="subText">ASN Information </p>
            <hr />

            {/* Header Details */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">HR</span>
                    </Col>
                    <Col xs={8} md={8} lg={8}>
                      <span className="headerTitle">Header Details</span>
                      <span className="subHeaderTitle"> </span>
                    </Col>
                    <Col xs={12} md={2} lg={2} className="headerButtonAlign">
                    <Col xs={12} md={6} lg={6}>
                      <button className="btnTable" onClick={() => createGRN()}>
                        Create GRN
                      </button>
                    </Col>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="ASN No"
                          name="asnNumber"
                          placeholder=""
                          value={getHeaderData?.asnNumber}
                          disabled={true}
                        />
                      </Col>
                      {/* <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Status"
                          name="asnStatusHeader"
                          placeholder=""
                          value={getHeaderData?.asnStatusHeader || ""}
                          disabled={true}
                        />
                      </Col> */}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="PO Number"
                          name="purchasingDocumentNr"
                          placeholder=""
                          value={getHeaderData?.purchasingDocumentNr || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="PO Date"
                          name="purchasingDocumentDate"
                          placeholder=""
                          value={getHeaderData?.purchasingDocumentDate || ""}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vendor Code"
                          id="vendorCode"
                          name="vendorCode"
                          value={getHeaderData?.vendorCode || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vendor Name"
                          id="vendorName"
                          name="vendorName"
                          value={getHeaderData?.vendorName || ""}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Shipment Type"
                          id="shippingType"
                          name="shippingType"
                          value={capitalize(getHeaderData?.shippingType) || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoice Number"
                          id="vendorInvoiceNo"
                          name="vendorInvoiceNo"
                          value={getHeaderData?.vendorInvoiceNo || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Invoice Date"
                          id="vendorInvoiceDate"
                          name="vendorInvoiceDate"
                          value={getHeaderData?.vendorInvoiceDate || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Vendor Total Invoice"
                          id="vendorTotalInvoiceValue"
                          name="vendorTotalInvoiceValue"
                          value={getHeaderData?.vendorTotalInvoiceValue || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Currency"
                          id="vendorInvoiceCurrency"
                          name="vendorInvoiceCurrency"
                          value={getHeaderData?.vendorInvoiceCurrency || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company Code"
                          id="vendorInvoiceCurrency"
                          name="vendorInvoiceCurrency"
                          value={getHeaderData?.vendorInvoiceCurrency || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Purchse Info"
                          name="asnRemark"
                          placeholder=""
                          value={getHeaderData?.asnRemark || ""}
                          disabled={true}
                        />
                      </Col>
                      {getHeaderData?.asnStatusHeader === "Cancelled" && (
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Cancelation Remarks"
                            name="cancellationRemark"
                            placeholder=""
                            value={getHeaderData?.cancellationRemark || ""}
                            disabled={true}
                          />
                        </Col>
                      )}
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding mt-4 pt-3"
                      >
                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion">
                            Header Text
                          </label>
                          <span
                            style={{ marginLeft: "15px" }}
                            onClick={() =>
                              headerTextShowModal(getHeaderData?.headerTextData)
                            }
                          >
                            <i className="bi bi-calendar2-week iconTable"></i>
                          </span>
                          <span className="suppliersIcon">
                            {" "}
                            {getHeaderData?.headerTextData?.length || 0}{" "}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Line Items */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LI</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">Line Items</span>
                      <span className="subHeaderTitle"> </span>
                    </Col>
                    {/* 
                    <Col xs={4} md={1} lg={1} className="">
                      <button
                        className="btnTable"
                        onClick={() => handleButtonClick()}
                      >
                        <i className="bi bi-check-circle"></i> Acknowledge
                      </button>
                    </Col> */}
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          progressPending={loading}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Radio buttons */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible3
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility3}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">MS</span>
                    </Col>

                    <Col xs={8} md={8} lg={4}>
                      <span className="headerTitle">Mode of Shipment</span>
                      <span className="subHeaderTitle">Shipment Details</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible3 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        {/* <div className="d-flex fs-5 fw-medium ms-3 my-3">
                          <div className="d-flex align-items-center me-3">
                            <div className="form-check">
                              <input
                                ref={inputRef}
                                className="form-check-input me-2"
                                type="radio"
                                value="road"
                                checked={selectedShipment === "road"}
                                // onChange={handleShipmentChange}
                              />
                              <label
                                className="form-check-label fs-5"
                                htmlFor="shipment1"
                              >
                                Road
                              </label>
                            </div>
                          </div>
                          <div className="d-flex align-items-center me-3">
                            <div className="form-check">
                              <input
                                ref={inputRef}
                                className="form-check-input me-2"
                                type="radio"
                                value="air"
                                checked={selectedShipment === "air"}
                                // onChange={handleShipmentChange}
                              />
                              <label
                                className="form-check-label fs-5"
                                htmlFor="shipment1"
                              >
                                Air
                              </label>
                            </div>
                          </div>
                          <div className="d-flex align-items-center me-3">
                            <div className="form-check">
                              <input
                                ref={inputRef}
                                className="form-check-input me-2"
                                type="radio"
                                value="courier"
                                checked={selectedShipment === "courier"}
                                // onChange={handleShipmentChange}
                              />
                              <label
                                className="form-check-label fs-5"
                                htmlFor="shipment1"
                              >
                                Courier
                              </label>
                            </div>
                          </div>
                          <div className="d-flex align-items-center me-2">
                            <div className="form-check">
                              <input
                                ref={inputRef}
                                className="form-check-input me-2"
                                type="radio"
                                value="ship"
                                checked={selectedShipment === "ship"}
                                // onChange={handleShipmentChange}
                              />
                              <label
                                className="form-check-label fs-5"
                                htmlFor="shipment1"
                              >
                                Ship
                              </label>
                            </div>
                          </div>
                        </div> */}
                        <div>{renderShipmentDetails()}</div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Invoice details */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">ID</span>
                    </Col>

                    <Col xs={4} md={8} lg={8}>
                      <span className="headerTitle"> Invoice details</span>
                      <span className="subHeaderTitle">Invoice details</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <div className="accordion-body">
                          <Row className="pt-2 pb-4">
                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Invoice Number *"
                                name="vendorInvoiceNo"
                                placeholder="Supplier Invoice #"
                                id="vendorInvoiceNo"
                                value={formInputs.vendorInvoiceNo}
                                disabled={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Invoice Date*"
                                name="vendorInvoiceDate"
                                placeholder="Invoice Date*"
                                id="vendorInvoiceDate"
                                type="date"
                                value={formInputs.vendorInvoiceDate}
                                disabled={true}
                              />
                            </Col>

                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Basic Value *"
                                name="vendorBasicValue"
                                placeholder="0.00"
                                id="vendorBasicValue"
                                value={formInputs.vendorBasicValue}
                                disabled={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Tax Amount"
                                name="vendorTaxValue"
                                placeholder="0.00"
                                id="vendorTaxValue"
                                value={formInputs.vendorTaxValue}
                                disabled={true}
                              />
                            </Col>

                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Other Charges"
                                name="vendorOtherChargesValue"
                                placeholder="0.00"
                                id="vendorOtherChargesValue"
                                value={formInputs.vendorOtherChargesValue}
                                disabled={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Freight Value"
                                name="vendorFreightValue"
                                placeholder="0.00"
                                id="vendorFreightValue"
                                value={formInputs.vendorFreightValue}
                                disabled={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Total Invoice Value "
                                name="vendorTotalInvoiceValue "
                                placeholder="0.00"
                                id="vendorTotalInvoiceValue"
                                value={(
                                  Number(formInputs.subTotalValue || 0) +
                                  Number(formInputs.getOtherChargesValue || 0) +
                                  Number(formInputs.vendorTaxValue || 0) +
                                  Number(formInputs.vendorFreightValue || 0)
                                ).toFixed(2)}
                                disabled={true}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            {/* Invoice details */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible4
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility4}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">GP</span>
                    </Col>

                    <Col xs={4} md={8} lg={8}>
                      <span className="headerTitle"> Gatepass details</span>
                      <span className="subHeaderTitle">Gatepass details</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible4 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <div className="accordion-body">
                          <Row className="pt-2 pb-4">
                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Gatepass Number"
                                name="gatepassNr"
                                placeholder="Gatepass Number"
                                id="gatepassNr"
                                value={formInputs.gatepassNr}
                                disabled={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Gatepass Date"
                                name="gatepassDate"
                                placeholder="Gatepass Date"
                                id="gatepassDate"
                                value={formInputs.gatepassCreatedDate}
                                disabled={true}
                              />
                            </Col>

                            <Col
                              xs={12}
                              md={4}
                              lg={3}
                              className="commTopButtonRightLeftPadding"
                            >
                              <InputField
                                className="inputBox"
                                label="Gatepass Time"
                                name="gatepassTime"
                                placeholder="Gatepass Time"
                                id="gatepassTime"
                                value={formInputs.gatepassCreatedTime}
                                disabled={true}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Item Text  */}
      <CommonItemLevelDatatableModal
        show={getItemTextModalShow}
        onHide={() => itemTextHideModal()}
        columns={getItemHeaderTextColumns}
        data={getItemHeaderTextData}
        title={getItemHeaderTextModalTitle}
      />

      {/* Header Text  */}
      <CommonItemLevelDatatableModal
        show={getHeaderTextModalShow}
        onHide={() => headerTextHideModal()}
        columns={getItemHeaderTextColumns}
        data={getItemHeaderTextData}
        title={getItemHeaderTextModalTitle}
      />
    </main>
  );
};

export default ASNDetailPage;

// import { useState } from 'react'
import React, { useState, useEffect } from "react";
// import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
// import { Combobox } from '@headlessui/react'

import DropdownList from "react-widgets/DropdownList";

export const ComboboxField = ({
  index,
  id,
  name,
  code,
  description,
  label,
  placeholder,
  error,
  data,
  setValue,
  getvalue = () => {},
  disabled,
  onChangeValue = () => {},
  extraLabel = "",
  required,
  errors = {
    name: "test",
  },
}) => {
  const [selectedData, setSelectedData] = useState(setValue);

  useEffect(() => {
    let obj = {
      ...selectedData,
      textField: name,
      textCode: code,
      textDesc: description,
      index: index,
      status: selectedData?.name ? true : false,
      label: label,
    };
    getvalue(obj);
    onChangeValue(index, name, selectedData?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getvalue, selectedData]);

  return (
    <>
      <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion">
        {label} {required ? "*" : ""}{" "}
        {extraLabel !== "" ? (
          <i className="bi bi-info-circle" title={extraLabel}></i>
        ) : (
          ""
        )}
      </label>
      <DropdownList
        id={id}
        name={name}
        className={`dropdownHive w-full rounded-md ${
          error ? "border-red-dropdown" : ""
        }`}
        data={data}
        dataKey="id"
        textField="name"
        defaultValue={""}
        value={setValue}
        onChange={(value) => setSelectedData(value)}
        filter="contains"
        placeholder={placeholder}
        disabled={disabled}
      />
      {error && (
        <span className="text-red-500 text-md fieldErrMsg">{error}</span>
      )}
    </>
  );
};

import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from "react-redux"; //

//Serivce
import ItemService from "../../../../services/supplier_appCommonService";

//Components
import Layout from "../../../../Layout/Layout";
import Constants from "../../../../common/SupplierConstants";
import { InputField } from "../../../../Components/formElements/InputField";
import { colData } from "./ReportTableColumns";
import { loadSupplierReport } from "../../../../redux/feature/reportSlice";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";

const SupplierReportsDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Report");
  localStorage.setItem("submenu", "-");

  let reportInfo = useSelector((state) => state?.report); // Report Details from Store
  let reportData = useSelector((state) => state?.report?.supplierReport); // Report Details from Store
  let reportDatapagination = useSelector(
    (state) => state?.report?.supplierReport?.pagination
  );

  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [getNewPerPage, setNewPerPage] = useState(100);
  const [getVendors, setVendors] = useState([]);
  const [getPlant, setPlant] = useState([]);
  const [getDropdownData, setDropdownData] = useState([]);
  const [formInputs, setFormInputs] = useState({});

  const [isCardVisible1, setCardVisible1] = useState(false);
  const [isCardVisible2, setCardVisible2] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };

  const loadReport = async () => {
    if (reportInfo?.data) {
      let obj = {
        queryName: reportInfo?.data?.queryName,
        page: 1,
        per_page: 100,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });

      dispatch(loadSupplierReport(obj));
      toast.dismiss(toastId);
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    if (reportInfo?.data?.queryName) {
      const reportCol = colData.find(
        (data) => data.name === reportInfo?.data?.queryName
      );
      if (reportCol) {
        setColumns(reportCol.columns);
      }
      loadReport();
      getMasterData();
    } else {
      navigate(Constants.SUPPLIER_REPORTS);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTableData(reportData.data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  const handleSearch = () => {
    console.log(">> formInputs >>", formInputs);

    let obj = {
      queryName: reportInfo?.data?.queryName,
      page: 1,
      per_page: 100,
      rowFilter: {
        $and: formInputs?.$and,
      },
    };

    dispatch(loadSupplierReport(obj));
    setTableData(reportData?.data);
  };

  const handlePageChange = (page) => {
    let obj = {
      queryName: reportInfo?.data?.queryName,
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(loadSupplierReport(obj));
    setTableData(reportData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      queryName: reportInfo?.data?.queryName,
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(loadSupplierReport(obj));
    setTableData(reportData?.data);
  };

  const getMasterData = async () => {
    await ItemService.getPlant({}).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setPlant(item?.data);
      } else {
        setPlant([]);
        console.log(">> Error: Loading setPlant getMasterData");
      }
    });

    await ItemService.getVendorId({}).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setVendors(item?.data);
      } else {
        setVendors([]);
        console.log(">> Error: Loading setVendors getMasterData");
      }
    });
  };

  useEffect(() => {
    setFormInputs((formInputs) => {
      const updatedAnd = [...(formInputs.$and || [])]; // Initialize or reuse the existing $and array

      // Remove any existing entry for the dropdown field
      const existingIndex = updatedAnd.findIndex(
        (item) => item[getDropdownData?.textField] !== undefined
      );

      if (existingIndex > -1) {
        updatedAnd.splice(existingIndex, 1); // Remove the existing field
      }

      // Add the new dropdown value if it's not empty
      if (getDropdownData?.name?.trim()) {
        updatedAnd.push({
          [getDropdownData.textField]: getDropdownData?.name?.trim(),
        });
      }

      // Return the updated form inputs with the modified $and array
      return {
        ...formInputs,
        [getDropdownData.textField]: getDropdownData?.name?.trim(),
        $and: updatedAnd,
      };
    });
  }, [getDropdownData]);

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => {
      const updatedInputs = { ...formInputs, [name]: value };

      // Start with the existing $and array or initialize a new one
      const filters = [...(formInputs.$and || [])];

      // Helper function to update or remove filters by key
      const updateFilter = (key, filterValue) => {
        const index = filters.findIndex((item) => item[key]);
        if (index > -1) {
          if (filterValue) {
            // Update the existing filter
            filters[index] = filterValue;
          } else {
            // Remove the filter if value is empty
            filters.splice(index, 1);
          }
        } else if (filterValue) {
          // Add a new filter if it doesn't exist
          filters.push(filterValue);
        }
      };

      // Update documentNumber filter
      if (
        updatedInputs.documentNumberFrom?.trim() &&
        updatedInputs.documentNumberTo?.trim()
      ) {
        updateFilter("documentNumber", {
          documentNumber: {
            $gte: updatedInputs.documentNumberFrom.trim(),
            $lte: updatedInputs.documentNumberTo.trim(),
          },
        });
      } else {
        updateFilter("documentNumber", null); // Remove if invalid
      }

      // Update purchasingDocumentDate filter
      if (
        updatedInputs.documentDateFrom?.trim() &&
        updatedInputs.documentDateTo?.trim()
      ) {
        updateFilter("purchasingDocumentDate", {
          purchasingDocumentDate: {
            $gte: updatedInputs.documentDateFrom.trim(),
            $lte: updatedInputs.documentDateTo.trim(),
          },
        });
      } else {
        updateFilter("purchasingDocumentDate", null); // Remove if invalid
      }

      // Return updated inputs including the merged $and filters
      return { ...updatedInputs, $and: filters };
    });
  };

  // handleClear function to reset the inputs and filters
const handleClear = () => {
  setFormInputs({
      vendorCode: '',
      plant: '',
      documentNumberFrom: '',
      documentNumberTo: '',
      documentDateFrom: '',
      documentDateTo: '',
      $and: [] // Clear the $and filters as well
  });
};

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">{reportInfo?.data?.name}</h2>
            <p className="subText">Complete Details of the Reports</p>
            <hr />

            {/* Filter Section */}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-down"
                            : "bi-chevron-right"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AF</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">Advanced Filter</span>
                      <span className="subHeaderTitle">Advanced Filter</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          id="vendor"
                          label="Vendor"
                          placeholder=""
                          data={getVendors}
                          iconClassName="dropdownIcon"
                          name="vendorCode"
                          getvalue={setDropdownData}
                          setValue={formInputs.vendorCode}
                          disabled={false}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          id="plant"
                          label="Plant"
                          placeholder=""
                          data={getPlant}
                          iconClassName="dropdownIcon"
                          name="plant"
                          getvalue={setDropdownData}
                          setValue={formInputs.plant}
                          disabled={false}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Doc No(From)"
                          id="documentNumberFrom"
                          name="documentNumberFrom"
                          placeholder=""
                          value={formInputs.documentNumberFrom || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Doc No(To)"
                          id="documentNumberTo"
                          name="documentNumberTo"
                          placeholder=""
                          value={formInputs.documentNumberTo || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          type="date"
                          className="inputBox"
                          label="Doc Date(From)"
                          id="documentDateFrom"
                          name="documentDateFrom"
                          placeholder=""
                          value={formInputs.documentDateFrom || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          type="date"
                          className="inputBox"
                          label="Doc Date(To)"
                          id="documentDateTo"
                          name="documentDateTo"
                          placeholder=""
                          value={formInputs.documentDateTo || ""}
                          onChange={onInputChange}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={{ span: 2, offset: 8 }}
                        lg={{ span: 2, offset: 8 }}
                        className="commTopButtonRightLeftPadding"
                      >
                        <button
                          className="btnTable"
                          onClick={() => handleSearch()}
                        >
                          Search
                        </button>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        lg={2}
                        className="commTopButtonRightLeftPadding"
                      >
                        <button className="btnDelete" onClick={()=>handleClear()}>Clear</button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Datatable Section */}
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-down"
                            : "bi-chevron-right"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">R</span>
                    </Col>
                    <Col xs={6} md={4} lg={4}>
                      <span className="headerTitle"> Reports</span>
                      <span className="subHeaderTitle">Reports and Status</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={tableData}
                          progressPending={reportData?.status}
                          paginationRowsPerPageOptions={
                            Constants.ROW_REPORT_PER_PAGE
                          }
                          pagination
                          paginationServer
                          paginationPerPage={100}
                          paginationTotalRows={reportDatapagination?.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default SupplierReportsDetails;

import React, { useState } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";

//Redux
import { useSelector } from "react-redux"; // useDispatch

//API
import ItemService from "../../../../../services/supplier_appCommonService";

//Components
import Layout from "../../../../../Layout/Layout";
import { InputField } from "../../../../../Components/formElements/InputField";
import { TextAreaField } from "../../../../../Components/formElements/TextAreaField";
import Constants from "../../../../../common/SupplierConstants";
import ApproveRemarksModal from "../../../../../Components/Modal/supplier_app/ApproveRemarksModal";
import SuccessModal from "../../../../../Components/Modal/supplier_app/SuccessModal";
import { ComboboxField } from "../../../../../Components/formElements/ComboboxField";

const CustomerFeedback = () => {
  localStorage.setItem("menu", "Purchasing");
  localStorage.setItem("submenu", "");

  let workflow = useSelector(
    (state) => state?.purchasing?.purchase_order?.workflowPO
  ); // workflowPO from Store

  // invoice columns
  const invoiceDataColumns = [
    {
      name: "Image",
      selector: (row) => row.Image,
      cell: (row) => (
        <>
          <img
            src="assets/img/profile-img.png"
            alt="Profile"
            class="rounded-circle profileImageCss"
            style={{ width: "40px" }}
          ></img>
        </>
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Material",
      selector: (row) => row.material,
      cell: (row) => (
        <>
          <p>{row.material}</p>
        </>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "QTY",
      cell: (row) => (
        <>
          <p>{row.qty}</p>
        </>
      ),
      width: "150px",
    },
    {
      name: "UnitPrice",
      selector: (row) => row.unitPrice,
      cell: (row) => (
        <>
          <div>
            <p className="m-0 p-0 pt-1">{row.unitPrice} </p>
          </div>
        </>
      ),
      width: "180px",
      sortable: true,
    },

    {
      name: "Gross Amt",
      selector: (row) => row.grossAmt,
      cell: (row) => <>{row.grossAmt}</>,
      sortable: true,
      width: "150px",
    },
    {
      name: "Tax%",
      cell: (row) => <>{row.tax}</>,
      sortable: true,
      width: "160px",
    },
    {
      name: "Tax Amt",
      cell: (row) => <>{row.taxAMt}</>,
      sortable: true,
      width: "180px",
    },
    {
      name: "Net Amt",
      selector: (row) => <>{row.netAmt}</>,
      sortable: true,
      width: "120px",
    },
  ];

  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);

  const [modalShow, setModalShow] = useState(false);
  const [getAction, setAction] = useState("");

  /* dummy data */
  const data = [
    {
      id: 1,
      material:
        "canon Big cakes self striping Yarn - 603 yd/551 m/10 50z/300g Each",
      qty: "5",
      unitPrice: "2,347.78",
      grossAmt: "11,3478.78",
      tax: "0.00",
      taxAMt: "0",
      netAmt: "11.347.78",
    },
    {
      id: 2,
      material:
        "canon Big cakes self striping Yarn - 603 yd/551 m/10 50z/300g Each",
      qty: "5",
      unitPrice: "2,347.78",
      grossAmt: "11,3478.78",
      tax: "0.00",
      taxAMt: "0",
      netAmt: "11.347.78",
    },
  ];
  /* success modal */
  const successModalData = {
    title: "Message",
    successMsg: "",
  };
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getsucceModalData, setsucceModalData] = useState(successModalData);
  const [getNextNavigate, setNextNavigate] = useState("");
  const onSuccessmodel = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const [formInputs, setFormInputs] = useState({
    transactionActivity: "INV",
    subTransactionActivity: "GRN",
    vendorCode: "",
    purchasingDocumentNr: "",
    purchasingDocumentDate: "",
    purchasingDocumentValue: "",
    currency: "",
    currencyCode: "",
    paymentTerms: "",
    totalInvoiceAmount: "",
    sumOfCurrentAmt: "",
    notes: "",
    weighSlipQty: "",
    itemClass: "",
    vendorInvoiceNo: "",
    vendorInvoiceDate: "",
    vendorInvoiceCurrency: "",
    vendorBasicValue: "",
    vendorFreightValue: "",
    vendorTaxValue: "",
    vendorOtherChargesValue: "",
    vendorTotalInvoiceValue: "",
    invoicedValue: "",
    generatedBy: "",
    createdBy: "",
  });

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
    // console.log("onInputChange => formInputs", formInputs);
  };

  const showApproveModal = async (name) => {
    setModalShow(true);
    setAction(name);
  };
  const workflowApproveReject = async (remarks) => {
    let obj = {};
    let bodyObj = workflow?.workflow_record?.workflowActionAPI?.Body;

    obj._id = workflow?.workflow_record?._id;
    obj.businessComponentId = workflow?.workflow_record?.businessComponentId;
    obj.eventId = workflow?.workflow_record?.eventId;
    obj.businessComponentInstanceId =
      workflow?.workflow_record?.businessComponentInstanceId;
    obj.stepId = workflow?.workflow_record?.stepId;
    obj.action = getAction;
    obj.remark = remarks;
    obj.editContextData = workflow?.workflow_record?.editContextData;
    obj.editForm = workflow?.workflow_record?.editForm;
    obj.data = {};
    let newObj = { ...bodyObj, ...obj };

    //Approve
    await ItemService.workflowApproveReject(newObj).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        successModalData.successMsg =
          getAction === "Approve"
            ? "Approved Successfully !!"
            : "Rejected Successfully !!";
        setsucceModalData(successModalData);
        setNextNavigate(Constants.INBOX);
        onSuccessmodel();
      } else {
        console.log(">> Error: Loading workflowApproveReject");
      }
    });
  };
  const handleValueChange = (remarks) => {
    workflowApproveReject(remarks);
  };
  const hideModal = () => {
    setModalShow(false);
  };

  const getDropdownOption = [
    {
      id: 1,
      name: "IN00123",
    },
    {
      id: 3,
      name: "IN00123",
    },
  ];
  return (
    <main className="dashboard main" id="main">
      <Layout />

      <Row className="justify-center subContent">
        <Col xs={12} md={12} lg={12} className="rightSide quoation">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">
              Customer Feedback
              <span>
                {formInputs?.invoiceStatusHeader === "Invoice Created" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {formInputs?.invoiceStatusHeader}
                  </span>
                ) : formInputs?.invoiceStatusHeader ===
                  "Pending for Approval" ? (
                  <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                    {formInputs?.invoiceStatusHeader}
                  </span>
                ) : formInputs?.invoiceStatusHeader === "Invoice Approved" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {formInputs?.invoiceStatusHeader}
                  </span>
                ) : (
                  formInputs?.invoiceStatusHeader
                )}
              </span>
            </h2>
            <p className="subText">Customer Feedback</p>
            <hr />
            <Row className="mb-4">
              <Col xs={12} md={8} lg={8} className="text-right">
                {workflow?.workflow_record?.decision?.length > 0 &&
                  workflow?.workflow_record?.decision?.map((item, index) => (
                    <button
                      className={
                        item === "Approve" || item === "Submit"
                          ? "btnCommon btn-approve"
                          : "btnCommon btn-danger"
                      }
                      onClick={() => showApproveModal(item)}
                      key={index}
                    >
                      <i
                        className={
                          item === "Approve" || item === "Submit"
                            ? "bi bi-check2-circle"
                            : "bi bi-x-circle"
                        }
                      ></i>{" "}
                      {item}
                    </button>
                  ))}
              </Col>
            </Row>
            <>
              {/*Customer Feedback*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">GH</span>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <span className="headerTitle">Customer Feedback</span>
                        <span className="subHeaderTitle">
                          Customer Feedback
                        </span>
                      </Col>
                    </Row>
                  </h2>
                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <ComboboxField
                            label="Invoice"
                            placeholder="Select Invoice"
                            data={getDropdownOption}
                            id="invoice"
                            iconClassName="dropdownIcon"
                            name="invoice"
                            className="dropdownOption"
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <ComboboxField
                            label="Delivery"
                            placeholder="Select Delivery"
                            data={getDropdownOption}
                            id="delivery"
                            iconClassName="dropdownIcon"
                            name="delivery"
                            className="dropdownOption"
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <ComboboxField
                            label="Item"
                            placeholder="Select Item"
                            data={getDropdownOption}
                            id="item"
                            iconClassName="dropdownIcon"
                            name="item"
                            className="dropdownOption"
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <ComboboxField
                            label="Year"
                            placeholder="Year"
                            data={getDropdownOption}
                            id="year"
                            iconClassName="dropdownIcon"
                            name="year"
                            className="dropdownOption"
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Batch"
                            name="batch"
                            placeholder=""
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Qunatity"
                            name="qunatity"
                            placeholder=""
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Shade"
                            name="shade"
                            placeholder=""
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Inv Date"
                            name="invDate"
                            type="date"
                            placeholder=""
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Material"
                            name="material"
                            placeholder=""
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <TextAreaField
                            className="inputBox"
                            label="Description"
                            type="text"
                            name="discription"
                            id="discription"
                            onChange={onInputChange}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Return Qty"
                            name="returnQty"
                            placeholder=""
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Value"
                            name="value"
                            placeholder=""
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="No of Packs"
                            name="noOfPacks"
                            placeholder=""
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <ComboboxField
                            label="Complaint Type"
                            placeholder=""
                            data={getDropdownOption}
                            id="complaintType"
                            iconClassName="dropdownIcon"
                            name="complaintType"
                            className="dropdownOption"
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Sub Category"
                            name="subCategory"
                            placeholder=""
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <TextAreaField
                            className="inputBox"
                            label="Return & Complaint"
                            name="returnComplaint"
                            placeholder=""
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <ComboboxField
                            label="Complaint Nature"
                            placeholder="Select Complaint"
                            data={getDropdownOption}
                            id="year"
                            iconClassName="dropdownIcon"
                            name="year"
                            className="dropdownOption"
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding d-flex-column"
                        >
                          <InputField
                            className="inputBox"
                            label="Upload File"
                            name="uploadFile"
                            type="file"
                            placeholder=""
                            // value={formInputs?.paymentTerms}
                            // onChange={onInputChange}
                            // disabled={getDisabledStatus}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              {/* invoice service itme details table*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible2
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility2}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">PI</span>
                      </Col>
                      <Col xs={12} md={8} lg={8}>
                        <span className="headerTitle">Item Details</span>
                        <span className="subHeaderTitle">Item Details</span>
                      </Col>
                    </Row>
                  </h2>
                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible2 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <DataTable
                            columns={invoiceDataColumns}
                            data={data}
                            // progressPending={loading}
                            paginationRowsPerPageOptions={
                              Constants.ROW_PER_PAGE
                            }
                            pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            selectableRows
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </Col>
        <Row className="d-felx justify-content-end w-100">
          <Col xs={12} md={4} lg={4} className="d-flex justify-content-end">
            <button className="btn">Reset</button>
            <button className="btn">submit</button>
          </Col>
        </Row>
      </Row>

      {/* Modal Workflow */}
      <ApproveRemarksModal
        show={modalShow}
        onHide={() => hideModal()}
        onvaluechange={handleValueChange}
      />

      {/* Success Modal Workflow */}
      <SuccessModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        successmodaldata={getsucceModalData}
        nextnavigate={getNextNavigate}
      />
    </main>
  );
};

export default CustomerFeedback;

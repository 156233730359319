import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../../Layout/Layout";
import DataTable from "react-data-table-component";
import POCollabrationCommonFillter from "../../../../Components/Filter/POCollabration/POCollabrationCommonFillter";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";

const CreateGHGScop1 = () => {
  localStorage.setItem("menu", "Purchasing");
  localStorage.setItem("submenu", "Payments");
  const [isCardVisible5, setCardVisible5] = useState(true);

  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  
  /* Passenger vehicles */
  const data = [
    {
      id: "1",
      paymentNo:"5324000279",
      status:"Acknowledged",
      documentValue: "25,973.93",
      docCurrency: "AED",
      paymentDate:"--",
      accountNo:"--",
      bankName:"--",
      beneficiaryName:"--",
      invoiceDetails:"--",
      clearingDate:"--",
      fiscalYear:"Feb212024,2023",
      vendor:"0000017975Emirates steel Testing Supplier - Nive Industries",
      localValue:"25,973.93",
      locCurrency:"AED",
      companyCode:"ES10",
    },
    {
      id: "1",
      paymentNo:"5324000279",
      status:"Acknowledged",
      documentValue: "25,973.93",
      docCurrency: "AED",
      paymentDate:"--",
      accountNo:"--",
      bankName:"--",
      beneficiaryName:"--",
      invoiceDetails:"--",
      clearingDate:"--",
      fiscalYear:"Feb212024,2023",
      vendor:"0000017975Emirates steel Testing Supplier - Nive Industries",
      localValue:"25,973.93",
      locCurrency:"AED",
      companyCode:"ES10",
    },
    {
      id: "1",
      paymentNo:"5324000279",
      status:"Acknowledged",
      documentValue: "25,973.93",
      docCurrency: "AED",
      paymentDate:"--",
      accountNo:"--",
      bankName:"--",
      beneficiaryName:"--",
      invoiceDetails:"--",
      clearingDate:"--",
      fiscalYear:"Feb212024,2023",
      vendor:"0000017975Emirates steel Testing Supplier - Nive Industries",
      localValue:"25,973.93",
      locCurrency:"AED",
      companyCode:"ES10",
    },
   
  ];
  const columns = [
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Payment No",
      cell: (row) => <>{row.paymentNo}</>,
      width: "150px",
    },
    {
      name: "Status",
      cell: (row) => <>
      <TableStatusField status={row.status}/>
      </>,
      width: "180px",
    },
    {
      name: "Attachments",
      selector: (row) => row.attachments,
      cell: (row) => (
        <div style={{width: "100%" }} className="d-flex justify-content-center">
          <img
            src="assets/img/newIcons/attached-file.png"
            alt="attachments"
            style={{ width: "25px" }}
            className="mt-1"
          />
        </div>
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "Document Value",
      cell: (row) => <>{row.documentValue}</>,
      width: "150px",
    },
    {
      name: "Doc Currency",
      cell: (row) => <>{row.docCurrency}</>,
      width: "170px",
    },
    {
      name: "Payment Date",
      cell: (row) => <>{row.paymentDate}</>,
      width: "150px",
    },
    {
      name: "Account No",
      cell: (row) => <>{row.accountNo}</>,
      width: "150px",
    },
    {
      name: "Bank Name",
      cell: (row) => <>{row.bankName}</>,
      width: "150px",
    },
    {
      name: "Beneficiary Name",
      cell: (row) => <>{row.beneficiaryName}</>,
      width: "150px",
    },
    {
      name: "Invoice Details",
      cell: (row) => <>{row.invoiceDetails}</>,
      width: "150px",
    },
    {
      name: "Clearing Date",
      cell: (row) => (
       <>
       {row.clearingDate}
       </>
      ),
      width: "150px",
    },
    {
      name: "Fiscal Year",
      cell: (row) => <>{row.fiscalYear}</>,
      width: "150px",
    },
    {
      name: "Vendor",
      cell: (row) => <>{row.vendor}</>,
      width: "200px",
    },
    {
      name: "Local Value",
      cell: (row) => <>{row.localValue}</>,
      width: "150px",
    },
    {
      name: "Loc Currency",
      cell: (row) => <>{row.locCurrency}</>,
      width: "150px",
    },
    {
      name: "Company Code",
      cell: (row) => <>{row.companyCode}</>,
      width: "150px",
    },
  ];

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={10} lg={10} className="mb-2">
              <h2 className="mainTitle">Payment</h2>
              <p className="subText">Lists of Payment and details
              </p>
              </Col>
              <hr />
            </Row>

            {/* Passenger vehicles -5*/}
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible5
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility5}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">PM</span>
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                      <span className="headerTitle">Payment List</span>
                      <span className="subHeaderTitle"> View of Payment and Status</span>
                    </Col>
                    <Col xs={2} md={1} lg={1} className="">
                    <POCollabrationCommonFillter />
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible5 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        lg={12}
                      >
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          // progressPending={loading}
                          // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          // pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </Col>
      </Row>
    </main>
  );
};

export default CreateGHGScop1;

import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./productcatalog.scss";

//Components
import Layout from "../../../Layout/Layout";
import StarRating from "./StarRating";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import { InputField } from "../../../Components/formElements/InputField";
import FormRange from "react-bootstrap/esm/FormRange";
import CatalogProductDetailsModal from "./CatalogProductDetailsModal";

let sortDropDown = [
  {
    id: "1",
    name: "Default Sorting",
  },
  {
    id: "2",
    name: "Sort by Popularity",
  },
  {
    id: "3",
    name: "Sort by price: Low to High",
  },
  {
    id: "4",
    name: "Sort by price: High to Low",
  },
];
let pagination = [
  {
    id: "1",
    name: "5",
  },
  {
    id: "2",
    name: "10",
  },
  {
    id: "3",
    name: "15",
  },
];

const products = [
  {
    id: 1,
    productName: "Arrow Classic Fit Cotton Shirt",
    productNumber: "AR12345",
    category: "Clothing",
    subCategory: "Shirts",
    stock: 100,
    sold: 80,
    views: 2500,
    price: 3000,
    discount: 15,
    discountedPrice: 2550,
    description:
      "A premium cotton shirt with a classic fit for formal occasions.",
    vendor: "Arrow",
    vendorLocation: "New York, USA",
    rating: 4.5,
    reviews: 35,
    weight: "300g",
    dimensions: "30cm x 20cm x 5cm",
    color: "White",
    material: "100% Cotton",
    careInstructions: "Machine wash, do not bleach",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P01.png",
    createdAt: "2024-03-01",
    updatedAt: "2024-03-10",
    tags: ["formal", "classic", "cotton"],
    warranty: "1 year",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["USA", "India", "Europe"],
      estimatedDelivery: "5-7 business days",
      cost: "Free",
    },
    featured: true,
    seasonal: false,
    bestseller: true,
  },
  {
    id: 2,
    productName: "Calvin Klein Slim Fit Jeans",
    productNumber: "CK98765",
    category: "Clothing",
    subCategory: "Pants",
    stock: 200,
    sold: 150,
    views: 3500,
    price: 5000,
    discount: 10,
    discountedPrice: 4500,
    description:
      "Slim fit jeans made from premium stretch denim for ultimate comfort.",
    vendor: "Calvin Klein",
    vendorLocation: "California, USA",
    rating: 4.3,
    reviews: 60,
    weight: "700g",
    dimensions: "35cm x 25cm x 5cm",
    color: "Blue",
    material: "98% Cotton, 2% Elastane",
    careInstructions: "Machine wash cold, tumble dry low",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P02.png",
    createdAt: "2024-03-05",
    updatedAt: "2024-03-20",
    tags: ["jeans", "stretch", "slim fit"],
    warranty: "6 months",
    returnPolicy: "Return within 15 days",
    shipping: {
      availableRegions: ["USA", "Canada", "Australia"],
      estimatedDelivery: "4-6 business days",
      cost: "Free",
    },
    featured: false,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 3,
    productName: "Flying Machine Denim Jacket",
    productNumber: "FM45678",
    category: "Clothing",
    subCategory: "Jackets",
    stock: 50,
    sold: 30,
    views: 4000,
    price: 6000,
    discount: 20,
    discountedPrice: 4800,
    description:
      "A stylish denim jacket with a rugged look, perfect for casual wear.",
    vendor: "Flying Machine",
    vendorLocation: "Mumbai, India",
    rating: 4.6,
    reviews: 75,
    weight: "800g",
    dimensions: "40cm x 30cm x 7cm",
    color: "Black",
    material: "100% Denim",
    careInstructions: "Hand wash cold, do not tumble dry",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P03.png",
    createdAt: "2024-02-25",
    updatedAt: "2024-03-15",
    tags: ["jacket", "denim", "casual"],
    warranty: "1 year",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["India", "UK", "USA"],
      estimatedDelivery: "6-8 business days",
      cost: "Paid",
    },
    featured: true,
    seasonal: false,
    bestseller: true,
  },
  {
    id: 4,
    productName: "Tommy Hilfiger Casual Polo T-Shirt",
    productNumber: "TH76543",
    category: "Clothing",
    subCategory: "T-Shirts",
    stock: 300,
    sold: 270,
    views: 6000,
    price: 2500,
    discount: 10,
    discountedPrice: 2250,
    description:
      "A breathable polo T-shirt with a classic Tommy Hilfiger design.",
    vendor: "Tommy Hilfiger",
    vendorLocation: "Amsterdam, Netherlands",
    rating: 4.8,
    reviews: 100,
    weight: "200g",
    dimensions: "25cm x 15cm x 2cm",
    color: "Red",
    material: "95% Cotton, 5% Lycra",
    careInstructions: "Machine wash, iron on low heat",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P04.png",
    createdAt: "2024-01-15",
    updatedAt: "2024-02-25",
    tags: ["casual", "polo", "breathable"],
    warranty: "6 months",
    returnPolicy: "Return within 15 days",
    shipping: {
      availableRegions: ["USA", "UK", "India"],
      estimatedDelivery: "3-5 business days",
      cost: "Free",
    },
    featured: false,
    seasonal: false,
    bestseller: true,
  },
  {
    id: 5,
    productName: "Polo Assn. Slim Fit Chinos",
    productNumber: "PA54321",
    category: "Clothing",
    subCategory: "Pants",
    stock: 120,
    sold: 90,
    views: 3200,
    price: 3500,
    discount: 5,
    discountedPrice: 3325,
    description:
      "Slim fit chinos for a modern, stylish look. chinos for a modern.",
    vendor: "Polo Assn.",
    vendorLocation: "London, UK",
    rating: 4.4,
    reviews: 40,
    weight: "600g",
    dimensions: "30cm x 20cm x 4cm",
    color: "Beige",
    material: "100% Cotton",
    careInstructions: "Machine wash cold, do not bleach",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P05.png",
    createdAt: "2024-03-01",
    updatedAt: "2024-03-18",
    tags: ["chinos", "slim fit", "cotton"],
    warranty: "1 year",
    returnPolicy: "Return within 20 days",
    shipping: {
      availableRegions: ["UK", "USA", "Europe"],
      estimatedDelivery: "4-6 business days",
      cost: "Free",
    },
    featured: true,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 6,
    productName: "Arrow Formal Blazer",
    productNumber: "AR22334",
    category: "Clothing",
    subCategory: "Jackets",
    stock: 80,
    sold: 60,
    views: 2400,
    price: 12000,
    discount: 20,
    discountedPrice: 9600,
    description: "A premium formal blazer for business and formal occasions.",
    vendor: "Arrow",
    vendorLocation: "New York, USA",
    rating: 4.7,
    reviews: 50,
    weight: "1.5kg",
    dimensions: "40cm x 30cm x 10cm",
    color: "Navy Blue",
    material: "Polyester Blend",
    careInstructions: "Dry clean only",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P06.png",
    createdAt: "2024-02-01",
    updatedAt: "2024-03-01",
    tags: ["formal", "blazer", "business"],
    warranty: "1 year",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["USA", "Canada", "Europe"],
      estimatedDelivery: "5-7 business days",
      cost: "Free",
    },
    featured: true,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 7,
    productName: "Calvin Kleint Leather Jacke",
    productNumber: "CK33445",
    category: "Clothing",
    subCategory: "Jackets",
    stock: 40,
    sold: 25,
    views: 4500,
    price: 15000,
    discount: 15,
    discountedPrice: 12750,
    description:
      "Stylish leather jacket perfect for casual outings and travel.",
    vendor: "Calvin Klein",
    vendorLocation: "California, USA",
    rating: 4.8,
    reviews: 80,
    weight: "2kg",
    dimensions: "45cm x 35cm x 15cm",
    color: "Black",
    material: "Genuine Leather",
    careInstructions: "Dry clean only",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P07.png",
    createdAt: "2024-02-10",
    updatedAt: "2024-03-05",
    tags: ["jacket", "leather", "stylish"],
    warranty: "2 years",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["USA", "Europe", "Australia"],
      estimatedDelivery: "4-6 business days",
      cost: "Paid",
    },
    featured: false,
    seasonal: true,
    bestseller: true,
  },
  {
    id: 8,
    productName: "Flying Machine Graphic T-Shirt",
    productNumber: "FM44321",
    category: "Clothing",
    subCategory: "T-Shirts",
    stock: 150,
    sold: 120,
    views: 3100,
    price: 1800,
    discount: 10,
    discountedPrice: 1620,
    description: "Graphic T-shirt with bold prints for a trendy casual look.",
    vendor: "Flying Machine",
    vendorLocation: "Mumbai, India",
    rating: 4.2,
    reviews: 40,
    weight: "250g",
    dimensions: "30cm x 20cm x 3cm",
    color: "White",
    material: "100% Cotton",
    careInstructions: "Machine wash, do not bleach",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P08.png",
    createdAt: "2024-01-20",
    updatedAt: "2024-02-25",
    tags: ["graphic", "T-shirt", "casual"],
    warranty: "6 months",
    returnPolicy: "Return within 15 days",
    shipping: {
      availableRegions: ["India", "USA", "UK"],
      estimatedDelivery: "5-7 business days",
      cost: "Free",
    },
    featured: true,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 9,
    productName: "Tommy Hilfiger Winter Jacket",
    productNumber: "TH56432",
    category: "Clothing",
    subCategory: "Jackets",
    stock: 70,
    sold: 50,
    views: 5000,
    price: 18000,
    discount: 20,
    discountedPrice: 14400,
    description:
      "Warm and comfortable winter jacket for extreme cold conditions.",
    vendor: "Tommy Hilfiger",
    vendorLocation: "Amsterdam, Netherlands",
    rating: 4.9,
    reviews: 95,
    weight: "2.5kg",
    dimensions: "50cm x 40cm x 15cm",
    color: "Grey",
    material: "Polyester Blend",
    careInstructions: "Dry clean only",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P09.png",
    createdAt: "2024-01-25",
    updatedAt: "2024-02-20",
    tags: ["winter", "jacket", "warm"],
    warranty: "1 year",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["USA", "Canada", "Europe"],
      estimatedDelivery: "6-8 business days",
      cost: "Free",
    },
    featured: true,
    seasonal: true,
    bestseller: true,
  },
  {
    id: 10,
    productName: "Polo Assn. Sports Cap",
    productNumber: "PA67543",
    category: "Accessories",
    subCategory: "Caps",
    stock: 300,
    sold: 250,
    views: 2200,
    price: 1200,
    discount: 5,
    discountedPrice: 1140,
    description: "Lightweight and durable sports cap for outdoor activities.",
    vendor: "Polo Assn.",
    vendorLocation: "London, UK",
    rating: 4.1,
    reviews: 30,
    weight: "150g",
    dimensions: "20cm x 15cm x 5cm",
    color: "Navy Blue",
    material: "Polyester",
    careInstructions: "Hand wash only",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P010.png",
    createdAt: "2024-03-01",
    updatedAt: "2024-03-10",
    tags: ["sports", "cap", "durable"],
    warranty: "3 months",
    returnPolicy: "Return within 15 days",
    shipping: {
      availableRegions: ["UK", "USA", "Europe"],
      estimatedDelivery: "3-5 business days",
      cost: "Free",
    },
    featured: false,
    seasonal: false,
    bestseller: true,
  },
];
const ProductCatalogShopwise = () => {
  localStorage.setItem("menu", "MaterialCatalogShopwise");
  localStorage.setItem("submenu", "Catalog");
  /* toggle button  */
  const [getToggle, setToggle] = useState(true);
  const toggleRowProduct = () => {
    setToggle(true);
  };
  const toggleColProduct = () => {
    setToggle(false);
  };
  /* modal  */
  const [modalShowDetailProduct, setModalShowDetailProduct] = useState(false);
  const [modalProduct, setModalProduct] = useState(null);

  const showProductDetail = (product) => {
    setModalShowDetailProduct(true);
    setModalProduct(product);
  };
  const hideModal = () => {
    setModalShowDetailProduct(false);
  };

  const categories = [
    {
      name: "Women",
      items: ["Paints", "Skirts", "Dresses", "Tops"],
    },
    {
      name: "Top",
      items: ["Blouses", "Crop Tops", "Tank Tops", "Shirts"],
    },
    {
      name: "T-Shirts",
      items: ["Graphic T-Shirts", "Plain T-Shirts", "Polo T-Shirts", "V-Neck"],
    },
    {
      name: "Men",
      items: ["Shirts", "Trousers", "Shorts", "Suits"],
    },
    {
      name: "Shoes",
      items: ["Sneakers", "Boots", "Formal Shoes", "Sandals"],
    },
  ];
  /* send product details to modal */
  // const showProductDetail = (product) => {
  //     // setModalProduct(product);
  //   };
  const [openCategory, setOpenCategory] = useState(null);
  const handleCategoryClick = (categoryName) => {
    if (openCategory === categoryName) {
      setOpenCategory(null);
    } else {
      setOpenCategory(categoryName);
    }
  };
  /* range */

  const [getRangeValue, setRangeValue] = useState(0);

  const rangeHandleChange = (e) => {
    setRangeValue(e.target.value);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Catalog </h2>
            <p className="subText">Catalog </p>
            <hr />
            <Row>
              <Col
                lg={2}
                className="d-flex flex-lg-column flex-xs-row flex-wrap"
                style={{ fontSize: "18px" }}
              >
                <Col xs={4} lg={12}>
                  <h5>Categories</h5>
                  <ul className="category">
                    {categories.map((category) => (
                      <React.Fragment key={category.name}>
                        {/* Main category item */}
                        <li className=" d-flex justify-content-between">
                          <span className="d-flex">
                            <div
                              className={`${
                                openCategory === category.name
                                  ? "rotate"
                                  : "still"
                              }`}
                            >
                              <i
                                className="bi bi-chevron-right"
                                onClick={() =>
                                  handleCategoryClick(category.name)
                                }
                              ></i>
                            </div>
                            <span className="ms-2">{category.name}</span>
                          </span>
                          <span className="me-4">
                            ({category.items.length})
                          </span>
                        </li>
                        {/* Dropdown items */}
                        {openCategory === category.name && (
                          <ul className="ms-4">
                            {category.items.map((item, index) => (
                              <li key={index} className="subcategory">
                                {item}
                              </li>
                            ))}
                          </ul>
                        )}
                      </React.Fragment>
                    ))}
                  </ul>
                  <hr className="d-lg-block d-none me-4" />
                </Col>
                <Col xs={4} lg={12}>
                  <h5>Filter</h5>
                  <ul>
                    <li className="pe-4">
                      <FormRange
                        min={0}
                        max={500}
                        value={getRangeValue}
                        onChange={rangeHandleChange}
                      />
                    </li>
                    <li>
                      Price : <span className="fw-bold">${getRangeValue}</span>
                    </li>
                  </ul>
                  <hr className="d-lg-block d-none me-4" />
                </Col>
                <Col xs={4} lg={12} className="brnad">
                  <h5>Brand</h5>
                  <ul>
                    <li>
                      <input type="checkbox" />
                      <span>Women</span>
                    </li>
                    <li>
                      <input type="checkbox" />
                      <span>Top</span>
                    </li>
                    <li>
                      <input type="checkbox" />
                      <span>T-Shirts</span>
                    </li>
                    <li>
                      <input type="checkbox" />
                      <span>Men</span>
                    </li>
                    <li>
                      <input type="checkbox" />
                      <span>Shoes</span>
                    </li>
                  </ul>
                  <hr className="d-lg-block d-none me-4" />
                </Col>
                <Col xs={4} lg={12} className="size">
                  <h5>Size</h5>
                  <ul className="d-flex">
                    <li>XS</li>
                    <li>S</li>
                    <li>M</li>
                    <li>L</li>
                    <li>XL</li>
                    <li>2XL</li>
                    <li>3XL</li>
                  </ul>
                  <hr className="d-lg-block d-none me-4" />
                </Col>
                <Col xs={12} lg={12}>
                  <h5>color</h5>
                  <ul className="d-flex">
                    <div className="product_color_switch d-flex flex-wrap">
                      <span
                        style={{
                          backgroundColor: " rgb(135, 85, 75)",
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "rgb(51, 51, 51)",
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "rgb(218, 50, 63)",
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "rgb(47, 54, 108)",
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "rgb(181, 182, 187)",
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "rgb(185, 194, 223)",
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "rgb(95, 183, 212)",
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "rgb(47, 54, 108)",
                        }}
                      ></span>
                    </div>
                  </ul>
                </Col>
              </Col>
              <Col lg={10}>
                <Col
                  lg={12}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <ComboboxField
                      label=""
                      placeholder="Sort the product"
                      data={sortDropDown}
                      id="sortProduct"
                      iconClassName="dropdownIcon"
                      name="sortProduct"
                      code="sortProductcode"
                      description="countryDesc"
                    />
                  </div>
                  <div className="d-flex gap-2 align-items-center ms-4 ">
                    <button
                      className={`${
                        getToggle ? "active-grid-layout" : "grid-layout "
                      }`}
                      onClick={toggleRowProduct}
                    >
                      <i class="bi bi-grid-3x3-gap-fill fs-4"></i>
                    </button>
                    <button
                      className={`${
                        getToggle ? "grid-layout" : "active-grid-layout"
                      }`}
                      onClick={toggleColProduct}
                    >
                      <i class="bi bi-list-task fs-4"></i>
                    </button>
                    <ComboboxField
                      // label="Store"
                      placeholder="Showing"
                      data={pagination}
                      id="pagination"
                      iconClassName="dropdownIcon"
                      name="pagination"
                      code="paginationcode"
                      description="countryDesc"
                    />
                  </div>
                </Col>
                <div className="d-flex gap-4 flex-wrap justify-content-start mt-4">
                  {getToggle ? (
                    <>
                      {products?.map((items) => (
                        <Col xs={12} md={4} lg={3} className="product-width">
                          <div className="product-grid">
                            <div className="product_img">
                              <img
                                src={items.imageUrl}
                                alt="product"
                                style={{ width: "520px", height: "400px" }}
                              />
                              <div className="product_action_box">
                                <ul className="list_none pr_action_btn">
                                  <li className="add-to-cart">
                                    <p>
                                      <i className="bi bi-cart2"></i> Add To
                                      Cart
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <i className="bi bi-shuffle"></i>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <i className="bi bi-search"></i>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <i className="bi bi-heart"></i>
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="product_info px-2">
                              <h6 className="product_title">
                                <span onClick={() => showProductDetail(items)}>
                                  {items.productName}
                                </span>
                              </h6>
                              <div className="product_price d-flex align-items-center">
                                <span className="price">$45.00</span>
                                <del>$55.25</del>
                                <div className="on_sale">
                                  <span>35% Off</span>
                                </div>
                              </div>
                              <div className="rating_wrap d-flex align-items-center px-2">
                                <div className="rating">
                                  <div className="product_rate">
                                    <StarRating
                                      totalStars={5}
                                      fixedRating={items.rating}
                                      enableHover={false}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="pr_switch_wrap d-flex flex-wrap justify-content-between px-2 pb-2">
                                <div className="product_color_switch d-flex flex-wrap">
                                  <span
                                    style={{
                                      backgroundColor: " rgb(135, 85, 75)",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      backgroundColor: "rgb(51, 51, 51)",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      backgroundColor: "rgb(218, 50, 63)",
                                    }}
                                  ></span>
                                </div>
                                <div>
                                  <InputField
                                    type="number"
                                    className="inputBox"
                                    label="Qnt:"
                                    name="quntity"
                                    placeholder="0"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </>
                  ) : (
                    <>
                      {products?.map((items) => (
                        <Col lg={12}>
                          <div className="product-row d-md-flex d-sm-block m-2">
                            <div className="product_img w-lg-25 w-sm-100">
                              <img
                                src={items.imageUrl}
                                alt="product"
                                style={{ width: "540px", height: "320px" }}
                              />
                            </div>
                            <div className="d-sm-block d-lg-flex w-75 px-2 mt-2">
                              <Col xs={12} md={8} lg={8}>
                                <h6 className="product_title">
                                  <span
                                    onClick={() => showProductDetail(items)}
                                  >
                                    {items.productName}
                                  </span>
                                </h6>
                                <div className="product_price d-flex align-items-center">
                                  <span className="price">{items.price}</span>
                                  <del>$55.25</del>
                                  <div className="on_sale">
                                    <span>35% Off</span>
                                  </div>
                                </div>
                                <div className="rating_wrap d-flex">
                                  <div className="rating">
                                    <div className="product_rate">
                                      <StarRating
                                        totalStars={5}
                                        fixedRating={items.rating}
                                        enableHover={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="pr_desc">
                                  <p className="m-0">{items.description}</p>
                                </div>
                                <div className="product_color_switch mt-4">
                                  <span
                                    style={{
                                      backgroundColor: " rgb(135, 85, 75)",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      backgroundColor: "rgb(51, 51, 51)",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      backgroundColor: "rgb(218, 50, 63)",
                                    }}
                                  ></span>
                                </div>
                              </Col>
                              <Col xs={12} md={4} lg={4} className="d-flex justify-content-lg-center mt-2">
                                <div className="pr_switch_wrap pb-2 d-flex flex-column justify-content-center">
                                  <div>
                                    <InputField
                                      type="number"
                                      className="inputBox"
                                      label="Qnt:"
                                      name="quntity"
                                      placeholder="0"
                                    />
                                  </div>
                                  <div className="list_product_action_box mt-2">
                                    <ul className="list_none pr_action_btn d-flex align-itmes-center gap-4 pb-2">
                                      <li className="add-to-cart btn d-felx justify-content-center fs-5">
                                        <p className="m-0 d-flex">
                                          <i className="bi bi-cart2"></i> 
                                          <span className="ms-2 text-nowrap">
                                          Add To
                                          Cart
                                          </span>
                                          </p>
                                      </li>
                                      <li>
                                        <p className="m-0">
                                          <i className="bi bi-shuffle"></i>
                                        </p>
                                      </li>
                                      <li>
                                        <p className="m-0">
                                          <i className="bi bi-search"></i>
                                        </p>
                                      </li>
                                      <li>
                                        <p className="m-0">
                                          <i className="bi bi-heart"></i>
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <CatalogProductDetailsModal
        show={modalShowDetailProduct}
        onHide={() => hideModal()}
        product={modalProduct}
      />
    </main>
  );
};

export default ProductCatalogShopwise;

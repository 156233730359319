import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
// import { useNavigate } from "react-router-dom";
import { InputField } from "../../../../Components/formElements/InputField";

//Redux
import { useSelector } from "react-redux"; //useDispatch

//Serivce
import ItemService from "../../../../services/supplier_appCommonService";

//Components
import Layout from "../../../../Layout/Layout";
import Constants from "../../../../common/SupplierConstants";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import WorkflowApproveReject from "../../../../Components/Workflow/WorkflowApproveReject";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";

const PurchaseRequisitionDetailedPage = () => {
  // const navigate = useNavigate();
  const divRef = useRef(null);
  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Purchase Requisition");

  let prNumber = useSelector(
    (state) => state?.sourcing?.purchase_requisition?.prNumber
  ); // User Details from Store

  let workflow = useSelector(
    (state) => state?.sourcing?.purchase_requisition?.workflowPR
  ); // workflowPO from Store

  let getConstantVar = useSelector((state) => state?.common?.data); // User getConstantVar from Store
  let config = useSelector(
    (state) => state?.common?.data?.sourcing?.purchaseRequisition
  ); // config Details from Store

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [getHeaderData, setHeaderData] = useState(false);
  // const [totalRows, setTotalRows] = useState(10);
  // const [perPage, setPerPage] = useState(10);
  const [isCardVisible, setCardVisible] = useState(true);
  const [isCardVisible2, setCardVisible2] = useState(true);

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");

  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);
  const [getViewHeaderText, setViewHeaderText] = useState(false);

  //Service Items
  let serviceColInfo = [
    {
      name: "Service Line Nr",
      cell: (row) => <span>{row.serviceLineNr} </span>,
      width: "180px",
    },
    {
      name: "Service Number",
      cell: (row) => <span>{row.serviceNr} </span>,
      width: "180px",
    },
    {
      name: "Service Desc",
      cell: (row) => <span>{row.serviceDescription} </span>,
      width: "180px",
    },
    {
      name: "UOM",
      cell: (row) => <span>{row.orderUnitOfMeasure} </span>,
      width: "180px",
    },
    {
      name: "Service Quantity",
      cell: (row) => <span>{row.serviceQuantity} </span>,
      width: "180px",
    },
    {
      name: "Unit Value",
      cell: (row) => <span>{row.unitValue} </span>,
      width: "180px",
    },
    {
      name: "Gross Value",
      cell: (row) => <span>{row.grossValue} </span>,
      width: "180px",
    },
    {
      name: "Delivery Date",
      cell: (row) => <span>{row.deliveryDate} </span>,
      width: "180px",
    },
  ];

  const [getItemNr, setItemNr] = useState("");
  const [getServiceData, setServiceData] = useState([]);
  const [getServiceTableStatus, setServiceTableStatus] = useState(false);

  useEffect(() => {
    if (divRef.current) {
      try {
        // eslint-disable-next-line no-unused-vars
        const styles = window.getComputedStyle(divRef.current);
      } catch (error) {
        console.error("Failed to get computed style:", error);
      }
    }
  }, [getServiceTableStatus]);

  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };
  // OnClick on lineItem  Data
  const lineItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(lineItemColInfo);
    setModalTitle("Line Item Details");
    setItemLevelModalShow(true);
  };

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };
  const toggleCardVisibility2 = () => {
    setCardVisible2(!isCardVisible2);
  };

  const loadInitialPageData = async () => {
    setLoading(true);
    if (prNumber) {
      let obj = {
        documentNumber: prNumber,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });
      await ItemService.getPRDetail(obj).then((item) => {
        // console.log(">> res!", item);
        if (item?.status === 200) {
          setHeaderData(item.data);
          setData(item?.data?.items);
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });

      setLoading(false);
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    loadInitialPageData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prNumber]);

  const columnsMAT = [
    {
      name: "PR ItemNr",
      selector: (row) => row.purchasingReqDocumentItemNr,
      sortable: true,
      width: "150px",
    },
    {
      name: "Item Text",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length || 0} </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <TableStatusField status={row.prStatusItem}/>
        </>
      ),
      width: "180px",
    },
    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "150px",
    },
    {
      name: "Material Desc",
      selector: (row) => row.materialDescription,
      sortable: true,
      width: "150px",
    },
    {
      name: "Quantity",
      selector: (row) => row.purchaseQuantity,
      sortable: true,
      width: "120px",
    },
    {
      name: "UOM",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "120px",
    },
    {
      name: "Price",
      selector: (row) => row.orderPriceUnit,
      sortable: true,
      width: "120px",
    },
    {
      name: "Net Value",
      selector: (row) => row.netValue,
      sortable: true,
      width: "200px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode,
      sortable: true,
      width: "120px",
    },
    {
      name: "Material Group",
      selector: (row) => row.materialGroup,
      sortable: true,
      width: "200px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      sortable: true,
      width: "140px",
    },
    {
      name: "User Department",
      selector: (row) => row.userDepartment,
      sortable: true,
      width: "200px",
    },
    {
      name: "Procurement Category",
      selector: (row) => row.procurementCategory,
      sortable: true,
      width: "200px",
    },
    {
      name: "procurement Team",
      selector: (row) => row.procurementTeam,
      sortable: true,
      width: "200px",
    },
    {
      name: "Requisitioner",
      selector: (row) => row.nameOfRequisitioner,
      sortable: true,
      width: "200px",
    },
    {
      name: "Plant",
      selector: (row) => row.businessLocation,
      sortable: true,
      width: "200px",
    },
  ];

  const columnsSER = [
    {
      name: "PR ItemNr",
      selector: (row) => row.purchasingReqDocumentItemNr,
      sortable: true,
      width: "150px",
    },
    {
      name: "Item Text",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => lineItemShowModal(row.items)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.items?.length || 0} </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Service",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() =>
              showServiceItemTable(
                row.services,
                row.purchasingReqDocumentItemNr
              )
            }
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon"> {row.services?.length || 0} </span>
        </>
      ),
      width: "180px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <TableStatusField status={row.prStatusItem}/>
        </>
      ),
      width: "180px",
    },
    {
      name: "Material/Service",
      selector: (row) => row.materialCode,
      sortable: true,
      width: "150px",
    },
    {
      name: "Material Desc",
      selector: (row) => row.materialDescription,
      sortable: true,
      width: "150px",
    },
    {
      name: "Quantity",
      selector: (row) => row.purchaseQuantity,
      sortable: true,
      width: "120px",
    },
    {
      name: "UOM",
      selector: (row) => row.orderUnitOfMeasure,
      sortable: true,
      width: "120px",
    },
    {
      name: "Price",
      selector: (row) => row.orderPriceUnit,
      sortable: true,
      width: "120px",
    },
    {
      name: "Net Value",
      selector: (row) => row.netValue,
      sortable: true,
      width: "200px",
    },
    {
      name: "Currency",
      selector: (row) => row.currencyCode,
      sortable: true,
      width: "120px",
    },
    {
      name: "Material Group",
      selector: (row) => row.materialGroup,
      sortable: true,
      width: "200px",
    },
    {
      name: "Delivery Date",
      selector: (row) => row.deliveryDate,
      sortable: true,
      width: "140px",
    },
    {
      name: "User Department",
      selector: (row) => row.userDepartment,
      sortable: true,
      width: "200px",
    },
    {
      name: "Procurement Category",
      selector: (row) => row.procurementCategory,
      sortable: true,
      width: "200px",
    },
    {
      name: "procurement Team",
      selector: (row) => row.procurementTeam,
      sortable: true,
      width: "200px",
    },
    {
      name: "Requisitioner",
      selector: (row) => row.nameOfRequisitioner,
      sortable: true,
      width: "200px",
    },
    {
      name: "Plant",
      selector: (row) => row.businessLocation,
      sortable: true,
      width: "200px",
    },
  ];

  const headerItemColInfo = [
    {
      name: "Header Text Code",
      cell: (row) => <span>{row.documentId} </span>,
      width: "200px",
    },
    {
      name: "Header Text",
      cell: (row) => <span>{row.prItemNr} </span>,
      width: "250px",
    },
  ];

  const lineItemColInfo = [
    {
      name: "Item Text Code",
      cell: (row) => <span>{row.documentId} </span>,
      width: "200px",
    },
    {
      name: "Item Text",
      cell: (row) => <span>{row.prItemNr} </span>,
      width: "250px",
    },
  ];

  // OnClick on Header Text  Data
  const headerTextShowModal = (headerTextData) => {
    setItemLevelData(headerTextData);
    setItemLevelColumns(headerItemColInfo);
    setModalTitle("Header Text Details");
    setViewHeaderText(true);
  };
  const headerTextHideModal = () => {
    setViewHeaderText(false);
  };

  // Workflow

  useEffect(() => {
    if (
      workflow?.workflow_record?.businessComponentId ===
      getConstantVar?.POBusinessComponentId
    ) {
      localStorage.setItem("menu", workflow?.page);
      localStorage.setItem("submenu", "-");
    } else {
      //console.log(">> getHeaderData", getHeaderData);
      if (getHeaderData.purchaseTypeCode === "MAT") {
        localStorage.setItem("menu", "Purchasing");
        localStorage.setItem("submenu", "Purchase Order");
      } else if (getHeaderData.purchaseTypeCode === "SA") {
        localStorage.setItem("menu", "Purchasing");
        localStorage.setItem("submenu", "Scheduling Agreement");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showServiceItemTable = (serviceData, itemNr) => {
    setItemNr(itemNr);
    setServiceData(serviceData);
    setServiceTableStatus(true);
  };
  const hide = () => {
    setServiceTableStatus(false);
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">
              Purchase Requisition Details :{" "}<span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                  {getHeaderData.purchaseReqDocumentNr}
                </span>
              <span>
                {getHeaderData?.prStatusHeader === "PR Approved" ? (
                  <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                    {getHeaderData?.prStatusHeader}
                  </span>
                ) : getHeaderData?.prStatusHeader === "In Release" ? (
                  <span className="bg-table-status bg-table-progress-status headerStatusDetailedPage">
                    {getHeaderData?.prStatusHeader}
                  </span>
                ) : getHeaderData?.prStatusHeader === "Acknowledged" ? (
                  <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                    {getHeaderData?.prStatusHeader}
                  </span>
                ) : getHeaderData?.prStatusHeader === "Cancelled" ? (
                  <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                    {getHeaderData?.prStatusHeader}
                  </span>
                ) : (
                  getHeaderData?.prStatusHeader
                )}
              </span>
            </h2>
            <p className="subText">Purchase Requisition Information </p>
            <hr />

            {/* Workflow Component */}
            <WorkflowApproveReject workflowData={workflow} />

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">HR</span>
                    </Col>
                    <Col xs={12} md={2} lg={2}>
                      <span className="headerTitle">Header Details</span>
                      <span className="subHeaderTitle"> </span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4">
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Document Type"
                          id="purchasingReqDocumentType"
                          name="purchasingReqDocumentType"
                          value={getHeaderData?.purchasingReqDocumentType || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="PR Number"
                          name="purchaseReqDocumentNr"
                          id="purchaseReqDocumentNr"
                          value={getHeaderData?.purchaseReqDocumentNr || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="version"
                          name="version"
                          id="version"
                          value={getHeaderData?.version || ""}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Company"
                          id="companyCode"
                          name="companyCode"
                          // placeholder=""
                          value={getHeaderData?.companyCode || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Purchasing Org"
                          id="purchasingOrg"
                          name="purchasingOrg"
                          // placeholder=""
                          value={getHeaderData?.purchasingOrg || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Purchasing Group"
                          id="purchasingGroup"
                          name="purchasingGroup"
                          // placeholder=""
                          value={getHeaderData?.purchasingGroup || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Requested On"
                          id="createdAt"
                          name="createdAt"
                          // placeholder=""
                          value={getHeaderData?.createdAt || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Requested By"
                          id="initiatorName"
                          name="initiatorName"
                          // placeholder=""
                          value={getHeaderData?.initiatorName || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Payment Terms"
                          id="paymentTerms"
                          name="paymentTerms"
                          // placeholder=""
                          value={getHeaderData?.paymentTerms || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Inco Terms"
                          id="incoTerms"
                          name="incoTerms"
                          // placeholder=""
                          value={getHeaderData?.incoTerms || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="IncoTerms Location"
                          id="incoTermsLocation"
                          name="incoTermsLocation"
                          // placeholder=""
                          value={getHeaderData?.incoTermsLocation || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="PurchasingReq Remark"
                          id="purchasingReqRemark"
                          name="purchasingReqRemark"
                          // placeholder=""
                          value={getHeaderData?.purchasingReqRemark || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="purchasingReq Value"
                          id="purchasingReqDocumentValue"
                          name="purchasingReqDocumentValue"
                          // placeholder=""
                          value={
                            getHeaderData?.purchasingReqDocumentValue || ""
                          }
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Status"
                          id="prStatusHeader"
                          name="prStatusHeader"
                          // placeholder=""
                          value={getHeaderData?.prStatusHeader || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding"
                      >
                        <InputField
                          className="inputBox"
                          label="Purchase Type"
                          id="purchaseType"
                          name="purchaseType"
                          // placeholder=""
                          value={getHeaderData?.purchaseType || ""}
                          // onChange={onInputChange}
                          disabled={true}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        lg={4}
                        className="commTopButtonRightLeftPadding mt-4 pt-3"
                      >
                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900 infoIconAccordion">
                            Header Text
                          </label>
                          <span
                            style={{ marginLeft: "15px" }}
                            onClick={() =>
                              headerTextShowModal(getHeaderData?.headerTextData)
                            }
                          >
                            <i className="bi bi-calendar2-week iconTable"></i>
                          </span>
                          <span className="suppliersIcon">
                            {" "}
                            {getHeaderData?.headerTextData?.length || 0}{" "}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible2
                            ? "bi-chevron-right"
                            : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility2}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">LI</span>
                    </Col>
                    <Col xs={12} md={2} lg={2}>
                      <span className="headerTitle">Line Items</span>
                      <span className="subHeaderTitle"> </span>
                    </Col>

                    <Col xs={12} md={8} lg={8} className="headerButtonAlign">
                      {config?.itemTableBtn?.block && (
                        <Col xs={4} md={1} lg={1} className="">
                          <button
                            className="btnTable"
                            onClick={() => {
                              alert("asd");
                            }}
                          >
                            <i className="bi bi-person"></i> Block
                          </button>
                        </Col>
                      )}

                      {config?.itemTableBtn?.unBlock && (
                        <Col xs={4} md={2} lg={2} className="">
                          <button
                            className="btnTable"
                            onClick={() => {
                              alert("asd");
                            }}
                          >
                            <i className="bi bi-person"></i>UnBlock
                          </button>
                        </Col>
                      )}

                      {config?.itemTableBtn?.deletion && (
                        <Col xs={4} md={2} lg={2} className="">
                          <button
                            className="btnTable"
                            onClick={() => {
                              alert("asd");
                            }}
                          >
                            <i className="bi bi-x-circle"></i> Deletion
                          </button>
                        </Col>
                      )}

                      {getServiceTableStatus && (
                        <Col xs={2} md={2} lg={2} className="">
                          <button
                            className="btnTable btn-danger"
                            onClick={hide}
                          >
                            <i className="bi bi-eye-slash-fill"></i> Hide
                            Serivces
                          </button>
                        </Col>
                      )}
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible2 ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={
                            getHeaderData?.purchaseTypeCode === "SER"
                              ? columnsSER
                              : columnsMAT
                          }
                          data={data}
                          progressPending={loading}
                          pagination
                          // paginationServer
                          // paginationTotalRows={totalRows}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          selectableRows
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            {/* Service Itemss */}
            {getServiceTableStatus && (
              <div
                ref={divRef}
                className="accordion mb-4"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible2
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility2}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">LI</span>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <span className="headerTitle">
                          {getHeaderData.purchaseTypeCode === "SER"
                            ? `Services - PR Item: ${getItemNr}`
                            : ""}
                        </span>
                        <span className="subHeaderTitle"> </span>
                      </Col>
                    </Row>
                  </h2>

                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible2 ? "visible" : ""
                    }`}
                    // aria-labelledby="headingOne"
                    // data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <DataTable
                            title=""
                            columns={
                              getHeaderData.purchaseTypeCode === "SER"
                                ? serviceColInfo
                                : ""
                            }
                            data={getServiceData}
                            progressPending={loading}
                            pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            selectableRows
                            paginationRowsPerPageOptions={
                              Constants.ROW_PER_PAGE
                            }
                            // onSelectedRowsChange={handleChange}
                            // clearSelectedRows={toggleCleared}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>

      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />

      {/* Header Text  */}
      <CommonItemLevelDatatableModal
        show={getViewHeaderText}
        onHide={() => headerTextHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />

    </main>
  );
};

export default PurchaseRequisitionDetailedPage;

import React, { useState } from "react";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";

const DatePickerComponent = (props) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = String(today.getMonth() + 1).padStart(2, "0");
  const currentFromDate = today.toISOString().split("T")[0];
  const currentToDate = new Date(today.setDate(today.getDate() + 30))
    .toISOString()
    .split("T")[0];

  const [action, setAction] = useState("Monthly");
  const [year, setYear] = useState(Number(currentYear));
  const [month, setMonth] = useState(currentMonth);
  const [fromDate, setFromDate] = useState(currentFromDate);
  const [toDate, setToDate] = useState(currentToDate);
  const [error, setError] = useState("");

  // Generate a list of years from 2020 to 2029
  const generateYears = () => {
    return Array.from({ length: 10 }, (_, i) => currentYear - 0 - i);
  };

  const handleActionChange = (e) => {
    setAction(e.target.value);
    setError("");

    // Reset based on action
    if (e.target.value === "Yearly") {
      setYear(Number(currentYear));
      setMonth("");
      setFromDate("");
      setToDate("");
    } else if (e.target.value === "Monthly") {
      setYear(Number(currentYear));
      setMonth(currentMonth);
      setFromDate("");
      setToDate("");
    } else if (e.target.value === "Custom") {
      setFromDate(currentFromDate);
      setToDate(currentToDate);
      setYear("");
      setMonth("");
    }
  };

  const validateDateRange = () => {
    if (fromDate && toDate) {
      const from = new Date(fromDate);
      const to = new Date(toDate);
      const diffDays = (to - from) / (1000 * 60 * 60 * 24);
      return diffDays <= 60 && diffDays >= 0;
    }
    return true;
  };

  const handleCancel = () => {
    props.cancelAction();
  };
  const handleSubmit = () => {
    if (action === "Custom" && !validateDateRange()) {
      setError(
        "The 'From' and 'To' dates must be within 60 days and 'To' date must be after 'From' date."
      );
      return;
    }
    setError("");

    const finalObject = {
      action,
      ...(action === "Yearly" && { year }),
      ...(action === "Monthly" && { month, year }),
      ...(action === "Custom" && { fromDate, toDate }),
    };

    props.getfiltereddata(finalObject);
    // console.log(finalObject);
  };

  return (
    <div className="filterBox">
      {error && <Alert variant="danger py-2">{error}</Alert>}

      <Form>
        <Form.Group as={Row} className="mb-3">
          <Col sm={3} className="commTopButtonRightLeftPadding">
            <h6>Action</h6>
            <Form.Select value={action} onChange={handleActionChange}>
              <option value="Yearly">Yearly</option>
              <option value="Monthly">Monthly</option>
              <option value="Custom">Custom</option>
            </Form.Select>
          </Col>

          {action === "Yearly" && (
            <Col sm={3} className="commTopButtonRightLeftPadding">
              <h6>Year</h6>
              <Form.Select
                value={year}
                onChange={(e) => setYear(Number(e.target.value))}
              >
                <option value="">Select Year</option>
                {generateYears().map((yr) => (
                  <option key={yr} value={yr}>
                    {yr}
                  </option>
                ))}
              </Form.Select>
            </Col>
          )}

          {action === "Monthly" && (
            <>
              <Col xs={6} sm={3} className="commTopButtonRightLeftPadding">
                <h6>Month</h6>
                <Form.Select
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                >
                  <option value="">Select Month</option>
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i + 1} value={String(i + 1).padStart(2, "0")}>
                      {new Date(0, i).toLocaleString("default", {
                        month: "long",
                      })}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              <Col xs={6} sm={3} className="commTopButtonRightLeftPadding">
                <h6>Year</h6>
                <Form.Select
                  value={year}
                  onChange={(e) => setYear(Number(e.target.value))}
                >
                  <option value="">Select Year</option>
                  {generateYears().map((yr) => (
                    <option key={yr} value={yr}>
                      {yr}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </>
          )}

          {action === "Custom" && (
            <>
              <Col xs={6} sm={3} className="commTopButtonRightLeftPadding">
                <h6>From Date</h6>
                <Form.Control
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </Col>

              <Col xs={6} sm={3} className="commTopButtonRightLeftPadding">
                <h6>To Date</h6>
                <Form.Control
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </Col>
            </>
          )}

          <Col sm={3} className="commTopButtonRightLeftPadding">
            <h6>&nbsp;</h6>
            <Button variant="primary" onClick={handleSubmit}>
              Search
            </Button>
            <Button variant="danger" onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </div>
  );
};

export default DatePickerComponent;

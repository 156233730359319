import React, { useEffect, useState, forwardRef } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./component.scss"

const StatusCountForList = forwardRef((props, ref) => {
  const [loadData, setLoadData] = useState(props?.statuscountdata);

  useEffect(() => {
    // console.log(">> props?.statuscountdata", props?.statuscountdata);
    setLoadData(props?.statuscountdata);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStatusClassName = (name) => {
    switch (name) {
      case "Non Invited":
        return "bg-table-status bg-table-info-status px-4 mr-2";
      case "Invited":
        return "bg-table-status bg-table-new-status px-4 mr-2";
      case "In Registration":
        return "bg-table-status bg-table-progress-status px-4 mr-2";
      case "Registered":
        return "bg-table-status bg-table-success-status px-4 mr-2";
      case "Reject":
        return "bg-table-status bg-table-danger-status px-4 mr-2";
      case "Deactivated":
        return "bg-table-status bg-table-danger-status px-4 mr-2";
      default:
        return "";
    }
  };

  return (
    <div className="my-4" ref={ref}>
      <form className="">
        {loadData && (
          <Row>
            <Col xs={12} md={12} lg={12} className="">
              <div className="card mb-0">
                <div className="card-body statusCountScroll">
                  {loadData.map((item) => (
                    <span
                      key={item.id}
                      className={getStatusClassName(item.name)}
                    >
                      {item.name} - {item.count}
                    </span>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        )}
      </form>
    </div>
  );
});

export default StatusCountForList;

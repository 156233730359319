import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { formatNumberShort } from "../../../common/Validations";

const POColumnChart = (props) => {
  const chartRef = useRef(null);
  // console.log(">> props ", props.chartdata);

  let defaultSeries = [
    {
      name: "",
      data: [],
    },
  ];
  let defaultCategories = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const seriesData = props?.chartdata?.series || defaultSeries;
  const categoriesData = props?.chartdata?.categories || defaultCategories;

  useEffect(() => {
    var options = {
      series: seriesData,
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "80%",
          borderRadius: 5,
          borderRadiusApplication: "end",
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return formatNumberShort(val) !== "0.00" ? formatNumberShort(val) : "";
        },
        offsetY: -20,
        style: {
          fontSize: '9px',
          colors: ["#304758"]
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: categoriesData,
      },
      // yaxis: {
      //   title: {
      //     text: "$ (thousands)",
      //   },
      // },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            // return "$ " + val + " thousands";
            return "" + formatNumberShort(val);
          },
        },
      },
    };

    const chart = new ApexCharts(chartRef.current, options);

    chart.render();

    return () => {
      chart.destroy();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.chartdata]);

  return <div id={`columnChart-${props?.key}`} ref={chartRef}></div>;
  
};

export default POColumnChart;

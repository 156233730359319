/* This page is for Approval and Display page */

import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Redux
import { useSelector, useDispatch } from "react-redux";

//Serivce
import ItemService from "../../../../services/supplier_appCommonService";

//Components
import Layout from "../../../../Layout/Layout";
import { InputField } from "../../../../Components/formElements/InputField";
import DataTable from "react-data-table-component";
// import { formatDateTime } from "../../../common/Validations";
import ShowHorizontalStatus from "../../../../Components/Modal/supplier_app/Workflow/ShowHorizontalStatus";
import Loader from "../../../../Components/Loader";
import EvaluationTable from "../../../../Components/PivotTable/EvaluationTable";
import CommLineItemTable from "../../../../Components/PivotTable/CommLineItemTable";
import SuccessRegisterModal from "../../../../Components/SuccessRegisterModal";
import Constants from "../../../../common/SupplierConstants";
import { getRfqQuotation } from "../../../../redux/feature/supplier_app/sourcingSlice";
import { TextAreaField } from "../../../../Components/formElements/TextAreaField";
import { formatDateTime, getCurrentDate } from "../../../../common/Validations";
import { ComboboxField } from "../../../../Components/formElements/ComboboxField";
import WorkflowApproveReject from "../../../../Components/Workflow/WorkflowApproveReject";
import RichTextEditor from "../../../../Components/Editor/RichTextEditor";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";

const CommercialEvaluation = () => {
  const dispatch = useDispatch();

  localStorage.setItem("menu", "Sourcing");
  localStorage.setItem("submenu", "Request for Quotation");

  let rfxNr = useSelector((state) => state?.sourcing?.rfq?.rfxNr); // User Details from Store
  let workflowConfig = useSelector((state) => state?.common?.data?.workflow); // workflowConfig Details from Store
  let sourcingConfig = useSelector((state) => state?.common?.data?.sourcing); // Common Details from Store
  let workflow = useSelector((state) => state?.sourcing?.rfq?.workflowTECH); // workflowTECH from Store
  let evaluationCriteriaData = useSelector(
    (state) =>
      state?.common?.data?.sourcing?.CommercialEvaluation?.EvaluationCriteria
  ); // evaluation CriteriaData from Store

  const modalData = {
    title: "Commercial Evaluation",
    successMsg: "",
  };

  const [loading, setLoading] = useState(true);
  const [techData, setTechData] = useState([]);
  const [lineItemData, setLineItemData] = useState([]);
  const [commercialEvaluationTableData, setCommercialEvaluationTableData] =
    useState([]);
  const [itemTableData, setItemTableData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const [getModalInfo, setModalInfo] = useState(modalData);
  const [getTechnicalUserList, setTechnicalUserList] = useState([]);
  const [getDropdownData, setDropdownData] = useState("");
  const [getDisabledStatus, setDisabledStatus] = useState(false);
  const [getEvaluationCriteria, setEvaluationCriteria] = useState([]);
  const [getExchangeRateData, setExchangeRateData] = useState([]);

  const [isCardVisible1, setCardVisible1] = useState(true);
  const [isCardVisible3, setCardVisible3] = useState(true);
  const [isCardVisible5, setCardVisible5] = useState(true);
  const [isCardVisible8, setCardVisible8] = useState(true);
  const [isCardVisible9, setCardVisible9] = useState(true);
  const [isCardVisible10, setCardVisible10] = useState(true);
  const [isCardVisible11, setCardVisible11] = useState(true);
  const [isCardVisible12, setCardVisible12] = useState(true);
  const [isCardVisible13, setCardVisible13] = useState(true);
  const [isCardVisible14, setCardVisible14] = useState(true);
  const [isCardVisible15, setCardVisible15] = useState(true);

  const [formInputs, setFormInputs] = useState({
    rfxType: "",
    productType: "",
    rfxValue: "",
    rfxHeaderStatus: "",
    rfxbidType: "",
    rfxNoOfEvaluationType: "",
    rfxCurrency: "",
    rfxTechFocalPersonId: "",
    technicalApprovalType: "",
    plant: "",
    buyerResponsible: "",
    companyAddress: "",
    rfxVersion: "",
    buyerEmail: "",
    initiatorName: "",
    initiator: "",
    billingAddressCodeDesc: "",
    createdOn: "",
    buyerConact: "",
    deliveryAddressCodeDesc: "",
    rfxCategoryType: "",
    rfxCategoryCode: "",
    rfxCategoryDesc: "",
    rfqCreation: "",
    rfxTitle: "",
    openingdate: "",
    openingtime: "",
    closingdate: "",
    closingtime: "",
    rfxDescription: "",
    typeOfPurchase: "",
    otherCurrencies: "",
    headerIncoTerms: "",
    incoTermsLocation: "",
    procurementStrategies: "",
    items: "",
    formCOMM: "",
    formTECH: "",
    termsConditions: [],
    supplierList: [],

    openingDateTime: "",
    closingDateTime: "",
    totalHour: "",
    techEvaluationStartedOn: "",
    techEvaluationPreparedOn: "",
    technicalEvaluator: "",
  });

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };

  const toggleCardVisibility3 = () => {
    setCardVisible3(!isCardVisible3);
  };
  const toggleCardVisibility5 = () => {
    setCardVisible5(!isCardVisible5);
  };
  const toggleCardVisibility8 = () => {
    setCardVisible8(!isCardVisible8);
  };
  const toggleCardVisibility9 = () => {
    setCardVisible9(!isCardVisible9);
  };
  const toggleCardVisibility10 = () => {
    setCardVisible10(!isCardVisible10);
  };
  const toggleCardVisibility11 = () => {
    setCardVisible11(!isCardVisible11);
  };
  const toggleCardVisibility12 = () => {
    setCardVisible12(!isCardVisible12);
  };
  const toggleCardVisibility13 = () => {
    setCardVisible13(!isCardVisible13);
  };
  const toggleCardVisibility14 = () => {
    setCardVisible14(!isCardVisible14);
  };
  const toggleCardVisibility15 = () => {
    setCardVisible15(!isCardVisible15);
  };

  const onInputChange = ({ target: { name, value } }) => {
    setFormInputs((formInputs) => ({ ...formInputs, [name]: value }));
  };

  // Function to handle text changes
  const handleInputChangeTable = (id, value) => {
    const updatedData = formInputs?.supplierList?.map((item) => {
      return item.AssociateNumber === id
        ? {
            ...item,
            qtnSupplierRemarks: value,
          }
        : item;
    });

    setFormInputs((prevState) => ({
      ...prevState, // Keep the other fields in formInputs unchanged
      supplierList: updatedData, // Update the supplierList field
    }));
  };

  const handleWeightageTableRow = (id, value) => {
    const newWeightageValue = Number(value);

    // Calculate the current total weightage without the item to be updated
    const currentTotalWeightage = getEvaluationCriteria.reduce(
      (acc, item) =>
        item.id !== id && item.id !== 5 ? acc + Number(item.weightage) : acc,
      0
    );

    // Calculate what the new total would be with the updated value
    const newTotalWeightage = currentTotalWeightage + newWeightageValue;

    // Check if the new total exceeds 100
    if (newTotalWeightage > 100) {
      toast.error("Total weightage cannot exceed 100.", {
        duration: 2000,
        position: "top-right",
      });
      return; // Exit the function without updating
    }

    // Update state if total is within allowed range
    setEvaluationCriteria((prevCriteria) =>
      prevCriteria.map((item) =>
        item.id === id
          ? { ...item, weightage: value }
          : item.id === 5
          ? { ...item, weightage: newTotalWeightage }
          : item
      )
    );
  };

  const suppliersColumns = [
    {
      name: "Supplier ID",
      selector: (row) => row.AssociateNumber || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btnInfo"
          // onClick={() => detailedPage(row.AssociateNumber)}
        >
          {row.AssociateNumber || "-"}{" "}
        </button>
      ),
      width: "120px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <>
          <TableStatusField status={row.status} />
        </>
      ),
      width: "170px",
    },

    {
      name: "Supplier ",
      selector: (row) => row.organisationName,
      sortable: true,
      width: "180px",
    },
    {
      name: "SAP Number",
      selector: (row) => row.sapAssociateNumber || "--",
      sortable: true,
      width: "130px",
    },

    {
      name: "Quotation Nr",
      selector: (row) => row.quotationNr || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btnInfo"
          // onClick={() => detailedPage(row.AssociateNumber)}
        >
          {row.quotationNr || "-"}{" "}
        </button>
      ),
      width: "140px",
    },
    {
      name: "Qtn Date",
      selector: (row) => formatDateTime(row?.quotationCreatedAt) || "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Qtn Version",
      selector: (row) => row?.qtnVersion || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Qtn Value",
      selector: (row) => row?.qtnSubTotalLineItem || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Qtn Currency",
      selector: (row) => row?.qtnBidderCurrencyCode || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: " Tech Doc",
      selector: (row) => row.technicalQuoteArticle,
      cell: (row) => (
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center"
        >
          <img
            src="assets/img/newIcons/attached-file.png"
            alt="attachments"
            style={{ width: "25px" }}
            className="mt-1"
          />
        </div>
      ),
      sortable: true,
      width: "120px",
    },
    {
      name: "Comm Doc",
      selector: (row) => row.commercialQuoteArticle,
      cell: (row) => (
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center"
        >
          <img
            src="assets/img/newIcons/attached-file.png"
            alt="attachments"
            style={{ width: "25px" }}
            className="mt-1"
          />
        </div>
      ),
      sortable: true,
      width: "120px",
    },

    {
      name: "Status",
      selector: (row) => row.qtnTechEvlStatus || "--",
      sortable: true,
      width: "100px",
    },
    {
      name: "Score",
      selector: (row) => row.commSupplierScore,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="qtnSupplierRemarks"
            value={row.commSupplierScore}
            onChange={(e) =>
              handleInputChangeTable(row.AssociateNumber, e.target.value)
            }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Remarks",
      selector: (row) => row.qtnSupplierRemarks,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="qtnSupplierRemarks"
            value={row.qtnSupplierRemarks}
            onChange={(e) =>
              handleInputChangeTable(row.AssociateNumber, e.target.value)
            }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
  ];
  const CommEvaluationSumColumns = [
    {
      name: "Supplier ID",
      selector: (row) => row.AssociateNumber || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btnInfo"
          // onClick={() => detailedPage(row.AssociateNumber)}
        >
          {row.AssociateNumber || "-"}{" "}
        </button>
      ),
      width: "120px",
    },
    {
      name: "Supplier ",
      selector: (row) => row.organisationName,
      sortable: true,
      width: "180px",
    },
    {
      name: "Quotation Nr",
      selector: (row) => row.quotationNr || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btnInfo"
          // onClick={() => detailedPage(row.AssociateNumber)}
        >
          {row.quotationNr || "-"}{" "}
        </button>
      ),
      width: "140px",
    },
    {
      name: "Initial Qtn Value",
      selector: (row) => row.initialQuotationValue,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="initialQuotationValue"
            value={row.initialQuotationValue}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Final Qtn Value",
      selector: (row) => row.finalQuotationValue,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="finalQuotationValue"
            value={row.finalQuotationValue}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Qtn Currency",
      selector: (row) => row.qtnBidderCurrencyCode,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="qtnBidderCurrencyCode"
            value={row.qtnBidderCurrencyCode}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Tech Score",
      selector: (row) => row.technicalScore,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="technicalScore"
            value={row.technicalScore}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Tech Eval Criteria",
      selector: (row) => row.technicalEvalCriteria,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="technicalEvalCriteria"
            value={row.technicalEvalCriteria}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Tech WT. Value",
      selector: (row) => row.technicalWeightedVal,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="technicalWeightedVal"
            value={row.technicalWeightedVal}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Qtn Other Chg",
      selector: (row) => row.qtnSubTotalOtherCharges,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="finalQuotationValue"
            value={row.qtnSubTotalOtherCharges}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Qtn Disc.",
      selector: (row) => row.qtnDiscTotalCharges,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="qtnDiscTotalCharges"
            value={row.qtnDiscTotalCharges}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Qtn Freight Chg.",
      selector: (row) => row.qtnFreightTotalCharges,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="qtnFreightTotalCharges"
            value={row.qtnFreightTotalCharges}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Incoterm",
      cell: (row) => (
        <>
          <span>{row.qtnIncoTermsDesc || "--"}</span>
        </>
      ),
      selector: (row) => row.qtnIncoTermsDesc,
      sortable: true,
      width: "180px",
    },
    // {
    //   name: "Incoterm Loc",
    //   selector: (row) =>  "--",
    //   sortable: true,
    //   width: "150px",
    // },
    {
      name: "PaymentTerms",
      cell: (row) => (
        <>
          <span>{row.qtnPaymentTermsDesc || "--"}</span>
        </>
      ),
      selector: (row) => row.qtnPaymentTermsDesc,
      sortable: true,
      width: "180px",
    },
    {
      name: "Shipment cost To Company",
      selector: (row) => row.shipmentCost,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="commSupplierRemarks"
            value={row.shipmentCost}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },

    {
      name: "Tot Landing Cost",
      selector: (row) => row.totLandingCost,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="totLandingCost"
            value={row.totLandingCost}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Comm Score",
      selector: (row) => row.CommercialScore,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="CommercialScore"
            value={row.CommercialScore}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Comm Eval Criteria",
      selector: (row) => row.commercialEvalCriteria,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="commercialEvalCriteria"
            value={row.commercialEvalCriteria}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Comm WT. Value",
      selector: (row) => row.commercialWeightedVal,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="commercialWeightedVal"
            value={row.commercialWeightedVal}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "OverallScores",
      selector: (row) => row.overallScores,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="overallScores"
            value={row.overallScores}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Supplier Rank",
      selector: (row) => row.bidderRanking,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="bidderRanking"
            value={row.bidderRanking}
            // onChange={(e) => handleInputChangeTable(row.AssociateNumber, e.target.value)
            // }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => "--",
      sortable: true,
      width: "100px",
    },
    {
      name: "Score",
      selector: (row) => row.commSupplierScore,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="qtnSupplierRemarks"
            value={row.commSupplierScore}
            onChange={(e) =>
              handleInputChangeTable(row.AssociateNumber, e.target.value)
            }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Remarks",
      selector: (row) => row.commSupplierRemarks,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="commSupplierRemarks"
            value={row.commSupplierRemarks}
            onChange={(e) =>
              handleInputChangeTable(row.AssociateNumber, e.target.value)
            }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
  ];
  const CommAwardSummaryColumns = [
    {
      name: "Supplier ID",
      selector: (row) => row.AssociateNumber || "--",
      sortable: true,

      width: "120px",
    },
    {
      name: "Supplier ",
      selector: (row) => row.organisationName,
      sortable: true,
      width: "180px",
    },
    {
      name: "Quotation Nr",
      selector: (row) => row.quotationNr || "--",
      sortable: true,

      width: "140px",
    },
    {
      name: "Quotation Version",
      selector: (row) => row.quotationNr || "--",
      sortable: true,

      width: "140px",
    },
    {
      name: "RFQ Currency",
      selector: (row) => row?.qtnSubTotalLineItem || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Initial Qtn Value",
      selector: (row) => row?.qtnSubTotalLineItem || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Final Qtn Value",
      selector: (row) => row?.qtnSubTotalLineItem || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Qtn Currency",
      selector: (row) => row?.qtnBidderCurrencyCode || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Other Chg",
      selector: (row) => row?.qtnSubTotalOtherCharges || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Disc.",
      selector: (row) => "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Freight Chg.",
      selector: (row) => "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Final Award Value",
      selector: (row) => "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Incoterm",
      selector: (row) => row?.qtnIncoTermsDesc || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Incoterm Loc",
      selector: (row) => "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Shipment cost To Company",
      selector: (row) => "--",
      sortable: true,
      width: "150px",
    },

    {
      name: "Tot Landing Cost",
      selector: (row) => "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Total Cost",
      selector: (row) => "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Exchange Rate",
      selector: (row) => "--",
      sortable: true,
      width: "180px",
    },
    {
      name: "Qtn Bid Currency",
      selector: (row) => "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Final Award Value RFQ Currency",
      selector: (row) => "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => "--",
      sortable: true,
      width: "100px",
    },
    {
      name: "Score",
      selector: (row) => row.commSupplierScore,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="qtnSupplierRemarks"
            value={row.commSupplierScore}
            onChange={(e) =>
              handleInputChangeTable(row.AssociateNumber, e.target.value)
            }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Remarks",
      selector: (row) => row.commSupplierRemarks,
      cell: (row) => (
        <>
          <InputField
            className="inputBox mt-1"
            name="commSupplierRemarks"
            value={row.commSupplierRemarks}
            onChange={(e) =>
              handleInputChangeTable(row.AssociateNumber, e.target.value)
            }
            disabled={getDisabledStatus}
          />
        </>
      ),
      sortable: true,
      width: "150px",
    },
  ];

  const evaluationCriteriaColumns = [
    {
      name: "Sl No",
      selector: (row) => row.slno,
      sortable: true,
      cell: (row) =>
        row.slno === "Total" ? (
          <span className="fw-bold"> {row.slno} </span>
        ) : (
          row.slno
        ),
      width: "150px",
    },
    {
      name: "Criteria",
      selector: (row) => row.criteria,
      sortable: true,
      cell: (row) => <span>{row.criteria}</span>,
      width: "600px",
    },
    {
      name: "Weightage",
      selector: (row) => row.weightage,
      sortable: true,
      cell: (row) =>
        row.id === 5 ? (
          <span className="numberFormate fw-bold pr-5"> {row.weightage} </span>
        ) : (
          <InputField
            type="number"
            className="inputBox mt-1 text-end"
            id="weightage"
            name="weightage"
            value={row.weightage || 0}
            onChange={(e) => handleWeightageTableRow(row.id, e.target.value)}
            // onBlur={(e) =>
            //   handleWeightageValueCal(row.id, e.target.value)
            // }
          />
        ),
      width: "120px",
    },
  ];

  const exchangeRateColumns = [
    {
      name: "Bidder Currency",
      selector: (row) => row.bidderCurrency,
      sortable: true,
      cell: (row) => <span>{row.bidderCurrency}</span>,
    },
    {
      name: "RFQ Currency",
      selector: (row) => row.RFQCurrency,
      sortable: true,
      cell: (row) => <span>{row.RFQCurrency}</span>,
    },
    {
      name: "Value",
      selector: (row) => row.value,
      sortable: true,
      cell: (row) => <span>{row.value}</span>,
    },
    {
      name: "Exchange Rate",
      selector: (row) => row.exchangeRate,
      sortable: true,
      cell: (row) => <span>{row.exchangeRate}</span>,
    },
  ];

  //Exchange Rate
  const loadExchangeRateData = async () => {
    let obj = {
      queryName: "getExchangeRateDetails",
      documentNumber: rfxNr,
    };
    await ItemService.generateQuery(obj).then((item) => {
      // console.log(">> res!", item);
      if (item?.status === 200) {
        setExchangeRateData(item.data);
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
  };

  // Load data
  const loadCommercialEvaluationData = async () => {
    if (rfxNr) {
      let obj = {
        queryName: "ShowRFXCommEvaluation",
        rfxNr: rfxNr,
      };
      const toastId = toast.loading("Loading...", {
        position: "top-right",
      });
      await ItemService.generateQuery(obj).then((item) => {
        // console.log(">> res!", item);
        if (item?.status === 200) {
          setFormInputs(item.data[0]);

          if (item?.data[0]?.rfxHeaderStatus === "Tech Approved") {
            setDisabledStatus(false);
          } else {
            setDisabledStatus(false);
          }

          setTimeout(() => {
            setTechData(item?.data);
            setLineItemData(item?.data);
          }, 500);
        } else {
          toast.error(item?.message || "Please try again !!", {
            duration: 4000,
            position: "top-right",
          });
        }
        toast.dismiss(toastId);
      });
      setLoading(false);
    } else {
      window.history.back();
    }
  };

  // From Child Component
  const handleEvaluationTableData = (data) => {
    // console.log(">> child tech ", data);
    setCommercialEvaluationTableData(data);
    setFormInputs((formInputs) => ({
      ...formInputs,
      rfxQuestions: data,
    }));
  };

  // From Child Component
  const handleItemData = (data) => {
    // console.log(">> item tech ", data);
    setItemTableData(data);
    setFormInputs((formInputs) => ({
      ...formInputs,
      items: data,
    }));
  };

  useEffect(() => {
    // loadInitialPageData();
    loadCommercialEvaluationData();
    loadTechUser();
    loadExchangeRateData();
    formInputs.techEvaluationStartedOn = getCurrentDate("");
    formInputs.techEvaluationPreparedOn = getCurrentDate("");
    setEvaluationCriteria(evaluationCriteriaData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfxNr]);

  const loadTechUser = async () => {
    let obj = {
      roleId: "COMMERCIAL",
    };
    await ItemService.getCommercialUser(obj).then((item) => {
      // console.log('>> Check title ',item);
      if (item?.status === 200) {
        setTechnicalUserList(item?.data);
      } else {
        setTechnicalUserList([]);
        console.log(">> Error: Loading loadTechUser");
      }
    });
  };

  /* Show Success Modal */
  const onSuccessfullyRegistered = () => {
    setShowSuccessModal(true);
  };
  const hideSuccessModal = () => {
    setShowSuccessModal(false);
  };

  // Get Dropdown data select
  useEffect(() => {
    // console.log(">>datarfq", getDropdownData);
    if (getDropdownData?.name) {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    } else {
      setFormInputs((formInputs) => ({
        ...formInputs,
        [getDropdownData?.textField]: getDropdownData?.name,
        [getDropdownData?.textCode]: getDropdownData?.code,
        [getDropdownData?.textDesc]: getDropdownData?.description,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDropdownData]);

  // SAVE
  const handleSave = async () => {
    // let requiredFields = [];

    // if(!validateRequired(formInputs?.organisationName)) {
    //   requiredFields.push('*Please enter Supplier Name');
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   return;
    // }

    // if (!validateEmail(formInputs?.email)) {
    //   requiredFields.push('*Invalid Contact Email address');
    //   setErrorFields(requiredFields);
    //   setShowFailureModal(true);
    //   return;
    // }

    // if (!getPOLineItemsData[0]?.materialCode) {
    //   toast.error("Alteast one PO Line item should be added.", {
    //     duration: 4000,
    //     position: "top-right",
    //   });
    //   return;
    // }

    formInputs.rfxQuestions = commercialEvaluationTableData;
    formInputs.items = itemTableData;

    // console.log(">> handleSave", formInputs);

    const toastId = toast.loading("Loading...", {
      position: "top-right",
    });

    let obj = {
      data: formInputs,
    };

    setDisabled(true);
    await ItemService.addRfxTechEvaluation(obj).then((item) => {
      // console.log(">> res!", item);
      if (item?.status === 200) {
        modalData.successMsg = item?.message;
        setModalInfo(modalData);
        onSuccessfullyRegistered();

        // Update response value in Redux Store
        dispatch(getRfqQuotation({}));
      } else {
        toast.error(item?.message || "Please try again !!", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    toast.dismiss(toastId);
    setDisabled(false);
  };

  const handleEditorChange = (content) => {
    setFormInputs((formInputs) => ({
      ...formInputs,
      instructions: content,
    }));
  };

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <Loader isLoading={loading} className={"text-center"} />
        </Col>
        {!loading && (
          <Col xs={12} md={12} className="rightSide">
            <div className="rightSideInner PageCommonCSS">
              <h2 className="mainTitle">
                Commercial Evaluation for
                <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                  {formInputs?.rfxNr}
                </span>
                <span>
                  {formInputs?.rfxHeaderStatus === "Published" ? (
                    <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "Cancelled" ? (
                    <span className="bg-table-status bg-table-danger-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "Commercial Approved" ? (
                    <span className="bg-table-status bg-table-success-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : formInputs?.rfxHeaderStatus === "Commercial Open" ? (
                    <span className="bg-table-status bg-table-new-status headerStatusDetailedPage">
                      {formInputs?.rfxHeaderStatus}
                    </span>
                  ) : (
                    formInputs?.rfxHeaderStatus
                  )}
                </span>
              </h2>
              <p className="subText">Commercial Evaluation Details</p>
              <hr />

              {/* Workflow Component */}
              <WorkflowApproveReject
                workflowData={workflow}
                formInputsData={formInputs}
              />

              {workflowConfig?.showInDetailPage &&
                formInputs?.workflowActionLogs?.length > 0 && (
                  <ShowHorizontalStatus
                    rowdatamodal={formInputs?.workflowActionLogs}
                  />
                )}

              {/*RFQ Type  */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible1
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility1}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">RT</span>
                      </Col>
                      <Col xs={12} md={4} lg={4}>
                        <span className="headerTitle">RFQ Details </span>
                        <span className="subHeaderTitle">
                          RFQ Type Information
                        </span>
                      </Col>
                    </Row>
                  </h2>

                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible1 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="RFQ ID"
                            name="rfxNr"
                            // placeholder=""
                            value={formInputs?.rfxNr || ""}
                            // onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="RFQ Version"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.rfxVersion || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Title"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxTitle || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Description"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxDescription || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="RFQ Category"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxCategoryDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="RFQ Type"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxTypeDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Number Of Evaluation"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxNoOfEvaluationTypeDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Product Type"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.productTypeDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Buyer Contact"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.rfxCurrencyCode || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Buyer Responsible"
                            name="UnitPrice"
                            // placeholder=""
                            value={formInputs?.initiatorName || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>

                        {/* <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Billing Address"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.billingAddressDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col> */}
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Company Address"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.companyAddressDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Delivery Address"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.companyAddressDesc || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="INCOTerms"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.INCOTermslocation || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="INCOTerms Location"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.INCOTermslocation || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              {/*Header Info */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible3
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility3}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">HI</span>
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <span className="headerTitle">
                          Commercial Evaluation Details
                        </span>
                        <span className="subHeaderTitle">
                          Commercial Evaluation Information
                        </span>
                      </Col>
                    </Row>
                  </h2>

                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible3 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="pt-2 pb-4">
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Tech Evaluation Started On"
                            type="date"
                            name="techEvaluationStartedOn"
                            value={
                              formInputs?.techEvaluationStartedOn ||
                              getCurrentDate("")
                            }
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Tech Evaluation Prepared On "
                            name="techEvaluationPreparedOn "
                            type="date"
                            value={
                              formInputs?.techEvaluationPreparedOn ||
                              getCurrentDate("")
                            }
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <ComboboxField
                            label="Comm Focal Point"
                            placeholder=""
                            data={getTechnicalUserList}
                            id="rfxTechFocalPersonId"
                            name="rfxTechFocalPersonId"
                            code="rfxTechFocalPersonIdCode"
                            description="rfxTechFocalPersonIdDesc"
                            iconClassName="dropdownIcon"
                            setValue={formInputs?.rfxTechFocalPersonId || ""}
                            getvalue={setDropdownData}
                            disabled={getDisabledStatus}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label=" Technical Approval Type"
                            name="firstName"
                            placeholder=""
                            value={formInputs?.technicalApprovalType || ""}
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Eligibility Score"
                            name="firstName"
                            placeholder=""
                            value={
                              sourcingConfig?.rfxControlData?.eligibilityScore
                            }
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Technical Evaluator"
                            name="technicalEvaluator"
                            placeholder=""
                            value={formInputs?.technicalEvaluator || ""}
                            onChange={onInputChange}
                            disabled={true}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <TextAreaField
                            className="inputBox"
                            label="Evaluator Comments"
                            name="evaluatorComments"
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <TextAreaField
                            className="inputBox"
                            label="Evaluator Supplier Clarification"
                            name="evaluatorSupplierClarification"
                            value={
                              formInputs?.evaluatorSupplierClarification || ""
                            }
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          className="commTopButtonRightLeftPadding"
                        >
                          <InputField
                            className="inputBox"
                            label="Upload Tech Eval Report Attachments"
                            name="uploadTechEvalReportAttachments"
                            onChange={onInputChange}
                            disabled={getDisabledStatus}
                          />
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              type="file"
                              id="formFile"
                              disabled={getDisabledStatus}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* Instructions 10*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible10
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility10}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">RE</span>
                      </Col>
                      <Col xs={8} md={9} lg={9}>
                        <span className="headerTitle">Instructions</span>
                        <span className="subHeaderTitle">
                          Instructions Section
                        </span>
                      </Col>
                    </Row>
                  </h2>

                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible10 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="p-4">
                        <Col xs={12} md={12} lg={12}>
                          <RichTextEditor
                            value={formInputs?.instructions}
                            readOnly={getDisabledStatus}
                            onChange={handleEditorChange}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              {/* Instructions 10*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible15
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility15}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">RE</span>
                      </Col>
                      <Col xs={8} md={9} lg={9}>
                        <span className="headerTitle">
                          Commercial Justification Memo
                        </span>
                        <span className="subHeaderTitle">
                          Commercial Justification Memo
                        </span>
                      </Col>
                    </Row>
                  </h2>

                  <div
                    className={`accordion-collapse collapse show ${
                      isCardVisible15 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      <Row className="p-4">
                        <Col xs={12} md={12} lg={12}>
                          <RichTextEditor
                            value={formInputs?.instructions}
                            readOnly={getDisabledStatus}
                            onChange={handleEditorChange}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* 5 */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible5
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility5}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">SD</span>
                      </Col>
                      <Col xs={12} md={6} lg={7}>
                        <span className="headerTitle">Suppliers</span>
                        <span className="subHeaderTitle">
                          Selected Suppliers Details
                        </span>
                      </Col>
                    </Row>
                  </h2>
                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible5 ? "visible" : ""
                    }`}
                    // aria-labelledby="headingOne"
                    // data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <DataTable
                            // title=""
                            columns={suppliersColumns}
                            data={formInputs?.supplierList}
                            // progressPending={loading}
                            // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                            // pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            selectableRows
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* Evaluation Criteria 12 */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible12
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility12}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">EC</span>
                      </Col>
                      <Col xs={12} md={6} lg={7}>
                        <span className="headerTitle">Evaluation Criteria</span>
                        <span className="subHeaderTitle">
                          Evaluation Criteria Details
                        </span>
                      </Col>
                    </Row>
                  </h2>
                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible12 ? "visible" : ""
                    }`}
                    // aria-labelledby="headingOne"
                    // data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <DataTable
                            // title=""
                            columns={evaluationCriteriaColumns}
                            data={getEvaluationCriteria}
                            // progressPending={loading}
                            // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                            // pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            // selectableRows
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* Exchange rate 13 */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible13
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility13}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">ER</span>
                      </Col>
                      <Col xs={12} md={6} lg={7}>
                        <span className="headerTitle">Exchange Rate</span>
                        <span className="subHeaderTitle">
                          Exchange Rate Details
                        </span>
                      </Col>
                    </Row>
                  </h2>
                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible13 ? "visible" : ""
                    }`}
                    // aria-labelledby="headingOne"
                    // data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <DataTable
                            // title=""
                            columns={exchangeRateColumns}
                            data={getExchangeRateData}
                            // progressPending={loading}
                            // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                            // pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            selectableRows
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* Commercial Evaluation 08*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible8
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility8}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">OC</span>
                      </Col>

                      <Col xs={4} md={8} lg={7}>
                        <span className="headerTitle">
                          Commercial Evaluation Questions
                        </span>
                        {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                      </Col>
                    </Row>
                  </h2>

                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible8 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body autoScroll">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <EvaluationTable
                            data={techData}
                            sendDataToParent={handleEvaluationTableData}
                            pageStatus={getDisabledStatus}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* Commercial Evaluation Summary 11 */}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible11
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility11}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">CE</span>
                      </Col>
                      <Col xs={12} md={6} lg={7}>
                        <span className="headerTitle">
                          Commercial Evaluation Summary
                        </span>
                        <span className="subHeaderTitle">
                          Commercial Evaluation Summary Details
                        </span>
                      </Col>
                    </Row>
                  </h2>
                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible11 ? "visible" : ""
                    }`}
                    // aria-labelledby="headingOne"
                    // data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <DataTable
                            // title=""
                            columns={CommEvaluationSumColumns}
                            data={formInputs?.supplierList}
                            // progressPending={loading}
                            // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                            // pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            selectableRows
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* Line Item 09*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible9
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility9}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">LI</span>
                      </Col>

                      <Col xs={4} md={8} lg={7}>
                        <span className="headerTitle">Line Item</span>
                        {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                      </Col>
                    </Row>
                  </h2>

                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible9 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body autoScroll">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <CommLineItemTable
                            data={lineItemData}
                            sendDataToParent={handleItemData}
                            pageStatus={getDisabledStatus}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {/* Award Summary 14*/}
              <div className="accordion mb-4" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <Row className="accordion-button">
                      <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                        <i
                          className={`bi ${
                            isCardVisible14
                              ? "bi-chevron-right"
                              : "bi-chevron-down"
                          }`}
                          onClick={toggleCardVisibility14}
                        ></i>
                      </Col>
                      <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                        <span className="iconLogo">AS</span>
                      </Col>

                      <Col xs={4} md={8} lg={7}>
                        <span className="headerTitle">Award Summary</span>
                        {/* <span className="subHeaderTitle">
                        Other Charges Table{" "}
                      </span> */}
                      </Col>
                    </Row>
                  </h2>

                  <div
                    // id="acc"
                    className={`accordion-collapse collapse show ${
                      isCardVisible14 ? "visible" : ""
                    }`}
                  >
                    <div className="accordion-body autoScroll">
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <DataTable
                            // title=""
                            columns={CommAwardSummaryColumns}
                            data={formInputs?.supplierList}
                            // progressPending={loading}
                            // paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                            // pagination
                            // paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            selectableRows
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>

              {!getDisabledStatus && (
                <div className="pageFooter">
                  <Row className="rowDirection">
                    <Col xs={12} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={handleSave}
                        disabled={disabled}
                      >
                        Create Commercial Evaluation
                      </button>
                    </Col>
                    <Col xs={12} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={handleSave}
                        disabled={disabled}
                      >
                        Assign Tech Evaluator
                      </button>
                    </Col>
                    <Col xs={12} md={2} lg={2} className="">
                      <button
                        className="btnTable"
                        onClick={handleSave}
                        disabled={disabled}
                      >
                        Save as Draft
                      </button>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </Col>
        )}
      </Row>

      <SuccessRegisterModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        modaldata={getModalInfo}
        aftersuccess={Constants.SOURCING_BUYER_REQUEST_FOR_QUOTATION}
      />
    </main>
  );
};

export default CommercialEvaluation;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../../Layout/Layout";
import { Card } from "react-bootstrap";
import { ComboboxField } from "../../../Components/formElements/ComboboxField";
import { SearchField } from "../../../Components/formElements/SearchField";
import CatalogProductDetailModal from "../../../Components/Modal/customer_app/CatalogModal/CatalogProductDetailModal";
// import { get } from "react-hook-form";
// import { useNavigate } from "react-router-dom";

//Componentd
// import Loader from "../../../Components/Loader";

//Redux
// import { useSelector } from "react-redux"; //useDispatch
/* const products = [
  {
    id: 1,
    productName: "Big Cakes Self Striping Yarn - 2 Packs Textile",
    productNumber: "T14116",
    category: "Yarn & Textiles",
    subCategory: "Knitting & Crochet",
    stock: 212,
    sold: 150,
    views: 4100,
    price: 10000,
    discount: 10,
    discountedPrice: 9000,
    description:
      "This roving yarn has everything a knitter and crocheter could want: lightweight, soft, and versatile.",
    vendor: "Galaxy Systems",
    vendorLocation: "New York, USA",
    rating: 4,
    reviews: 45,
    weight: "500g",
    dimensions: "20cm x 10cm x 5cm",
    color: "Multicolor",
    material: "100% Acrylic",
    careInstructions: "Hand wash only, do not bleach",
    availability: "In Stock",
    imageUrl: "path/to/image1.jpg",
    createdAt: "2024-01-10",
    updatedAt: "2024-02-15",
    tags: ["lightweight", "soft", "crochet", "knitting"],
    warranty: "6 months",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["USA", "Canada", "Europe"],
      estimatedDelivery: "3-5 business days",
      cost: "Free",
    },
    featured: true,
    seasonal: false,
    bestseller: true,
  },
  {
    id: 2,
    productName: "Caron Big Cakes Self Striping Yarn ~ 603",
    productNumber: "T14117",
    category: "Yarn & Textiles",
    subCategory: "Crochet Yarn",
    stock: 320,
    sold: 200,
    views: 5300,
    price: 5000,
    discount: 15,
    discountedPrice: 4250,
    description:
      "A lightweight yarn perfect for cozy projects. Great for all knitting and crochet enthusiasts.",
    vendor: "Galaxy Systems",
    vendorLocation: "Los Angeles, USA",
    rating: 3.5,
    reviews: 38,
    weight: "450g",
    dimensions: "22cm x 10cm x 6cm",
    color: "Blue Gradient",
    material: "100% Wool",
    careInstructions: "Machine washable, low heat dry",
    availability: "Limited Stock",
    imageUrl: "path/to/image2.jpg",
    createdAt: "2024-02-01",
    updatedAt: "2024-02-25",
    tags: ["gradient", "lightweight", "soft"],
    warranty: "3 months",
    returnPolicy: "Return within 15 days",
    shipping: {
      availableRegions: ["USA", "Europe"],
      estimatedDelivery: "5-7 business days",
      cost: "$10",
    },
    featured: false,
    seasonal: true,
    bestseller: false,
  },
  {
    id: 3,
    productName: "Bernat POP!, 5oz, Gauge 4 Medium, 100% Acrylic",
    productNumber: "T14118",
    category: "Yarn & Textiles",
    subCategory: "Medium Gauge Yarn",
    stock: 180,
    sold: 75,
    views: 2800,
    price: 10000,
    discount: 5,
    discountedPrice: 9500,
    description:
      "This yarn is ideal for various projects with its premium quality and beautiful colors.",
    vendor: "FiberWorks Inc.",
    vendorLocation: "Austin, USA",
    rating: 3,
    reviews: 25,
    weight: "400g",
    dimensions: "18cm x 9cm x 5cm",
    color: "Pastel Mix",
    material: "100% Acrylic",
    careInstructions: "Hand wash only, lay flat to dry",
    availability: "In Stock",
    imageUrl: "path/to/image3.jpg",
    createdAt: "2024-01-15",
    updatedAt: "2024-02-20",
    tags: ["medium", "quality", "colorful"],
    warranty: "6 months",
    returnPolicy: "Exchange only within 30 days",
    shipping: {
      availableRegions: ["USA", "Canada"],
      estimatedDelivery: "3-4 business days",
      cost: "Free",
    },
    featured: false,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 4,
    productName: "Lion Brand Yarn 217-602 Ferris Wheel Yarn",
    productNumber: "T14119",
    category: "Yarn & Textiles",
    subCategory: "Designer Yarn",
    stock: 400,
    sold: 350,
    views: 6100,
    price: 7550,
    discount: 20,
    discountedPrice: 6040,
    description:
      "An excellent choice for vibrant and durable knit or crochet creations.",
    vendor: "Galaxy Systems",
    vendorLocation: "Chicago, USA",
    rating: 2,
    reviews: 19,
    weight: "600g",
    dimensions: "24cm x 12cm x 8cm",
    color: "Bright Rainbow",
    material: "Polyester Blend",
    careInstructions: "Machine wash warm, do not tumble dry",
    availability: "Pre-order",
    imageUrl: "path/to/image4.jpg",
    createdAt: "2024-02-10",
    updatedAt: "2024-03-01",
    tags: ["vibrant", "designer", "durable"],
    warranty: "No warranty",
    returnPolicy: "Return within 7 days",
    shipping: {
      availableRegions: ["USA", "Canada", "Europe"],
      estimatedDelivery: "2-5 business days",
      cost: "$15",
    },
    featured: true,
    seasonal: true,
    bestseller: true,
  },
  {
    id: 5,
    productName: "Big Cakes Self Striping Yarn - 2 Packs Textile",
    productNumber: "T14116",
    category: "Yarn & Textiles",
    subCategory: "Knitting & Crochet",
    stock: 212,
    sold: 150,
    views: 4100,
    price: 10000,
    discount: 10,
    discountedPrice: 9000,
    description:
      "This roving yarn has everything a knitter and crocheter could want: lightweight, soft, and versatile.",
    vendor: "Galaxy Systems",
    vendorLocation: "New York, USA",
    rating: 4,
    reviews: 45,
    weight: "500g",
    dimensions: "20cm x 10cm x 5cm",
    color: "Multicolor",
    material: "100% Acrylic",
    careInstructions: "Hand wash only, do not bleach",
    availability: "In Stock",
    imageUrl: "path/to/image1.jpg",
    createdAt: "2024-01-10",
    updatedAt: "2024-02-15",
    tags: ["lightweight", "soft", "crochet", "knitting"],
    warranty: "6 months",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["USA", "Canada", "Europe"],
      estimatedDelivery: "3-5 business days",
      cost: "Free",
    },
    featured: true,
    seasonal: false,
    bestseller: true,
  },
  {
    id: 6,
    productName: "Caron Big Cakes Self Striping Yarn ~ 603",
    productNumber: "T14117",
    category: "Yarn & Textiles",
    subCategory: "Crochet Yarn",
    stock: 320,
    sold: 200,
    views: 5300,
    price: 5000,
    discount: 15,
    discountedPrice: 4250,
    description:
      "A lightweight yarn perfect for cozy projects. Great for all knitting and crochet enthusiasts.",
    vendor: "Galaxy Systems",
    vendorLocation: "Los Angeles, USA",
    rating: 3.5,
    reviews: 38,
    weight: "450g",
    dimensions: "22cm x 10cm x 6cm",
    color: "Blue Gradient",
    material: "100% Wool",
    careInstructions: "Machine washable, low heat dry",
    availability: "Limited Stock",
    imageUrl: "path/to/image2.jpg",
    createdAt: "2024-02-01",
    updatedAt: "2024-02-25",
    tags: ["gradient", "lightweight", "soft"],
    warranty: "3 months",
    returnPolicy: "Return within 15 days",
    shipping: {
      availableRegions: ["USA", "Europe"],
      estimatedDelivery: "5-7 business days",
      cost: "$10",
    },
    featured: false,
    seasonal: true,
    bestseller: false,
  },
  {
    id: 7,
    productName: "Bernat POP!, 5oz, Gauge 4 Medium, 100% Acrylic",
    productNumber: "T14118",
    category: "Yarn & Textiles",
    subCategory: "Medium Gauge Yarn",
    stock: 180,
    sold: 75,
    views: 2800,
    price: 10000,
    discount: 5,
    discountedPrice: 9500,
    description:
      "This yarn is ideal for various projects with its premium quality and beautiful colors.",
    vendor: "FiberWorks Inc.",
    vendorLocation: "Austin, USA",
    rating: 3,
    reviews: 25,
    weight: "400g",
    dimensions: "18cm x 9cm x 5cm",
    color: "Pastel Mix",
    material: "100% Acrylic",
    careInstructions: "Hand wash only, lay flat to dry",
    availability: "In Stock",
    imageUrl: "path/to/image3.jpg",
    createdAt: "2024-01-15",
    updatedAt: "2024-02-20",
    tags: ["medium", "quality", "colorful"],
    warranty: "6 months",
    returnPolicy: "Exchange only within 30 days",
    shipping: {
      availableRegions: ["USA", "Canada"],
      estimatedDelivery: "3-4 business days",
      cost: "Free",
    },
    featured: false,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 9,
    productName: "Lion Brand Yarn 217-602 Ferris Wheel Yarn",
    productNumber: "T14119",
    category: "Yarn & Textiles",
    subCategory: "Designer Yarn",
    stock: 400,
    sold: 350,
    views: 6100,
    price: 7550,
    discount: 20,
    discountedPrice: 6040,
    description:
      "An excellent choice for vibrant and durable knit or crochet creations.",
    vendor: "Galaxy Systems",
    vendorLocation: "Chicago, USA",
    rating: 2,
    reviews: 19,
    weight: "600g",
    dimensions: "24cm x 12cm x 8cm",
    color: "Bright Rainbow",
    material: "Polyester Blend",
    careInstructions: "Machine wash warm, do not tumble dry",
    availability: "Pre-order",
    imageUrl: "path/to/image4.jpg",
    createdAt: "2024-02-10",
    updatedAt: "2024-03-01",
    tags: ["vibrant", "designer", "durable"],
    warranty: "No warranty",
    returnPolicy: "Return within 7 days",
    shipping: {
      availableRegions: ["USA", "Canada", "Europe"],
      estimatedDelivery: "2-5 business days",
      cost: "$15",
    },
    featured: true,
    seasonal: true,
    bestseller: true,
  },
]; */

const products = [
  {
    id: 1,
    productName: "Arrow Classic Fit Cotton Shirt",
    productNumber: "AR12345",
    category: "Clothing",
    subCategory: "Shirts",
    stock: 100,
    sold: 80,
    views: 2500,
    price: 3000,
    discount: 15,
    discountedPrice: 2550,
    description:
      "A premium cotton shirt with a classic fit for formal occasions.",
    vendor: "Arrow",
    vendorLocation: "New York, USA",
    rating: 4.5,
    reviews: 35,
    weight: "300g",
    dimensions: "30cm x 20cm x 5cm",
    color: "White",
    material: "100% Cotton",
    careInstructions: "Machine wash, do not bleach",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P01.png",
    createdAt: "2024-03-01",
    updatedAt: "2024-03-10",
    tags: ["formal", "classic", "cotton"],
    warranty: "1 year",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["USA", "India", "Europe"],
      estimatedDelivery: "5-7 business days",
      cost: "Free",
    },
    featured: true,
    seasonal: false,
    bestseller: true,
  },
  {
    id: 2,
    productName: "Calvin Klein Slim Fit Jeans",
    productNumber: "CK98765",
    category: "Clothing",
    subCategory: "Pants",
    stock: 200,
    sold: 150,
    views: 3500,
    price: 5000,
    discount: 10,
    discountedPrice: 4500,
    description:
      "Slim fit jeans made from premium stretch denim for ultimate comfort.",
    vendor: "Calvin Klein",
    vendorLocation: "California, USA",
    rating: 4.3,
    reviews: 60,
    weight: "700g",
    dimensions: "35cm x 25cm x 5cm",
    color: "Blue",
    material: "98% Cotton, 2% Elastane",
    careInstructions: "Machine wash cold, tumble dry low",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P02.png",
    createdAt: "2024-03-05",
    updatedAt: "2024-03-20",
    tags: ["jeans", "stretch", "slim fit"],
    warranty: "6 months",
    returnPolicy: "Return within 15 days",
    shipping: {
      availableRegions: ["USA", "Canada", "Australia"],
      estimatedDelivery: "4-6 business days",
      cost: "Free",
    },
    featured: false,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 3,
    productName: "Flying Machine Denim Jacket",
    productNumber: "FM45678",
    category: "Clothing",
    subCategory: "Jackets",
    stock: 50,
    sold: 30,
    views: 4000,
    price: 6000,
    discount: 20,
    discountedPrice: 4800,
    description:
      "A stylish denim jacket with a rugged look, perfect for casual wear.",
    vendor: "Flying Machine",
    vendorLocation: "Mumbai, India",
    rating: 4.6,
    reviews: 75,
    weight: "800g",
    dimensions: "40cm x 30cm x 7cm",
    color: "Black",
    material: "100% Denim",
    careInstructions: "Hand wash cold, do not tumble dry",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P03.png",
    createdAt: "2024-02-25",
    updatedAt: "2024-03-15",
    tags: ["jacket", "denim", "casual"],
    warranty: "1 year",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["India", "UK", "USA"],
      estimatedDelivery: "6-8 business days",
      cost: "Paid",
    },
    featured: true,
    seasonal: false,
    bestseller: true,
  },
  {
    id: 4,
    productName: "Tommy Hilfiger Casual Polo T-Shirt",
    productNumber: "TH76543",
    category: "Clothing",
    subCategory: "T-Shirts",
    stock: 300,
    sold: 270,
    views: 6000,
    price: 2500,
    discount: 10,
    discountedPrice: 2250,
    description:
      "A breathable polo T-shirt with a classic Tommy Hilfiger design.",
    vendor: "Tommy Hilfiger",
    vendorLocation: "Amsterdam, Netherlands",
    rating: 4.8,
    reviews: 100,
    weight: "200g",
    dimensions: "25cm x 15cm x 2cm",
    color: "Red",
    material: "95% Cotton, 5% Lycra",
    careInstructions: "Machine wash, iron on low heat",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P04.png",
    createdAt: "2024-01-15",
    updatedAt: "2024-02-25",
    tags: ["casual", "polo", "breathable"],
    warranty: "6 months",
    returnPolicy: "Return within 15 days",
    shipping: {
      availableRegions: ["USA", "UK", "India"],
      estimatedDelivery: "3-5 business days",
      cost: "Free",
    },
    featured: false,
    seasonal: false,
    bestseller: true,
  },
  {
    id: 5,
    productName: "Polo Assn. Slim Fit Chinos",
    productNumber: "PA54321",
    category: "Clothing",
    subCategory: "Pants",
    stock: 120,
    sold: 90,
    views: 3200,
    price: 3500,
    discount: 5,
    discountedPrice: 3325,
    description:
      "Slim fit chinos for a modern, stylish look. chinos for a modern.",
    vendor: "Polo Assn.",
    vendorLocation: "London, UK",
    rating: 4.4,
    reviews: 40,
    weight: "600g",
    dimensions: "30cm x 20cm x 4cm",
    color: "Beige",
    material: "100% Cotton",
    careInstructions: "Machine wash cold, do not bleach",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P05.png",
    createdAt: "2024-03-01",
    updatedAt: "2024-03-18",
    tags: ["chinos", "slim fit", "cotton"],
    warranty: "1 year",
    returnPolicy: "Return within 20 days",
    shipping: {
      availableRegions: ["UK", "USA", "Europe"],
      estimatedDelivery: "4-6 business days",
      cost: "Free",
    },
    featured: true,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 6,
    productName: "Arrow Formal Blazer",
    productNumber: "AR22334",
    category: "Clothing",
    subCategory: "Jackets",
    stock: 80,
    sold: 60,
    views: 2400,
    price: 12000,
    discount: 20,
    discountedPrice: 9600,
    description: "A premium formal blazer for business and formal occasions.",
    vendor: "Arrow",
    vendorLocation: "New York, USA",
    rating: 4.7,
    reviews: 50,
    weight: "1.5kg",
    dimensions: "40cm x 30cm x 10cm",
    color: "Navy Blue",
    material: "Polyester Blend",
    careInstructions: "Dry clean only",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P06.png",
    createdAt: "2024-02-01",
    updatedAt: "2024-03-01",
    tags: ["formal", "blazer", "business"],
    warranty: "1 year",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["USA", "Canada", "Europe"],
      estimatedDelivery: "5-7 business days",
      cost: "Free",
    },
    featured: true,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 7,
    productName: "Calvin Kleint Leather Jacke",
    productNumber: "CK33445",
    category: "Clothing",
    subCategory: "Jackets",
    stock: 40,
    sold: 25,
    views: 4500,
    price: 15000,
    discount: 15,
    discountedPrice: 12750,
    description:
      "Stylish leather jacket perfect for casual outings and travel.",
    vendor: "Calvin Klein",
    vendorLocation: "California, USA",
    rating: 4.8,
    reviews: 80,
    weight: "2kg",
    dimensions: "45cm x 35cm x 15cm",
    color: "Black",
    material: "Genuine Leather",
    careInstructions: "Dry clean only",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P07.png",
    createdAt: "2024-02-10",
    updatedAt: "2024-03-05",
    tags: ["jacket", "leather", "stylish"],
    warranty: "2 years",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["USA", "Europe", "Australia"],
      estimatedDelivery: "4-6 business days",
      cost: "Paid",
    },
    featured: false,
    seasonal: true,
    bestseller: true,
  },
  {
    id: 8,
    productName: "Flying Machine Graphic T-Shirt",
    productNumber: "FM44321",
    category: "Clothing",
    subCategory: "T-Shirts",
    stock: 150,
    sold: 120,
    views: 3100,
    price: 1800,
    discount: 10,
    discountedPrice: 1620,
    description: "Graphic T-shirt with bold prints for a trendy casual look.",
    vendor: "Flying Machine",
    vendorLocation: "Mumbai, India",
    rating: 4.2,
    reviews: 40,
    weight: "250g",
    dimensions: "30cm x 20cm x 3cm",
    color: "White",
    material: "100% Cotton",
    careInstructions: "Machine wash, do not bleach",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P08.png",
    createdAt: "2024-01-20",
    updatedAt: "2024-02-25",
    tags: ["graphic", "T-shirt", "casual"],
    warranty: "6 months",
    returnPolicy: "Return within 15 days",
    shipping: {
      availableRegions: ["India", "USA", "UK"],
      estimatedDelivery: "5-7 business days",
      cost: "Free",
    },
    featured: true,
    seasonal: false,
    bestseller: false,
  },
  {
    id: 9,
    productName: "Tommy Hilfiger Winter Jacket",
    productNumber: "TH56432",
    category: "Clothing",
    subCategory: "Jackets",
    stock: 70,
    sold: 50,
    views: 5000,
    price: 18000,
    discount: 20,
    discountedPrice: 14400,
    description:
      "Warm and comfortable winter jacket for extreme cold conditions.",
    vendor: "Tommy Hilfiger",
    vendorLocation: "Amsterdam, Netherlands",
    rating: 4.9,
    reviews: 95,
    weight: "2.5kg",
    dimensions: "50cm x 40cm x 15cm",
    color: "Grey",
    material: "Polyester Blend",
    careInstructions: "Dry clean only",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P09.png",
    createdAt: "2024-01-25",
    updatedAt: "2024-02-20",
    tags: ["winter", "jacket", "warm"],
    warranty: "1 year",
    returnPolicy: "Return within 30 days",
    shipping: {
      availableRegions: ["USA", "Canada", "Europe"],
      estimatedDelivery: "6-8 business days",
      cost: "Free",
    },
    featured: true,
    seasonal: true,
    bestseller: true,
  },
  {
    id: 10,
    productName: "Polo Assn. Sports Cap",
    productNumber: "PA67543",
    category: "Accessories",
    subCategory: "Caps",
    stock: 300,
    sold: 250,
    views: 2200,
    price: 1200,
    discount: 5,
    discountedPrice: 1140,
    description: "Lightweight and durable sports cap for outdoor activities.",
    vendor: "Polo Assn.",
    vendorLocation: "London, UK",
    rating: 4.1,
    reviews: 30,
    weight: "150g",
    dimensions: "20cm x 15cm x 5cm",
    color: "Navy Blue",
    material: "Polyester",
    careInstructions: "Hand wash only",
    availability: "In Stock",
    imageUrl: "assets/img/product_img/P010.png",
    createdAt: "2024-03-01",
    updatedAt: "2024-03-10",
    tags: ["sports", "cap", "durable"],
    warranty: "3 months",
    returnPolicy: "Return within 15 days",
    shipping: {
      availableRegions: ["UK", "USA", "Europe"],
      estimatedDelivery: "3-5 business days",
      cost: "Free",
    },
    featured: false,
    seasonal: false,
    bestseller: true,
  },
];

let CategorydropdownOptions = [
  {
    id: "1",
    name: "Jackets(132)",
  },
  {
    id: "2",
    name: "Pantes(40)",
  },
  {
    id: "3",
    name: "Cap(60)",
  },
];
let StatusdropdownOptions = [
  {
    id: "1",
    name: "All status",
  },
  {
    id: "2",
    name: "single status",
  },
  {
    id: "3",
    name: "double status",
  },
];
let PricedropdownOptions = [
  {
    id: "1",
    name: "$100-500",
  },
  {
    id: "2",
    name: "$500-2000",
  },
  {
    id: "3",
    name: "$2000-7000",
  },
];
let StoredropdownOptions = [
  {
    id: "1",
    name: "All store",
  },
  {
    id: "2",
    name: "Adiddas",
  },
  {
    id: "3",
    name: "Alian soly",
  },
];
const CatalogMaster = () => {
  // const navigate = useNavigate();
  localStorage.setItem("menu", "ProductCatalog");
  localStorage.setItem("submenu", "catalogMaster");
  const [isCardVisible1, setCardVisible1] = useState(true);

  const toggleCardVisibility1 = () => {
    setCardVisible1(!isCardVisible1);
  };
  //   let menuData = useSelector((state) => state?.menu); // User Details from Store
  // let menuData= [
  //   {
  //       id:'1',
  //       hrefLink:"/supplier-report-details",
  //       icon:"bi bi-list-ul",
  //       name:"PO Report",
  //       description:"",
  //       color:""
  //   },
  //   {
  //       id:'2',
  //       hrefLink:"/supplier-report-details",
  //       icon:"bi-file-earmark-plus",
  //       name:"PR Report",
  //       description:"",
  //       color:""
  //   },
  //   {
  //       id:'3',
  //       hrefLink:"/supplier-report-details",
  //       icon:"bi-truck",
  //       name:"ASN Report",
  //       description:"",
  //       color:""
  //   },
  //   {
  //       id:'4',
  //       hrefLink:"/supplier-report-details",
  //       icon:"bi-file-earmark-text",
  //       name:"Invoice Report",
  //       description:"",
  //       color:""
  //   },
  // ]
  //   console.log("menudata",menuData.length)
  // const handleClick = (hrefLink) => {
  //   navigate(hrefLink);
  // };

  const [getToggle, setToggle] = useState(true);
  const toggleRowProduct = () => {
    setToggle(true);
  };
  const toggleColProduct = () => {
    setToggle(false);
  };
  const [modalShowDetailProduct, setModalShowDetailProduct] = useState(false);
  const showProductDetail = () => {
    setModalShowDetailProduct(true);
  };
  const hideModal = () => {
    setModalShowDetailProduct(false);
  };
  return (
    <main className="dashboard main homePage" id="main">
      <Layout />
      <Row className="homeScreen justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <h2 className="mainTitle">Catalog Master</h2>
            <p className="subText">Catalog Master</p>
            <hr />
            <div className="accordion mb-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible1
                            ? "bi-chevron-down"
                            : "bi-chevron-right"
                        }`}
                        onClick={toggleCardVisibility1}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">AF</span>
                    </Col>
                    <Col xs={8} md={9} lg={9}>
                      <span className="headerTitle">Advanced Filter</span>
                      <span className="subHeaderTitle">Advanced Filter</span>
                    </Col>
                  </Row>
                </h2>

                <div
                  className={`accordion-collapse collapse show ${
                    isCardVisible1 ? "visible" : ""
                  }`}
                >
                  <div className="accordion-body">
                    <Row className="pt-2 pb-4 d-flex align-items-center">
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding mt-4"
                      >
                        <SearchField
                          name="searchKey"
                          // value={getCommonSearchTxt}
                          // onChange={(event) =>
                          //   setCommonSearchTxt(event.target.value)
                          // }
                          // onClick={() => handleSearchFuncApi()}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Show"
                          placeholder="Please select the Category"
                          data={CategorydropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                          //   setValue={formInputs.country}
                          // getvalue={setDropdownData}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Sort by"
                          placeholder="Please select the Category"
                          data={CategorydropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                          //   setValue={formInputs.country}
                          // getvalue={setDropdownData}
                        />
                      </Col>
                      {/*   <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding mt-4"
                      >
                        <button className="btnTable d-flex justify-content-center ">
                          <i className="bi bi-funnel me-2"></i> Filter
                        </button>
                      </Col> */}
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Category"
                          placeholder="Please select the Category"
                          data={CategorydropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                          //   setValue={formInputs.country}
                          // getvalue={setDropdownData}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Status"
                          placeholder="Please select the status"
                          data={StatusdropdownOptions}
                          id="category"
                          iconClassName="dropdownIcon"
                          name="category"
                          code="categorycode"
                          description="countryDesc"
                          //   setValue={formInputs.country}
                          // getvalue={setDropdownData}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Price"
                          placeholder="Please select the Price"
                          data={PricedropdownOptions}
                          id="price"
                          iconClassName="dropdownIcon"
                          name="price"
                          code="pricecode"
                          description="countryDesc"
                          //   setValue={formInputs.country}
                          // getvalue={setDropdownData}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        lg={3}
                        className="commTopButtonRightLeftPadding"
                      >
                        <ComboboxField
                          label="Store"
                          placeholder="Please select the status"
                          data={StoredropdownOptions}
                          id="store"
                          iconClassName="dropdownIcon"
                          name="store"
                          code="storecode"
                          description="countryDesc"
                          //   setValue={formInputs.country}
                          // getvalue={setDropdownData}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={{ span: 2, offset: 8 }}
                        lg={{ span: 2, offset: 8 }}
                        className="commTopButtonRightLeftPadding"
                      >
                        <button className="btnTable">Search</button>
                      </Col>
                      <Col
                        xs={12}
                        md={2}
                        lg={2}
                        className="commTopButtonRightLeftPadding"
                      >
                        <button className="btnTable">Refresh</button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <Row className="d-flex justify-content-center justify-content-sm-start">
              <Col
                xs={2}
                md={{ span: 1, offset: 10 }}
                lg={{ span: 1, offset: 10 }}
                className="commTopButtonRightLeftPadding d-none d-md-block"
              >
                <button className="btn" onClick={toggleRowProduct}>
                  <i class="bi bi-list-task fs-4"></i>
                </button>
              </Col>
              <Col
                xs={2}
                md={1}
                lg={1}
                className="commTopButtonRightLeftPadding"
              >
                <button className="btn" onClick={toggleColProduct}>
                  <i class="bi bi-grid-3x3-gap-fill fs-4"></i>
                </button>
              </Col>
              {getToggle ? (
                <>
                  {products?.map((items) => (
                    <Col md={6} lg={12} className={`mb-3`}>
                      <Card
                        className="d-flex flex-row align-items-center p-2"
                        style={{ width: "100%" }}
                        onClick={showProductDetail}
                      >
                        {/* Image Section */}
                        <Col xs={4} lg={{ span: 2, offset: 1 }} className="p-0">
                          <img
                            src={items.imageUrl}
                            alt="Product"
                            style={{
                              width: "160px",
                              height: "160px",
                              objectFit: "cover",
                            }}
                          />
                        </Col>
                        {/* Content Section */}
                        <Col
                          xs={4}
                          lg={{ span: 3, offset: 0 }}
                          style={{ fontSize: "18px" }}
                        >
                          <Card.Body>
                            <p className="m-0 fw-bold">{items.productName}</p>

                            <p className="m-0">
                              <span className="fw-bold">Rating :</span>{" "}
                              {items.rating}/5
                            </p>
                            <p className="m-0">
                              <spaan className="fw-bold">Product ID : </spaan>
                              {items.productNumber}
                            </p>
                            <p className="m-0">{items.vendor}</p>
                            <p className="m-0">{items.description}</p>
                          </Card.Body>
                        </Col>
                        <Col
                          xs={4}
                          md={{ span: 2, offset: 2 }}
                          lg={{ span: 2, offset: 2 }}
                          className="d-flex flex-column"
                        >
                          <Card.Title>$ {items.price}</Card.Title>
                          <button className="btn btn-primary mt-2 d-flex justi align-items-center">
                            <p className="m-0 me-4">Add to Cart</p>
                            <i class="bi bi-cart-plus fs-5"></i>
                          </button>
                        </Col>
                      </Card>
                    </Col>
                  ))}
                </>
              ) : (
                <>
                  {products?.map((items) => (
                    <Col xs={12} md={6} lg={3} className="mb-3">
                      <Card
                        className="d-flex flex-column align-items-center p-2"
                        style={{ width: "90%" }}
                        onClick={showProductDetail}
                      >
                        {/* Image Section */}
                        <img
                          src={items.imageUrl}
                          alt="Product"
                          style={{
                            width: "160px",
                            height: "160px",
                            objectFit: "cover",
                          }}
                        />
                        {/* Content Section */}
                        <Card.Body className="text-start">
                          <p className="m-0 fw-bold">{items.productName}</p>
                          <Card.Title>$ {items.price}</Card.Title>
                          <p className="m-0">
                            <span className="fw-bold">Rating :</span>{" "}
                            {items.rating}/5
                          </p>
                          <p className="m-0">
                            <spaan className="fw-bold">Product ID : </spaan>
                            {items.productNumber}
                          </p>

                          <p className="m-0">{items.vendor}</p>
                          <p className="m-0">
                            {items.description.slice(0, 60)}...
                          </p>
                          <button className="btn btn-primary mt-2 d-flex align-items-center">
                            <p className="m-0 me-4">Add to Cart</p>
                            <i class="bi bi-cart-plus fs-5"></i>
                          </button>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <CatalogProductDetailModal
        show={modalShowDetailProduct}
        onHide={() => hideModal()}
      />
    </main>
  );
};

export default CatalogMaster;

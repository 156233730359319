
/* APPLICATION ROUTE CONSTANTS */

module.exports = Object.freeze({
  
    // Status
    SELF_REGISTRATION: '/self-registration',

    /* Routers Link supplier_app */
    APP_HOME: '/applications',
    HOME: '/home',
    HOME_INTRO:'/supplier-intro',
    DASHBOARD: '/dashboard',
    INBOX: '/inbox',
    OUTBOX: '/outbox',
    RESET_PASSWORD: '/reset-password',

    // SUPPILER
    SUPPILER_REGISTRATION: '/supplier-registration',
    SUPPILER_LIST: '/supplier-list',
    SUPPILER_MASS_UPLOAD: '/supplier-mass-upload',
    SUPPLIER_MASTER_CREATE: '/supplier-master-creation',
    SUPPLIER_DETAILED_PAGE_CREATE:'/supplier-details',
    SUPPLIER_DETAILED_PAGE_WORKFLOW:'/supplier-details-workflow',

    // SOURCING 
    SOURCING_PURCHASE_REQUISITION_LIST: '/purchase-requisition',
    SOURCING_PURCHASE_REQUISITION_CREATE: '/create-purchase-requisition',
    SOURCING_PURCHASE_REQUISITION_DETAILED_PAGE: '/purchase-requisition-details',

    SOURCING_VENDOR_REQUEST_FOR_QUOTATION: '/supplier-request-for-quotation',
    SOURCING_VENDOR_QUOTATION_LIST: '/supplier-quotation',
    SOURCING_VENDOR_RFQ_DETAILS: '/supplier-rfq-details',  
    SOURCING_CREATE_QUOTATION:'/create-quotation',
    SOURCING_QUOTATION_DETAILS:'/quotation-details',
    
    SOURCING_BUYER_REQUEST_FOR_QUOTATION: '/buyer-request-for-quotation',
    SOURCING_BUYER_RFQ_DETAILS: '/buyer-rfq-details',    
    SOURCING_BUYER_QUOTATION_LIST: '/buyer-quotation',
    SOURCING_CREATE_RFQ: '/create-request-for-quotation',
    SOURCING_CREATE_MANUAL_RFQ: '/create-manual-rfq',

    SOURCING_COMMERCIAL_EVALUATION: '/commercial-evaluation',
    SOURCING_TECHNICAL_EVALUATION: '/technical-evaluation',
    SOURCING_PURCHASE_ORDER_CREATION: '/create-purchase-orders',

    SOURCING_TECH_EVALUATION_REQUEST_FOR_QUOTATION: '/tech-request-for-quotation',
    SOURCING_TECH_EVALUATION_DETAILS: '/tech-rfq-details',

    //PO COLLABRATION    
    POCOLLABRATION_SERVICE_PURCHASE_ORDER_LIST:'/service-purchase-order',
    POCOLLABRATION_PURCHASE_ORDER_LIST:'/purchase-order',
    POCOLLABRATION_PURCHASE_ORDER_CREATION:'/create-purchase-order',
    POCOLLABRATION_PURCHASE_ORDER_DETAIL_PAGE:'/purchase-order-detail',
    POCOLLABRATION_GOODS_RECEIVED_NOTE:'/goods-received-note',
    POCOLLABRATION_SERVICE_ENTRY_SHEET:'/service-entry-sheet',
    POCOLLABRATION_INVOICE_CREATION:'/create-invoice',
    POCOLLABRATION_INVOICE_CREATION_SES:'/create-invoice-ses',
    POCOLLABRATION_INVOICE:'/invoice',
    POCOLLABRATION_INVOICE_DETIAILS:'/invoice-details',
    POCOLLABRATION_PAYMENT_LIST:'/payments',
    POCOLLABRATION_ASN:'/advanced-shipping-notice',    
    POCOLLABRATION_ASN_DETAIL_PAGE:'/asn-detail',
    POCOLLABRATION_ASN_CREATION:'/create-asn',
    POCOLLABRATION_SA_ASN_CREATION:'/create-sa-asn',
    POCOLLABRATION_GRN_CREATION:'/create-grn',
    POCOLLABRATION_GRN_DETAILS:'/goods-received-note-details',
    POCOLLABRATION_SES_CREATION:'/create-entrysheet',
    POCOLLABRATION_SCHEDULING_AGREEMENT:'/scheduling-agreement',
    POCOLLABRATION_SERVICE_ENTRY_DETAILS:'/service-entry-sheet-details',
    
    
    //SAP REPROCESS
    SAPREPROCESS_GRN_REPROCESS:'/grn-reprocess',
    SAPREPROCESS_SES_REPROCESS:'/ses-reprocess',
    SAPREPROCESS_INVOICE_REPROCESS:'/invoice-reprocess',

    
    //USER DELEGATION
    USER_DELEGATION:'/user-deletion',

    //SINGLE SOURCING 
    SINGLESOURCING_SINGLE_SOURCING_CREATION:'/single-source-create',
    SINGLESOURCING_SINGLE_SOURCING_LIST:'/single-source-list',
    SINGLESOURCING_SINGLE_SOURCING_DETAILS:'/single-source-details',

    //AdvancePayment
    ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_CREATION:'/advance-payment-request-create',
    ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_LIST:'/advance-payment-request-list',
    ADVANCE_PAYMENT_ADVANCE_PAYMENT_REQUEST_DETAILS:'/advance-payment-request-details',

    //USER MANUAL
    USER_MANUAL_BUYER_MANUAL:'/buyer-manual',
    USER_MANUAL_SUPPLIER_MANUAL:'/supplier-manual',

    //GHG - GREEN HOUS GAS 
    GHG_SCOPE1_CREATION:'/ghg-scope1-create',

    //ADMIN
    ADMIN_MANAGE_USER_LIST:'/manage-user',
    ADMIN_MANAGE_ADD_USER:'/manage-add-user',
    ADMIN_MANAGE_EDIT_USER:'/manage-edit-user',
    ADMIN_MANAGE_USER_LIST_DETAILS:'/manage-user-detials',
    ADMIN_MANAGE_ROLE:'/manage-role',

    //AUCTION
    AUCTION_REVERSE_AUCTION_LIST:'/reverse-auction-list',
    AUCTION_REVERSE_AUCTION_CREATION:'/create-auction',
    AUCTION_REVERSE_AUCTION_HISTORY:'/reverse-auction-history',
    AUCTION_FORWARD_AUCTION_LIST:'/forward-auction-list',
    AUCTION_FORWARD_AUCTION_HISTORY:'/forward-auction-history',

    //CONTRACT MANAGEMENT  
    CONTRACTMANAGEMENT_CONTRACT_GENERATE_LIST:'/contract-generate-list',
    CONTRACTMANAGEMENT_CONTRACT_REVIEW_LIST:'/contract-review-list',
    CONTRACTMANAGEMENT_CONTRACT_DRAFT_TO_FINAL_LIST:'/contract-draft-final-list',
    CONTRACTMANAGEMENT_MONITORING_LIST:'/contract-monitoring-list',

    //GATEPASS
    GATEPASS_GATEPASS_CREATE:'/gatepass-create',
    GATEPASS_GATEPASS_LIST:'/gatepass-list',
    GATEPASS_GATEPASS_DETAIL_PAGE:'/gatepass-detail',
  
    //DELEGATION
    DELEGATION_TASK:'/task-delegation',
    DELEGATION_USER:'/user-delegation',

    //REPORTS
    SUPPLIER_REPORTS:'/supplier-reports',
    SUPPLIER_REPORT_DETAILS:'/supplier-report-details',

    /* catalog */
    MATERIAL_CATALOG_LIST:'/material-catalog',
    MATERIAL_CATALOG_MASTER:'/material-catalog-master',

    /* Material Catalog  Shopwise */
    MATERIAL_CATALOG_SHOPWISE:'/material-catalog-shopwise',

    //CATEGORY
    CATEGORY_LIST:'/category-list',
    CREATE_CATEGORY:'/create-category',
    CATEGORY_DETAILS:'/category-detials',

    //INVOICE DOWN PAYMENT
    INVOICE_DOWN_PAYMENT_LIST:'/invoice-down-payment-list',
    INVOICE_DOWN_PAYMENT_CREATE:'/invoice-down-payment-create',

  
    /* Routers Link */
    NOT_FOUND: "pages-faq.html",
    LOGIN: "/login",
    REGISTER: '/registeration',
    CALLBACK: '/callback',

    
    SETTINGS: '/settings',
    PROFILE: '/profile',
    COMPANY: '/company',
    PAYMENT: '/payment',
    USERS: '/users',
    LOCATION: '/location',
    UPCOMING_SHIFT: '/upcoming-shifts',
    ONGOING_SHIFT: '/todays-shifts',
    PAST_SHIFT: '/past-shifts',
    EVENT_DETAILS: '/event-details',
    EDIT_TIMESHEET: '/edit-timesheet',
    MANAGE_SHIFT: '/manage-shift',
    BOOKSHIFT: '/bookshift',


    /* Positions Master Data */

    ROW_PER_PAGE : [10, 50, 100, 300, 500],
    ROW_REPORT_PER_PAGE : [100, 200, 300, 400, 500],



})


import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { ComboboxField } from '../../../Components/formElements/ComboboxField';
import Layout from "../../Layout/Layout";
import LineChart from "./Charts/LineChart";
import AreaChart from "./Charts/AreaChart";
import SuppliersColumnChart from "./Charts/SuppliersColumnChart";
import POColumnChart from "./Charts/POColumnChart";
import BarChart from "./Charts/BarChart";
import PieChart from "./Charts/PieChart";
import DonutChart from "./Charts/DonutChart";
import { useNavigate } from "react-router-dom";

//Serivce
import ItemService from "../../services/supplier_appCommonService";
import LoaderSmall from "../../Components/LoaderSmall";
import {
  formatNumberShort,
  formatToTwoDigits,
  getCurrentDate,
} from "../../common/Validations";
import DatePickerComponent from "./Charts/FilterComponent/DatePickerComponent";
import SupplierConstants from "../../common/SupplierConstants";

const Dashboard = () => {
  localStorage.setItem("menu", "Dashboard");
  localStorage.setItem("submenu", "-");
  const navigate = useNavigate();

  const navigateToMainPage = (page) => {
    if(page === 'supplier'){
      navigate(SupplierConstants.SUPPILER_LIST);
    }else if(page === 'users'){
      navigate(SupplierConstants.ADMIN_MANAGE_USER_LIST);
    }else if(page === 'pr'){
      navigate(SupplierConstants.SOURCING_PURCHASE_REQUISITION_LIST);
    }else if(page === 'po'){
      navigate(SupplierConstants.POCOLLABRATION_PURCHASE_ORDER_LIST);
    }
  
  };

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = String(today.getMonth() + 1).padStart(2, "0");

  const [filterShow, setFilterShow] = useState(false);

  const [totalUserCount, setTotalUserCount] = useState("");
  const [totalUserCountStatus, setTotalUserCountStatus] = useState(false);

  const [supplierCount, setSupplierCount] = useState("");
  const [supplierCountStatus, setSupplierCountStatus] = useState(false);

  const [poCount, setPoCount] = useState("");
  const [poCountStatus, setPoCountStatus] = useState(false);

  const [prCount, setPrCount] = useState("");
  const [prCountStatus, setPrCountStatus] = useState(false);

  const [supplierStatusColChart, setSupplierStatusColChart] = useState("");
  const [supplierStatusColChartStatus, setSupplierStatusColChartStatus] =
    useState(false);

  const [poStatusColChart, setPOStatusColChart] = useState("");
  const [poStatusColChartStatus, setPOStatusColChartStatus] = useState(false);

  // PR COUNT
  const loadPRCount = async () => {
    setPrCountStatus(true);
    let obj = {};

    await ItemService.prCount(obj).then((item) => {
      // console.log(">> Details res!", item);
      if (item?.status === 200) {
        setPrCount(item.data[0]);
      } else {
        toast.error(item?.message || "Please try again for Total PO Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setPrCountStatus(false);
  };

  // PO COUNT
  const loadPOCount = async () => {
    setPoCountStatus(true);
    let obj = {};

    await ItemService.poCount(obj).then((item) => {
      // console.log(">> Details res!", item);
      if (item?.status === 200) {
        setPoCount(item.data[0]);
      } else {
        toast.error(item?.message || "Please try again for Total PO Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setPoCountStatus(false);
  };

  // Supplier Count
  const loadSupplierCount = async () => {
    setSupplierCountStatus(true);
    let obj = {};

    await ItemService.supplierCount(obj).then((item) => {
      // console.log(">> Details res!", item);
      if (item?.status === 200) {
        setSupplierCount(item.data[0]);
      } else {
        toast.error(item?.message || "Please try again for Total PO Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setSupplierCountStatus(false);
  };

  // User Count
  const loadTotalUserCount = async () => {
    setTotalUserCountStatus(true);
    let obj = {};

    await ItemService.totalUserCount(obj).then((item) => {
      // console.log(">> Details res!", item);
      if (item?.status === 200) {
        setTotalUserCount(item.data[0]);
      } else {
        toast.error(item?.message || "Please try again for Total User Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setTotalUserCountStatus(false);
  };

  // Suppliers Chart
  const suppliersChart = async (filterData) => {
    setSupplierStatusColChartStatus(true);
    filterData.module = "supplier";
    filterData.chartType = "column";

    await ItemService.dashboard(filterData).then((item) => {
      // console.log(">> dashboard res!", item);
      if (item?.status === 200) {
        item.data.module = "supplier";
        setSupplierStatusColChart(item.data);
      } else {
        toast.error(item?.message || "Please try again for Total User Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setSupplierStatusColChartStatus(false);
  };

  // PO Chart
  const poChart = async (filterData) => {
    setPOStatusColChartStatus(true);
    filterData.module = "po";
    filterData.chartType = "pie";

    await ItemService.dashboard(filterData).then((item) => {
      // console.log(">> dashboard res!", item);
      if (item?.status === 200) {
        item.data.module = "po";
        setPOStatusColChart(item.data);
      } else {
        toast.error(item?.message || "Please try again for Total User Count", {
          duration: 4000,
          position: "top-right",
        });
      }
    });
    setPOStatusColChartStatus(false);
  };

  // On Filtered Data
  const handleFilterData = async (filter) => {
    // console.log(">> filter", filter);

    await suppliersChart(filter);
    await poChart(filter);
  };

  useEffect(() => {
    let defaultFilter = {
      action: "Monthly",
      month: currentMonth,
      year: currentYear,
    };

    loadPRCount();
    loadPOCount();
    loadSupplierCount();
    loadTotalUserCount();

    suppliersChart(defaultFilter);
    poChart(defaultFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="dashboard main" id="main">
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner timesheet">
            <Row>
              <Col xs={10} md={10}>
                <h2 className="mainTitle">
                  Dashboard -
                  <span className="bg-table-status bg-table-documentNumber-status headerStatusDetailedPage">
                    {getCurrentDate()}
                  </span>
                </h2>
                <p className="subText my-0">Dashboard detailed page</p>
              </Col>
              <Col xs={2} md={2}>
                <span
                  className="filterIcon"
                  onClick={() => setFilterShow(!filterShow)}
                >
                  <i class="bi bi-filter"></i>
                </span>
              </Col>
            </Row>
            <hr />

            {filterShow && (
              <DatePickerComponent
                getfiltereddata={handleFilterData}
                cancelAction={() => setFilterShow(!filterShow)}
              />
            )}

            <section className="section">
              <div className="row">
                <div className="col-lg-3 commTopButtonRightLeftPadding">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">
                        Procurement
                        {/* <span>| Today</span> */}
                      </h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-cart"></i>
                        </div>
                        <div className="ps-3">
                          <LoaderSmall isLoading={prCountStatus} />
                          <h6>
                            {!prCountStatus
                              ? formatNumberShort(prCount?.value || 0)
                              : ""}
                          </h6>
                          {/* <span className="text-success small pt-1 fw-bold">
                            12%
                          </span>{" "} */}
                          <span className="text-muted small pt-2 ps-1">
                            Value
                          </span>
                          <span className="text-muted small pt-2 ps-1" onClick={()=>navigateToMainPage('pr')}>
                            <i className="bi bi-arrow-right-square-fill dashboard-navigate-icon"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 commTopButtonRightLeftPadding">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      <h5 className="card-title">
                        Revenue
                        {/* <span>| This Month</span> */}
                      </h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-currency-dollar"></i>
                        </div>
                        <div className="ps-3">
                          <LoaderSmall isLoading={poCountStatus} />
                          <h6>
                            {!poCountStatus
                              ? formatNumberShort(poCount?.value || 0)
                              : ""}
                          </h6>
                          {/* <span className="text-success small pt-1 fw-bold">
                            12%
                          </span>{" "} */}
                          <span className="text-muted small pt-2 ps-1">
                            Revenue Generated
                          </span>
                          <span className="text-muted small pt-2 ps-1"  onClick={()=>navigateToMainPage('po')}>
                            <i className="bi bi-arrow-right-square-fill dashboard-navigate-icon"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 commTopButtonRightLeftPadding">
                  <div className="card info-card customers-card">
                    <div className="card-body">
                      <h5 className="card-title">
                        Supplier
                        {/* <span>| This Year</span> */}
                      </h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-people"></i>
                        </div>
                        <div className="ps-3">
                          <LoaderSmall isLoading={supplierCountStatus} />
                          <h6>
                            {!supplierCountStatus
                              ? formatToTwoDigits(supplierCount?.count || 0)
                              : ""}
                          </h6>
                          {/* <span className="text-success small pt-1 fw-bold">
                            12%
                          </span>{" "} */}
                          <span className="text-muted small pt-2 ps-1">
                            Total Count
                          </span>
                          <span className="text-muted small pt-2 ps-1"  onClick={()=>navigateToMainPage('supplier')}>
                            <i className="bi bi-arrow-right-square-fill dashboard-navigate-icon"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 commTopButtonRightLeftPadding">
                  <div className="card info-card customers-card">
                    <div className="card-body">
                      <h5 className="card-title">
                        Total Users
                        {/* <span>| This Year</span> */}
                      </h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-people"></i>
                        </div>
                        <div className="ps-3">
                          <LoaderSmall isLoading={totalUserCountStatus} />
                          <h6>
                            {!totalUserCountStatus
                              ? formatToTwoDigits(totalUserCount?.count || 0)
                              : ""}
                          </h6>
                          {/* <span className="text-success small pt-1 fw-bold">
                            12%
                          </span>{" "} */}
                          <span className="text-muted small pt-2 ps-1">
                            Total Count
                          </span>
                          <span className="text-muted small pt-2 ps-1"  onClick={()=>navigateToMainPage('users')}>
                            <i className="bi bi-arrow-right-square-fill dashboard-navigate-icon"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                {/* Suppliers */}
                <div className="col-lg-12 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Suppliers</h5>

                      {/* <div id="columnChart"></div> */}
                      <LoaderSmall isLoading={poStatusColChartStatus} />
                      <h6>
                        {!poStatusColChartStatus ? (
                          <SuppliersColumnChart
                            key="supplier"
                            chartdata={supplierStatusColChart}
                          />
                        ) : (
                          ""
                        )}
                      </h6>
                    </div>
                  </div>
                </div>

                {/* PO */}
                <div className="col-lg-12 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Purchase Order</h5>

                      {/* <div id="columnChart"></div> */}
                      <LoaderSmall isLoading={supplierStatusColChartStatus} />
                      <h6>
                        {!supplierStatusColChartStatus ? (
                          <POColumnChart
                            key="po"
                            chartdata={poStatusColChart}
                          />
                        ) : (
                          ""
                        )}
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Procurement</h5>

                      <div id="lineChart"></div>
                      <LineChart />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Revenue</h5>

                      <div id="areaChart"></div>
                      <AreaChart />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Country</h5>

                      <div id="barChart"></div>
                      <BarChart />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Team</h5>

                      <div id="pieChart"></div>
                      <PieChart />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 commTopButtonRightLeftPadding">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Team</h5>

                      <div id="donutChart"></div>
                      <DonutChart />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default Dashboard;

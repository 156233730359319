import React, { useState, useEffect } from "react";
// import toast from 'react-hot-toast';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from "react-redux"; //useDispatch

//Serivce
import ItemService from "../../../../services/supplier_appCommonService";

//Components
import Layout from "../../../../Layout/Layout";
import CreateSupplierMasterModal from "../../../../Components/Modal/supplier_app/CreateSupplierMasterModal";
import Constants from "../../../../common/SupplierConstants";
import CommonItemLevelDatatableModal from "../../../../Components/Modal/supplier_app/CommonItemLevelDatatableModal";
import {
  addsupplierID,
  getSupplierList,
  workflow,
} from "../../../../redux/feature/supplier_app/supplierSlice";
import CommonFillter from "../../../../Components/Filter/Supplier/SupplierCommonFillter";
import ShowStatus from "../../../../Components/Modal/supplier_app/Workflow/ShowStatus";
import { formatDateTime } from "../../../../common/Validations";
import { SearchField } from "../../../../Components/formElements/SearchField";
import SuccessModal from "../../../../Components/Modal/supplier_app/SuccessModal";
import CreateInviteSupplierModal from "../../../../Components/Modal/supplier_app/CreateInviteSupplierModal";
import ConfirmSendInvitationModel from "../../../../Components/Modal/supplier_app/ConfirmSendInvitationModel";
import ConfirmationLoader from "../../../../Components/ConfirmationLoader";
import BroadcastModel from "../../../../Components/Modal/supplier_app/BroadcastModel";
import SupplierDeactivateModel from "../../../../Components/Modal/supplier_app/SupplierDeactivateModel";
import StatusCountForList from "../../../../Components/StatusCountForList";
import { TableStatusField } from "../../../../Components/formElements/TableStatusField";

const SupplierList = () => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const navigate = useNavigate();
  localStorage.setItem("menu", "Supplier");
  localStorage.setItem("submenu", "Supplier List");
  let supplierData = useSelector((state) => state?.supplier); // User Details from Store
  let supplierTable = useSelector((state) => state?.supplier.pagination); // User Details from Store
  let config = useSelector((state) => state?.common?.data?.suppliers); // User Details from Store
  // Format the price above to USD using the locale, style, and currency.
  // let USDollar = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });

  const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [totalRows, setTotalRows] = useState(10);
  const [isCardVisible, setCardVisible] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowStatus, setModalShowStatus] = useState(false);
  const [sendDataModal, setSendDataModal] = useState("");
  const [modelTitle, setModelTitle] = useState("");

  const [getItemLevelData, setItemLevelData] = useState([]);
  const [getItemLevelColumns, setItemLevelColumns] = useState([]);
  const [getModalTitle, setModalTitle] = useState("");
  const [itemLevelModalShow, setItemLevelModalShow] = useState(false);

  const [getAccountGroup, setAccountGroup] = useState([]);
  const [getNewPerPage, setNewPerPage] = useState(10);
  const [getCommonSearchTxt, setCommonSearchTxt] = useState("");

  const [isPageLoading, setIsPageLoading] = useState(false); // Loading state
  const [selectedRows, setSelectedRows] = useState([]);

  const [statusCountData, setStatusCountData] = useState([]);

  /* pop success modal */
  const [getShowSuccessModal, setShowSuccessModal] = useState(false);
  const successModalData = {
    title: "Success",
    successMsg: "",
  };
  const [getsucceModalData, setsucceModalData] = useState(successModalData);
  const [getShowSupplierInviteModal, setShowSupplierInviteModal] =
    useState(false);
  const [getShowSendInvitationModal, setShowSendInvitationModal] =
    useState(false);
  const [getShowhideBroadcastModel, setShowhideBroadcastModel] =
    useState(false);
  const [getShowSupplierDeactivateModel, setShowSupplierDeactivateModel] =
    useState(false);

  const onSuccessmodel = () => setShowSuccessModal(true);
  const hideSuccessModal = () => setShowSuccessModal(false);
  const hideSupplierInviteModal = () => setShowSupplierInviteModal(false);
  const hideSendInvitationModal = () => setShowSendInvitationModal(false);
  const hideBroadcastModel = () => setShowhideBroadcastModel(false);
  const hideSupplierDeactivateModel = () =>
    setShowSupplierDeactivateModel(false);
  const handleRowSelect = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };
  const sendInvitation = async () => {
    if (selectedRows.length === 0) {
      // alert("Please select at least one row.")
      successModalData.title = "Error";
      successModalData.successMsg = "Please select at least one row.";
      setsucceModalData(successModalData);
      onSuccessmodel();
      return;
    }
    try {
      setIsPageLoading(true); // Start loading
      const emailIds = selectedRows.map((row) => row.email);
      let obj = {
        email: emailIds,
      };
      // console.log("obj", obj);
      await ItemService.sendInvitation(obj).then((item) => {
        // console.log(">> item!", item);
        if (item?.status === 200) {
          successModalData.successMsg = item?.message;
          setsucceModalData(successModalData);
          onSuccessmodel();
        } else {
        }
      });
    } catch (error) {
      console.log("Failed to send invitaion:", error);
    } finally {
      setIsPageLoading(false); // End loading
    }
  };
  const handleBroadcast = async (payload) => {
    try {
      setIsPageLoading(true); // Start loading
      const emailIds = selectedRows.map((row) => row.email);
      let obj = {
        email: emailIds,
        broadcastRemark: payload?.broadcastRemark,
      };
      // console.log("obj", obj);
      await ItemService.supplierBroadcast(obj).then((item) => {
        // console.log(">> item!", item);
        if (item?.status === 200) {
          successModalData.successMsg = item?.message;
          setsucceModalData(successModalData);
          onSuccessmodel();
        } else {
        }
      });
    } catch (error) {
      console.log("Failed to Broadcast:", error);
    } finally {
      setIsPageLoading(false); // End loading
    }
  };
  const handleDeactivateSupplier = async (payload) => {
    try {
      setIsPageLoading(true); // Start loading
      const supplierIds = selectedRows.map((row) => row.userId);
      let obj = {
        supplierIds: supplierIds,
        remark: payload?.remark,
      };
      // console.log("obj", obj);
      await ItemService.supplierDeactivate(obj).then((item) => {
        // console.log(">> item!", item);
        if (item?.status === 200) {
          successModalData.successMsg = item?.message;
          setsucceModalData(successModalData);
          onSuccessmodel();
        } else {
        }
      });
    } catch (error) {
      console.log("Failed to De-activate:", error);
    } finally {
      setIsPageLoading(false); // End loading
    }
  };
  const inviteSupplier = async (formData) => {
    setIsPageLoading(true); // Start loading
    try {
      await ItemService.inviteSupplier(formData).then((item) => {
        if (item?.status === 200) {
          console.log("item", item);
          successModalData.successMsg = item?.message;
          setShowSuccessModal(true);
          setsucceModalData(successModalData);
          onSuccessmodel();
        } else {
          console.error("Failed to send invitation:");
        }
      });
    } catch (error) {
      console.log("Failed to send invitaion:", error);
    } finally {
      setIsPageLoading(false); // End loading
    }
  };
  const detailedPage = (supplierID, workflowActionLogs) => {
    dispatch(addsupplierID(supplierID));
    dispatch(workflow(workflowActionLogs));
    navigate(Constants.SUPPLIER_DETAILED_PAGE_CREATE);
  };

  const itemLevelHideModal = () => {
    setItemLevelModalShow(false);
  };
  // OnClick on categoryItem  Data
  const categoryItemShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(categoryItemColInfo);
    setModalTitle("Category Details");
    setItemLevelModalShow(true);
  };

  // OnClick on Certificate document Data
  const certificateDocumentShowModal = (itemLevelData) => {
    setItemLevelData(itemLevelData);
    setItemLevelColumns(certificateDocumentColInfo);
    setModalTitle("Certificate document Details");
    setItemLevelModalShow(true);
  };

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  /* Modal Function */
  const createRFQ = () => {
    setModalShow(true);
  };

  const hideModal = () => {
    setModalShow(false);
  };

  const loadInitialSuuplierData = async () => {
    setData(supplierData?.data);
  };

  const handlePageChange = (page) => {
    let obj = {
      page: page,
      per_page: getNewPerPage,
    };

    dispatch(getSupplierList(obj));
    setData(supplierData?.data);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let obj = {
      page: page,
      per_page: newPerPage,
    };
    setNewPerPage(newPerPage);
    dispatch(getSupplierList(obj));
    setData(supplierData?.data);
  };

  // Common Search Function on button
  const handleSearchFuncApi = async () => {
    let obj = {
      page: 1,
      per_page: 10,
      searchTerm: getCommonSearchTxt,
    };
    dispatch(getSupplierList(obj));
  };

  const loadTotalStatusCount = async () => {
    let obj = {
      queryName: "getSupplier_StatusChartList",
      chartCategory: "status",
    };
    try {
      await ItemService.generateChartQuery(obj).then((item) => {
        // console.log(">> item.data", item?.data);

        if (item?.status === 200) {
          setStatusCountData(item?.data);
        } else {
          console.error("Failed to Status Count");
        }
      });
    } catch (error) {
      console.log("Failed to Status Count", error);
    }
  };

  useEffect(() => {
    loadInitialSuuplierData(); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierData]);

  //on page load
  useEffect(() => {
    dispatch(getSupplierList({}));
    getMasterData();

    //load status count
    loadTotalStatusCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: "Collaboration",
      cell: (row) => (
        <>
          <span>
            <i className="bi bi-git iconTable"></i>
          </span>
          <span>
            <i className="bi bi-collection-play iconTable"></i>
          </span>
          <span>
            <i className="bi bi-stack iconTable"></i>
          </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Profile",
      width: "80px",
      cell: (row) => (
        <img
          src="assets/img/profile-img.png"
          alt="Profile"
          className="rounded-circle profileImageCss"
          style={{ width: "40px" }}
        />
      ),
    },
    {
      name: "Type",
      selector: (row) => row.supplierType || "--",
      sortable: true,
      cell: (row) => (
        <>
          {row.supplierTypeCode === "S01" ? (
            <span className="bg-table-status bg-table-info-status">
              {row.supplierTypeDesc}
            </span>
          ) : row.supplierTypeCode === "S02" ? (
            <span className="bg-table-status bg-table-new-status">
              {row.supplierTypeDesc}
            </span>
          ) : (
            row.supplierTypeDesc
          )}
        </>
      ),
      width: "150px",
    },
    {
      name: "Supplier ID",
      selector: (row) => row.AssociateNumber || "--",
      sortable: true,
      cell: (row) => (
        <button
          className="btnInfo"
          onClick={() =>
            detailedPage(row.AssociateNumber, row.workflowActionLogs)
          }
        >
          {row.AssociateNumber || "-"}{" "}
        </button>
      ),
      width: "120px",
    },
    {
      name: "Supplier Name",
      cell: (row) => (
        <>
          <span className="m-0 p-0 pt-1 fw-bold">{row.organisationName}</span>
        </>
      ),
      selector: (row) => row.organisationName,
      sortable: true,
      width: "150px",
    },
    {
      name: "Workflow log",
      selector: (row) => row.workflowActionLogs,
      cell: (row) => (
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center iconTable"
        >
          <img
            src="assets/img/newIcons/workflow.png"
            alt="attachments"
            style={{ width: "35px" }}
            className="mt-1"
            onClick={() => showStatusInfo(row.workflowActionLogs)}
          />
        </div>
      ),
      sortable: true,
      width: "132px",
    },
    {
      name: "Version",
      cell: (row) => (
        <>
          <span>{row.version || "--"}</span>
        </>
      ),
      selector: (row) => row.version,
      sortable: true,
      width: "100px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <>
          <TableStatusField status={row.status} />
        </>
      ),
      width: "170px",
    },
    {
      name: "SAP Number",
      selector: (row) => row.sapAssociateNumber || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Contact Person",
      cell: (row) => (
        <>
          <span>{row.contactfirstName || "--"}</span>
        </>
      ),
      selector: (row) => row.contactfirstName || "--",
      sortable: true,
      width: "150px",
    },
    {
      name: "Contact Number",
      cell: (row) => (
        <>
          <span>{row.contactNumber || "--"}</span>
        </>
      ),
      selector: (row) => row.contactNumber,
      sortable: true,
      width: "150px",
    },
    {
      name: "Contact Email",
      cell: (row) => (
        <>
          <span>{row.email || "--"}</span>
        </>
      ),
      selector: (row) => row.email,
      sortable: true,
      width: "220px",
    },
    {
      name: "Initiator",
      selector: (row) => row.initiator || "--",
      sortable: true,
      width: "100px",
    },
    // {
    //   name: "Supplier 360",
    //   cell: (row) => <button className="btnInfo">{"View"} </button>,

    //   width: "150px",
    // },
    // {
    //   name: "Financial Health",
    //   selector: (row) => row.sap_rfq || "--",
    //   sortable: true,
    //   width: "150px",
    // },
    // {
    //   name: "Evaluation Score",
    //   selector: (row) => row.sap_rfq || "--",
    //   sortable: true,
    //   width: "150px",
    // },
    // {
    //   name: "ESG score",
    //   selector: (row) => row.sap_rfq || "--",
    //   sortable: true,
    //   width: "100px",
    // },
    // {
    //   name: "ICV Score %",
    //   selector: (row) => row.totalScore || "--",
    //   sortable: true,
    //   width: "150px",
    // },
    {
      name: "Category",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => categoryItemShowModal(row.vendorCategory)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.vendorCategory?.length || 0}{" "}
          </span>
        </>
      ),
      width: "120px",
    },
    {
      name: "Certificates ",
      cell: (row) => (
        <>
          <span
            style={{ marginLeft: "12px" }}
            onClick={() => certificateDocumentShowModal(row.certifications)}
          >
            <i className="bi bi-calendar2-week iconTable"></i>
          </span>
          <span className="suppliersIcon">
            {" "}
            {row.certifications?.length > 0
              ? row.certifications?.length
              : "0"}{" "}
          </span>
        </>
      ),
      width: "150px",
    },
    {
      name: "Created Date",
      selector: (row) => formatDateTime(row.createdAt),
      sortable: true,
      width: "160px",
    },
    {
      name: "Company",
      cell: (row) => (
        <>
          <span> {row.companyCode} </span>
        </>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Block Reason",
      selector: (row) => row.rfqTechFocalPerson,
      sortable: true,
      width: "130px",
    },
    {
      name: "SAP Remark",
      selector: (row) => row.rfqTechTargetDate,
      sortable: true,
      width: "140px",
    },
    {
      name: "SAP Error Msg",
      selector: (row) => row.extensionCount,
      sortable: true,
      width: "140px",
    },

    {
      name: "SAP Reprocess",
      cell: (row) => (
        <>
          <button className="btnTable">Reprocess {row.publish}</button>
        </>
      ),
      width: "140px",
    },
    // {
    //   name: "Send Invite",
    //   cell: (row) => (
    //     <>
    //       <button className="btnTable">Invite {row.publish}</button>
    //     </>
    //   ),
    //   width: "140px",
    // },
    // {
    //   name: "Re-Invite",
    //   cell: (row) => (
    //     <>
    //       <button className="btnTable">Re-Invite {row.publish}</button>
    //     </>
    //   ),
    //   width: "140px",
    // },
    // {
    //   name: "Edit Supplier",
    //   cell: (row) => (
    //     <>
    //       <button className="btnTable">Edit {row.publish}</button>
    //     </>
    //   ),
    //   width: "140px",
    // },
    // {
    //   name: "Deactivate",
    //   cell: (row) => (
    //     <>
    //       <button className="btnTable">Deactivate {row.publish}</button>
    //     </>
    //   ),
    //   width: "140px",
    // },
  ];

  const categoryItemColInfo = [
    {
      name: "Category Code",
      cell: (row) => <span>{row.categoryCode} </span>,
      width: "180px",
    },
    {
      name: "Sub Category Code",
      cell: (row) => <span>{row.subCategoryCode} </span>,
      width: "180px",
    },
    {
      name: "Vendor Category",
      cell: (row) => <span>{row.vendorCategoryText} </span>,
      width: "180px",
    },
    {
      name: "Vendor Sub Category",
      cell: (row) => <span>{row.vendorSubCategoryLongText} </span>,
      width: "180px",
    },
  ];

  const certificateDocumentColInfo = [
    {
      name: "Certificate Type",
      cell: (row) => <span>{row.documentType} </span>,
      width: "180px",
    },
    {
      name: "Certificate Number",
      cell: (row) => <span>{row.documentNumber} </span>,
      width: "180px",
    },
    {
      name: "Expiry Date",
      cell: (row) => <span>{row.expirationDate} </span>,
      width: "180px",
    },
    {
      name: "Issueing Authority/Accreditation",
      cell: (row) => <span>{row.issuingAuthority} </span>,
      width: "180px",
    },

    {
      name: "Certificate Attachement",
      cell: (row) => <span>{row.certificateAttachement} </span>,
      width: "180px",
    },
    // {
    //   name: "Vendor Block",
    //   cell: (row) => <span>{row.subCategoryCode} </span>,
    //   width: "180px",
    // },
    // {
    //   name: "Remainder",
    //   cell: (row) => <span>{row.subCategoryCode} </span>,
    //   width: "180px",
    // },
  ];

  const getMasterData = async () => {
    let obj = {
      codeType: "accountGroup",
    };
    await ItemService.getMasterData(obj).then((item) => {
      // console.log('>> item!',item);
      if (item?.status === 200) {
        setAccountGroup(item?.data);
      } else {
        setAccountGroup([]);
        console.log(">> Error: Loading getMasterData");
      }
    });
  };

  /* Modal Function */
  const showStatusInfo = (rowData) => {
    setModalShowStatus(true);
    setSendDataModal(rowData);
    setModelTitle("Status Information");
  };

  const hideModalStatus = () => {
    setModalShowStatus(false);
  };

  return (
    <main className="dashboard main" id="main">
      {/* Spinner Overlay */}
      <ConfirmationLoader isPageLoading={isPageLoading} />
      <Layout />
      <Row className="justify-center subContent">
        <Col xs={12} md={12} className="rightSide">
          <div className="rightSideInner PageCommonCSS">
            <Row>
              <Col xs={12} md={9} lg={9}>
                <h2 className="mainTitle">Supplier List</h2>
                <p className="subText">Supplier Directory and Overview </p>
              </Col>
              <Col xs={12} md={3} lg={3} className="mt-3">
                <SearchField
                  name="searchKey"
                  value={getCommonSearchTxt}
                  onChange={(event) => setCommonSearchTxt(event.target.value)}
                  onClick={() => handleSearchFuncApi()}
                />
              </Col>
            </Row>
            <hr />

            {/* Page level indiviual status count data */}
            {statusCountData.length > 0 && (
              <StatusCountForList statuscountdata={statusCountData} />
            )}

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <Row className="accordion-button">
                    <Col xs={1} md={1} lg={1} className="text-left arrowBtn">
                      <i
                        className={`bi ${
                          isCardVisible ? "bi-chevron-right" : "bi-chevron-down"
                        }`}
                        onClick={toggleCardVisibility}
                      ></i>
                    </Col>
                    <Col xs={1} md={1} lg={1} className="text-center logoBtn">
                      <span className="iconLogo">SL</span>
                    </Col>
                    <Col xs={8} md={3} lg={3}>
                      <span className="headerTitle">Supplier List</span>
                      <span className="subHeaderTitle">
                        View of Suppliers and Status{" "}
                      </span>
                    </Col>

                    <Col xs={12} md={7} lg={7} className="">
                      <Row>
                        {config?.supplierRequestBtn && (
                          <Col xs={2} md={2} lg={2} className="">
                            <button
                              className="btnTable"
                              onClick={() => createRFQ()}
                            >
                              <i className="bi bi-plus-lg"></i> Supplier Request
                            </button>
                          </Col>
                        )}

                        {config?.sendInviteBtn && (
                          <Col xs={2} md={2} lg={2} className="">
                            <button
                              className="btnTable"
                              onClick={() => setShowSendInvitationModal(true)}
                            >
                              <i className="bi bi-cursor"></i> Send Invitation
                            </button>
                          </Col>
                        )}

                        {config?.sendInviteBtn && (
                          <Col xs={2} md={2} lg={2} className="">
                            <button
                              className="btnTable"
                              onClick={() => setShowSupplierInviteModal(true)}
                            >
                              <i className="bi bi-cursor"></i> Invite Supplier
                            </button>
                          </Col>
                        )}
                        {config?.broadcastBtn && (
                          <Col
                            xs={2}
                            md={2}
                            lg={2}
                            className="btnSpaceInMobile"
                          >
                            <button
                              className="btnTable"
                              onClick={() => setShowhideBroadcastModel(true)}
                            >
                              <i className="bi bi-envelope"></i> Broadcast
                            </button>
                          </Col>
                        )}

                        {config?.deactivateListBtn && (
                          <Col
                            xs={2}
                            md={2}
                            lg={2}
                            className="btnSpaceInMobile"
                          >
                            <button
                              className="btnTable"
                              onClick={() =>
                                setShowSupplierDeactivateModel(true)
                              }
                            >
                              {/* <i className="bi bi-justify"></i> */}
                              Deactivate List
                            </button>
                          </Col>
                        )}

                        {config?.categoryListBtn && (
                          <Col
                            xs={2}
                            md={2}
                            lg={2}
                            className="btnSpaceInMobile"
                          >
                            <button
                              className="btnTable"
                              onClick={() => {
                                alert("asd");
                              }}
                            >
                              {/* <i className="bi bi-justify"></i>  */}
                              Category List
                            </button>
                          </Col>
                        )}
                        {config?.filter && (
                          <Col xs={2} md={1} lg={1} className="">
                            <CommonFillter />
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </h2>

                <div
                  // id="acc"
                  className={`accordion-collapse collapse show ${
                    isCardVisible ? "visible" : ""
                  }`}
                  // aria-labelledby="headingOne"
                  // data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        <DataTable
                          title=""
                          columns={columns}
                          data={data}
                          progressPending={supplierData?.status}
                          paginationRowsPerPageOptions={Constants.ROW_PER_PAGE}
                          pagination
                          paginationServer
                          paginationTotalRows={supplierTable.total}
                          onChangeRowsPerPage={handlePerRowsChange}
                          onChangePage={handlePageChange}
                          onSelectedRowsChange={handleRowSelect}
                          selectableRows
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <CreateSupplierMasterModal
        show={modalShow}
        onHide={() => hideModal()}
        dropdownData={getAccountGroup}
      />

      <CommonItemLevelDatatableModal
        show={itemLevelModalShow}
        onHide={() => itemLevelHideModal()}
        columns={getItemLevelColumns}
        data={getItemLevelData}
        title={getModalTitle}
      />

      <ShowStatus
        show={modalShowStatus}
        onHide={() => hideModalStatus()}
        rowdatamodal={sendDataModal}
        modeltitle={modelTitle}
      />
      <SuccessModal
        show={getShowSuccessModal}
        onHide={() => hideSuccessModal()}
        successmodaldata={getsucceModalData}
      />
      <CreateInviteSupplierModal
        show={getShowSupplierInviteModal}
        onHide={hideSupplierInviteModal}
        onSubmit={inviteSupplier}
      />
      <ConfirmSendInvitationModel
        show={getShowSendInvitationModal}
        onHide={hideSendInvitationModal}
        onSubmit={sendInvitation}
        selectedRows={selectedRows}
      />
      <BroadcastModel
        show={getShowhideBroadcastModel}
        onHide={hideBroadcastModel}
        onSubmit={handleBroadcast}
        selectedRows={selectedRows}
      />
      <SupplierDeactivateModel
        show={getShowSupplierDeactivateModel}
        onHide={hideSupplierDeactivateModel}
        onSubmit={handleDeactivateSupplier}
        selectedRows={selectedRows}
      />
    </main>
  );
};

export default SupplierList;

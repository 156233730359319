import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ItemService from "../../services/supplier_appCommonService";
import ItemServiceCustomer from "../../services/customer_appCommonService";

const initialState = {
    data:{},
    supplierReport:{
        data:[],
        status:true,
        pagination:{
            page:"",
            per_page:"",
            total:""
        }
    },
    customerReport:{
        data:[],
        status:true,
        pagination:{
            page:"",
            per_page:"",
            total:""
        }
    }
};

export const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers: {
        reportInfo(state,action){            
            state.data = action.payload
        },
    },
    extraReducers: (builder) =>{
        builder
        .addCase(loadSupplierReport.pending, (state,action)=>{
            state.supplierReport.status = true;
        })
        .addCase(loadSupplierReport.fulfilled, (state,action)=>{
            state.supplierReport.data = action.payload.data;
            state.supplierReport.pagination.page = action.payload.page;
            state.supplierReport.pagination.per_page = action.payload.per_page;
            state.supplierReport.pagination.total = action.payload.total;
            state.supplierReport.status = false;
        })
        .addCase(loadCustomerReport.pending, (state,action)=>{
            state.customerReport.status = true;
        })
        .addCase(loadCustomerReport.fulfilled, (state,action)=>{
            state.customerReport.data = action.payload.data;
            state.customerReport.pagination.page = action.payload.page;
            state.customerReport.pagination.per_page = action.payload.per_page;
            state.customerReport.pagination.total = action.payload.total;
            state.customerReport.status = false;
        })
    }
  });

export const { reportInfo } = reportSlice.actions;
export default reportSlice.reducer;

export const loadSupplierReport = createAsyncThunk('loadSupplierReport', async (param) =>{
    console.log(">> loaded loadSupplierReport");
    const res = await ItemService.generateReportQuery(param);
    return res;
})

export const loadCustomerReport = createAsyncThunk('loadCustomerReport', async (param) =>{
    console.log(">> loaded loadCustomerReport");
    const res = await ItemServiceCustomer.generateReportQuery(param);
    return res;
})
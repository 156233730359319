/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ItemService from "../../../services/customer_appCommonService";

const initialState = {
  status: true,
  inquiryID: "",
  inquiry: {
    data: [],
    status: true,
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
    completedInquiryList: [],
  },
  quotationID: "",
  quotation: {
    data: [],
    status: true,
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },
  createPOQuotation: [],
  poID: "",
  po: {
    data: [],
    status: true,
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },
  salesOrderID: "",
  salesOrder: {
    data: [],
    status: true,
    selectedSalesOrderDetails:{},
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },
  deliveryID: "",
  delivery: {
    data: [],
    status: true,
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },
  invoiceID: "",
  invoice: {
    data: [],
    status: true,
    pagination: {
      page: "",
      per_page: "",
      total: "",
    },
  },

  createDelivery: [],
};

export const customerSlice = createSlice({
  name: "preSales",
  initialState,
  reducers: {
    addInquiryID(state, action) {
      state.inquiryID = action.payload;
    },
    addQuotationID(state, action) {
      state.quotationID = action.payload;
    },
    addPoID(state, action) {
      state.poID = action.payload;
    },
    addSalesOrderID(state, action) {
      state.salesOrderID = action.payload;
    },
    addSalesOrderSelectedDetails(state, action) {
      state.salesOrder.selectedSalesOrderDetails = action.payload;
    },
    workflow(state, action) {
      state.customerWorkflow = action.payload;
    },
    addCompletedInquiryList(state, action) {
      state.inquiry.completedInquiryList = action.payload;
    },
    addCreateDelivery(state, action) {
      state.createDelivery = action.payload;
    },
    addCreatePOQuotation(state, action) {
      state.createPOQuotation = action.payload;
    },
    addDeliveryID(state, action) {
      state.deliveryID = action.payload;
    },
    addInvoiceID(state, action) {
      state.invoiceID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInquiryList.pending, (state, action) => {
        state.inquiry.status = true;
      })
      .addCase(getInquiryList.fulfilled, (state, action) => {
        state.inquiry.data = action.payload.data;
        state.inquiry.pagination.page = action.payload.page;
        state.inquiry.pagination.per_page = action.payload.per_page;
        state.inquiry.pagination.total = action.payload.total;
        state.inquiry.status = false;
      })
      .addCase(getQuotationList.pending, (state, action) => {
        state.quotation.status = true;
      })
      .addCase(getQuotationList.fulfilled, (state, action) => {
        state.quotation.data = action.payload.data;
        state.quotation.pagination.page = action.payload.page;
        state.quotation.pagination.per_page = action.payload.per_page;
        state.quotation.pagination.total = action.payload.total;
        state.quotation.status = false;
      })
      .addCase(getCustomerPOList.pending, (state, action) => {
        state.po.status = true;
      })
      .addCase(getCustomerPOList.fulfilled, (state, action) => {
        state.po.data = action.payload.data;
        state.po.pagination.page = action.payload.page;
        state.po.pagination.per_page = action.payload.per_page;
        state.po.pagination.total = action.payload.total;
        state.po.status = false;
      })
      .addCase(getSalesOrderList.pending, (state, action) => {
        state.salesOrder.status = true;
      })
      .addCase(getSalesOrderList.fulfilled, (state, action) => {
        state.salesOrder.data = action.payload.data;
        state.salesOrder.pagination.page = action.payload.page;
        state.salesOrder.pagination.per_page = action.payload.per_page;
        state.salesOrder.pagination.total = action.payload.total;
        state.salesOrder.status = false;
      })
      .addCase(getDeliveryList.pending, (state, action) => {
        state.delivery.status = true;
      })
      .addCase(getDeliveryList.fulfilled, (state, action) => {
        state.delivery.data = action.payload.data;
        state.delivery.pagination.page = action.payload.page;
        state.delivery.pagination.per_page = action.payload.per_page;
        state.delivery.pagination.total = action.payload.total;
        state.delivery.status = false;
      })
      .addCase(getCustomerInvoiceList.pending, (state, action) => {
        state.invoice.status = true;
      })
      .addCase(getCustomerInvoiceList.fulfilled, (state, action) => {
        state.invoice.data = action.payload.data;
        state.invoice.pagination.page = action.payload.page;
        state.invoice.pagination.per_page = action.payload.per_page;
        state.invoice.pagination.total = action.payload.total;
        state.invoice.status = false;
      });
  },
});

export const {
  addInquiryID,
  addQuotationID,
  workflow,
  addPoID,
  addSalesOrderID,
  addSalesOrderSelectedDetails,
  addCompletedInquiryList,
  addCreateDelivery,
  addCreatePOQuotation,
  addDeliveryID,
  addInvoiceID
} = customerSlice.actions;
export default customerSlice.reducer;

export const getInquiryList = createAsyncThunk(
  "getInquiryList",
  async (param) => {
    console.log(">> loaded getInquiryList");
    const res = await ItemService.generateCustomerQuery(param);
    return res;
  }
);

export const getQuotationList = createAsyncThunk(
  "getQuotationList",
  async (param) => {
    console.log(">> loaded getQuotationList");
    const res = await ItemService.generateCustomerQuery(param);
    return res;
  }
);

export const getCustomerPOList = createAsyncThunk(
  "getCustomerPOList",
  async (param) => {
    console.log(">> loaded getCustomerPOList");
    const res = await ItemService.generateCustomerQuery(param);
    return res;
  }
);

export const getSalesOrderList = createAsyncThunk(
  "getSalesOrderList",
  async (param) => {
    console.log(">> loaded getSalesOrderList");
    const res = await ItemService.generateCustomerQuery(param);
    return res;
  }
);

export const getDeliveryList = createAsyncThunk(
  "getDeliveryList",
  async (param) => {
    console.log(">> loaded getDeliveryList");
    const res = await ItemService.generateCustomerQuery(param);
    return res;
  }
);

export const getCustomerInvoiceList = createAsyncThunk(
  "getCustomerInvoiceList",
  async (param) => {
    console.log(">> loaded getCustomerInvoiceList");
    const res = await ItemService.generateCustomerQuery(param);
    return res;
  }
);
